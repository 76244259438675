import { Contract } from '@app/modules/contract/models/contract';
import { ContractService } from '@app/modules/contract/services/contract.service';
import { EmployeeDialogComponent } from '@app/modules/county/components/employee-dialog.component';
import { County } from '@app/modules/county/models/county';
import { CountyEmployee } from '@app/shared/models/county-employee';
import { CountyService } from '@app/modules/county/services/county.service';
import { CountyEmployeeService, CountyEmployees } from '@app/modules/county/services/county-employee.service';
import { WithUserId } from '@app/shared/models/user';
import { DialogService } from '@app/shared/services/dialog.service';
import { ErrorService } from '@app/shared/services/error.service';
import * as globals from '@app/shared/globals';
import * as util from '@app/shared/util';
import * as _ from 'lodash';

import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { TitleService } from '@app/shared/services/title.service';
import { UserService } from '@app/shared/services/user.service';
import { AddressForm } from '@app/shared/forms/address.form';
import { MatSort } from '@angular/material/sort';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-county-page',
  templateUrl: './county-page.component.html',
  styleUrls: ['./county-page.component.scss']
})
export class CountyPageComponent implements OnInit, OnDestroy {
  Object = Object;
  countyId: string;
  isAdmin = false;
  countyForm: UntypedFormGroup;
  county: County;
  showCountySpinner = true;
  footerFixed: boolean;
  submitted: boolean;

  contractsTable: MatTableDataSource<Contract>;
  contractsTableColumns = ['select', 'name'];
  @ViewChild('contractsPaginator', { static: false })
  contractsPaginator: MatPaginator;

  employeesTable: MatTableDataSource<CountyEmployee>;
  employeesTableColumns = ['fullName', 'emailAddress', 'roles', 'edit'];
  @ViewChild('employeesPaginator', { static: false })
  employeesPaginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) employeesTableSort: MatSort;
  employeeColumnSearch = false;
  employeesTableFilters = {
    general: '',
    fullName: '',
    emailAddress: '',
    roles: { county_admin: true, county_order_editor: true, county_order_viewer: true, county_depot_viewer: true,county_viewer: true } ,
    rolesString: ''
  };

  rolesTranslations = {
    county_admin: 'Beheerder',
    county_order_editor: 'Consulent',
    county_order_viewer: 'Backoffice',
    county_depot_viewer: 'Depotbeheerder',
    county_viewer: 'Inzien'
  };

  private originalContractId: string;
  private contractsSubscription: Subscription;
  private employeesSubscription: Subscription;

  private lastInnerWidth: number;
  private resizeBreakpoint = 767;
  private contractItemsQty = 0;
  private pipeRun = true;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    public dialog: MatDialog,
    private contractService: ContractService,
    private countyService: CountyService,
    private countyEmployeeService: CountyEmployeeService,
    private dialogService: DialogService,
    private errorService: ErrorService,
    private userService: UserService,
    private formBuilder: UntypedFormBuilder,
    private titleService: TitleService
  ) {
    this.buildForm(); // Done in the constructor so the form is available when the HTML is rendered
  }

  async ngOnInit() {
    try {
      const promises = [];
      this.countyId = this.route.snapshot.paramMap.get('countyId');

      const user = await this.userService.getUser();
      this.isAdmin = user.isAdmin;

      // For administrators load the contracts, otherwise disable the form
      if (this.isAdmin) {
        promises.push(this.loadContracts());
      } else {
        this.countyForm.disable();
      }

      // In case of an existing county load the county and the employees
      if (this.countyId) {
        this.submitted = true;
        promises.push(this.loadCounty());
        promises.push(this.loadEmployees());
      } else {
        this.titleService.setTitle('Nieuwe gemeente');
      }

      await Promise.all(promises);
      this.showCountySpinner = false;
    } catch (error) {
      this.errorService.alertAndLog(error, 'Het ophalen van de gemeente is mislukt.').onClosed(async () => {
        this.router.navigate(['counties']);
      });
    }

    window.addEventListener('scroll', () => {
      if (this.submitted) {
        const windowScrollTop = window.scrollY;
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;
        const documentHeightWithoutWindowHeight = documentHeight - windowHeight;
        this.scrollEventListener(windowScrollTop, windowHeight, documentHeight, documentHeightWithoutWindowHeight);
      }
    });

    window.addEventListener('resize', () => {
      this.resizeEventListener();
    });
  }

  ngOnDestroy() {
    if (this.contractsSubscription) {
      this.contractsSubscription.unsubscribe();
    }

    if (this.employeesSubscription) {
      this.employeesSubscription.unsubscribe();
    }
  }

  private async loadContracts(): Promise<void> {
    this.contractsSubscription = this.contractService.contracts$
      .pipe(takeWhile(() => this.pipeRun))
      .subscribe({
      next: (contractsMap) => {
        const contracts = util.mapOjectToArray(contractsMap, 'contractId').sort((lhs, rhs) => {
          return lhs.name.localeCompare(rhs.name);
        });

        // Stop reload when all items will be loaded
        if (contracts.length > this.contractItemsQty) {
          this.contractItemsQty = contracts.length;
        } else {
          this.pipeRun = false
        }

        this.contractsTable = new MatTableDataSource(contracts);
        this.contractsTable.paginator = this.contractsPaginator;
      },
      error: (error) => {
        this.errorService.alertAndLog(error, 'Het ophalen van de gemeente is mislukt.').onClosed(async () => {
          this.router.navigate(['counties']);
        });
        return error;
      }
    });
  }

  private async loadCounty(): Promise<void> {
    this.county = await this.countyService.getCounty(this.countyId);
    this.countyForm.patchValue(this.county);
    this.originalContractId = this.countyForm.value.contractId;
    this.titleService.setTitle(`Gemeente ${this.county.name}`);
  }

  private async loadEmployees(): Promise<void> {
    this.employeesSubscription = this.countyEmployeeService.getCountyEmployees$(this.countyId).subscribe({
      next: (employeesMap: CountyEmployees) => {
        const employeesArray = util.mapOjectToArray(employeesMap, 'userId');
        employeesArray.forEach((employee) => {
          employee.fullName =
            employee.name.initials + ' ' + (employee.name.prefix ? employee.name.prefix + ' ' : '') + employee.name.lastName;
          employee.emailAddress = employee.contactDetails.emailAddress;
          employee.rolesString = this.translateAndJoinRoles(employee.roles.counties[this.countyId]);
        });

        this.employeesTable = new MatTableDataSource(employeesArray);
        this.employeesTable.sort = this.employeesTableSort;
        this.employeesTable.paginator = this.employeesPaginator;

        this.employeesTable.filterPredicate = (data: any, filterType: string) => {
          let filteredData;
          // clear filters
          if (filterType === 'clear') {
            filteredData = data;
          }

          // use general filter
          else if (filterType === 'general') {
            filteredData = JSON.stringify(data).toLowerCase().includes(this.employeesTableFilters.general.toLowerCase());
          }

          // use table header filters
          else {
            // check for input fields (fullName, emailAddress, roles)
            filteredData =
              data.fullName.toLowerCase().includes(this.employeesTableFilters.fullName.toLowerCase()) &&
              data.emailAddress.toLowerCase().includes(this.employeesTableFilters.emailAddress.toLowerCase()) &&
              data.rolesString.toLowerCase().includes(this.employeesTableFilters.rolesString.toLowerCase());

            if (filteredData) {
              // check for role checkboxes
              let roleFound = false;
              for (const key of Object.keys(this.employeesTableFilters.roles)) {
                if (this.employeesTableFilters.roles[key] && data.rolesString.includes(this.rolesTranslations[key])) {
                  roleFound = true;
                  break;
                }
              }

              if (!roleFound) {
                return null;
              }
            }
          }

          return filteredData;
        };
      },
      error: (error) => {
        this.errorService.alertAndLog(error, 'Het ophalen van de gemeente is mislukt.').onClosed(async () => {
          this.router.navigate(['counties']);
        });
        return error;
      }
    });
  }

  filterContracts(filterValue: string) {
    this.contractsTable.filter = filterValue.trim().toLowerCase();
  }

  submitForm() {
    this.submitted = true;
    if (this.countyForm.invalid) {
      this.scrollToFirstError();
      util.markFormGroupTouched(this.countyForm);
      this.dialogService.openErrorAlert(
        'Invoer ongeldig',
        'De invoer is ongeldig. ' + 'Pas de gegevens aan en probeer de gemeente opnieuw aan te maken of te wijzigen.'
      );
    } else {
      if (this.countyId) {
        this.updateCounty();
      } else {
        this.createCounty();
      }
    }
  }

  async createCounty(): Promise<void> {
    this.showCountySpinner = true;
    let countyId;
    try {
      // @todo create a County instance here to pass to the service
      let countyToSave: County = {
        ...this.countyForm.value,
        notifyEmail: '',
        notifyOn: false
      };
      countyId = await this.countyService.createCounty(countyToSave);
      this.dialogService.openInfoAlert('Gemeente toegevoegd', 'De gemeente is toegevoegd.').onClosed(async () => {
        this.router.navigate(['counties']);
      });
    } catch (error) {
      this.errorService.alertAndLog(error, 'Het toevoegen van de gemeente is mislukt.');
      this.showCountySpinner = false;
      return;
    }
    this.showCountySpinner = false;
  }

  async changeContractValidateRequest(newContractId: string): Promise<void> {
    if (this.originalContractId !== newContractId) {
      this.showCountySpinner = true;
      try {
        await this.countyService.changeContractValidateRequest(this.countyId, newContractId);
      } catch (error) {
        if (error.message === `Please remove all the employee in the county: ${this.countyId}`) {
          this.dialogService.openErrorAlert(
            'Foutmelding',
            'Verwijder alstublieft alle medewerkers in de gemeente voordat u van contract verandert.'
          );
          this.countyForm.patchValue({ contractId: this.originalContractId });
        }
      }
      this.showCountySpinner = false;
    }
  }

  async updateCounty(): Promise<void> {
    this.showCountySpinner = true;
    try {
      // @todo create a County instance here to pass to the service
      let countyToSave: County = {
        ...this.county,
        ...this.countyForm.value
      };
      await this.countyService.updateCounty(this.countyId, countyToSave);
      this.dialogService.openInfoAlert('Gemeente aangepast', 'De gemeente is aangepast.').onClosed(async () => {
        this.router.navigate(['counties']);
      });
    } catch (error) {
      this.errorService.alertAndLog(error, 'Het aanpassen van de gemeente is mislukt.');
    }
    this.showCountySpinner = false;
  }

  addEmployeeThroughDialog() {
    const dialogRef = this.dialog.open(EmployeeDialogComponent, {
      width: '80%',
      maxWidth: '750px',
      data: {
        contractId: this.countyForm.value.contractId,
        countyId: this.countyId
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.action === 'add') {
        this.addEmployee(result.countyEmployee as CountyEmployee, result.employeeInExistingCounty);
      }
    });
  }

  editEmployeeThroughDialog(countyEmployeeWithUserId: CountyEmployee & WithUserId) {
    const countyEmployee = _.cloneDeep(countyEmployeeWithUserId);
    delete countyEmployee.userId;

    const dialogRef = this.dialog.open(EmployeeDialogComponent, {
      width: '80%',
      maxWidth: '750px',
      data: {
        countyId: this.countyId,
        countyName: this.countyForm.value.name,
        contractId: this.countyForm.value.contractId,
        userId: countyEmployeeWithUserId.userId,
        countyEmployee
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.action === 'edit') {
          this.editEmployee(result.userId, result.countyEmployee as CountyEmployee);
        } else if (result.action === 'removeFromCounty') {
          this.removeEmployeeFromCounty(result.userId);
        }
      }
    });
  }

  async addEmployee(countyEmployee: CountyEmployee, employeeInExistingCounty: boolean): Promise<void> {
    this.showCountySpinner = true;

    try {
      await this.countyEmployeeService.createCountyEmployee(countyEmployee, employeeInExistingCounty);
      this.dialogService.openInfoAlert('Medewerker aangemaakt', 'De medewerker is aangemaakt.');
    } catch (error) {
      if (error.message === 'The employee belongs to different contract') {
        this.dialogService.openInfoAlert(
          'Emailadres in gebruik',
          `
        Er bestaat al een account met het door u opgegeven email-adres buiten het contract waar uw gemeente onder valt.
        Het is niet mogelijk om een account dat binnen een ander contract wordt gebruikt, toegang te geven tot uw gemeente.
        Geef een email-adres op welke niet in gebruik is binnen een ander contract en probeer de medewerker opnieuw toe te voegen.
        `
        );
      } else {
        this.errorService.alertAndLog(error, 'Het toevoegen van de werknemer is mislukt.');
      }
    }

    this.showCountySpinner = false;
  }

  async editEmployee(userId: string, countyEmployee: CountyEmployee): Promise<void> {
    this.showCountySpinner = true;

    try {
      await this.countyEmployeeService.updateCountyEmployee(userId, countyEmployee);
      this.dialogService.openInfoAlert('Gegevens gewijzigd', 'De gegevens van de medewerker zijn gewijzigd.');
    } catch (error) {
      this.errorService.alertAndLog(error, 'Het aanpassen van de werknemer is mislukt.');
    }

    this.showCountySpinner = false;
  }

  async removeEmployeeFromCounty(userId: string): Promise<void> {
    this.showCountySpinner = true;
    try {
      await this.countyEmployeeService.removeEmployeeFromCounty(userId, this.countyId);
      this.dialogService.openInfoAlert('Gegevens gewijzigd', 'Medewerker is verwijderd uit de gemeente.');
    } catch (error) {
      this.errorService.alertAndLog(error, 'Het aanpassen van de werknemer is mislukt.');
    }
    this.showCountySpinner = false;
  }

  scrollEventListener(windowScrollTop, windowHeight, documentHeight, documentHeightWithoutWindowHeight) {
    // fix for basic dialog removing scrollbar
    if (documentHeight === windowHeight && windowScrollTop === 0) {
      this.footerFixed = true;
    } else if (windowScrollTop >= documentHeightWithoutWindowHeight - 70) {
      this.footerFixed = false;
    } else {
      this.footerFixed = true;
    }
  }

  scrollToFirstError() {
    setTimeout(() => {
      const firstElementWithError = document.querySelector(
        'textarea.ng-invalid, input.ng-invalid, select.ng-invalid, .toggle-error-span.invalid'
      );
      if (firstElementWithError) {
        firstElementWithError.scrollIntoView();
        window.scrollBy(0, -150);
      }
    }, 10);
  }

  private buildForm(): void {
    this.countyForm = this.formBuilder.group({
      name: [null, [Validators.required, Validators.pattern(globals.lettersAndAccentsRegex)]],
      cbsCode: [null, [Validators.required, Validators.minLength(4)]],
      emailAddress: [null, [Validators.email]],
      phoneNumber: [null, [Validators.pattern('[0-9]*'), Validators.minLength(10), Validators.maxLength(10)]],
      address: new AddressForm(),
      iWMO: [null],
      socialSecurityNumberRequired: [null],
      clientViewAccess: [null],
      clientRepairRequest: [null],
      clientModificationRequest: [null],
      clientPickUpRequest: [null],
      hasDepot: [null],
      orderReferenceNumberRequired: [null],
      contractId: [null, [Validators.required]]
    });
  }

  translateAndJoinRoles(rolesObject): string {
    const roles = [];
    for (const key of Object.keys(rolesObject)) {
      if (rolesObject[key]) {
        roles.push(key);
      }
    }
    return (roles || []).map((role) => this.rolesTranslations[role] || role).join(', ');
  }

  translateRole(role: string) {
    return this.rolesTranslations[role] || role;
  }

  filterEmployees(filterType) {
    // clear filters
    if (filterType === 'clear') {
      this.employeesTableFilters.general = '';
      this.resetEmployeesHeaderFilters();
    }

    // use table header filters
    else if (filterType !== 'general') {
      this.employeesTableFilters.general = '';
    }

    // use general filter
    else {
      this.resetEmployeesHeaderFilters();
    }

    this.employeesTable.filter = filterType;
  }

  resetEmployeesHeaderFilters() {
    this.employeesTableFilters.fullName = '';
    this.employeesTableFilters.emailAddress = '';
    this.employeesTableFilters.rolesString = '';
    for (const key of Object.keys(this.employeesTableFilters.roles)) {
      this.employeesTableFilters.roles[key] = true;
    }
  }

  toggleEmployeesColumnSearch() {
    this.employeeColumnSearch = !this.employeeColumnSearch;
  }

  // reset filters once header filters are shown/hidden
  resizeEventListener() {
    const newInnerWidth = window.innerWidth;
    if (newInnerWidth < this.resizeBreakpoint && this.lastInnerWidth >= this.resizeBreakpoint) {
      this.filterEmployees('clear');
    } else if (newInnerWidth >= this.resizeBreakpoint && this.lastInnerWidth < this.resizeBreakpoint) {
      this.filterEmployees('clear');
    }
    this.lastInnerWidth = newInnerWidth;
  }
}
