import { Component } from '@angular/core';


@Component({
    selector: 'app-client-activation-page',
    templateUrl: './client-activation-page.component.html',
    styleUrls: ['./client-activation-page.component.scss']
  })
  export class ClientActivationPageComponent  {

  }
