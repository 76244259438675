import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { ClientsTableComponent } from '@app/modules/order/components/clients-with-orders-table/clients-with-orders-table.component';
import { TitleService } from '@app/shared/services/title.service';
import { ClientService } from '@app/modules/client/services/client.service';
import { UserService, SignedInUser } from '@app/shared/services/user.service';
import { CountyService } from '@app/modules/county/services/county.service';
import { County } from '@app/modules/county/models/county';
import { ClientSearchQuery } from '@app/shared/models/client-search-query';

import { Subscription } from 'rxjs';

@Component({
  selector: 'app-clients-page',
  templateUrl: './clients-page.component.html',
  styleUrls: ['./clients-page.component.scss']
})
export class ClientsPageComponent implements OnInit, OnDestroy {

  @ViewChild('countyClientsOrders')
  countyClientsOrdersComponent: ClientsTableComponent;

  showSpinner = false;
  searchSubmitted = false;
  searchError = false;
  clientsData: Array<any>;

  userSubscription: Subscription;
  user: SignedInUser;
  currentActiveCountyId: string;
  county: County;

  constructor(
    private titleService: TitleService,
    private clientService: ClientService,
    private userService: UserService,
    private countyService: CountyService,
  ) {}

  ngOnInit() {
    this.titleService.setTitle('Cliënten');
    this.userSubscription = this.userService.user$.subscribe({
      next: async (user) => {
        if (!this.currentActiveCountyId || (user && this.currentActiveCountyId !== user.countyId)) {
          this.currentActiveCountyId = user.countyId;
          this.county = await this.countyService.getCounty(user.countyId);
          this.resetSearch();
        }
      },
      error: (e) => {
        console.error(e);
      }
    });
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }

  async searchForClients(clientSearchQuery: ClientSearchQuery){
    if (clientSearchQuery.birthDate || clientSearchQuery.partialLastName)
    {
      this.searchError = false;
      this.searchSubmitted = false;
      this.showSpinner = true;
      try {
        this.clientsData = await this.clientService.searchClients(clientSearchQuery);
        this.searchSubmitted = true;
      }
      catch (error) {
        this.searchError = true;
      }
      this.showSpinner = false;
    }
  }

  resetSearch() {
    this.searchError = false;
    this.searchSubmitted = false;
    this.showSpinner = false;
    this.clientsData = null;
  }
}
