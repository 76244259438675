
export const mapErrorTypeToContent = (msg: string, default_msg: string): string => {
    var contactstr = 'contact';
    var contact_hyper_link = contactstr.link('/about/about-contact');
    var requeststr = 'overige aanvragen/diensten';
    var request_hyper_link = requeststr.link('/contact');

    switch (msg) {
        case 'MISA_ERROR_ALLADINSS_GETITEM_FAULT':
            return 'Uw gemeente heeft geen depot bij Medipoint';
        case 'MISA_EMAIL_ALREADY_EXISTS':
            return 'Er bestaat al een client met het opgegeven email adres. ' +
                'Selecteer een bestaande client uit de lijst met clienten ' +
                'of maak een nieuwe client aan door een ander email adres op te geven.';
        case 'MISA_SSN_ALREADY_EXISTS':
            return 'Er bestaat al een client met het opgegeven BSN. ' +
                'Selecteer een bestaande client uit de lijst met clienten ' +
                'of maak een nieuwe client aan door een ander BSN op te geven.';
        case 'MISA_ERROR_UNABLE_TO_DETERMINE_SSN':
            return 'Het BSN nummer wat u opvoert is niet geldig. Wij verzoeken u ' +
                'om een geldig BSN nummer in te voeren.';
        case 'MISA_ERROR_ALLADINSS_ADDCLIENT_FAULT':
            return 'De gegevens waarmee u de cliënt probeert op te voeren zijn ongeldig. ' +
                'Wij verzoeken u om het emailadres en het BSN nummer te controleren. ' +
                'Mocht u deze melding nogmaals krijgen neem dan ' +
                `${contact_hyper_link} met ons op.`;
        case 'MISA_ERROR_ALLADINSS_ADDWMOORDER_FAULT':
        case 'MISA_ERROR_ALLADINSS_GETCLIENT_FAULT':
        case 'MISA_ERROR_ALLADINSS_GETESTABLISHMENT_FAULT':
        case 'MISA_ERROR_BAD_REQUEST':
        case 'MISA_ERROR_DATAWEAVE_FAULT':
            return 'Er is helaas iets misgegaan. Wij verzoeken u om de aanvraag ' +
                'nogmaals in te dienen. Mocht u deze melding nogmaals krijgen neem dan ' +
                `${contact_hyper_link} met ons op.`;
        case 'MISA_ERROR_CLIENT_DECEASED_IN_DATABASE':
            return 'De cliënt is bij onze administratie anders bekend. Wij verzoeken u om contact op ' +
                'te nemen met de Wmo vestiging zodat zij u verder kunnen helpen.';
        case 'MISA_ERROR_INTERNAL_SERVER_ERROR':
            return 'Er is helaas sprake van een technische foutmelding. Wij verzoeken u om ' +
                `${contact_hyper_link} met ons op te nemen zodat wij u verder kunnen helpen. `;
        case 'MISA_ERROR_WMO_COUNTY_NOT_IN_DATABASE':
            return 'De aanvraag die u probeert te doen voor deze specifieke gemeente ' +
                'is niet toegestaan. Wij verzoeken u om ' +
                `${contact_hyper_link} met ons op te nemen zodat ` +
                'wij u verder kunnen helpen.';
        case 'MISA_ERROR_WMO_PRODUCT_NOT_ON_WHITELIST':
            return 'De voorziening waar u een aanvraag voor probeert in te ' +
                'dienen is niet toegestaan. Wij verzoeken u om ' +
                `${contact_hyper_link} met ons op te nemen zodat wij u verder kunnen helpen.`;
        case 'NOT_ALLOW_IWMO_CHANGE_ORDER':
            return 'Het is niet mogelijk om als iWmo gemeente een order te ' +
                'wijzigen via de Wmo portal. Wilt u de order wijzigen stuur dan ' +
                'via iWmo een 301 intrekking en vervolgens een 301 nieuwe toewijzing ' +
                'om de order te wijzigen. Als u de cliëntgegevens wilt wijzigen kunt u dat via ' +
                `${request_hyper_link} doen.`;
        case 'NOT_ALLOW_IWMO_CANCEL_ORDER':
            return 'Het is niet mogelijk om als iWmo gemeente een order te ' +
                'annuleren via de Wmo portal. Wilt u de order annuleren stuur ' +
                'dan via iWmo een 301 intrekking om de order te annuleren.'
        default:
            return default_msg;
    }
}