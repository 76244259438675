import { Establishment } from '@app/modules/establishment/models/establishment';
import { EstablishmentService } from '@app/modules/establishment/services/establishment.service';
import { AddressAutocompleteComponent } from '@app/shared/components/address-autocomplete/address-autocomplete.component';
import * as globals from '@app/shared/globals';
import { ErrorService } from '@app/shared/services/error.service';
import { atLeastOne } from '@app/shared/validators/atLeastOne.validator';
import * as util from '@app/shared/util';

import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, FormControl, Validators } from '@angular/forms';
import { DialogService } from '@app/shared/services/dialog.service';

import { TitleService } from '@app/shared/services/title.service';
import { Address } from '@app/shared/models/address';
import { AddressForm } from '@app/shared/forms/address.form';


@Component({
  selector: 'app-establishment-page',
  templateUrl: './establishment-page.component.html',
  styleUrls: ['./establishment-page.component.scss']
})
export class EstablishmentPageComponent implements OnInit {

  public util = util;

  establishmentForm: UntypedFormGroup;
  establishmentId: string;
  isThisAnEditPage = false;
  showEstablishmentSpinner = true;
  loadingMessage: string;
  submitted = false;
  footerFixed = false;

  @ViewChild(AddressAutocompleteComponent) addressAutocomplete;

  constructor(
    private establishmentService: EstablishmentService,
    private errorService: ErrorService,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private fb: UntypedFormBuilder,
    private titleService: TitleService
  ) {
  }

  ngOnInit() {
    this.buildForm();
    this.establishmentId = this.route.snapshot.paramMap.get('establishmentId');

    if (this.establishmentId) {

      this.isThisAnEditPage = true;
      this.establishmentService.getEstablishment(this.establishmentId).then((establishment) => {

        this.establishmentForm.patchValue(establishment);
        this.footerFixed = true;
        this.submitted = true;
        this.showEstablishmentSpinner = false;
        this.titleService.setTitle(`Vestiging ${establishment.name}`);

      }).catch((error) => {
        this.errorService.alertAndLog(error, 'Het laden van de vestiging is mislukt.').onClosed(async () => {
          this.router.navigate(['establishments']);
        });
      });

    }
    else {
      this.showEstablishmentSpinner = false;
      this.titleService.setTitle('Nieuwe vestiging');
    }

    window.addEventListener('scroll', () => {
      if (this.submitted) {
        const windowScrollTop = window.scrollY;
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;
        const documentHeightWithoutWindowHeight = documentHeight - windowHeight;
        this.scrollEventListener(windowScrollTop, windowHeight, documentHeight, documentHeightWithoutWindowHeight);
      }
    });
  }

  scrollEventListener(windowScrollTop, windowHeight, documentHeight, documentHeightWithoutWindowHeight) {
    // fix for basic dialog removing scrollbar
    if (documentHeight === windowHeight && windowScrollTop === 0) {
      this.footerFixed = true;
    }
    else if (windowScrollTop >= (documentHeightWithoutWindowHeight - 70)) {
      this.footerFixed = false;
    }
    else {
      this.footerFixed = true;
    }
  }

  scrollToFirstError() {

    setTimeout(() => {
      const firstElementWithError =
        document.querySelector('textarea.ng-invalid, input.ng-invalid, select.ng-invalid, .toggle-error-span.invalid');
      if (firstElementWithError) {
        firstElementWithError.scrollIntoView();
        window.scrollBy(0, -150);
      }
    }, 10);

  }

  submitForm() {

    this.submitted = true;

    if (this.establishmentForm.invalid) {

      this.scrollToFirstError();
      util.markFormGroupTouched(this.establishmentForm);
      this.dialogService.openErrorAlert(
        'Opslaan mislukt',
        'De wijzigingen konden niet worden opgeslagen, omdat niet alle verplichte velden zijn ingevuld.'
      );

    }
    else {

      if (this.isThisAnEditPage) {
        this.updateEstablishment();
      }
      else {
        this.saveEstablishment();
      }

    }

  }

  saveEstablishment() {

    this.showEstablishmentSpinner = true;

    const establishment = this.createEstablishmentDoc(this.establishmentForm.value);
    establishment.archived = false;

    this.establishmentService.createEstablishment(establishment).then(() => {

      this.dialogService.openInfoAlert(
        'Vestiging toegevoegd',
        'De vestiging is toegevoegd.'
      )
        .onClosed(async () => {
          this.router.navigate(['establishments']);
        });

    }).catch(() => {

      this.showEstablishmentSpinner = false;
      this.dialogService.openErrorAlert(
        'Opslaan mislukt',
        'De wijzigingen konden niet worden opgeslagen.'
      );

    });

  }

  updateEstablishment() {

    this.showEstablishmentSpinner = true;

    const establishment = this.createEstablishmentDoc(this.establishmentForm.value);

    this.establishmentService.updateEstablishment(this.establishmentId, establishment).then(() => {

      this.dialogService.openInfoAlert(
        'Vestiging opgeslagen',
        'De gegevens van de vestiging zijn bijgewerkt.'
      )
        .onClosed(async () => {
          this.router.navigate(['establishments']);
        });

    }).catch(() => {

      this.showEstablishmentSpinner = false;
      this.dialogService.openErrorAlert(
        'Opslaan mislukt',
        'De wijzigingen konden niet worden opgeslagen.'
      );

    });

  }

  createEstablishmentDoc(formData: any): Establishment {
    const doc: Establishment = {
      address: formData.address,
      alladinId: formData.alladinId,
      archived: !!formData.archived,
      contactPerson: formData.contactPerson,
      name: formData.name
    };

    util.removeNullAndEmptyStringValues(doc);
    return doc;
  }

  buildForm() {
    this.establishmentForm = this.fb.group({

      name: [null, [Validators.required, Validators.pattern(globals.lettersAndAccentsRegex)]],
      alladinId: [null],
      address: new AddressForm(),
      contactPerson: this.fb.group({
        initials: [null, [Validators.required, Validators.maxLength(20), Validators.pattern(globals.initialsRegex)]],
        firstName: [null, [Validators.maxLength(20), Validators.pattern(globals.lettersAndAccentsRegex)]],
        prefix: [null, [Validators.maxLength(12), Validators.pattern(globals.lettersAndAccentsRegex)]],
        lastName: [null, [Validators.required, Validators.maxLength(100), Validators.pattern(globals.lettersAndAccentsRegex)]],
        emailAddress: [null, [Validators.email]],
        phoneNumber: [null, [Validators.pattern('[0-9]*'), Validators.minLength(10), Validators.maxLength(10)]]
      },
        { validators: atLeastOne(Validators.required, ['emailAddress', 'phoneNumber']) })

    });
  }
}
