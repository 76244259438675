import { Reason } from './../../../../shared/models/reason';
import { Component, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConfigService } from '@app/shared/services/config.service';

import * as util from '@app/shared/util';
import * as moment from 'moment';
import { WorkHour } from './../../../../shared/models/workHour';
import { CountyService } from '@app/modules/county/services/county.service';
import { SignedInUser, UserService } from '@app/shared/services/user.service';
import { Subscription } from 'rxjs';
import { County } from '@app/modules/county/models/county';

@Component({
  selector: 'app-request-dialog',
  templateUrl: './request-dialog.component.html',
  styleUrls: ['./request-dialog.component.scss']
})
export class RequestDialogComponent {
  public requestType: string;

  requestForm: UntypedFormGroup;
  holidays: Array<moment.Moment> = [];
  workHour: WorkHour;
  tomorrow: moment.Moment = moment.utc().add(1, 'days').startOf('day');
  showInforMessage = false;
  reasonListAll: Reason[] = [];
  reasonList: Reason[] = [];
  user: SignedInUser;
  county: County;

  userSubscription: Subscription;


  constructor(
    configService: ConfigService,
    private dialogRef: MatDialogRef<RequestDialogComponent>,
    private countyService: CountyService,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA)
    data: {
      requestType: string;
    }
  ) {
    this.requestType = data.requestType;
    if (this.requestType === 'repair') {
      this.requestForm = new UntypedFormGroup({
        remarks: new UntypedFormControl(null, [Validators.required])
      });
    } else if (this.requestType === 'adjustment') {
      this.requestForm = new UntypedFormGroup(
        {
          remarks: new UntypedFormControl(null, [Validators.required]),
          isConsulent: new UntypedFormControl('Nee', []),
          isSomeoneElse: new UntypedFormControl('Nee', []),
          consulentName: new UntypedFormControl('', []),
          consulentContact: new UntypedFormControl('', []),
          otherPersonName: new UntypedFormControl('', []),
          otherPersonContact: new UntypedFormControl('', []),
        },
        { validators: [this.adjustmentFormValidator()] }
      );
    } else if (this.requestType === 'collection') {
      this.requestForm = new UntypedFormGroup({
        collectionDate: new UntypedFormControl(null, [Validators.required]),
        remarks: new UntypedFormControl(null),
        endReason: new UntypedFormControl('', [Validators.required]),
      });
    }
    configService.getReason().then((reasons: Reason[]) => {
      this.reasonListAll = reasons.sort((n1, n2) => n1.order - n2.order);;
    });
    configService.getWorkDayHour().then((workhour: WorkHour) => {
      this.workHour = workhour;
      this.showInforMessage = util.outsideWorkingkHours(workhour);
    });
    configService.getHolidays().then((holidays: Array<any>) => {
      const today = new Date();

      holidays.forEach((holiday) => {
        const dateToHoliday = new Date(holiday.seconds * 1000);
        this.showInforMessage =  this.showInforMessage || util.datesAreOnSameDay(today, dateToHoliday);
        this.holidays.push(moment(holiday.toDate()).startOf('day'));
      });
    });

    this.userSubscription = this.userService.user$.subscribe(async (user) => {
      this.user = user;
      this.county = await this.countyService.getCounty(this.user.countyId);
      if (this.county.iWMO){
        this.reasonList = this.reasonListAll.filter(reason => reason.iwmo);
      } else {
        this.reasonList = this.reasonListAll.filter(reason => reason.iwmo === false);
      }
    });
  }
  adjustmentFormValidator(): ValidatorFn {
    return (formGroup: UntypedFormGroup): ValidationErrors | null => {
      if (formGroup.controls.isConsulent.value === 'Ja') {
        if (formGroup.controls.consulentName.value === '') {
          formGroup.controls.consulentName.setErrors({ consulentName: true });
        }
        if (formGroup.controls.consulentContact.value === '') {
          formGroup.controls.consulentContact.setErrors({ consulentContact: true });
        }
      }
      if (formGroup.controls.isSomeoneElse.value === 'Ja') {
        if (formGroup.controls.otherPersonName.value === '') {
          formGroup.controls.otherPersonName.setErrors({ otherPersonName: true });
        }
        if (formGroup.controls.otherPersonContact.value === '') {
          formGroup.controls.otherPersonContact.setErrors({ otherPersonContact: true });
        }
      }

      return;
    };
  }

  fooVal(control: UntypedFormControl): ValidationErrors {
    if (control.parent !== undefined) {
      if (control.parent.get('isConsulent').value === 'Ja') {
        if (control.parent.get('consulentName').value === '') {
          return { consulentName: true };
        }
        if (control.parent.get('consulentContact').value === '') {
          return { consulentContact: true };
        }
      }
      if (control.parent.get('isSomeoneElse').value === 'Ja') {
        if (control.parent.get('otherPersonName').value === '') {
          return { otherPersonName: true };
        }
        if (control.parent.get('otherPersonContact').value === '') {
          return { otherPersonContact: true };
        }
      }
    }
    return null;
  }

  submitForm(action) {
    if (this.requestForm.valid || action === 'cancel') {
      let formValues: any = {};
      if (this.requestType === 'adjustment') {
        formValues = {
          gewensteAanpassing: this.requestForm.value.remarks,
          consulentAanwezig: this.requestForm.value.isConsulent,
          iemandAndersAanwezig: this.requestForm.value.isSomeoneElse,
          naamConsulent: this.requestForm.value.consulentName,
          contactgegevensConsulent: this.requestForm.value.consulentContact,
          naamAnderePersoon: this.requestForm.value.otherPersonName,
          contactgegevensAnderePersoon: this.requestForm.value.otherPersonContact
        };
      } else {
        formValues = this.requestForm;
      }
      const result = {
        action,
        categoryForm: formValues
      };
      this.dialogRef.close(result);
      this.userSubscription.unsubscribe();
    } else {
      util.markFormGroupTouched(this.requestForm);
    }
  }

  collectionDatePickerFilter = (d: Date | null): boolean => {
    const currentDate = moment(d).startOf('day');
    const holidayIndex = this.holidays.findIndex((x) => x.isSame(currentDate, 'day'));
    return currentDate.day() !== 6 && currentDate.day() !== 0 && holidayIndex < 0 && currentDate.diff(this.tomorrow, 'days') > 0;
  }
}
