import { Injectable } from '@angular/core';
import { UserManual } from '../models/user-manual';
import { CloudLogger, CloudLoggingService } from '@app/shared/services/cloud-logging.service';
import { AuthService, AuthUser } from '@app/shared/services/auth.service';

import { Observable } from 'rxjs';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { FirestoreObservableQuery } from '@app/shared/util/firestore-observable-query';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AngularFirePerformance } from '@angular/fire/compat/performance';
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root'
})
export class ManualService {
  collectionName = `user-manuals`;
  userManuals$: Observable<any>;
  userManualsCollection: AngularFirestoreCollection<UserManual> = this.firestore.collection<UserManual>(this.collectionName);

  private cloudLog: CloudLogger;
  private requestAdditionalService$: (data: any) => Observable<any>;
  private storageRef = firebase.storage().ref();

  constructor(
    cloudLoggingService: CloudLoggingService,
    functions: AngularFireFunctions,
    private authService: AuthService,
    private firestore: AngularFirestore,
    private performance: AngularFirePerformance
  ) {
    const userManualsQuery = new FirestoreObservableQuery<any>(firestore, {
      collection: this.collectionName
    });

    this.userManuals$ = userManualsQuery.observable$;
    this.authService.user$.subscribe({
    next: (user: AuthUser) => {
      userManualsQuery.setCleanup(!user);
    }});

    this.cloudLog = cloudLoggingService.createLogger('manuals.service');
    this.requestAdditionalService$ = functions.httpsCallable('requestAdditionalService');
  }

  async getUserManuals(): Promise<UserManual[]> {
    const userManuals: UserManual[] = [];
    const snapshot = await this.userManualsCollection.ref.get();
    if (!snapshot.empty) {
      snapshot.forEach((doc) => {
        const id = doc.id;
        const { title, downloadURL } = doc.data();
        userManuals.push({
          id,
          title,
          downloadURL: downloadURL
        });
      });
    }
    return userManuals;
  }

  async deleteManual(manual: UserManual): Promise<void> {
    await this.firestore.doc(`user-manuals/${manual.id}`).delete();
    await this.storageRef.child(`/manuals/${manual.id}.pdf`).delete();
  }

  async saveManual(manual: UserManual, file: File): Promise<UserManual> {
    manual.id = this.firestore.createId();
    const uploadTask = this.storageRef.child(`/manuals/${manual.id}.pdf`).put(file);
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {},
      (error) => {
        console.log(error);
        return null;
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          manual.downloadURL = downloadURL;
          this.userManualsCollection.doc(manual.id).set({
            id: '',
            title: manual.title,
            downloadURL: manual.downloadURL
          });
        });
      }
    );
    return manual;
  }
}
