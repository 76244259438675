<h2>Veelgestelde vragen</h2>
<ng-container *ngIf="faqItems.length > 0">
  <div *ngFor="let faqItem of faqItems">
    <p class="question-text" [ngClass]="{ active: faqItem.id === activeFaqItemId }" (click)="showFaqIndex(faqItem.id)">
      {{ faqItem.question }}
      <mat-icon [@rotateIcon]="faqItem.id === activeFaqItemId ? 'rotated' : 'default'">expand_more</mat-icon>
    </p>
    <ng-container *ngIf="faqItem.id === activeFaqItemId">
      <p class="no-orders-text">
        {{ faqItem.answer }}
      </p>
    </ng-container>
  </div>
</ng-container>
<ng-container *ngIf="faqItems.length === 0">
  <div>Er zijn geen Veelgestelde vragen.</div>
</ng-container>
<p class="no-orders-text mt-50">
  Staat uw vraag er niet tussen en komt u er niet uit met de handleiding? Dan kunt contact opnemen via het contactformulier en wij zullen u
  zo spoedig mogelijk proberen te helpen.
</p>
