import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ClientSearchQuery } from '@app/shared/models/client-search-query';

@Component({
  selector: 'app-client-search',
  templateUrl: './client-search.component.html',
  styleUrls: ['./client-search.component.scss']
})
export class ClientSearchComponent implements OnInit {

  constructor() { }

  @Output() outputSearchTerms = new EventEmitter<ClientSearchQuery>();
  @Input() public useDatePicker = false;

  searchClientForm: UntypedFormGroup;

  ngOnInit() {
    this.searchClientForm = new UntypedFormGroup({
      partialLastName: new UntypedFormControl(null),
      birthDate: new UntypedFormControl(null),
    });
  }

  emitSearchTerms() {
    const searchClientQuery: ClientSearchQuery = new ClientSearchQuery();

    if (this.searchClientForm.value.partialLastName) {
      this.searchClientForm.patchValue(this.searchClientForm.value.partialLastName.trim());
      searchClientQuery.partialLastName = this.searchClientForm.value.partialLastName.toLowerCase();
    }
    if (this.searchClientForm.value.birthDate) {
      searchClientQuery.birthDate = this.searchClientForm.value.birthDate.toString();
    }

    this.outputSearchTerms.emit(searchClientQuery);
  }

  clearSearchIfEmpty(){
    if (this.useDatePicker === false && !this.searchClientForm.value.partialLastName && !this.searchClientForm.value.birthDate) {
      this.emitSearchTerms();
    }
  }

}
