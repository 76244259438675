<div class="general-form-header">
  <h1>{{ title }}</h1>
</div>
<div class="page-subtitle" *ngIf="iWmoCountiesEditingOrder; else other">
  <b>Let op!</b> Het is voor een iWmo-gemeente mogelijk om hier een bijlage toe te voegen.
</div>
<ng-template #other>
  <div class="page-subtitle" *ngIf="county && county.iWMO">
    <b>Let op!</b>
    Overige wijzigingen kunnen niet worden gedaan via de portal. Wilt u de order wijzigen stuur dan via iWmo een 301 intrekking en
    vervolgens een 301 nieuwe toewijzing om de order te wijzigen.
  </div>
</ng-template>

<form [formGroup]="orderForm">
  <!-- Select client -->
  <div class="general-form-container" [class.hidden]="isSubmitted" *ngIf="!iWmoCountiesEditingOrder">
    <app-spinner *ngIf="showClientsSpinner" class="absolute small" loadingMessage=""></app-spinner>

    <div class="general-form-subheader">
      <h2>1. Selecteer een cli&euml;nt</h2>
    </div>

    <div class="general-form-list borderless">
      <app-client-search class="margin-bottom" (outputSearchTerms)="searchForClients($event)" [useDatePicker]="true"></app-client-search>

      <table *ngIf="clientsTable" class="form-mat-table" id="clients-table" mat-table [dataSource]="clientsTable" multiTemplateDataRows>
        <ng-container matColumnDef="select">
          <th mat-header-cell class="radio-checkbox-cell" *matHeaderCellDef></th>
          <td mat-cell class="radio-checkbox-cell" *matCellDef="let client; let i = dataIndex">
            <input type="radio" name="selectedClient" id="client-{{ client.misaClientId }}" (change)="changeMisaClient(client)" />
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Naam</th>
          <td mat-cell *matCellDef="let client; let i = dataIndex" class="client-name">
            <label for="client-{{ client.misaClientId }}">
              {{ client.formattedName }}
            </label>
          </td>
        </ng-container>

        <ng-container matColumnDef="birthDate">
          <th class="email-address" mat-header-cell *matHeaderCellDef>Geboortedatum</th>
          <td class="email-address" mat-cell *matCellDef="let client; let i = dataIndex">
            <label for="client-{{ client.misaClientId }}">
              {{ client.formattedBirthDate }}
            </label>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="clientsTableColumns"></tr>
        <tr mat-row *matRowDef="let client; columns: clientsTableColumns"></tr>
      </table>

      <div *ngIf="clientsTable" class="client-table-footer">
        <mat-paginator #clientsPaginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
      </div>
    </div>
  </div>

  <!-- Documents -->
  <div class="general-form-container" *ngIf="iWmoCountiesEditingOrder">
    <div class="general-form-subheader">
      <h2>{{ getFormNumber(2) }}. Bestanden</h2>
    </div>
    <div
      class="low-row"
      [class]="orderHistoryItems && orderHistoryItems.documents && Object.keys(orderHistoryItems.documents).length > 0 ? 'low-row' : 'row'"
    >
      <app-document-uploader
        [documentsForm]="orderForm.get('documents')"
        [filePath]="tempOrderId"
        [customMetadata]="{ countyId: this.user?.countyId }"
      ></app-document-uploader>
    </div>

    <div
      class="expandable-list low-row"
      [class.expand]="orderHistoryItemsDocumentsExpanded"
      *ngIf="orderHistoryItems && orderHistoryItems.documents && Object.keys(orderHistoryItems.documents).length > 0"
    >
      <h3 (click)="orderHistoryItemsDocumentsExpanded = !orderHistoryItemsDocumentsExpanded">
        Bestanden geschiedenis
        <mat-icon *ngIf="!orderHistoryItemsDocumentsExpanded">expand_more</mat-icon>
        <mat-icon *ngIf="orderHistoryItemsDocumentsExpanded">expand_less</mat-icon>
      </h3>
      <ul>
        <ng-container *ngFor="let documentsByUploadDate of orderHistoryItems.documents | keyvalue">
          <li *ngFor="let document of documentsByUploadDate.value.documents">
            <span class="date">{{ documentsByUploadDate.value.dateCreated }}</span>
            <span>{{ document.fileName }}</span>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
  <!-- Buttons -->
  <div id="general-form-actions" [ngClass]="{ fixed: footerFixed && isSubmitted }" *ngIf="iWmoCountiesEditingOrder">
    <div class="general-form-actions-container">
      <button class="secondary-cta" routerLink="/orders">Annuleren</button>

      <button class="cta" id="order-submit-button" (click)="submitForm()">
        {{ getSubmitButtonText() }}
      </button>
    </div>
  </div>

  <div class="general-form-header" *ngIf="iWmoCountiesEditingOrder">
    <h1>Vaste gegevens</h1>
  </div>
  <div class="page-subtitle" *ngIf="iWmoCountiesEditingOrder">
    <b>Let op!</b> Overige wijzigingen kunnen niet worden gedaan via de portal. Wilt u de order wijzigen stuur dan via iWmo een 301
    intrekking en vervolgens een 301 nieuwe toewijzing om de order te wijzigen.
  </div>
  <!-- Client -->
  <app-client-form
    #clientFormSection
    *ngIf="orderForm && county"
    [clientForm]="orderForm.controls.client"
    [newClient]="!misaClientId"
    [newOrder]="!misaOrderId"
    [socialSecurityNumberRequired]="county?.socialSecurityNumberRequired"
    [iwmo]="iWmoCountiesEditingOrder"
    [headerIndex]="iWmoCountiesEditingOrder ? getFormNumber(3) : getFormNumber(2)"
    [existingClientWithContactPerson]="
      orginalClientDetails && orginalClientDetails.contactPersons && orginalClientDetails.contactPersons.length > 0 ? true : false
    "
  ></app-client-form>

  <!-- Products multi selection  -->
  <div class="general-form-list" id="general-form-list" *ngIf="isLoading">
    <div class="general-form-list-header">
      <h2>{{ iWmoCountiesEditingOrder ? getFormNumber(6) : getFormNumber(7) }}. Hulpmiddel</h2>
    </div>
    <div class="product-categories for-order-page">
      <p
      *ngIf="contract && isProductExits"
      class="product-selection-disabled-warning"
    >
      Algemene aanvraag/Mobiliteitsbehoefte
    </p>
      <div *ngIf="!misaOrderId && !isProductExits">
        <mat-accordion>
          <div class="accordion-item" *ngFor="let item of accordionData; let i = index">
            <mat-expansion-panel (opened)="item.panelOpenState = true" (closed)="item.panelOpenState = false">
              <mat-expansion-panel-header>
                <mat-panel-title> <b>{{ item.alladinId }}: {{ item.name }} </b> </mat-panel-title>
                <mat-panel-description>
                  <span class="badge">
                      {{item.selectedCount}}

                   </span>
                 </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="product-category-head" *ngFor="let product of item.categories">
                <div *ngIf="product.type === 'product'" class="radio-container">
                  <mat-checkbox
                  (change)="changeProduct(product)"
                  formControlName="product"> </mat-checkbox>
                </div>
                <div class="img" (click)="product.type === 'category' && (product.expanded = !product.expanded)">
                  <img [src]="(product.image.storageFilePath | downloadUrl: 'contract-images' | async) || ''" width="64" height="64" />
                </div>
                <div
                  class="product-category-text-and-controls"
                  (click)="product.type === 'category' && (product.expanded = !product.expanded)"
                >
                  <span class="product-category-text" [matTooltip]="product.description">{{ product.alladinId }}: {{ product.name }}</span>
                </div>
              </div>
            </mat-expansion-panel>
          </div>
        </mat-accordion>
      </div>

      <div class="product-categories for-order-page" *ngIf="misaOrderId && productOfExistingOrder">
        <div class="product-category-head existing-order">
          <div class="img">
            <img [src]="(productOfExistingOrder.image.storageFilePath | downloadUrl: 'contract-images' | async) || ''" />
          </div>
          <div class="product-category-text-and-controls">
            <span class="product-category-text" [matTooltip]="productOfExistingOrder.description">
              {{ productOfExistingOrder.alladinId }}: {{ productOfExistingOrder.name }}
            </span>
          </div>
        </div>
      </div>


      <!--
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="image">
          <th mat-header-cell *matHeaderCellDef> </th>
          <td mat-cell *matCellDef="let category">
            <div
                class="icon-container expand-collapse"
                *ngIf="category.type === 'category'"
                (click)="category.expanded = !category.expanded"
              >
                <img *ngIf="!category.expanded" src="/assets/images/icon-expand.png" />
                <img *ngIf="category.expanded" src="/assets/images/icon-collapse.png" />
              </div>
              <div class="img" (click)="category.type === 'category' && (category.expanded = !category.expanded)">
                <img [src]="(category.image.storageFilePath | downloadUrl: 'contract-images' | async) || ''" />
              </div>
          </td>

        </ng-container>
        <ng-container matColumnDef="number">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let category">
            <span class="product-category-text" [matTooltip]="category.description">{{ category.alladinId }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="detail">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let category">
            <span class="line vert"></span>
            <span class="line hor"></span>
            <span class="line vert-white"></span>

            {{ category.name }}
            <div class="category-body" *ngIf="category.expanded">
              <div *ngIf="category.categories">
                <ng-container *ngTemplateOutlet="recursiveList; context: { $implicit: category.categories }"> </ng-container>
              </div>

              <div class="no-categories-container" *ngIf="category.categories && category.categories && categories.length == 0">
                <span>Er zijn geen producten of subcategorien beschikbaar in deze categorie.</span>
              </div>
            </div>

          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></tr>
      </table>
       -->
    </div>
  </div>
<!--
  <div class="general-form-list" id="general-form-list">
    <div class="general-form-list-header">
      <h2>{{ iWmoCountiesEditingOrder ? getFormNumber(6) : getFormNumber(7) }}. Hulpmiddel</h2>
    </div>
    <ng-template #recursiveList let-categories>
      <div class="product-categories for-order-page">
        <div
          class="product-categories-row"
          [class.category]="category.type === 'category'"
          [class.product]="category.type === 'product'"
          [class.expanded]="category.expanded"
          *ngFor="let category of categories; let i = index"
        >
          <span class="line vert"></span>
          <span class="line hor"></span>
          <span class="line vert-white"></span>

          <div class="product-category-head">
            <div
              class="icon-container expand-collapse"
              *ngIf="category.type === 'category'"
              (click)="category.expanded = !category.expanded"
            >
              <img *ngIf="!category.expanded" src="/assets/images/icon-expand.png" />
              <img *ngIf="category.expanded" src="/assets/images/icon-collapse.png" />
            </div>
            <div *ngIf="category.type === 'product'" class="radio-container">
              <input type="radio" formControlName="product" [value]="category" />
            </div>

            <div class="img" (click)="category.type === 'category' && (category.expanded = !category.expanded)">
              <img [src]="(category.image.storageFilePath | downloadUrl: 'contract-images' | async) || ''" />
            </div>

            <div
              class="product-category-text-and-controls"
              (click)="category.type === 'category' && (category.expanded = !category.expanded)"
            >
              <span class="product-category-text" [matTooltip]="category.description">{{ category.alladinId }}: {{ category.name }}</span>
            </div>
          </div>

          <div class="category-body" *ngIf="category.expanded">
            <div *ngIf="category.categories">
              <ng-container *ngTemplateOutlet="recursiveList; context: { $implicit: category.categories }"> </ng-container>
            </div>

            <div class="no-categories-container" *ngIf="category.categories && category.categories && categories.length == 0">
              <span>Er zijn geen producten of subcategorien beschikbaar in deze categorie.</span>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <div *ngIf="contract && contract.categories && !misaOrderId && !contract.productSelectionDisabled" class="products">
      <ng-container *ngTemplateOutlet="recursiveList; context: { $implicit: contract.categories }"> </ng-container>
    </div>

    <p
      *ngIf="contract && contract.categories && !misaOrderId && contract.productSelectionDisabled"
      class="product-selection-disabled-warning"
    >
      Algemene aanvraag/Mobiliteitsbehoefte
    </p>

    <div class="product-categories for-order-page" *ngIf="misaOrderId && productOfExistingOrder">
      <div class="product-category-head existing-order">
        <div class="img">
          <img [src]="(productOfExistingOrder.image.storageFilePath | downloadUrl: 'contract-images' | async) || ''" />
        </div>
        <div class="product-category-text-and-controls">
          <span class="product-category-text" [matTooltip]="productOfExistingOrder.description">
            {{ productOfExistingOrder.alladinId }}: {{ productOfExistingOrder.name }}
          </span>
        </div>
      </div>
    </div>

    <div *ngIf="orderForm.controls.product.touched && orderForm.controls.product.invalid" class="error-span margin-top">
      Selecteer een product.
    </div>
  </div>
-->

  <!-- Other -->
  <div class="general-form-extra-information">
    <div class="general-form-list-header">
      <h2>{{ iWmoCountiesEditingOrder ? getFormNumber(7) : getFormNumber(8) }}. Overige informatie</h2>
    </div>

    <div class="row">
      <div
        class="input-container"
        [ngClass]="{ invalid: orderForm.controls.orderReferenceNumber.touched && orderForm.controls.orderReferenceNumber.invalid }"
      >
        <app-error-messages [formControls]="orderForm.controls.orderReferenceNumber"></app-error-messages>
        <input
          type="text"
          formControlName="orderReferenceNumber"
          name="orderReferenceNumber"
          [required]="county?.orderReferenceNumberRequired"
        />
        <label>Order referentienummer</label>
      </div>
    </div>

    <div [class]="orderHistoryItems && orderHistoryItems.remarks && Object.keys(orderHistoryItems.remarks).length > 0 ? 'low-row' : 'row'">
      <div class="input-container" [ngClass]="{ invalid: orderForm.controls.remarks.touched && orderForm.controls.remarks.invalid }">
        <app-error-messages [formControls]="orderForm.controls.remarks"></app-error-messages>
        <textarea formControlName="remarks" name="remarks" rows="5"></textarea>
        <label>Opmerkingen</label>
      </div>
    </div>

    <div
      class="expandable-list low-row"
      [class.expand]="orderHistoryItemsRemarksExpanded"
      *ngIf="orderHistoryItems && orderHistoryItems.remarks && Object.keys(orderHistoryItems.remarks).length > 0"
    >
      <h3 (click)="orderHistoryItemsRemarksExpanded = !orderHistoryItemsRemarksExpanded">
        Opmerkingen geschiedenis
        <mat-icon *ngIf="!orderHistoryItemsRemarksExpanded">expand_more</mat-icon>
        <mat-icon *ngIf="orderHistoryItemsRemarksExpanded">expand_less</mat-icon>
      </h3>
      <ul>
        <li *ngFor="let remark of orderHistoryItems.remarks | keyvalue">
          <span class="date">{{ remark.value.dateCreated }}</span>
          <span>{{ remark.value.remark }}</span>
        </li>
      </ul>
    </div>
  </div>

  <!-- Documents -->
  <div class="general-form-container" *ngIf="!iWmoCountiesEditingOrder">
    <div class="general-form-subheader">
      <h2>{{ getFormNumber(9) }}. Bestanden</h2>
    </div>
    <div
      class="low-row"
      [class]="orderHistoryItems && orderHistoryItems.documents && Object.keys(orderHistoryItems.documents).length > 0 ? 'low-row' : 'row'"
    >
      <app-document-uploader
        [documentsForm]="orderForm.get('documents')"
        [filePath]="tempOrderId"
        [customMetadata]="{ countyId: this.user?.countyId }"
      ></app-document-uploader>
    </div>

    <div
      class="expandable-list low-row"
      [class.expand]="orderHistoryItemsDocumentsExpanded"
      *ngIf="orderHistoryItems && orderHistoryItems.documents && Object.keys(orderHistoryItems.documents).length > 0"
    >
      <h3 (click)="orderHistoryItemsDocumentsExpanded = !orderHistoryItemsDocumentsExpanded">
        Bestanden geschiedenis
        <mat-icon *ngIf="!orderHistoryItemsDocumentsExpanded">expand_more</mat-icon>
        <mat-icon *ngIf="orderHistoryItemsDocumentsExpanded">expand_less</mat-icon>
      </h3>
      <ul>
        <ng-container *ngFor="let documentsByUploadDate of orderHistoryItems.documents | keyvalue">
          <li *ngFor="let document of documentsByUploadDate.value.documents">
            <span class="date">{{ documentsByUploadDate.value.dateCreated }}</span>
            <span>{{ document.fileName }}</span>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>

  <!-- Buttons -->
  <div id="general-form-actions" [ngClass]="{ fixed: footerFixed && isSubmitted }" *ngIf="!iWmoCountiesEditingOrder">
    <div class="general-form-actions-container">
      <button class="secondary-cta" routerLink="/orders">Annuleren</button>

      <!--
      <button class="cta" id="order-submit-button" (click)="submitForm()">
        {{ getSubmitButtonText() }}
      </button>
      -->
      <button class="cta" id="order-submit-button" (click)="openConfirmDialog()">
        {{ getSubmitButtonText() }}
      </button>
    </div>
  </div>

  <div id="general-form-actions-placeholder" [ngClass]="{ fixed: footerFixed && isSubmitted }"></div>
</form>

<app-spinner loadingMessage="{{ loadingMessage }}" *ngIf="visible == 'spinner'"></app-spinner>

<div id="general-form-footer">
  <span>Door het versturen worden deze aanvraag gegevens naar Medipoint verzonden</span>
</div>
