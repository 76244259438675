<div class="general-form-header">
  <h1>Beheer</h1>
</div>

<div class="general-form-container">

  <div class="input-icon-container margin-bottom">
      <mat-icon>search</mat-icon>
      <input matInput (keyup)="filterTable($event.target.value)" placeholder="Zoek" type="text" />
  </div>

  <table class="lister-table admins" mat-table [dataSource]="adminsTable">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Naam</th>
        <td mat-cell *matCellDef="let element">{{element.displayName}}</td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td mat-cell *matCellDef="let element">{{element.emailAddress}}</td>
      </ng-container>

      <ng-container matColumnDef="role">
          <th class="role" mat-header-cell *matHeaderCellDef>Rol</th>
          <td class="role select-td" mat-cell *matCellDef="let element">
            <select id="roles-for-{{element.userId}}" [disabled]="userId === element.userId" (change)="setAdminOrSuperAdmin(element.userId, element.displayName, $event.target.value, $event.target[$event.target.selectedIndex].text)">
              <option value="" [selected]="element.role == null">Geen</option>
              <option value="admin" [selected]="element.role === 'admin'">Admin</option>
              <option value="super_admin" [selected]="element.role === 'super_admin'">Super admin</option>
            </select>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="adminsTableColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: adminsTableColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>

</div>

<app-spinner *ngIf="showSpinner" loadingMessage=""></app-spinner>
