import { Component, OnInit, OnDestroy } from '@angular/core';
import { TitleService } from '@app/shared/services/title.service';
import { ClientService } from '@app/modules/client/services/client.service';
import { UserService, SignedInUser } from '@app/shared/services/user.service';
import { CountyService } from '@app/modules/county/services/county.service';
import { County } from '@app/modules/county/models/county';
import { ClientSearchQuery } from '@app/shared/models/client-search-query';

import { Subscription } from 'rxjs';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-outstanding-requests-page',
  templateUrl: './uitstaande-voorzieningen.component.html',
  styleUrls: ['./uitstaande-voorzieningen.component.scss'],
  animations: [
    trigger('rotateIcon', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(-180deg)' })),
      transition('rotated => default', animate('225ms ease-out')),
      transition('default => rotated', animate('225ms ease-in'))
    ])
  ]
})
export class OutstandingRequestsComponent implements OnInit, OnDestroy {
  showSpinner = false;
  searchSubmitted = false;
  searchError = false;
  clientsData: Array<any>;

  userSubscription: Subscription;
  user: SignedInUser;
  currentActiveCountyId: string;
  county: County;

  constructor(
    private titleService: TitleService,
    private clientService: ClientService,
    private userService: UserService,
    private countyService: CountyService
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Uitstaande voorzieningen');
    this.userSubscription = this.userService.user$.subscribe(async (user) => {
      if (!this.currentActiveCountyId || (user && this.currentActiveCountyId !== user.countyId)) {
        this.currentActiveCountyId = user.countyId;
        this.county = await this.countyService.getCounty(user.countyId);
        this.resetSearch();
      }
    });
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }

  async searchForClients(clientSearchQuery: ClientSearchQuery) {
    if (clientSearchQuery.birthDate || clientSearchQuery.partialLastName) {
      this.searchError = false;
      this.searchSubmitted = false;
      this.showSpinner = true;
      clientSearchQuery.allowAllRole = true;
      try {
        this.clientsData = await this.clientService.searchClients(clientSearchQuery);
        this.searchSubmitted = true;
      } catch (error) {
        this.searchError = true;
      }
      this.showSpinner = false;
    }
  }
  resetSearch() {
    this.searchError = false;
    this.searchSubmitted = false;
    this.showSpinner = false;
    this.clientsData = null;
  }
}
