import { ActivationResult, ActivateClientResponse, DeactivateClientResponse } from '@app/modules/client/models/activation-result';
import { Client } from '@app/shared/models/client';
import { CloudLoggingService, CloudLogger } from '@app/shared/services/cloud-logging.service';
import { FirestoreObservableDocument } from '@app/shared/util/firestore-observable-document';
import * as util from '@app/shared/util';

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import firebase from 'firebase/compat/app';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { ClientSearchQuery } from '@app/shared/models/client-search-query';
import { AngularFirePerformance } from '@angular/fire/compat/performance';
import { trace } from '@angular/fire/compat/performance';

export type Clients = { [key: string]: Client };

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  private cloudLog: CloudLogger;
  private activateClient$: (data: any) => Observable<any>;
  private deactivateClient$: (data: any) => Observable<any>;
  private searchClients$: (data: any) => Observable<any>;
  private getItemsClient$: (data: any) => Observable<any>;
  private getItemsDetailClient$: (data: any) => Observable<any>;

  constructor(
    cloudLoggingService: CloudLoggingService,
    functions: AngularFireFunctions,
    private firestore: AngularFirestore,
    private performance: AngularFirePerformance
  ) {
    this.cloudLog = cloudLoggingService.createLogger('client.service');
    this.activateClient$ = functions.httpsCallable('activateClient');
    this.deactivateClient$ = functions.httpsCallable('deactivateClient');
    this.searchClients$ = functions.httpsCallable('searchClients');
    this.getItemsClient$ = functions.httpsCallable('getItemsClient');
    this.getItemsDetailClient$ = functions.httpsCallable('getItemsDetailClient');
  }

  async searchClients(searchClientQuery: ClientSearchQuery): Promise<any> {
    return util.takeOneAsPromise(this.searchClients$(searchClientQuery));
  }

  getClient$(userId: string): Observable<Client> {
    return new FirestoreObservableDocument<Client>(this.firestore, {
      documentPath: ['users', userId],
      postQueryMap: (data: any) => {
        return data as Client;
      }
    }).observable$.pipe(trace('ClientService.getClient$'));
  }

  async getClient(userId: string): Promise<Client> {
    return util.takeOneAsPromise(this.getClient$(userId));
  }

  async activateClient(misaClientId: string): Promise<ActivateClientResponse> {
    const response: any = await this.activateClient$({ misaClientId }).pipe(trace('activateClient')).toPromise();

    this.cloudLog.info(`Activated client ${misaClientId}`);

    if ([ActivationResult.ACTIVATED, ActivationResult.CREATED].includes(response.result)) {
      await firebase.auth().sendPasswordResetEmail(response.emailAddress);
    }

    return response;
  }

  async activateClientByForm(activationRequest: any): Promise<ActivateClientResponse> {
    const response: any = await this.activateClient$(activationRequest).pipe(trace('activateClientByForm')).toPromise();

    this.cloudLog.info(`Activated client ${activationRequest.misaClientId}`);

    if ([ActivationResult.ACTIVATED, ActivationResult.CREATED].includes(response.result)) {
      await firebase.auth().sendPasswordResetEmail(response.emailAddress);
    }

    return response;
  }

  async deactivateClient(misaClientId: string): Promise<DeactivateClientResponse> {
    const response: any = await this.deactivateClient$({ misaClientId }).pipe(trace('deactivateClient')).toPromise();

    this.cloudLog.info(`Deactivated client ${misaClientId}`);
    return response;
  }

  async getItemsClient(misaClientId: string) {
    const items: any = await this.getItemsClient$({ misaClientId: misaClientId })
      .pipe(trace('getItemsClient'))
      .toPromise();

    const itemDetails = Promise.all(
      items.map(async (item) => {
        const itemDetail = await this.getItemsDetailClient$({ misaClientId: misaClientId, misaItemId: item.id })
          .pipe(trace('getItemsDetailClient'))
          .toPromise();
        // @ts-ignore
        return { itemId: item.id, ...itemDetail };
      })
    );
    return itemDetails;
  }

  async getItemsDetailClient(misaClientId: string, misaItemId: string) {
    return this.getItemsDetailClient$({ misaClientId: misaClientId, misaItemId: misaItemId })
      .pipe(trace('getItemsDetailClient'))
      .toPromise();
  }
}
