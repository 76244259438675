import { NotificationService } from './../../../../shared/services/notification.service';
import { Reason } from '../../../../shared/models/reason';
import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Category } from '@app/modules/contract/models/category';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-multi-product-dialog',
  templateUrl: './multi-product-dialog.component.html',
  styleUrls: ['./multi-product-dialog.component.scss']
})
export class MultiProductDialogComponent {

  displayedColumns: string[] = ['select', 'image', 'number', 'detail'];
  dataSource = new MatTableDataSource<Category>();
  selection = new SelectionModel< Category>(true, []);


  constructor(
    private dialogRef: MatDialogRef<MultiProductDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: {
      products: any[];
    },
    private notificationService: NotificationService,
  ) {
    this.dataSource.data = data.products;
    this.dataSource.data.forEach(row => this.selection.select(row));

  }

  submitForm(submit: boolean){
    if (this.selection.selected.length > 0){
      this.dialogRef.close({submit, selected: this.selection.selected});
    } else {
      this.notificationService.openSnackBar('Selecteer minstens één product');
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }
}
