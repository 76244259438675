<div [formGroup]="documentsForm">
    <div class="file-container">

        <span class="fake-label">Aanvullende documenten</span>
        <input type="file" name="order-files" id="order-files" multiple (change)="addFiles($event)" #orderFileInput />
        <label for="order-files" class="cta">
            <mat-icon>cloud_upload</mat-icon>
            <span *ngIf="documentsForm.controls.length === 0">Upload bestanden</span>
            <span *ngIf="documentsForm.controls.length > 0">Upload meer bestanden</span>
        </label>

        <ul class="file-list" *ngIf="documentsForm.length > 0">

            <li *ngFor="let document of documentsForm.controls; let documentIndex = index"
                [formGroupName]="documentIndex" [class.error]="document.touched && document.controls.type.invalid">

                <button class="remove-cta" (click)="removeFile(documentIndex)">
                    <mat-icon>clear</mat-icon>
                </button>

                <span class="icon">
                    <mat-icon
                        *ngIf="document.value.fileFormat === 'PNG' || document.value.fileFormat === 'JPG' || document.value.fileFormat === 'JPEG' || document.value.fileFormat === 'BMP' || document.value.fileFormat === 'BMP'">
                        photo
                    </mat-icon>

                    <mat-icon
                        *ngIf="document.value.fileFormat != 'PNG' && document.value.fileFormat != 'JPG' && document.value.fileFormat != 'JPEG' && document.value.fileFormat != 'BMP' && document.value.fileFormat != 'BMP'">
                        insert_drive_file
                    </mat-icon>
                </span>
                <span class="file-name">{{document.value.fileName}}</span>

                <div class="input-container" [ngClass]="{'invalid': document.touched && document.controls.type.invalid}">

                    <span *ngIf="document.touched && document.controls.type.invalid" class="error-span">
                        Selecteer een bestandstype.
                    </span>

                    <select class="file-type-select" formControlName="type" required>
                        <option disabled selected value="">Selecteer</option>
                        <option value="APPLICATION">Aanvraag</option>
                        <option value="CORRESPONDENCE">Correspondentie</option>
                        <option value="REPORT_ERGOTHERAPIST">Rapport ergotherapeut</option>
                    </select>

                </div>

            </li>

        </ul>


    </div>
</div>

<app-spinner loadingMessage="{{loadingMessage}}" *ngIf="visible=='spinner'"></app-spinner>
