import {HasObservable, LazyCachedObservable} from '@app/shared/util/observable';
import * as devLog from '@app/shared/util/dev-log';

import {AngularFirestore, QueryFn} from '@angular/fire/compat/firestore';
import { combineLatest, Observable } from 'rxjs';
import {map} from 'rxjs/operators';


export class FirestoreObservableQueryOptions<T> {
  collection: string;
  queryFunction?: QueryFn;
  postQueryFilter?: (documentData: any) => boolean;
  postQueryMap?: (documentData: any) => T;
};


export class FirestoreObservableQuery<T> implements HasObservable<{[key: string]: T}> {

  observable$: Observable<{[key: string]: T}>;

  private lazyObservable: LazyCachedObservable<{[key: string]: T}>;

  constructor(
    firestore: AngularFirestore,
    options: FirestoreObservableQueryOptions<T>
  ) {
    if (!options.postQueryFilter) {
      options.postQueryFilter = (() => true);
    }

    if (!options.postQueryMap) {
      options.postQueryMap = ((value: any) => value);
    }

    this.lazyObservable = new LazyCachedObservable<{[key: string]: T}>({
      createObservable$: this.createObservable$.bind(this, firestore, options)
    });

    this.observable$ = this.lazyObservable.observable$;
  }

  setCleanup(enableCleanup: boolean) {
    this.lazyObservable.setCleanUpAfterLastObserver(enableCleanup);
  }

  private createObservable$(firestore: AngularFirestore,
    options: FirestoreObservableQueryOptions<T>): Observable<{[key: string]: T}> {

      const collection = firestore.collection<T>(options.collection, options.queryFunction);

      return collection.snapshotChanges().pipe(
        map(actions => {
          devLog.info(`[Observable] Got Firestore query results (/${options.collection})`);

          return actions.reduce((obj, action) => {
            const doc = action.payload.doc;
            const data = doc.data() as T;

            if (options.postQueryFilter(action)) {
              obj[doc.id] = options.postQueryMap(data);
            }

            return obj;
          }, {} as {[key: string]: T});
        })
      );
    }
};


export class OrCombinedFirestoreQuery<T> implements HasObservable<{[key: string]: T}> {

  observable$: Observable<{[key: string]: T}>;

  constructor(queries: HasObservable<{[key: string]: T}>[]) {
    const lazyObservable = new LazyCachedObservable<{[key: string]: T}>({
      createObservable$: () => {
        return combineLatest(queries.map(lazy => lazy.observable$)).pipe(
          map(queryResults => {
            return queryResults.reduce((result, obj) => Object.assign(result, obj), {} as {[key: string]: T});
          })
        );
      }
    });

    lazyObservable.setCleanUpAfterLastObserver(false);
    this.observable$ = lazyObservable.observable$;
  }
};
