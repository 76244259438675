import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

import { DialogService } from '@app/shared/services/dialog.service';
import { ContactService } from '@app/modules/contact/services/contact.service';
import { UserService, SignedInUser } from '@app/shared/services/user.service';
import { TitleService } from '@app/shared/services/title.service';
import { NotificationService } from '@app/shared/services/notification.service';

import * as util from '@app/shared/util';
import * as msgLog from '@app/shared/util/error-text';

import { Subject } from '@app/modules/contact/models/subject';
import { Attachment } from '@app/modules/contact/models/attachment';

import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SubjectDialogComponent } from '../../components/subject-dialog/subject-dialog.component';
import { ErrorService } from '@app/shared/services/error.service';
import { Router } from '@angular/router';
import { take, takeUntil, takeWhile } from 'rxjs/operators';
import { formatName } from '@app/shared/models/name';

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit, OnDestroy {
  private componentActive: boolean;
  showSpinner = true;
  contactForm: UntypedFormGroup;
  footerFixed: boolean;
  subjects: Subject[];
  signedInUser: SignedInUser;
  subjectsReceived = false;
  attachments: Attachment[] = [];
  fileList: FileList | null;
  allowedFileTypes: string[] = ['PNG', 'DOC', 'DOCX', 'PDF', 'JPG', 'JPEG', 'PNG', 'BMP', 'XLS', 'XLSX', 'CSV', 'TXT', 'DAT', 'MSG'];
  uploadError: string = '';

  constructor(
    private dialogService: DialogService,
    private titleService: TitleService,
    public contactService: ContactService,
    private userService: UserService,
    private notificationService: NotificationService,
    private errorService: ErrorService,
    private router: Router,
    public dialog: MatDialog
  ) {}

  async ngOnInit(): Promise<void> {
    this.componentActive = true;
    this.titleService.setTitle('Overige aanvragen/diensten');
    this.contactForm = new UntypedFormGroup({
      subject: new UntypedFormControl('', [Validators.required]),
      name: new UntypedFormControl(null, [Validators.required]),
      email: new UntypedFormControl(null, [Validators.required, Validators.email]),
      clientDetails: new UntypedFormControl(null, []),
      remark: new UntypedFormControl(null, [Validators.required]),
      phoneNumber: new UntypedFormControl(null, [Validators.pattern('^[0-9]+$'), Validators.minLength(10), Validators.maxLength(10)])
    });

    this.userService.user$.pipe(takeWhile(() => this.componentActive), take(1)).subscribe({
      next: async (user?: SignedInUser) => {
      this.signedInUser = user;

      if (user) {
        this.contactForm.controls.name.setValue(formatName(user.name));
        this.contactForm.controls.email.setValue(user.email);
        this.contactForm.controls.phoneNumber.setValue(user.contactDetails.mobileNumber);
        this.contactService.subjects$
          .pipe(
            takeWhile(() => this.componentActive),
            takeUntil(this.userService.noUserLoggedIn$),
            take(1)
          )
          .subscribe({
              next: (subjectsMap) => {
                this.subjects = util.mapOjectToArray(subjectsMap, 'subjectId').sort((lhs, rhs) => lhs.name.localeCompare(rhs.name));
                this.showSpinner = false;
                if (this.subjectsReceived) {
                  this.notificationService.openSnackBar('Onderwerp is aangepast');
                } else {
                  this.subjectsReceived = true;
                }
              },
              error: (e) => {
                console.error(e);
                this.errorService.alertAndLog(e, 'Onderwerp kon niet geladen worden.').onClosed(async () => {
                  this.router.navigate(['']);
                });
              }
            }
          );
      }
    }});

    window.addEventListener('scroll', () => {
      const windowScrollTop = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const documentHeightWithoutWindowHeight = documentHeight - windowHeight;
      this.scrollEventListener(windowScrollTop, windowHeight, documentHeight, documentHeightWithoutWindowHeight);
    });
  }

  ngOnDestroy() {
    this.componentActive = false;
  }

  scrollEventListener(windowScrollTop: number, windowHeight, documentHeight, documentHeightWithoutWindowHeight): void {
    // fix for basic dialog removing scrollbar
    if (documentHeight === windowHeight && windowScrollTop === 0) {
      this.footerFixed = true;
    } else if (windowScrollTop >= documentHeightWithoutWindowHeight - 70) {
      this.footerFixed = false;
    } else {
      this.footerFixed = true;
    }
  }

  async submitForm(): Promise<void> {
    if (this.contactForm.invalid) {
      util.markFormGroupTouched(this.contactForm);
      this.dialogService.openErrorAlert(
        'Invoer ongeldig',
        'De wijzigingen konden niet worden opgeslagen, omdat niet alle verplichte velden zijn ingevuld.'
      );
    } else {
      this.showSpinner = true;
      try {
        let subjectName: string;
        this.subjects.forEach((subject: Subject) => {
          if (subject.subjectId === this.contactForm.value.subject) {
            subjectName = subject.name;
            return;
          }
        });

        var messageBody = {
          Naam: this.contactForm.value.name,
          'E-mail adres': this.contactForm.value.email,
          Cliëntgegevens: this.contactForm.value.clientDetails,
          clientDetails: this.contactForm.value.clientDetails,
          Opmerking: this.contactForm.value.remark,
          'Telefoonnummer consulent': this.contactForm.value.phoneNumber
        };

        await this.contactService.requestAdditionalService(
          subjectName,
          JSON.stringify(messageBody),
          this.attachments.length > 0 ? this.attachments : null,
          this.contactForm.value
        );
        this.dialogService.openInfoAlert('Aanvraag ontvangen', 'Uw aanvraag is ontvangen.').onClosed(async () => {
          this.router.navigate(['']);
        });
      } catch (error) {
        this.showSpinner = false;
        let message: string;
        message = msgLog.mapErrorTypeToContent(error.message, 'De wijzigingen konden niet worden opgeslagen.');
        this.dialogService.openErrorAlert('Opslaan mislukt', message);
      }
    }
  }

  async showAddSubjectDialog(): Promise<void> {
    const dialogRef = this.dialog.open(SubjectDialogComponent, {
      width: '80%',
      maxWidth: '750px'
    });

    dialogRef.afterClosed().subscribe(async (result: { action: string; newSubject: string }) => {
      let duplicateFound = false;
      if (result.action === 'add') {
        this.subjects.forEach((subject: Subject) => {
          if (subject.name === result.newSubject) {
            this.dialogService.openErrorAlert(`Foutmelding`, 'Onderwerp bestaat al.');
            duplicateFound = true;
            return;
          }
        });

        if (!duplicateFound) {
          await this.addSubject(result.newSubject);
        }
      }
    });
  }

  async addSubject(newSubject: string) {
    this.showSpinner = true;
    try {
      await this.contactService.addSubject(newSubject);
      this.notificationService.openSnackBar('Een onderwerp is toegevoegd');
    } catch (error) {
      this.dialogService.openErrorAlert(`Foutmelding`, 'Fout bij toevoegen van onderwerp.');
    } finally {
      this.showSpinner = false;
    }
  }

  async deleteSubject(subjectId: string): Promise<void> {
    this.showSpinner = true;
    try {
      await this.contactService.deleteSubject(subjectId);
      this.notificationService.openSnackBar('Onderwerp is verwijderd');
    } catch (error) {
      this.dialogService.openErrorAlert(`Foutmelding`, 'Fout bij verwijderen van onderwerp.');
    } finally {
      this.showSpinner = false;
    }
  }

  async addFiles(event: Event) {
    const element = event.currentTarget as HTMLInputElement;

    for (let index = 0; index < element.files.length; index++) {
      const file = element.files[index];
      const fileName = file.name;
      const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toUpperCase();
      if (this.allowedFileTypes.indexOf(fileExtension) >= 0) {
        let fileContent = await this.toBase64(file);
        console.log(file);
        //fileContent = fileContent.replace(`data:${file.type};base64,`, '');
        fileContent = fileContent.replace(/^data:(.*,)?/, '');


        this.attachments.push({
          id: util.createId(),
          name: file.name,
          content: fileContent
        });
        this.uploadError = '';
      } else {
        this.uploadError = `${fileExtension} is een ongeldig bestandstype`;
      }
    }
  }

  deleteAttachment(attachmentId: string) {
    this.attachments = this.attachments.filter((attachment: Attachment) => attachment.id !== attachmentId);
  }

  toBase64 = (file) =>
    new Promise<any>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
}
