<div class="general-form-header">
  <h1>Contactformulier</h1>
</div>
<h2>Waar gaat uw vraag over?</h2>
<div class="option-container">
  <input type="radio" (change)="onOptionChanged($event)" name="contactoption" id="option1" value="option1" />
  <label for="option1">Ik heb een vraag over een levering, voorziening of een operationele vraag.</label>
</div>
<div class="option-container">
  <input type="radio" (change)="onOptionChanged($event)" name="contactoption" id="option2" value="option2" />
  <label for="option2">Ik heb een vraag over de (technische) werking van de portal (bijv. inloggen, foutmeldingen)</label>
</div>
<div class="contact-detail" *ngIf="selectedOption === 'option1'">
  <app-about-contact-detail></app-about-contact-detail>
</div>
<div class="contact-detail" *ngIf="selectedOption === 'option2'">
  <app-about-contact-form></app-about-contact-form>
</div>
