<ng-container *ngIf="order && !loadingOrder">
  <div class="order-columns">
    <div class="column expandable image-column" (click)="toggleExpanded('product')">
      <div class="header">Afbeelding</div>
      <div class="content">
        <ng-container *ngTemplateOutlet="productImage; context: { order: order, classes: '' }"></ng-container>
      </div>
    </div>

    <div class="column order-column expandable" (click)="toggleExpanded('product')">
      <div class="header">
        <span>Aanvraag</span>
        <mat-icon [@rotateIcon]="isExpanded('product') ? 'rotated' : 'default'">expand_more</mat-icon>
      </div>
      <div class="content">
        <ng-container *ngTemplateOutlet="productImage; context: { order: order, classes: 'tablet-small-visible' }"> </ng-container>
        <ul>
          <li>
            {{ getProductIdName(product) }}
          </li>
          <li *ngIf="hasSubstate('PLANNING_FITTING_DATE')">De aanvraag wordt behandeld</li>
          <li *ngIf="hasSubstate('FITTING_DATE_PLANNED')">Er is een pasafspraak gepland</li>
          <li *ngIf="hasSubstate('PROCESSING_ADVICE', 'QUOTE_BEING_MADE')">In behandeling adviseur</li>
          <li *ngIf="order.orderCreationDate" class="order-creation-date">
            Aangevraagd op:
            <span class="nowrap">{{ order.orderCreationDate | date: 'dd-MM-yyyy' }}</span>
          </li>
          <li *ngIf="order.fittingDate" class="fitting-date">
            Pasdatum:
            <span class="nowrap">{{ order.fittingDate | date: 'dd-MM-yyyy' }}</span>
          </li>
        </ul>
      </div>
      <div class="arrow" *ngIf="isExpanded('product')">
        <div></div>
      </div>
    </div>

    <ng-container *ngTemplateOutlet="productExpanded; context: { order: order, classes: 'expanded tablet-small-visible' }"> </ng-container>

    <div
      class="column judge-column"
      [ngClass]="{
        expandable: enableJudgeColumn,
        red: redJudgeColumn,
        green: greenJudgeColumn
      }"
      (click)="enableJudgeColumn ? toggleExpanded('judge') : void"
    >
      <div class="header">
        <span>Akkoord gemeente</span>
        <mat-icon [@rotateIcon]="isExpanded('judge') ? 'rotated' : 'default'" *ngIf="enableJudgeColumn"> expand_more</mat-icon>
      </div>
      <div class="content">
        <ul>
          <li class="no-data" aria-hidden="true"></li>
          <li *ngIf="hasSubstate('QUOTE_WAITING_FOR_APPROVAL')">Wacht op akkoord offerte</li>
          <li *ngIf="hasSubstate('AWAITING_APPROVAL_DELIVERY')">Geleverd, maar wacht op akkoord</li>
          <li *ngIf="hasSubstate('QUOTE_APPROVED')">Offerte geaccordeerd</li>
          <li *ngIf="order.approvalDate" class="approval-date">
            Akkoord op:
            <span class="nowrap">{{ order.approvalDate | date: 'dd-MM-yyyy' }}</span>
          </li>
        </ul>
      </div>
      <div class="arrow" *ngIf="isExpanded('judge')">
        <div></div>
      </div>
    </div>

    <ng-container
      *ngTemplateOutlet="
        judgeExpanded;
        context: {
          order: order,
          classes: 'expanded tablet-small-visible',
          idSuffix: 'small'
        }
      "
    >
    </ng-container>

    <div class="column workplace-column">
      <div class="header">In werkplaats</div>
      <div class="content">
        <ul>
          <li class="no-data" aria-hidden="true"></li>
          <li *ngIf="hasSubstate('ORDER_PLACED', 'ORDER_RECEIVED', 'ORDER_RETURNED')">Voorziening of onderdelen in bestelling</li>
          <li *ngIf="hasSubstate('PREPARING_SERVICE')">Wordt voorbereid</li>
          <li *ngIf="hasSubstate('EXECUTING_SERVICE')">In behandeling</li>
          <li *ngIf="order.serviceDate" class="service-date">
            Gestart op:
            <span class="nowrap">{{ order.serviceDate | date: 'dd-MM-yyyy' }}</span>
          </li>
          <li *ngIf="order.serviceDateEnd" class="service-date-end">
            Klaar op:
            <span class="nowrap">{{ order.serviceDateEnd | date: 'dd-MM-yyyy' }}</span>
          </li>
        </ul>
      </div>
    </div>

    <div class="column delivery-column">
      <div class="header">Levering</div>
      <div class="content">
        <ul>
          <li class="no-data" aria-hidden="true"></li>
          <li *ngIf="hasSubstate('READY_FOR_DELIVERY', 'DELIVERY_PLANNED')">Klaar voor levering</li>
          <li *ngIf="order.plannedDeliveryDate" class="planned-delivery-date">
            Geplande leverdatum:
            <span class="nowrap">{{ order.plannedDeliveryDate | date: 'dd-MM-yyyy' }}</span>
          </li>
          <li *ngIf="order.dateDelivered" class="date-delivered">
            Geleverd op:
            <span class="nowrap">{{ order.dateDelivered | date: 'dd-MM-yyyy' }}</span>
          </li>
        </ul>
      </div>
    </div>

    <div class="column with-client-column" [class.green]="hasSubstate('DELIVERED')">
      <div class="header">
        <span>Bij client</span>
      </div>
      <div class="content">
        <ul>
          <li class="no-data" aria-hidden="true"></li>
          <li *ngIf="hasSubstate('ITEM_IN_VIEW')">Voorziening op zicht bij cliënt</li>
          <li *ngIf="hasSubstate('DELIVERED')">Geleverd</li>
          <li *ngIf="hasSubstate('RETRIEVED')">De voorziening is opgehaald</li>
          <li *ngIf="order.dateDelivered" class="weeks-since-delivery">
            In bezit
            <span *ngIf="weeksSinceDelivery == 1"> sinds {{ weeksSinceDelivery }} week </span>
            <span *ngIf="weeksSinceDelivery > 1"> sinds {{ weeksSinceDelivery }} weken </span>
          </li>
          <li *ngIf="order.plannedRetrievalDate" class="planned-retrieval-date">
            Geplande ophaaldatum:
            <span class="nowrap">{{ order.plannedRetrievalDate | date: 'dd-MM-yyyy' }}</span>
          </li>
        </ul>
      </div>
      <div class="arrow" *ngIf="isExpanded('withClient')">
        <div></div>
      </div>
    </div>
    <div
      class="column"
      [class.green]="hasSubstate('DELIVERED')"
      *ngIf="(user.isCountyOrderEditor || user.isCountyOrderViewer) && hasSubstate('DELIVERED') && !county.iWMO"
    >
      <div class="header">
        <span></span>
      </div>
      <div class="content">
        <div class="button-container">
          <button class="small-cta" (click)="doRequest()" [disabled]="!isRequestEnabled()">Verzoek indienen</button>
          <p *ngIf="!isRequestEnabled()">U heeft geen rechten om een verzoek in te dienen</p>
        </div>
      </div>
    </div>

    <ng-container *ngTemplateOutlet="withClientExpanded; context: { order: order, classes: 'expanded tablet-small-visible' }">
    </ng-container>
  </div>

  <ng-container *ngTemplateOutlet="productExpanded; context: { order: order, classes: 'expanded tablet-small-hidden' }"> </ng-container>

  <ng-container
    *ngTemplateOutlet="
      judgeExpanded;
      context: {
        order: order,
        classes: 'expanded tablet-small-hidden',
        idSuffix: ''
      }
    "
  >
  </ng-container>

  <ng-container *ngTemplateOutlet="withClientExpanded; context: { order: order, classes: 'expanded tablet-small-hidden' }"> </ng-container>
</ng-container>

<div class="spinner-container" *ngIf="!order && loadingOrder">
  <app-spinner class="absolute horizontal"></app-spinner>
</div>
<!--
[Zendesk ticket] 1198
[Comment] Orders which can not be found or result in null should not be displayed in the UI 
<p class="error-message" *ngIf="!order && !loadingOrder">
  De aanvraag (met ID: <strong>{{misaOrderId}}</strong>) kon niet worden geladen.
</p>
-->

<ng-template #productExpanded let-order="order" let-classes="classes">
  <div [class]="classes" *ngIf="isExpanded('product')">
    <mat-icon class="icon-close" (click)="hideExpanded()">close</mat-icon>

    <h3 class="product-name">{{ product.name }}</h3>
    <p class="product-description">{{ product.description }}</p>

    <div *ngIf="user.isCountyOrderEditor">
      <div class="general-btn-container order-buttons">
        <button class="remove-cta small-cta cancel-order-button" (click)="cancelOrder()" [disabled]="order.cancelable === false">
          Aanvraag annuleren
        </button>
        <button class="cta small-cta edit-order-button" (click)="editOrder()" [disabled]="!order.changeable">
          <span matTooltipClass="tooltip" [matTooltip]="!order.changeable ? 'Het is niet meer mogelijk om deze aanvraag te wijzigen' : ''"
            >Aanvraag wijzigen</span
          >
        </button>
      </div>
      <span class="error-span cannot-cancel-warning" *ngIf="order.cancelable === false">
        Het is niet (meer) mogelijk om deze aanvraag te annuleren.
      </span>
    </div>
    <app-spinner class="absolute small" *ngIf="processing"></app-spinner>
  </div>
</ng-template>

<ng-template #judgeExpanded let-order="order" let-classes="classes" let-idSuffix="idSuffix">
  <div [class]="classes" *ngIf="isExpanded('judge')">
    <form class="approval-form" #approvalForm="ngForm" (ngSubmit)="submitDecision(approvalForm)">
      <mat-icon class="icon-close" (click)="hideExpanded(); value = ''">close</mat-icon>

      <h3>Akkoord gemeente</h3>

      <p>De assemlage en levering wordt in gang gezet, zodra akkoord is gegeven op onderstaande offerte:</p>
      <button
        [disabled]="!user.isCountyOrderEditor"
        class="secondary-cta small-cta download-button"
        *ngIf="getLatestQuote()"
        (click)="downloadQuote(getLatestQuote().id)"
      >
        <span> <mat-icon>publish</mat-icon>Download offerte </span>
      </button>
      <app-spinner *ngIf="downloadingQuote" class="absolute horizontal"></app-spinner>
      <span *ngIf="!getLatestQuote()">Geen offerte beschikbaar</span>
      <div style="margin-top: 15px" *ngIf="county.iWMO">
        <span>Indien akkoord met de offerte onderneem dan de volgende stap:</span>
        <blockquote>
          Verstuur een nieuwe toewijzing (301 bericht) via iWmo o.v.v. offertenummer, geplaatst in het commentaarveld van het toegewezen
          product.
        </blockquote>
        <span>
          <i
            >Indien voorafgaand aan de offerte reeds een toewijzing (301 bericht) via iWmo verzonden is, tevens het verzoek om de incorrecte
            toewijzing in te trekken.</i
          >
        </span>
      </div>
      <ul class="order-decisions" *ngIf="user.isCountyOrderEditor">
        <li *ngIf="!county.iWMO">
          <input
            type="radio"
            name="decision"
            class="approve-radio"
            id="judge-approve-{{ misaOrderId }}-{{ idSuffix }}"
            [(ngModel)]="value"
            value="approve"
          />
          <label for="judge-approve-{{ misaOrderId }}-{{ idSuffix }}">
            <span> Ja, ik ga akkoord: ga door met assemblage en voorbereiding levering </span>
          </label>
        </li>
        <li>
          <input
            type="radio"
            name="decision"
            class="contact-radio"
            id="judge-contact-{{ misaOrderId }}-{{ idSuffix }}"
            [(ngModel)]="value"
            value="contact"
          />
          <label for="judge-contact-{{ misaOrderId }}-{{ idSuffix }}">
            Nee, ik ga niet akkoord, pauzeer deze aanvraag en neem contact met mij op: {{ formatName(user.name) }} (<a
              href="mailto://{{ user.email }}"
              >{{ user.email }}</a
            >)
          </label>
        </li>
        <li>
          <input
            type="radio"
            name="decision"
            class="reject-radio"
            id="judge-reject-{{ misaOrderId }}-{{ idSuffix }}"
            [(ngModel)]="value"
            value="reject"
          />
          <label for="judge-reject-{{ misaOrderId }}-{{ idSuffix }}">
            <span *ngIf="county.iWMO">
              Nee, ik ga niet akkoord en indien een toewijzing is verstuurd heb ik heb ervoor gezorgd dat de toewijzing via iWmo is
              ingetrokken
            </span>
            <span *ngIf="!county.iWMO"> Nee, ik ga niet akkoord. Annuleer deze aanvraag volledig </span>
          </label>
        </li>
      </ul>
      <div class="button-container" *ngIf="user.isCountyOrderEditor">
        <button class="small-cta submit-approval-button" type="submit" [disabled]="!value">Beslissing doorgeven</button>
      </div>
    </form>
    <app-spinner class="absolute small" *ngIf="processing"></app-spinner>
  </div>
</ng-template>

<ng-template #productImage let-order="order" let-classes="classes">
  <div [class]="'image-container ' + classes">
    <div *ngIf="product.imageUrl$ | async">
      <img [src]="product.imageUrl$ | async" />
    </div>
    <app-spinner class="absolute horizontal" *ngIf="!(product.imageUrl$ | async)"></app-spinner>
  </div>
</ng-template>

<ng-template #withClientExpanded let-order="order" let-classes="classes">
  <div [class]="classes" *ngIf="isExpanded('withClient')">
    <mat-icon class="icon-close" (click)="hideExpanded()">close</mat-icon>
    <h3 [class.no-margin-bottom]="county.iWMO">Bij Client</h3>
    <div class="button-container three-buttons with-client-buttons" *ngIf="!county.iWMO">
      <button [disabled]="!user.isCountyOrderEditor" class="small-cta repair-button" (click)="showRepairRequest()">
        Reparatieverzoek indienen
      </button>
      <button [disabled]="!user.isCountyOrderEditor" class="small-cta adjustment-button" (click)="showAdjustmentRequest()">
        Aanpassingsverzoek indienen
      </button>
      <button class="small-cta collection-button" (click)="showCollectionRequest()">Ophaalverzoek indienen</button>
    </div>
    <app-spinner class="absolute small" *ngIf="processing"></app-spinner>
  </div>
</ng-template>
