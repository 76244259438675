import { OrderDocumentsComponent } from './../../components/order-documents/order-documents.component';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { OrderService } from '@app/modules/order/services/order.service';
import { SignedInUser, UserService } from '@app/shared/services/user.service';
import { Subscription } from 'rxjs';
import { ClientService } from '../../services/client.service';
import * as moment from 'moment';
import { CountyService } from '@app/modules/county/services/county.service';
import { County } from '@app/modules/county/models/county';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { RequestTypeSelectorComponent } from '@app/modules/client/components/request-type-selector/request-type-selector.component';
import { RequestDialogComponent } from '@app/modules/order/components/request-dialog/request-dialog.component';
import * as msgLog from '@app/shared/util/error-text';
import { DialogService } from '@app/shared/services/dialog.service';
import { ErrorService } from '@app/shared/services/error.service';
import { ConfigService } from '@app/shared/services/config.service';
import { Config } from '@app/shared/models/config';
import { WorkHour } from '@app/shared/models/workHour';
import { outsideWorkingkHours } from '@app/shared/util';
import * as util from '@app/shared/util';
import { UrgentDialogComponent } from '@app/modules/order/components/urgent-dialog/urgent-dialog.component';

@Component({
  selector: 'app-client-home-page',
  templateUrl: './client-home-page.component.html',
  styleUrls: ['./client-home-page.component.scss'],
  animations: [
    trigger('rotateIcon', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(-180deg)' })),
      transition('rotated => default', animate('225ms ease-out')),
      transition('default => rotated', animate('225ms ease-in'))
    ]),
    trigger('clientInfoRotateIcon', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(-180deg)' })),
      transition('rotated => default', animate('225ms ease-out')),
      transition('default => rotated', animate('225ms ease-in'))
    ])
  ]
})
export class ClientHomePageComponent implements OnInit, OnDestroy {
  showSpinner = true;
  userSubscription: Subscription;
  expandVoorzieningen = false;
  expandOrders = false;
  user: SignedInUser;
  county: County;
  clientRequests: any[] = [];
  clientOrders: any[] = [];
  misaClientId: string;

  workHour: WorkHour;
  showInfoMessage = false;
  isPublicHoliday = false;
  selectedProd: any;


  allowedStatus: string[] = [
    'QUOTE_WAITING_FOR_APPROVAL',
    'PLANNING_FITTING_DATE',
    'PROCESSING_ADVICE',
    'ORDER_RECEIVED',
    'DELIVERY_PLANNED',
    'READY_FOR_DELIVERY',
    'ORDER_RETURNED',
    'EXECUTING_SERVICE',
    'PREPARING_SERVICE',
    'ORDER_PLACED',
    'QUOTE_BEING_MADE',
    'ITEM_IN_VIEW',
    'QUOTE_WAITING_FOR_APPROVAL'
  ];

  constructor(
    private clientService: ClientService,
    private userService: UserService,
    private orderService: OrderService,
    private countyService: CountyService,
    private dialog: MatDialog,
    private errorService: ErrorService,
    private dialogService: DialogService,
    private configService: ConfigService
  ) {
    this.configService.config$.subscribe({
      next: (config: Config) => {
        this.workHour = config.hours;
      }
    });
  }

  async ngOnInit(): Promise<void> {
    this.userSubscription = this.userService.user$.subscribe( async (user) => {
      this.user = user;
      const client = await this.clientService.getClient(user.userId);
      this.misaClientId = client.misaClientId;
      this.county = await this.countyService.getCounty(this.user.countyId);
      await this.loadOrders();
    });
    this.configService.getWorkDayHour().then((data: WorkHour) => {
      this.workHour = data;
      this.showInfoMessage = outsideWorkingkHours(data);
    });
    this.configService.getHolidays().then((holidays: Array<any>) => {
     const findHoliday = holidays.find(holiday => util.datesAreOnSameDay( new Date(), new Date(holiday.seconds  * 1000)));
     if (findHoliday){
        this.isPublicHoliday = true;
        this.showInfoMessage = true;
      }
    });
  }
  async ngOnDestroy(): Promise<void> {
    this.userSubscription.unsubscribe();
  }

  async loadOrders() {
    this.clientRequests = [];
    this.clientOrders = [];
    try {
      const orders = await this.orderService.getClientOrders(this.misaClientId);
      await Promise.all(
        orders.misaOrderIds.map(async (orderId) => {
          const order = await this.orderService.getOrder(orderId);
          if (this.allowedStatus.includes(order.substate, 0)) {
            order.products.map(async (product) => {
              let productName = '';
              try {
                const item: any = await this.clientService.getItemsDetailClient(this.misaClientId, product.id);
                productName = item.name.split(' - ')[0];
              } catch (error) {
                productName = product.id;
              }
              this.clientOrders.push({
                id: orderId,
                name: productName,
                isExpanded: false,
                status: this.statusText(order.substate),
                fittingDate: this.formatDate(order.fittingDate),
                orderCreationDate: this.formatDate(order.orderCreationDate),
                approvalDate: this.formatDate(order.approvalDate),
                plannedDeliveryDate: this.formatDate(order.plannedDeliveryDate),
              });
            });
          } else if (order.substate === 'DELIVERED') {
            await this.extractOrderItems({ misaOrderId: orderId, ...order });
          }
        })
      )
        .catch((err) => {
          this.dialogService.openErrorAlert('Foutmelding', 'De aanvragen konden niet worden opgehaald.');
        })
        .finally(() => {
          this.showSpinner = false;
        });
    } catch (error) {
      this.dialogService.openErrorAlert('Foutmelding', 'De aanvragen konden niet worden opgehaald.');
      this.showSpinner = false;
    }
  }

  statusText(status: string): string {
    switch (status) {
      case 'PLANNING_FITTING_DATE':
        return 'De aanvraag wordt behandeld';
      case 'FITTING_DATE_PLANNED':
        return 'Er is een pasafspraak gepland';
      case 'PROCESSING_ADVICE':
      case 'QUOTE_BEING_MADE':
        return 'In behandeling adviseur';
      case 'QUOTE_WAITING_FOR_APPROVAL':
        return 'Wacht op akkoord offerte';
      case 'QUOTE_APPROVED':
        return 'Offerte goedgekeurd';
      case 'ITEM_IN_VIEW':
        return 'Voorziening op zicht bij cliënt';
      case 'ORDER_PLACED':
      case 'ORDER_RECEIVED':
      case 'ORDER_RETURNED':
        return 'Voorziening of onderdelen in bestelling';
      case 'PREPARING_SERVICE':
        return 'Wordt voorbereid';
      case 'EXECUTING_SERVICE':
        return 'In behandeling';
      case 'READY_FOR_DELIVERY':
      case 'DELIVERY_PLANNED':
        return 'Klaar voor levering';
      case 'AWAITING_APPROVAL_DELIVERY':
        return 'Geleverd, maar wacht op akkoord';
      case 'TO_BE_PLANNED':
        return 'Reparatie in te plannen';
      default:
        return 'Onbekende status';
    }
  }

  async extractOrderItems(order: any) {
    return Promise.all(
      order.products.map(async (prod) => {
        const item: any = await this.clientService.getItemsDetailClient(this.misaClientId, prod.id);
        const nameSplitted = item.name.split(' - ');
        const formattedDeliveryDate = '';

        const filteredMaintenanceHistory = item.maintenanceHistory.filter(
          (x) =>
            (x.action === 'REPAIR' || x.action === 'ADJUST') &&
            x.rawstatus !== 'AWAITING_APPROVAL_DELIVERY' &&
            x.rawstatus !== 'DELIVERED' &&
            x.rawstatus !== 'COMPLETED'
        );
        const filteredDeployHistory = item.deployHistory.filter((x) => x.action === 'PICKUP_ORDER' && x.rawstatus !== 'COMPLETED');

        const isRequestEnabled =
          this.userService.canRequestPickup(this.user, this.county) ||
          this.userService.canRequestRepair(this.user, this.county) ||
          this.userService.canRequestAdjustment(this.user, this.county);
        const isDownloadEnabled = order.documents.length > 0 ? true : false;
        const unifiedHistory = [...filteredMaintenanceHistory, ...filteredDeployHistory];

        this.clientRequests.push({
          filteredMaintenanceHistory,
          filteredDeployHistory,
          item: { itemId: prod.id, ...item },
          formattedDeliveryDate,
          name: nameSplitted[0],
          category: nameSplitted[1],
          isRequestEnabled,
          isExpanded: false,
          misaOrderId: order.misaOrderId,
          unifiedHistory,
          isDownloadEnabled
        });
      })
    );
  }

  toggleVoorzieningen() {
    if (this.clientRequests.length > 0) {
      this.expandVoorzieningen = !this.expandVoorzieningen;
    }
  }
  toggleOrders() {
    if (this.clientOrders.length > 0) {
      this.expandOrders = !this.expandOrders;
    }
  }

  formatDate(date?: Date) {
    if (!date) {
      return '-';
    }
    return moment(date).format('DD-MM-YYYY');
  }

  toggleItem(item: any) {
    item.isExpanded = !item.isExpanded;
  }

  openDialog(prd: any) {
    this.selectedProd = prd;

    this.showInfoMessage = this.isPublicHoliday || outsideWorkingkHours(this.workHour);
    const dialogSelectorRef = this.dialog.open(RequestTypeSelectorComponent, {
      width: '400px',
      data: {
        repair: this.userService.canRequestRepair(this.user, this.county),
        adjustment: this.userService.canRequestAdjustment(this.user, this.county),
        pickup: this.userService.canRequestPickup(this.user, this.county)
      }
    });

    dialogSelectorRef.afterClosed().subscribe({
    next: (result) => {
      if (this.showInfoMessage && result === 'repair') {
        this.openUrgentDialog(result, this.selectedProd.item.itemId, this.selectedProd.misaOrderId);
       } else if (result && result !== '') {
        this.openRequestDialog(result, this.selectedProd.item.itemId, this.selectedProd.misaOrderId);
      }
    }});
  }

  openDocuments(prd: any) {
    this.showSpinner = true;
    this.orderService.getOrder(prd.misaOrderId).then(item => {
      const dialogSelectorRef = this.dialog.open(OrderDocumentsComponent, {
        width: '600px',
        data: {
          documents: item.documents,
          title:'',
        }
      });
      this.showSpinner = false;
    });
  }
  openRequestDialog(requestType: string, productId: string, misaOrderId: string) {
    const requestDialogRef = this.dialog.open(RequestDialogComponent, {
      width: '80%',
      maxWidth: '750px',
      data: {
        requestType
      }
    });

    requestDialogRef.afterClosed().subscribe((result) => {
      if (result && result.action === 'add') {
        this.showSpinner = true;
        switch (requestType) {
          case 'repair':
            this.doRepairRequest(result, misaOrderId, productId);
            break;
          case 'collection':
            this.doCollectionRequest(result, misaOrderId);
            break;
          case 'adjustment':
            this.doAdjustmentRequest(result, misaOrderId, productId);
            break;
          default:
            this.errorService.alertAndLog('Unknown requestType');
            break;
        }
      }
    });
  }

  doRepairRequest(result: any, misaOrderId: string, productId: string) {
    const remarks = result.categoryForm.controls.remarks.value;

    this.orderService
      .requestItemRepair(this.user.countyId, misaOrderId, productId, remarks)
      .then(() => {
        this.dialogService.openInfoAlert('Reparatieverzoek indienen', 'Uw reparatieverzoek is ontvangen.');
      })
      .then(() => this.loadOrders())
      .catch((reason) => {
        this.errorService.alertAndLog(reason);
      })
      .finally(() => {
        this.showSpinner = false;
      });
  }

  doAdjustmentRequest(result: any, misaOrderId: string, productId: string) {
    const remarks = JSON.stringify(result.categoryForm);

    this.orderService
      .requestItemAdjustment(this.user.countyId, misaOrderId, productId, remarks)
      .then(() => {
        this.dialogService.openInfoAlert('Aanpassingsverzoek indienen', 'Uw aanpassingsverzoek is ontvangen.');
      })
      .then(() => this.loadOrders())
      .catch((reason) => {
        this.errorService.alertAndLog(reason);
      })
      .finally(() => {
        this.showSpinner = false;
      });
  }

  doCollectionRequest(result: any, misaOrderId: string) {
    const endDate = result.categoryForm.controls.collectionDate.value.toDate();
    const remarks = result.categoryForm.controls.remarks.value ? result.categoryForm.controls.remarks.value : null;
    const endReason = result.categoryForm.controls.endReason.value ? result.categoryForm.controls.endReason.value : null;

    this.orderService
      .endOrder(misaOrderId, endDate, remarks, endReason)
      .then(() => {
        this.dialogService.openInfoAlert('Ophaalverzoek indienen', 'Uw ophaalverzoek is ontvangen.');
      })
      .then(() => this.loadOrders())
      .catch((reason) => {
        let message: string;
        message = msgLog.mapErrorTypeToContent(reason.message, 'Het goedkeuren van de offerte is mislukt.');
        this.errorService.alertAndLog(reason, message);
      })
      .finally(() => {
        this.showSpinner = false;
      });
  }
  openUrgentDialog(requestType: string, productId: string, misaOrderId: string) {
    const urgentDialogRef = this.dialog.open(UrgentDialogComponent, {
      width: '70%',
      maxWidth: '550px',
      data: {
        requestType
      }
    });
    urgentDialogRef.afterClosed().subscribe((result) => {
      if (result){
        this.openRequestDialog(requestType, productId, misaOrderId);
      }
    });
  }
}
