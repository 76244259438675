import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { OrderDocumentService } from '@app/modules/order/services/order-document.service';
import { NotificationService } from '@app/shared/services/notification.service';

@Component({
  selector: 'app-order-documents',
  templateUrl: './order-documents.component.html',
  styleUrls: ['./order-documents.component.scss']
})
export class OrderDocumentsComponent {
  selectedType: string;
  displayedColumns: string[] = ['fileName', 'status'];
  dataSourceBlo;
  dataSourceAf;
  title: string

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { documents: any[],misaOrderId: string, title: string },
    private dialogRef: MatDialogRef<OrderDocumentsComponent>,
    private readonly orderDocumentService: OrderDocumentService,
    private notificationService: NotificationService,
  ) {
    this.title = this.data.title;
    this.dataSourceBlo = this.data.documents.filter(item => item.documentType === 'LOAN_AGREEMENT').sort((n1, n2) => n1.name - n2.name);
    this.dataSourceAf = this.data.documents.filter(item => item.documentType === 'DELIVERY_SET_SIGNED_PAPERS').sort((n1, n2) => n1.name - n2.name);
  }

  closeDialog() {
    this.dialogRef.close('');
  }

  selectType(selectedType: string) {
    this.dialogRef.close(selectedType);
  }
  async downloadFile(file){
    this.orderDocumentService.downloadDocumentAsFileWithOrderId(file.id, this.data.misaOrderId).then(fileBase64 => {
      console.log(fileBase64);
    }, error => {
      this.notificationService.openSnackBar(error);
    });
  }
}
