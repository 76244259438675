<div class="general-form-header">
  <h1>
    Uitstaande voorzieningen
    <span *ngIf="county && county.name"> van gemeente {{ county.name }}</span>
  </h1>
</div>

<div class="header-row">
  <app-client-search (outputSearchTerms)="searchForClients($event)" [useDatePicker]="true"></app-client-search>
</div>

<p>Zoek op achternaam en/of geboortedatum om de uitstaande voorzieningen en de lopende verzoeken in te zien.</p>
<ng-container>
  <app-client-table-with-requests *ngIf="searchSubmitted === true"
    [prefetchedClientsData]="clientsData"
    noOrdersMessage="Er zijn geen resultaten gevonden voor uw zoekopdracht."
    errorMessage="De aanvragen konden niet worden opgehaald."
  ></app-client-table-with-requests>
</ng-container>
<app-spinner *ngIf="showSpinner" loadingMessage=""></app-spinner>
