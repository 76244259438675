<div class="container-left">
  <a routerLink="/"><img src="assets/images/medipoint-logo.png" id="header-logo"></a>
  <p *ngIf="environmentName" class="environment-name">{{environmentName}}</p>
 
  <div class="header-element menu-item-container" *ngIf="(authService.user$) | async">
    <ng-container *ngTemplateOutlet="menuItems; context: { material: false }"></ng-container>
    <ng-container *ngIf="configService.config$ | async as config">
      <hr style="margin-top: 25px;"/>
        <a *ngIf="config.footerLinks.termsAndConditions" id="footer-link-terms-and-conditions" href="{{config.footerLinks.termsAndConditions}}"
          target="_blank" class="mat-menu-item config-link">Algemene voorwaarden</a>
        <a *ngIf="config.footerLinks.privacyPolicy" id="footer-link-privacy-policy" href="{{config.footerLinks.privacyPolicy}}"
          target="_blank" class="mat-menu-item config-link">Privacybeleid</a>
      </ng-container>
  </div>

  <div class="vertical-divider"></div>

  <div class="header-element logged-in desktop-login">
    <mat-icon class="menu-button" [matMenuTriggerFor]="menu">menu</mat-icon>
    <mat-menu #menu="matMenu">
      <ng-container *ngTemplateOutlet="menuItems; context: { material: true }"></ng-container>
      <ng-container *ngIf="configService.config$ | async as config">
            <a *ngIf="config.footerLinks.termsAndConditions" id="footer-link-terms-and-conditions" href="{{config.footerLinks.termsAndConditions}}"
              target="_blank" class="mat-menu-item ng-tns-c162-0 ng-star-inserted">Algemene voorwaarden</a>
            <a *ngIf="config.footerLinks.privacyPolicy" id="footer-link-privacy-policy" href="{{config.footerLinks.privacyPolicy}}"
              target="_blank" class="mat-menu-item ng-tns-c162-0 ng-star-inserted">Privacybeleid</a>
      </ng-container>
    </mat-menu>
  </div>

<ng-template #menuItems let-material="material">
  <!-- [attr.mat-menu-item]="material" does not work. Setting the class directly does. -->
  <a [class.mat-menu-item]="material" *ngIf="isCountyOrderViewer || isCountyOrderEditor || isCountyViewer" routerLink="orders" [class.active]="currentUrl?.includes('/orders')">Aanvragen</a>
  <a [class.mat-menu-item]="material" *ngIf="(isCountyDepotViewer && countyOfSignedInUser && countyOfSignedInUser.hasDepot)" routerLink="depot" [class.active]="currentUrl?.includes('/depot')">Depot</a>
  <a [class.mat-menu-item]="material" *ngIf="isAdmin" routerLink="contracts" [class.active]="currentUrl?.includes('/contract')">Contracten</a>
  <a [class.mat-menu-item]="material" *ngIf="isClient" routerLink="client-home" [class.active]="currentUrl?.includes('/client-home')">Home</a>
  <a [class.mat-menu-item]="material" *ngIf="isClient" routerLink="client/{{this.userId}}" [class.active]="currentUrl?.includes('/client/')">Profiel</a>
  <a [class.mat-menu-item]="material" class="cursor-pointer" *ngIf="isClient" (click)="showOnboarding()">Hoe werkt het?</a>
  <a [class.mat-menu-item]="material" *ngIf="isClient" routerLink="client-contact" [class.active]="currentUrl?.includes('/client-contact')">Contact</a>
  <a [class.mat-menu-item]="material" *ngIf="isClient" routerLink="client-faq" [class.active]="currentUrl?.includes('/client-faq')">Veelgestelde vragen</a>
  <a [class.mat-menu-item]="material" *ngIf="isAdmin" routerLink="establishments" [class.active]="currentUrl?.includes('/establishment')">Vestigingen</a>
  <a [class.mat-menu-item]="material" *ngIf="isCountyOrderViewer||isCountyViewer || isCountyOrderEditor" routerLink="clients" [class.active]="currentUrl?.includes('/client')">Cli&euml;nten</a>
  <a [class.mat-menu-item]="material" *ngIf="isCountyAdmin || isCountyDepotViewer || isCountyOrderEditor || isCountyOrderViewer||isCountyViewer" routerLink="uitstaande-voorzieningen" [class.active]="currentUrl?.includes('/uitstaande-voorzieningen')">Uitstaande voorzieningen</a>
  <a [class.mat-menu-item]="material" *ngIf="isCountyAdmin" [routerLink]="'county/' + countyId" [class.active]="currentUrl?.includes('/county/')">Gemeente</a>
  <a [class.mat-menu-item]="material" *ngIf="isAdmin" routerLink="counties" [class.active]="currentUrl?.includes('/count')">Gemeenten</a>
  <a [class.mat-menu-item]="material" *ngIf="isSuperAdmin" routerLink="administrators" [class.active]="currentUrl == '/administrators'">Beheer</a>
  <a [class.mat-menu-item]="material" *ngIf="isCountyAdmin || isCountyDepotViewer || isCountyOrderEditor || isCountyOrderViewer||isCountyViewer" routerLink="county-select" [class.active]="currentUrl?.includes('/county-select')">Gemeenten</a> <!--county select for employees-->
  <a [class.mat-menu-item]="material" *ngIf="isCountyOrderEditor || isSuperAdmin" routerLink="contact" [class.active]="currentUrl?.includes('/contact')">Overige aanvragen/diensten</a>
  <a [class.mat-menu-item]="material" *ngIf="isSuperAdmin || isAdmin || isCountyAdmin || isCountyDepotViewer || isCountyOrderViewer ||isCountyViewer|| isCountyOrderEditor" routerLink="about/news" [class.active]="currentUrl == '/about/news'">Nieuws<span *ngIf="hasNewNewsItem"  class="menu-notification">&nbsp;&#x25cf;</span></a>
  <a [class.mat-menu-item]="material" *ngIf="isSuperAdmin || isAdmin || isCountyAdmin || isCountyDepotViewer || isCountyOrderViewer ||isCountyViewer||isCountyOrderEditor" routerLink="about" [class.active]="currentUrl == '/about'||currentUrl == '/about/user-manuals'||currentUrl == '/about/about-contact'">Over de Wmo portal</a>
</ng-template>
