
export enum UserRole {
  CLIENT = 'client',
  COUNTY_DEPOT_VIEWER = 'county_depot_viewer',
  COUNTY_ORDER_VIEWER = 'county_order_viewer',
  COUNTY_ORDER_EDITOR = 'county_order_editor',
  COUNTY_ADMIN = 'county_admin',
  COUNTY_VIEWER = 'county_viewer',
  ADMIN = 'admin',
  SUPER_ADMIN = 'super_admin'
}
