import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService, SignedInUser } from '@app/shared/services/user.service';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-about-news-page',
  templateUrl: './about-news-page.component.html',
  styleUrls: ['./about-news-page.component.scss']
})
export class AboutNewsPageComponent implements OnInit, OnDestroy {
  signedInUser: SignedInUser;
  private componentActive: boolean;

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.componentActive = true;
    this.userService.user$.pipe(takeWhile(() => this.componentActive)).subscribe({
    next: (user?: SignedInUser) => {
      this.signedInUser = user;
    }});
  }

  ngOnDestroy() {
    this.componentActive = false;
  }
}
