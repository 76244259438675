<div class="general-form-container">
  <div class="general-form-subheader">
    <h2>Handleidingen</h2>
  </div>
  <div class="low-row">
    <ul>
      <li *ngFor="let file of manualFiles">
        <button (click)="deleteManual(file.id)" class="remove-icon-only-cta">
          <mat-icon>delete</mat-icon>
        </button>
        <a href="{{ file.downloadURL }}" target="_blank" title="zie document" class="remove-icon-only-cta link-as-button">
          <mat-icon>launch</mat-icon>
        </a>
        <span>{{ file.title }}</span>
      </li>
    </ul>
  </div>
  <div class="low-row subject-edit-controls" *ngIf="manualFiles.length < maxAllowedFileCount">
    <button class="cta" (click)="showEditor()">+ Toevoegen</button>
  </div>
  <div class="low-row max-file-warning" *ngIf="manualFiles.length >= maxAllowedFileCount">
    U kunt maximaal {{ maxAllowedFileCount }} bestanden toevoegen.
  </div>
</div>

<ng-template #manualEditor>
  <div class="general-form-subheader">
    <h2>Nieuwe Handleiding</h2>
  </div>
  <div class="row">
    <div class="input-container">
      <input type="text" [(ngModel)]="newManual.title" required />
      <label>Titel</label>
    </div>
  </div>
  <div class="row">
    <div class="input-container">
      <input type="file" (change)="onFileChange($event)" accept=".pdf" required />
      <label>Detail</label>
    </div>
  </div>
  <div class="row">
    <button (click)="saveActiveManual()" [disabled]="!isFormValid()"  class="cta">Toevoegen</button>
  </div>
</ng-template>
