import * as category from '@app/modules/contract/models/category';
import { Contract } from '@app/modules/contract/models/contract';
import { County } from '@app/modules/county/models/county';
import { ContractService } from '@app/modules/contract/services/contract.service';
import { CountyService } from '@app/modules/county/services/county.service';
import { RequestDialogComponent } from '@app/modules/order/components/request-dialog/request-dialog.component';
import { Order } from '@app/modules/order/models/order';
import { OrderService } from '@app/modules/order/services/order.service';
import { formatName } from '@app/shared/models/name';
import { DownloadUrl } from '@app/shared/pipes/download-url.pipe';
import { DialogService } from '@app/shared/services/dialog.service';
import { ErrorService } from '@app/shared/services/error.service';
import { SignedInUser, UserService } from '@app/shared/services/user.service';
import * as util from '@app/shared/util';
import * as msgLog from '@app/shared/util/error-text';

import { trigger, style, state, transition, animate } from '@angular/animations';
import { OnInit, Component, Input, Output, EventEmitter } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import * as moment from 'moment';
import { ReplaySubject, of } from 'rxjs';

import { CloudLogger, CloudLoggingService } from '@app/shared/services/cloud-logging.service';

@Component({
  selector: 'app-single-client-order',
  templateUrl: './single-client-order.component.html',
  styleUrls: ['./single-client-order.component.scss'],
  animations: [
    trigger('rotateIcon', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(-180deg)' })),
      transition('rotated => default', animate('225ms ease-out')),
      transition('default => rotated', animate('225ms ease-in'))
    ])
  ]
})
export class SingleClientOrderComponent implements OnInit {
  private cloudLog: CloudLogger;

  productImagePlaceholderUrl = '/assets/images/medipoint-logo-small.png';

  contract?: Contract = null;
  expanded = '';
  formatName = formatName;
  loadingOrder = true;
  processing = false;
  user: SignedInUser;
  county: County;
  order?: Order = null;

  greenJudgeColumn = false;
  product?: any;
  redJudgeColumn = false;
  weeksSinceDelivery?: number;

  @Input()
  misaOrderId: string;

  @Output()
  productName = new EventEmitter<string>();

  @Output()
  productId = new EventEmitter<string>();

  @Output()
  orderLoaded = new EventEmitter<{ misaOrderId: string; result: boolean }>();

  constructor(
    private cloudLoggingService: CloudLoggingService,
    private contractService: ContractService,
    private countyService: CountyService,
    private dialogService: DialogService,
    private downloadUrl: DownloadUrl,
    private errorService: ErrorService,
    private orderService: OrderService,
    private userService: UserService,
    private dialog: MatDialog
  ) { }

  async ngOnInit(): Promise<void> {
    this.cloudLog = this.cloudLoggingService.createLogger(`single-client-order.component(misaOrderId: ${this.misaOrderId})`);
    try {
      this.user = util.checkObject(await this.userService.getUser());
      this.county = await this.countyService.getCounty(this.user.countyId);
      this.contract = await this.contractService.getContract(this.county.contractId);
    } catch (error) {
      this.loadingOrder = false;
      return;
    }

    await this.load();
  }

  async load(): Promise<void> {
    this.expanded = '';
    this.loadingOrder = true;
    this.order = null;
    this.processing = false;

    try {
      await this.fetchOrder();
      this.orderLoaded.emit({ misaOrderId: this.misaOrderId, result: true });
    } catch (error) {
      this.cloudLog.error(`Error getting order from MISA (${error})`);
      this.orderLoaded.emit({ misaOrderId: this.misaOrderId, result: false });
    } finally {
      this.loadingOrder = false;
    }
  }

  hasSubstate(...substates: string[]) {
    return substates.some((substate) => substate === this.order.substate);
  }

  isExpanded(column: string): boolean {
    return this.expanded === column;
  }

  toggleExpanded(expandedColumn: string): void {
    this.expanded = this.expanded === expandedColumn ? '' : expandedColumn;
  }

  hideExpanded(): void {
    this.expanded = '';
  }

  private async fetchOrder(): Promise<void> {
    const order = util.checkObject(await this.orderService.getOrder(this.misaOrderId));
    const product = order.products.length > 0 ? order.products[0] : null;

    this.order = order;

    this.greenJudgeColumn = [
      'QUOTE_APPROVED',
      'ITEM_IN_VIEW',
      'ORDER_PLACED',
      'ORDER_RECEIVED',
      'PREPARING_SERVICE',
      'ORDER_RETURNED',
      'EXECUTING_SERVICE',
      'READY_FOR_DELIVERY',
      'DELIVERY_PLANNED',
      'SERVICE_RETRIEVAL_PLANNED',
      'RETRIEVAL_ON_NEW_DELIVERY',
      'TEMPORARY_STORAGE',
      'DELIVERED',
      'RETRIEVED',
      'PLANNING_SERVICE_DATE',
      'SERVICE_DATE_PLANNED'
    ].includes(order.substate);

    (this.product = product
      ? {
        description: undefined,
        id: product.id,
        imageUrl$: undefined,
        name: undefined,
        productId: product.productId
      }
      : undefined),
      (this.redJudgeColumn = ['QUOTE_WAITING_FOR_APPROVAL', 'AWAITING_APPROVAL_SERVICE', 'AWAITING_APPROVAL_DELIVERY'].includes(
        order.substate
      ));

    this.weeksSinceDelivery = order.dateDelivered ? moment().diff(moment(order.dateDelivered), 'weeks') : 0;

    this.setProductInfo();
  }

  private setProductInfo(): void {
    const productId = this.product.productId;
    const product = category.findProductInCategories(productId, this.contract.categories);

    if (!product) {
      this.product.imageUrl$ = of(this.productImagePlaceholderUrl);
      return;
    }

    this.productName.emit(product.name);
    this.productId.emit(product.alladinId);

    this.product.description = product.description;
    this.product.name = product.name;

    const urlSubject = new ReplaySubject<string>(1);

    this.downloadUrl.transform(product.image.storageFilePath, 'contract-images').subscribe(urlSubject);

    this.product.imageUrl$ = urlSubject.asObservable();
  }

  showRepairRequest() {
    const dialogRef = this.dialog.open(RequestDialogComponent, {
      width: '80%',
      maxWidth: '750px',
      data: {
        requestType: 'repair'
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.action === 'add') {
        this.processing = true;
        const remarks = result.categoryForm.controls.remarks.value;

        this.orderService
          .requestItemRepair(this.user.countyId, this.misaOrderId, this.product.id, remarks)
          .then(() => {
            this.dialogService.openInfoAlert(
              'Reparatieverzoek indienen',
              'Uw reparatieverzoek is ontvangen. We nemen spoedig contact met u op.'
            );
          })
          .catch((reason) => {
            this.errorService.alertAndLog(reason);
          })
          .finally(() => {
            this.processing = false;
          });
      }
    });
  }

  showAdjustmentRequest() {
    const dialogRef = this.dialog.open(RequestDialogComponent, {
      width: '80%',
      maxWidth: '750px',
      data: {
        requestType: 'adjustment'
      }
    });

    dialogRef.afterClosed().subscribe((result) => {

      if (result && result.action === 'add') {
        this.processing = true;
        const remarks = JSON.stringify(result.categoryForm);

        this.orderService
          .requestItemAdjustment(this.user.countyId, this.misaOrderId, this.product.id, remarks)
          .then(() => {
            this.dialogService.openInfoAlert(
              'Aanpassingsverzoek indienen',
              'Uw aanpassingsverzoek is ontvangen. We nemen spoedig contact met u op.'
            );
          })
          .catch((reason) => {
            this.errorService.alertAndLog(reason);
          })
          .finally(() => {
            this.processing = false;
          });
      }
    });
  }

  showCollectionRequest() {
    const dialogRef = this.dialog.open(RequestDialogComponent, {
      width: '80%',
      maxWidth: '750px',
      data: {
        requestType: 'collection'
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.action === 'add') {
        this.processing = true;
        const endDate = result.categoryForm.controls.collectionDate.value.toDate();
        const remarks = result.categoryForm.controls.remarks.value ? result.categoryForm.controls.remarks.value : null;
        const endReason = result.categoryForm.controls.endReason.value ? result.categoryForm.controls.endReason.value : null;

        this.orderService
          .endOrder(this.misaOrderId, endDate, remarks, endReason)
          .then(() => {
            this.dialogService.openInfoAlert('Ophaalverzoek indienen', 'Uw afhaalverzoek is ontvangen. We komen spoedig bij u terug.');
          })
          .catch((reason) => {
            let message: string;
            message = msgLog.mapErrorTypeToContent(reason.message, 'Het goedkeuren van de offerte is mislukt.');
            this.errorService.alertAndLog(reason, message);
          })
          .finally(() => {
            this.processing = false;
          });
      }
    });
  }
}
