<div class="page-header-container">
  <div class="home-header">
    <h1>Welkom,</h1>
    <p>in de 'Mijn Wmo' klantenportal van Medipoint.</p>
  </div>
  <div class="spacer"></div>
  <div class="contact-btn">
    <a class="cta white-btn" [routerLink]="['/client-contact']">Neem contact op</a>
  </div>
</div>


<div class="item-container">
  <div class="header-row hover-hand" (click)="toggleVoorzieningen()">
    <h4 class="container-header-text">Mijn voorzieningen</h4>
    <div class="badge-expand">
      <span class="item-count-badge" matBadge="{{clientRequests.length}}" matBadgeOverlap="false"></span>
      <mat-icon [@rotateIcon]="expandVoorzieningen ? 'rotated' : 'default'">expand_more</mat-icon>
   </div>
  </div>
  <ng-container *ngIf="expandVoorzieningen">
    <div class="pb-20" *ngIf="clientRequests.length > 0">
      <div *ngFor="let prd of clientRequests">
        <div class="columns hover-hand" (click)="toggleItem(prd)">
          <div class="column item-header-cell">{{ prd.name }}</div>
          <div class="column item-header-cell">
            <div>
              <mat-icon [@rotateIcon]="prd.isExpanded ? 'rotated' : 'default'">expand_more</mat-icon>
            </div>
            <div *ngFor="let requestItem of prd.unifiedHistory">
              <span *ngIf="requestItem.action === 'REPAIR'" class="maintenance-type-badge repair">R</span>
              <span *ngIf="requestItem.action === 'ADJUST'" class="maintenance-type-badge adjustment">A</span>
              <span *ngIf="requestItem.action === 'PICKUP_ORDER'" class="maintenance-type-badge pickup">O</span>
            </div>
          </div>
        </div>
        <div class="columns columns-bg" *ngIf="prd.isExpanded">
          <div class="column column-additional-padding">
            <div class="column-header">Voorziening gegevens</div>
            <div class="item-heading">Voorzieningsnummer:</div>
            <div class="value">{{ prd.item.itemId }}</div>
            <div class="item-heading">Type:</div>
            <div class="value">{{ prd.category }}</div>
          </div>
          <div class="column column-additional-padding">
            <div class="column-header">Lopende verzoeken</div>
            <div class="maintenance-item-container">
              <span *ngIf="prd.filteredMaintenanceHistory.length + prd.filteredDeployHistory.length === 0"
                >Er zijn geen lopende verzoeken voor deze cliënt</span
              >
              <div class="maintenance-item" *ngFor="let requestItem of prd.filteredMaintenanceHistory">
                <div class="value-title">Type verzoek:</div>
                <div>
                  <span *ngIf="requestItem.action === 'REPAIR'" class="maintenance-type-badge repair">R</span>
                  <span *ngIf="requestItem.action === 'ADJUST'" class="maintenance-type-badge adjustment">A</span>
                  <span class="bold-label">
                    <ng-container *ngIf="requestItem.action === 'REPAIR'"> Reparatie </ng-container>
                    <ng-container *ngIf="requestItem.action === 'ADJUST'"> Aanpassing </ng-container>
                  </span>
                </div>
                <div class="item-heading">Ingediend op:</div>
                <div class="bold-label">{{ formatDate(requestItem.dateInput) }}</div>
                <ng-container *ngIf="requestItem.status !== undefined && requestItem.status.length > 0">
                  <div class="item-heading">Status:</div>
                  <div class="bold-label">{{ requestItem.status }}</div>
                </ng-container>
              </div>
              <div class="maintenance-item" *ngFor="let requestItem of prd.filteredDeployHistory">
                <div class="item-heading">Type verzoek:</div>
                <div>
                  <span class="maintenance-type-badge pickup">O</span>
                  <span class="bold-label"> Ophalen </span>
                </div>
                <div class="item-heading">Ingediend op:</div>
                <div class="bold-label">{{ formatDate(requestItem.dateInput) }}</div>
                <ng-container *ngIf="requestItem.status !== undefined && requestItem.status.length > 0">
                  <div class="item-heading">Status:</div>
                  <div class="bold-label">{{ requestItem.status }}</div>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="column column-additional-padding">
            <div class="column-header">Acties</div>
            {{isDownloadEnabled}}
            <button class="small-cta" (click)="openDialog(prd)" [disabled]="!prd.isRequestEnabled">Verzoek indienen </button> <br>
            <!--<button class="small-cta gray" (click)="openDocuments(prd)"  [disabled]="!prd.isDownloadEnabled" >Bekijk downloads </button> -->
            <p *ngIf="!prd.isRequestEnabled">U heeft geen rechten om een verzoek in te dienen</p>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div class="item-container">
  <div class="header-row hover-hand" (click)="toggleOrders()">
    <h4 class="container-header-text">Mijn aanvragen</h4>
    <div class="badge-expand">
     <span class="item-count-badge" matBadge="{{clientOrders.length}}" matBadgeOverlap="false"></span>
      <mat-icon [@rotateIcon]="expandOrders ? 'rotated' : 'default'">expand_more</mat-icon>
  </div>
  </div>
  <ng-container *ngIf="expandOrders">
    <div class="pb-20 inner-content" *ngIf="clientOrders.length > 0">
      <div *ngFor="let prd of clientOrders">
        <div class="columns hover-hand" (click)="toggleItem(prd)">
          <div class="column item-header-cell">{{prd.name}}</div>
          <div class="column item-header-cell">
            <div>
              <mat-icon [@rotateIcon]="prd.isExpanded ? 'rotated' : 'default'">expand_more</mat-icon>
            </div>
          </div>
        </div>
        <div class="columns columns-bg" *ngIf="prd.isExpanded">
          <div class="column column-additional-padding">
            <div class="column-header">Aanvraag</div>
            <div class="item-heading">Status:</div>
            <div class="value">{{prd.status}}</div>
            <div class="item-heading">Aanvraagdatum:</div>
            <div class="value">{{prd.orderCreationDate}}</div>
            <div class="item-heading">Pasdatum:</div>
            <div class="value">{{prd.fittingDate}}</div>
          </div>
          <div class="column column-additional-padding">
            <div class="column-header">Akkoord gemeente</div>
            <div class="item-heading">Akkoord op:</div>
            <div class="value">{{prd.approvalDate}}</div>
          </div>
          <div class="column column-additional-padding">
            <div class="column-header">Levering</div>
            <div class="item-heading">Geplande leverdatum:</div>
            <div class="value">{{prd.plannedDeliveryDate}}</div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<app-spinner *ngIf="showSpinner" loadingMessage=""></app-spinner>
