import {Component, ViewChild, OnDestroy, OnInit} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';

import {ContractService} from '@app/modules/contract/services/contract.service';
import {CountyService} from '@app/modules/county/services/county.service';
import { TitleService } from '@app/shared/services/title.service';
import {County} from '@app/modules/county/models/county';
import {ErrorService} from '@app/shared/services/error.service';
import {NotificationService} from '@app/shared/services/notification.service';
import * as util from '@app/shared/util';
import { takeWhile } from 'rxjs/operators';


@Component({
  selector: 'app-counties-page',
  templateUrl: './counties-page.component.html',
  styleUrls: ['./counties-page.component.scss']
})
export class CountiesPageComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator, {static: true})
  tablePaginator: MatPaginator;

  countiesTable: MatTableDataSource<County>;
  countiesTableColumns = ['name', 'contract', 'edit'];
  showCountiesSpinner = true;

  private countiesSubscription: Subscription;
  private countiesReceived = false;

  constructor(
    private errorService: ErrorService,
    private contractService: ContractService,
    private countyService: CountyService,
    private notificationService: NotificationService,
    private router: Router,
    private titleService: TitleService
  ) {}

  ngOnInit() {
    this.titleService.setTitle('Gemeenten');

    this.countiesSubscription = this.countyService.counties$
      .pipe(takeWhile(() => !this.countiesReceived))
      .subscribe({
      next: countiesMap => {
      const counties = util.mapOjectToArray<County>(countiesMap, 'countyId')
        .sort((lhs, rhs) => lhs.name.localeCompare(rhs.name));

      counties.forEach((county: County) => {
        county['contract'] = this.contractService.getContract(county.contractId);
      });

      this.countiesTable = new MatTableDataSource(counties);
      this.countiesTable.paginator = this.tablePaginator;
      this.showCountiesSpinner = false;

      if (this.countiesReceived) {
        this.notificationService.openSnackBar('De lijst met gemeentes is bijgewerkt');
      } else {
        this.countiesReceived = true;
      }
    },
    error: error => {
      this.errorService.alertAndLog(error, 'Het laden van de gemeentes is mislukt.').onClosed(async () => {
        this.router.navigate(['']);
      });
    }});
  }

  ngOnDestroy() {
    this.countiesSubscription.unsubscribe();
  }

  filterTable(filterValue: string) {
    this.countiesTable.filter = filterValue.trim().toLowerCase();
  }
};
