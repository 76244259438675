import { WorkHour } from './../../../../shared/models/workHour';
import { Component, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Establishment } from '@app/modules/establishment/models/establishment';
import { EstablishmentService } from '@app/modules/establishment/services/establishment.service';
import { ConfigService } from '@app/shared/services/config.service';
import { outsideWorkingkHours } from '@app/shared/util';
import * as moment from 'moment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-urgent-dialog',
  templateUrl: './urgent-dialog.component.html',
  styleUrls: ['./urgent-dialog.component.scss']
})
export class UrgentDialogComponent {
  public requestType: string;

  holidays: Array<moment.Moment> = [];
  workHour: any;
  tomorrow: moment.Moment = moment.utc().add(1, 'days').startOf('day');
  showInforMessage = false;
  establishmentOfSignedInUser: Establishment;
  processing = false;

  private establishmentOfSignedInUserSubscription: Subscription;

  constructor(
    configService: ConfigService,
    private dialogRef: MatDialogRef<UrgentDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: {
      requestType: string;
    },
    private establishmentService: EstablishmentService,
    private dialog: MatDialog,
  ) {
    this.requestType = data.requestType;
    configService.getHolidays().then((days: Array<any>) => {
      days.forEach((d) => {
        this.holidays.push(moment(d.toDate()).startOf('day'));
      });
    });
    configService.getWorkDayHour().then((workHour: WorkHour) => {
      this.workHour = workHour;
      this.showInforMessage = outsideWorkingkHours(this.workHour);

    });
    this.establishmentOfSignedInUserSubscription = this.establishmentService.establishmentOfSignedInUser$.subscribe((establishment) => {
      this.establishmentOfSignedInUser = establishment;
    });

  }
  closeDialog() {
    this.dialogRef.close(false);
  }
  createRequest(){
    this.dialogRef.close(true);
  }
  getContactDetails() {
    const details: string[] = [];
    if (this.establishmentOfSignedInUser) {
      if (this.establishmentOfSignedInUser.contactPerson.phoneNumber) {
        details.push(
          `<a href="tel:${this.establishmentOfSignedInUser.contactPerson.phoneNumber}">${this.establishmentOfSignedInUser.contactPerson.phoneNumber}</a>`
        );
      }
    }

    return details.join(' / ');
  }
}
