import { Component, Input, OnInit } from '@angular/core';
import { County } from '@app/modules/county/models/county';
import { CountyService } from '@app/modules/county/services/county.service';
import { DialogService } from '@app/shared/services/dialog.service';
import { UserService } from '@app/shared/services/user.service';

@Component({
  selector: 'app-county-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss']
})
export class CountyNotificationSettingsComponent implements OnInit {
  @Input() countyId: string;
  county: County;
  isFormEnabled: boolean = true;

  constructor(private countyService: CountyService, private dialogService: DialogService, private userService: UserService) {}

  async ngOnInit(): Promise<void> {
    const user = await this.userService.getUser();
    this.county = await this.countyService.getCounty(this.countyId);
    if (!(user.isCountyAdmin || user.isAdmin)) {
      this.isFormEnabled = false;
    }
  }

  submitForm() {
    if (!this.isFormValid()) {
      this.dialogService.openErrorAlert(
        'Invoer ongeldig',
        'De invoer is ongeldig. ' + 'Pas de gegevens aan en probeer de gemeente opnieuw aan te maken of te wijzigen.'
      );
    } else {
      this.countyService.updateCounty(this.countyId, this.county);
      this.dialogService.openInfoAlert('Succes', 'Meldingsgegevens opgeslagen.');
    }
  }

  isFormValid() {
    if (this.county.notifyOn) {
      return this.isEmail(this.county.notifyEmail);
    }

    return true;
  }

  isEmail(search: string): boolean {
    var serchfind: boolean;
    var regexp = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    serchfind = regexp.test(search);
    return serchfind;
  }
}
