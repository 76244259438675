
export interface Category {
  type: string;
  name: string;
  description?: string;
  alladinId?: string;
  image: {
    name: string;
    storageFilePath: string;
  };
  categories: Category[];
  panelOpenState: boolean;
  selected: boolean;
  selectedCount: number;

}


export const findProductInCategories = (productId: string, categories: Category[]): Category | undefined => {
  for (const category of categories) {
    const product = findProduct(productId, category);

    if (product) {
      return product;
    }
  }
}


const findProduct = (productId: string, category: Category): Category | undefined => {
  if (category.type === 'product') {
    if (category.alladinId === productId) {
      return category;
    }
  } else if (category.type === 'category') {
    return findProductInCategories(productId, category.categories);
  }
}
