import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-request-type-selector',
  templateUrl: './request-type-selector.component.html',
  styleUrls: ['./request-type-selector.component.scss']
})
export class RequestTypeSelectorComponent {
  selectedType: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { repair: boolean; adjustment: boolean; pickup: boolean },
    private dialogRef: MatDialogRef<RequestTypeSelectorComponent>
  ) {
    this.selectedType = data.repair ? 'repair' : 'collection';
  }

  closeDialog() {
    this.dialogRef.close('');
  }

  selectType(selectedType: string) {
    this.dialogRef.close(selectedType);
  }
}
