<div class="general-form-header">
    <h1>Vestigingen</h1>
    <a routerLink="/establishment" class="secondary-cta" href="/establishment">+ Vestiging toevoegen</a>
  </div>

  <div class="general-form-container">

    <div class="input-icon-container margin-bottom">
        <mat-icon>search</mat-icon>
        <input matInput (keyup)="filterTable($event.target.value)" placeholder="Zoek" type="text" />
    </div>

    <table class="lister-table establishments" mat-table [dataSource]="establishmentsTable">

        <ng-container matColumnDef="name">
            <th class="name" mat-header-cell *matHeaderCellDef> Naam </th>
            <td class="name" mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="address">
            <th class="address" mat-header-cell *matHeaderCellDef> Adres </th>
            <td class="address" mat-cell *matCellDef="let element">
              {{element.address.street}}
              {{element.address.houseNumber}}<span *ngIf="element.address.houseNumberAddition">{{element.address.houseNumberAddition}}</span>, {{element.address.postalCode}} {{element.address.city}}
            </td>
        </ng-container>

        <ng-container matColumnDef="contactPerson">
            <th class="contactPerson" mat-header-cell *matHeaderCellDef> Contactpersoon </th>
            <td class="contactPerson" mat-cell *matCellDef="let element">
              {{element.contactPerson.initials}}
              <span *ngIf="element.contactPerson.firstName">({{element.contactPerson.firstName}}) </span>
              <span *ngIf="element.contactPerson.prefix">{{element.contactPerson.prefix}} </span>
              {{element.contactPerson.lastName}}
            </td>
        </ng-container>

        <ng-container matColumnDef="edit">
            <th class="edit cta-td" mat-header-cell *matHeaderCellDef>  </th>
            <td class="edit cta-td" mat-cell *matCellDef="let element">
                <a class="cta"
                    routerLink="/establishment/{{element.establishmentId}}"
                    href="/establishment/{{element.establishmentId}}">
                    Bijwerken
              </a>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="establishmentsTableColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: establishmentsTableColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>

  </div>

  <app-spinner *ngIf="showEstablishmentsSpinner" loadingMessage=""></app-spinner>
