<div [formGroup]="clientForm">

    <div class="general-form-container" formGroupName="name" *ngIf="!iwmo">
        <div class="general-form-subheader">
          <h2>{{headerIndex}}. Naam</h2>
        </div>

        <div class="row">

            <div class="input-container"
                [ngClass]="{'invalid': (clientForm['controls'].name['controls'].initials.touched && clientForm['controls'].name['controls'].initials.invalid)}">
                <app-error-messages [formControls]="clientForm['controls'].name['controls'].initials"></app-error-messages>
                <input #initials type="text"
                    (blur)="clientForm.patchValue({ name: {initials: util.formatInitials(initials.value)}} )"
                    formControlName="initials" name="initials" id="initials" required />
                <label>Voorletters</label>
            </div>

            <div class="input-container">
                <app-error-messages [formControls]="clientForm['controls'].name['controls'].firstName"></app-error-messages>
                <input type="text" formControlName="firstName" name="firstName" />
                <label>Voornaam</label>
            </div>

        </div>

        <div class="row">

            <div class="input-container prefix">
                <app-error-messages [formControls]="clientForm['controls'].name['controls'].prefix"></app-error-messages>
                <input type="text" formControlName="prefix" />
                <label>Tussenvoegsel(s)</label>
            </div>

            <div class="input-container"
                [ngClass]="{'invalid': (clientForm['controls'].name['controls'].lastName.touched && clientForm['controls'].name['controls'].lastName.invalid)}">
                <app-error-messages [formControls]="clientForm['controls'].name['controls'].lastName"></app-error-messages>
                <input type="text" formControlName="lastName" name="lastName"
                [required]="util.hasValidator(clientForm, 'name.lastName', 'required')" />
                <label matTooltip="Hier alleen als achternaam de eigen naam invullen.
                Bij dubbel naam gebruik van de partner hier dan alleen de meisjesnaam invoeren
                en bij Achternaam partner de partnernaam.
                Met naamgebruik kun je aangeven hoe de cliënt aangesproken dient te worden.">
                    Achternaam (eigen naam) <span class="info"><mat-icon>info</mat-icon></span>
                </label>
            </div>

        </div>

        <div class="row">

            <div class="input-container prefix">
                <app-error-messages [formControls]="clientForm['controls'].name['controls'].partnerNamePrefix">
                </app-error-messages>
                <input type="text" formControlName="partnerNamePrefix" />
                <label>Tussenvoegsels partner</label>
            </div>

            <div class="input-container">
                <app-error-messages [formControls]="clientForm['controls'].name['controls'].partnerName">
                </app-error-messages>
                <input type="text" formControlName="partnerName" [required]="util.hasValidator(clientForm, 'name.partnerName', 'required')" />
                <label>Achternaam partner</label>
            </div>

        </div>

        <div class="low-row">
            <div class="input-container">
                <app-error-messages [formControls]="clientForm['controls'].name['controls'].nameUsage"></app-error-messages>
                <select formControlName="nameUsage" name="nameUsage" required>
                    <option value="FAMILY_NAME_ONLY">Achternaam</option>
                    <option value="PARTNER_NAME_ONLY">Partnernaam</option>
                    <option value="PARTNER_NAME_FOLLOWED_BY_FAMILY_NAME">Partnernaam gevolgd door achternaam</option>
                    <option value="FAMILY_NAME_FOLLOWED_BY_PARTNER_NAME">Achternaam gevolgd door partnernaam</option>
                </select>
                <label>Gebruik partnernaam</label>
            </div>
        </div>

    </div>

    <div class="general-form-container" formGroupName="contactDetails" *ngIf="!iwmo">
        <div class="general-form-subheader">
          <h2>{{headerIndex+1}}. Contactgegevens</h2>
        </div>

        <div class="row">

            <div class="input-container"
                [ngClass]="{'invalid': (clientForm['controls'].contactDetails['controls'].emailAddress.touched && clientForm['controls'].contactDetails['controls'].emailAddress.invalid)}">
                <app-error-messages [formControls]="clientForm['controls'].contactDetails['controls'].emailAddress">
                </app-error-messages>
                <input type="email" formControlName="emailAddress" name="emailAddress" />
                <label>Emailadres</label>
            </div>

        </div>

        <div class="low-row">

            <div class="input-container"
                [ngClass]="{'invalid': (clientForm['controls'].contactDetails['controls'].phoneNumber.touched && clientForm['controls'].contactDetails['controls'].phoneNumber.invalid)}">
                <app-error-messages marginBottom=true
                    [formControls]="clientForm['controls'].contactDetails['controls'].phoneNumber">
                </app-error-messages>
                <input type="text" formControlName="phoneNumber" name="phoneNumber" />
                <label matTooltip="Een geldig Nederlands telefoonnummer bestaat uit 10 cijfers.">
                    Telefoonnummer (vast) <span class="info"><mat-icon>info</mat-icon></span>
                </label>
            </div>

            <div class="input-container"
                [ngClass]="{'invalid': (clientForm['controls'].contactDetails['controls'].mobileNumber.touched && clientForm['controls'].contactDetails['controls'].mobileNumber.invalid)}">
                <app-error-messages marginBottom=true
                    [formControls]="clientForm['controls'].contactDetails['controls'].mobileNumber"></app-error-messages>
                <input type="text" formControlName="mobileNumber" name="mobileNumber" />
                <label matTooltip="Een geldig Nederlands mobiel nummer bestaat uit 10 cijfers en begint met 06.">
                    Telefoonnummer (mobiel) <span class="info"><mat-icon>info</mat-icon></span>
                </label>
            </div>

        </div>

        <div class="low-row">
            <span
                [ngClass]="{'toggle-error-span': true, 'invalid': (clientForm['controls'].contactDetails.touched && clientForm['controls'].contactDetails.errors && clientForm['controls'].contactDetails.errors.atLeastOne)}">
                Minimaal een email adres of telefoonnummer is verplicht.
            </span>
        </div>

    </div>


    <div class="general-form-container">
        <div class="general-form-subheader">
          <h2>{{iwmo ? "2" : headerIndex+2}}. Persoonsgegevens</h2>
        </div>

        <div class="row" *ngIf="newClient && newOrder && socialSecurityNumberRequired">

            <div class="input-container"
                [ngClass]="{'invalid': (clientForm['controls'].socialSecurityNumber.touched && clientForm['controls'].socialSecurityNumber.invalid)}">
                <app-error-messages [formControls]="clientForm['controls'].socialSecurityNumber">
                </app-error-messages>
                <input type="text" formControlName="socialSecurityNumber" name="socialSecurityNumber" required />
                <label>Burgerservicenummer (BSN)</label>
            </div>

        </div>

        <div class="row" *ngIf="(!newClient || !newOrder) && socialSecurityNumberRequired">
          <div class="input-container">
            <input type="text" value="XXXXXXXXX" disabled required />
            <label>Burgerservicenummer (BSN)</label>
          </div>
        </div>

        <div class="row">

            <div class="input-container"
                [ngClass]="{'invalid': (clientForm['controls'].countyCustomerNumber.touched && clientForm['controls'].countyCustomerNumber.invalid)}">
                <app-error-messages [formControls]="clientForm['controls'].countyCustomerNumber">
                </app-error-messages>
                <input type="text" formControlName="countyCustomerNumber" name="countyCustomerNumber" [required]="!socialSecurityNumberRequired" />
                <label>Gemeente relatienummer</label>
            </div>

        </div>

        <div class="row date-row">

            <div class="input-container"
                [ngClass]="{'invalid': (clientForm['controls'].birthDate.touched && clientForm['controls'].birthDate.invalid)}">
                <app-error-messages [formControls]="clientForm['controls'].birthDate"></app-error-messages>
                <input [matDatepicker]="birthDatePicker" id="birthDate" placeholder="Geboortedatum"
                    formControlName="birthDate" name="birthDate" required />
                <label>Geboortedatum</label>
            </div>
            <mat-datepicker-toggle matSuffix [for]="birthDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #birthDatePicker startView="multi-year" [startAt]="birthDateStartDate"></mat-datepicker>

        </div>

        <div class="low-row">

            <div class="input-container"
                [ngClass]="{'invalid': (clientForm['controls'].gender.touched && clientForm['controls'].gender.invalid)}">
                <app-error-messages [formControls]="clientForm['controls'].gender"></app-error-messages>
                <select name="gender" formControlName="gender" name="gender" required>
                    <option value="MALE">Man</option>
                    <option value="FEMALE">Vrouw</option>
                    <option value="UNKNOWN">Onbekend</option>
                </select>
                <label>Geslacht</label>
            </div>

        </div>

    </div>

    <!-- Address -->
    <div class="general-form-container">
        <div class="general-form-subheader">
          <h2>{{iwmo ? "3" : headerIndex+3}}. Adres</h2>
        </div>
        <app-address-autocomplete [addressForm]="clientForm['controls'].addresses['controls'][0]">
        </app-address-autocomplete>
    </div>

    <!-- Contact Person -->
    <div class="general-form-container" formArrayName="contactPersons">
        <div class="general-form-subheader">
          <h2>{{iwmo ? "4" : headerIndex+4}}. Contactpersoon</h2>
        </div>
        <div *ngFor="let contactPerson of clientForm.get('contactPersons')['controls']; let contactPersonIndex = index">
            <app-contact-person
                [contactPersonForm]="clientForm['controls'].contactPersons['controls'][contactPersonIndex]"
                [userIsClient]="userIsClient">
            </app-contact-person>
            <div class="low-row">
                <button [attr.disabled]="existingClientWithContactPerson ? true : null" class="remove-cta" (click)="removeContactPerson(contactPersonIndex)">
                  <span
                  [matTooltip]="existingClientWithContactPerson ? 'Het is niet mogelijk om een bestaande contactpersoon te verwijderen' : ''">
                    - Contactpersoon verwijderen
                  </span>
                </button>
            </div>
        </div>
        <button class="cta" [disabled]="clientForm.disabled" (click)="addContactPerson()" *ngIf="clientForm.get('contactPersons')['controls'].length < 1">
          + Contactpersoon toevoegen
        </button>
    </div>

</div>

