import { OrderService } from '@app/modules/order/services/order.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { UserService } from '@app/shared/services/user.service';
import { ClientService } from '@app/modules/client/services/client.service';

@Component({
  selector: 'app-single-client-orders',
  templateUrl: './single-client-orders.component.html',
  styleUrls: ['./single-client-orders.component.scss'],
  animations: [
    trigger('rotateIcon', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(-180deg)' })),
      transition('rotated => default', animate('225ms ease-out')),
      transition('default => rotated', animate('225ms ease-in'))
    ])
  ]
})
export class SingleClientOrdersComponent implements OnInit {
  @Input()
  private tableTitle: string;

  @Input()
  private orderState: string;

  @Input()
  public noOrdersMessage: string;

  @Input()
  private errorMessage: string;

  @ViewChild(MatPaginator, { static: true })
  private tablePaginator: MatPaginator;
  public tableDataSource: MatTableDataSource<any>;

  public ordersLoading = true;
  public ordersLoadingFailed = false;

  orders = [];

  constructor(private orderService: OrderService, private clientService: ClientService, private userService: UserService) {}

  async ngOnInit(): Promise<void> {
    await this.load();
  }

  async load(): Promise<void> {
    this.ordersLoading = true;
    this.ordersLoadingFailed = false;

    this.tableDataSource = new MatTableDataSource([]);
    this.tableDataSource.paginator = this.tablePaginator;

    try {
      const user = await this.userService.getUser();
      const client = await this.clientService.getClient(user.userId);
      const misaClientId = client.misaClientId;
      if (misaClientId) {
        this.populateTable(misaClientId);
      } else {
        this.showErrorMessage();
      }
    } catch (error) {
      this.showErrorMessage();
    }
  }

  filterTable(filterTerm: string): void {
    this.tableDataSource.filter = filterTerm.trim().toLowerCase();
  }

  onOrderFetchHandler(event): void {
    if (!event.result) {
      this.orders = this.orders.filter((o) => o.misaOrderId !== event.misaOrderId);
      this.tableDataSource = new MatTableDataSource(this.orders);
    }
  }

  async populateTable(misaClientId) {
    try {
      const misaOrderIds = (await this.orderService.getClientOrders(misaClientId)).misaOrderIds;

      misaOrderIds.forEach((misaOrderId) => {
        this.orders.push({ misaOrderId, productName: '', productId: '' });
      });

      this.ordersLoading = false;

      this.tableDataSource = new MatTableDataSource(this.orders);
      this.tableDataSource.paginator = this.tablePaginator;
    } catch (error) {
      this.showErrorMessage();
    }
  }

  showErrorMessage() {
    this.ordersLoading = false;
    this.ordersLoadingFailed = true;
  }
}
