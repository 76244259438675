import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import * as util from '@app/shared/util';

@Component({
  selector: 'app-subject-dialog',
  templateUrl: './subject-dialog.component.html',
  styleUrls: ['./subject-dialog.component.scss']
})
export class SubjectDialogComponent implements OnInit {
  addSubjectForm: UntypedFormGroup;


  constructor(private dialogRef: MatDialogRef<SubjectDialogComponent>) { }

  ngOnInit(): void {
    this.addSubjectForm = new UntypedFormGroup({
      subject: new UntypedFormControl(null, [Validators.required]),
    });
  }

  submitForm(action: string) {
    if (this.addSubjectForm.valid || action === 'cancel') {
      const result = {
        action,
        newSubject: this.addSubjectForm.value.subject
      };
      this.dialogRef.close(result);
    }
    else {
      util.markFormGroupTouched(this.addSubjectForm);
    }
  }

}
