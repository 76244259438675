<h1 class="data-title" [ngClass]="{'error': isError}">{{title}}</h1>
<div class="body-container">
  <span class="data-body" [innerHTML]="body" ></span>
  <ul *ngIf="messageList">
    <li *ngFor="let listItem of messageList; let i = index">
      {{listItem}}
    </li>
  </ul>
</div>

<div class="button-container" *ngIf="!isConfirmation && !isConfirmationRemove">
  <button class="cta" (click)="closeDialog()">{{closeDialogButtonText}}</button>
</div>

<div class="button-container two-buttons" *ngIf="isConfirmation">
  <button class="secondary-cta" (click)="closeDialogWithResponse(false)">Nee</button>
  <button class="cta" (click)="closeDialogWithResponse(true)">Ja</button>
</div>

<div class="button-container two-buttons" *ngIf="isConfirmationRemove">
  <button class="secondary-cta" (click)="closeDialogWithResponse(false)">Annuleren</button>
  <button class="remove-cta" (click)="closeDialogWithResponse(true)">Verwijderen</button>
</div>
