<div class="general-form-header">
  <h1>Over de Wmo portal</h1>
  <div class="fill"></div>
</div>

<div *ngIf="signedInUser && !signedInUser.isSuperAdmin && !signedInUser.isAdmin">
  <div class="header-row">
    <button routerLink="/about/user-manuals" class="cta">Download handleiding</button>
    <button routerLink="/about/about-contact" class="cta">Contactformulier</button>
  </div>
  <app-faq-items></app-faq-items>
</div>

<ng-container *ngIf="signedInUser && (signedInUser.isSuperAdmin || signedInUser.isAdmin)">
  <app-faq-items-admin></app-faq-items-admin>
  <app-about-manuals-admin></app-about-manuals-admin>
</ng-container>
