import { OrderService } from '@app/modules/order/services/order.service';
import { UserService, SignedInUser } from '@app/shared/services/user.service';
import { CountyService } from '@app/modules/county/services/county.service';
import { ClientService } from '@app/modules/client/services/client.service';
import { formatAddress } from '@app/shared/models/address';
import { DialogService } from '@app/shared/services/dialog.service';
import { ErrorService } from '@app/shared/services/error.service';
import { formatName, formatLastName } from '@app/shared/models/name';
import * as util from '@app/shared/util';

import { Component, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { trigger, state, style, transition, animate } from '@angular/animations';
import * as moment from 'moment';

import { ClientSearchQuery } from '@app/shared/models/client-search-query';
import { DeactivateClientResponse, ActivateClientResponse } from '@app/modules/client/models/activation-result';
import { County } from '@app/modules/county/models/county';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-clients-with-orders-table',
  templateUrl: './clients-with-orders-table.component.html',
  styleUrls: ['./clients-with-orders-table.component.scss'],
  animations: [
    trigger('rotateIcon', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(-180deg)' })),
      transition('rotated => default', animate('225ms ease-out')),
      transition('default => rotated', animate('225ms ease-in'))
    ]),
  ]
})
export class ClientsTableComponent implements OnInit, OnDestroy {

  @Input()
  startExpanded = false;

  @Input()
  expandToggle = true;

  @Input()
  tableTitle: string;

  @Input()
  orderState: string;

  @Input()
  noOrdersMessage: string;

  @Input()
  errorMessage: string;

  @Input()
  prefetchedClientsData: Array<any>;

  @ViewChild(MatPaginator, { static: true })
  private tablePaginator: MatPaginator;

  private loadInBackground = false;

  expanded = false;

  tableDataSource: MatTableDataSource<any>;
  tableColumns = ['formattedName', 'info', 'iconExpand'];
  clientsLoading = true;
  clientsLoadingFailed = false;
  showSpinner = false;

  numTotalOrders = 0;

  currentActiveCountyId: string;
  county: County;
  userSubscription: Subscription;
  user: SignedInUser;

  constructor(
    private orderService: OrderService,
    private userService: UserService,
    private countyService: CountyService,
    private clientService: ClientService,
    private dialogService: DialogService,
    private errorService: ErrorService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.userSubscription = this.userService.user$.subscribe({
      next: async (user) => {
      this.user = user;
      if (!this.currentActiveCountyId || (user && this.currentActiveCountyId !== user.countyId)) {
        this.currentActiveCountyId = user.countyId;
        this.close(true);
        if (this.user && this.user.countyId) {
          this.county = await this.countyService.getCounty(this.user.countyId);
        }
        else {
          this.county = null;
        }

        if (this.startExpanded) {
          await this.expand();
        }
      }
    }});
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }

  async expand(): Promise<void> {
    this.expanded = true;
    this.clientsLoading = true;
    this.clientsLoadingFailed = false;

    if (!this.loadInBackground) {
      this.tableDataSource = new MatTableDataSource([]);
      this.tableDataSource.paginator = this.tablePaginator;
    }

    try {
      const clientsWithOrders = await this.getClientsWithOrders();
      this.populateTable(clientsWithOrders);
      if (this.tableTitle) {
        this.numTotalOrders = clientsWithOrders.reduce((acc, cur) => acc + cur.misaOrderIds.length, 0);
      }

    } catch (error) {
      this.clientsLoadingFailed = true;
    }

    this.clientsLoading = false;
  }

  close(userUpdated?: boolean) {
    this.expanded = false;

    if (userUpdated) {
      this.loadInBackground = false;
      this.numTotalOrders = 0;
    }
    else if (this.numTotalOrders > 0) {
      this.loadInBackground = true;
    }
  }

  async toggleExpanded(): Promise<void> {
    if (this.expanded) {
      this.close();
    } else {
      await this.expand();
    }
  }

  filterTable(filterTerm: ClientSearchQuery): void {
    if (this.tableDataSource) {
      this.tableDataSource.filter = JSON.stringify(filterTerm);
    }
  }

  async getClientsWithOrders(): Promise<any[]> {
    if (this.prefetchedClientsData) {
      return this.prefetchedClientsData;
    } else {
      const loadFunction = {
        active: this.orderService.getClientsWithActiveOrders,
        recent: this.orderService.getClientsWithRecentOrders,
        waiting: this.orderService.getClientsWithWaitingOrders,
      }[this.orderState];
      if (!loadFunction) {
        throw new Error(`"orderState" attribute is invalid: ${this.orderState}`);
      }

      return await loadFunction.bind(this.orderService)();
    }
  }

  populateTable(clients: any[]): void {
    const dataSource = clients.map(clientAndOrders => {
      const client = util.tryGet(clientAndOrders, 'client');

      return {
        ageInYears: client.birthDate ? moment.utc().diff(moment(client.birthDate), 'years') : null,
        archived: client.archived,
        contactPerson: client.contactPerson,
        contactPersonType: client.contactPerson && client.contactPerson.type ? this.translateContactPersonType(client.contactPerson.type) : null,
        expanded: false,
        formattedName: client.name ? formatName(client.name) : '',
        formattedLastName: client.name ? formatLastName(client.name) : '',
        formattedBirthDate: client.birthDate ? moment(client.birthDate).format('DD-MM-YYYY') : '',
        formattedAddress: client.address ? formatAddress(client.address) : '',
        hasContactPerson: Object.keys(client.contactPerson).length > 0,
        misaOrderIds: clientAndOrders.misaOrderIds || [],
        numContactPersons: 1,
        orderLoadingTriggered: false,
        misaClientId: client.misaClientId,
        userId: client.userId,
        emailAddress: client.contactDetails && client.contactDetails.emailAddress ? client.contactDetails.emailAddress : null,
        deceased: !!client.deceased,
        phoneNumber: client.contactDetails && client.contactDetails.phoneNumber,
        mobileNumber: client.contactDetails && client.contactDetails.mobileNumber,
      };
    }).sort((lhs, rhs) => {
      return lhs.formattedLastName.localeCompare(rhs.formattedLastName);
    });

    this.tableDataSource = new MatTableDataSource(dataSource);
    this.tableDataSource.paginator = this.tablePaginator;

    this.tableDataSource.filterPredicate = (data: any, filterValue: string) => {
      const filterValueObject = JSON.parse(filterValue);
      let filteredData;
      if (!filterValueObject.partialLastName && !filterValueObject.birthDate) {
        filteredData = data;
      }
      else if (filterValueObject.partialLastName && !filterValueObject.birthDate) {
        filteredData = data.formattedLastName.toLowerCase().includes(filterValueObject.partialLastName.toLowerCase());
      }
      else if (!filterValueObject.partialLastName && filterValueObject.birthDate) {
        filteredData = data.formattedBirthDate.includes(filterValueObject.birthDate);
      }
      else {
        filteredData = (
          (data.formattedLastName.toLowerCase().includes(filterValueObject.partialLastName.toLowerCase())) &&
          (data.formattedBirthDate.includes(filterValueObject.birthDate))
        );
      }
      return filteredData;
    };
  }

  async activateClient(misaClientId: string): Promise<void> {
    this.showSpinner = true;
    try {
      const activateClientResponse: ActivateClientResponse = await this.clientService.activateClient(misaClientId);

      if (activateClientResponse.result === 'ALREADY_ACTIVE') {
        this.errorService.alertAndLog('ALREADY_ACTIVE', 'Dit account is al actief.');
      }
      else if (activateClientResponse.result === 'BIRTHDATE_NOT_MATCH') {
        this.errorService.alertAndLog('BIRTHDATE_NOT_MATCH', 'De combinatie van uw klantnummer en geboortedatum herkennen wij helaas niet. Kunt u deze gegevens controleren en aanpassen?');
      }
      else if (activateClientResponse.result === 'EMAIL_ALREADY_EXISTS') {
        this.errorService.alertAndLog('EMAIL_ALREADY_EXISTS', 'Er is al een account geactiveerd voor een andere cliënt op dit e-mailadres. Om uw account te activeren dient u een ander e-mailadres te gebruiken.');
      }
      else if (activateClientResponse.result === 'COUNTY_NOT_MATCH') {
        this.errorService.alertAndLog('COUNTY_NOT_MATCH', 'Controleer of u de juiste gemeente heeft geselecteerd.');
      }
      else if (activateClientResponse.result === 'IWMO_NOT_ALLOW_CHANGE_ORDER') {
        this.errorService.alertAndLog('IWMO_NOT_ALLOW_CHANGE_ORDER', 'Het e-mail adres wat door u is ingevuld is anders dan het bij ons bekende e-mail adres, neem contact op met uw Wmo vestiging om uw e-mail adres te wijzigen.');
      }
      else {
        this.dialogService.openInfoAlert('Account geactiveerd', 'Het account is geactiveerd.');

        const clientToActivate = this.tableDataSource.data.find(client => misaClientId === client.misaClientId);
        clientToActivate.archived = false;
      }

    } catch (error) {
      this.errorService.alertAndLog(error, 'Het activeren van het account is mislukt.');
    }
    this.showSpinner = false;
  }

  async deactivateClient(misaClientId: string): Promise<void> {
    this.showSpinner = true;
    try {
      const deactivateClientResponse: DeactivateClientResponse = await this.clientService.deactivateClient(misaClientId);

      if (deactivateClientResponse.result === 'USER_NOT_FOUND') {
        this.errorService.alertAndLog('USER_NOT_FOUND', 'Kon account niet deactiveren omdat het niet in onze database bestaat.');
      }
      else {
        this.dialogService.openInfoAlert('Account gedeactiveerd', 'Het account is gedeactiveerd.');

        const clientToDeactivate = this.tableDataSource.data.find(client => misaClientId === client.misaClientId);
        clientToDeactivate.archived = true;
      }

    } catch (error) {
      this.errorService.alertAndLog(error, 'Het deactiveren van het account is mislukt.');
    }
    this.showSpinner = false;
  }

  translateContactPersonType(type: string): string | null {
    switch (type) {
      case 'PARTNER':
        return 'partner';
      case 'CHILD':
        return 'kind';
      case 'PARENT':
        return 'ouder';
      case 'ADMINISTRATOR':
        return 'beheerder';
      case 'FAMILY_MEMBER':
        return 'familielid';
      case 'RELATIVE':
        return 'betrekkelijk';
      case 'GUARDIAN':
        return 'voogd';
      case 'CAREGIVER':
        return 'verzorger';
      case 'CLIENT_SUPPORTER':
        return 'klant / ondersteuning';
      case 'FRIEND_ACQUAINTANCE':
        return 'vriend / kennis';
      case 'OTHER':
        return 'anders';
      case 'FINANCIAL_AUTHORIZED':
        return 'financieel geautoriseerd';
      case 'FOSTER_PARENT':
        return 'pleegouder';
      case 'HEIR':
        return 'erfgenaam';
      default:
        return null;
    }
  }

}
