<ng-container *ngIf="expanded">
  <div [ngClass]="{ hidden: tableDataSource?.data.length == 0 }">
    <table class="clients-orders-table" mat-table [dataSource]="tableDataSource" multiTemplateDataRows>
      <tr
        class="client-row"
        mat-row
        *matRowDef="let item; columns: tableColumns"
        (click)="expandClient($event,item)"
        [ngClass]="{ expanded: item.expanded }"
      ></tr>
      <tr class="details-row" [class.hidden]="!item.expanded" mat-row *matRowDef="let item; columns: ['expandedDetails']"></tr>

      <ng-container matColumnDef="formattedName">
        <td mat-cell *matCellDef="let item">
          {{ item.formattedName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="info">
        <td mat-cell *matCellDef="let item">
          {{ item.formattedBirthDate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="iconExpand">
        <td mat-cell *matCellDef="let item">
          <mat-icon [@rotateIcon]="item.expanded ? 'rotated' : 'default'">expand_more</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="expandedDetails">
        <td colspan="3" mat-cell *matCellDef="let item">
          <div class="show-more-info" (click)="clientInfoExpanded = !clientInfoExpanded">
            <span>Toon meer cliënt informatie</span>
            <span class="client-info-rotate-icon"
              ><mat-icon [@clientInfoRotateIcon]="clientInfoExpanded ? 'rotated' : 'default'">expand_more</mat-icon></span
            >
          </div>
          <div class="mt-5" *ngIf="clientInfoExpanded">
            <table class="mat-table client-info-table">
              <tr class="mat-row">
                <td class="mat-cell">Telefoonnummer</td>
                <td class="mat-cell">Mobiel</td>
                <td class="mat-cell">Contactpersoon</td>
                <td class="mat-cell">Adres</td>
              </tr>
              <tr class="mat-row">
                <td class="mat-cell">
                  <span class="client-info" *ngIf="item.phoneNumber">{{ item.phoneNumber }} (vast)</span>
                </td>
                <td class="mat-cell">
                  <span class="client-info" *ngIf="item.mobileNumber">{{ item.mobileNumber }} (mobiel)</span>
                </td>
                <td class="mat-cell">
                  <span class="client-info" *ngIf="!item.hasContactPerson">Geen contactpersoon</span>
                  <span class="contact-person" *ngIf="item.hasContactPerson">
                    <span class="title">
                      Contactpersoon<span *ngIf="item.contactPersonType"> ({{ item.contactPersonType }})</span>:
                    </span>
                    <span *ngIf="item.contactPerson.initials"> {{ item.contactPerson.initials }}</span>
                    <span *ngIf="item.contactPerson.firstName"> {{ item.contactPerson.firstName }}</span>
                    <span *ngIf="item.contactPerson.prefix"> {{ item.contactPerson.prefix }}</span>
                    <span *ngIf="item.contactPerson.lastName"> {{ item.contactPerson.lastName }}</span>
                    <span *ngIf="item.contactPerson.emailAddress || item.contactPerson.phoneNumber"> (</span>
                    <span *ngIf="item.contactPerson.emailAddress">{{ item.contactPerson.emailAddress }}</span>
                    <span *ngIf="item.contactPerson.emailAddress && item.contactPerson.phoneNumber"> &bull; </span>
                    <span *ngIf="item.contactPerson.phoneNumber">{{ item.contactPerson.phoneNumber }}</span>
                    <span *ngIf="item.contactPerson.emailAddress || item.contactPerson.phoneNumber">)</span>
                  </span>
                </td>
                <td class="mat-cell">
                  <span class="client-info" *ngIf="item.formattedAddress">{{ formattedAddress }}</span>
                </td>
              </tr>
            </table>
          </div>
          <div class="mt-5 mb-5">
            <ng-container *ngIf="item.deliveredOrders.length > 0">
              <div *ngFor="let deliveredOrder of item.deliveredOrders">
                <app-client-order-with-request [misaOrder]="deliveredOrder"></app-client-order-with-request>
              </div>
            </ng-container>
            <ng-container *ngIf="!item.ordersLoading && item.deliveredOrders.length < 1">
              <p class="no-orders-text">Er zijn geen uitstaande voorzieningen voor deze klant</p>
            </ng-container>
            <div class="spinner-container" *ngIf="item.ordersLoading">
              <app-spinner class="absolute horizontal"></app-spinner>
            </div>
          </div>
        </td>
      </ng-container>
    </table>
  </div>
  
</ng-container>

<mat-paginator
  [class.hidden]="!expanded || tableDataSource?.data.length == 0"
  [pageSize]="20"
  [pageSizeOptions]="[10, 20, 50]"
  showFirstLastButtons
></mat-paginator>

<app-spinner *ngIf="showSpinner"></app-spinner>
