import { environment } from '@app/environment';
import { AuthService, AuthUser } from '@app/shared/services/auth.service';
import { MultiFactorAuthService } from '@app/shared/services/multi-factor-auth.service';
import { TitleService } from '@app/shared/services/title.service';
import { CloudLogger, CloudLoggingService } from '@app/shared/services/cloud-logging.service';
import { ConfigService } from '@app/shared/services/config.service';
import { DialogService } from '@app/shared/services/dialog.service';
import { ErrorService } from '@app/shared/services/error.service';
import { UserService, SignedInUser } from '@app/shared/services/user.service';

import { Component, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import firebase from 'firebase/compat';

@Component({
  selector: 'app-sign-in-page',
  templateUrl: './sign-in-page.component.html',
  styleUrls: ['./sign-in-page.component.scss']
})
export class SignInPageComponent implements OnDestroy {
  @ViewChild('credentialsForm') public credentialsForm: NgForm;
  @ViewChild('forgotPasswordForm') public forgotPasswordForm: NgForm;

  visible = 'login';
  credentials: any = {
    email: '',
    password: ''
  };
  forgotPasswordEmail = '';

  private cloudLog: CloudLogger;

  private phoneHint: string;
  private verificationId: string;
  private smsCode: string;
  private invalidSms = false;
  private errorResolver: firebase.auth.MultiFactorResolver;
  private disableSendSmsButton = false;
  private resendSmsCountdown: number;
  private resendSmsInterval;
  private resendSmsIntervalTime = 1000;
  private runResendSmsIntervalOnce = false; // used only for testing

  public showSpinner = false;

  private userSubscription: Subscription;
  private authSubscription: Subscription;
  private version: string;

  constructor(
    private authService: AuthService,
    private multiFactorAuthService: MultiFactorAuthService,
    private configService: ConfigService,
    private cloudLoggingService: CloudLoggingService,
    private dialogService: DialogService,
    private errorService: ErrorService,
    private router: Router,
    private titleService: TitleService,
    private userService: UserService
  ) {
    this.cloudLog = this.cloudLoggingService.createLogger('sign-in-page.component');
    this.titleService.setTitle('Inloggen');

    this.userSubscription = this.userService.user$.subscribe((user?: SignedInUser) => {
      if (!user) {
        this.showSpinner = false;
      }
    });

    this.authSubscription = this.authService.user$.subscribe((user?: AuthUser) => {
      if (user) {
        window.location.reload(); // reload page to redirect to the default page.
      }
    });
    this.version = environment.version;
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
    this.authSubscription.unsubscribe();
    this.clearSmsInterval();
  }

  setVisible(elementName: string) {
    this.visible = elementName;
    if (elementName === 'resetPassword') {
      this.titleService.setTitle('Wachtwoord vergeten');
    } else if (elementName === 'login-employees') {
      this.titleService.setTitle('Login voor medewerkers');
    } else {
      this.titleService.setTitle('Inloggen');
    }
  }

  async onSubmit() {
    this.showSpinner = true;
    try {
      await this.authService.signInWithEmailPassword(this.credentials.email, this.credentials.password);
    } catch (error) {
      if (error.code === 'auth/multi-factor-auth-required') {
        this.errorResolver = error.resolver;
        this.phoneHint = this.multiFactorAuthService.getPhoneHint(this.errorResolver);
        this.visible = 'multiFactorAuthentication';
        setTimeout(() => {
          this.multiFactorAuthService.initRecaptcha();
        }, 100);
      } else {
        this.cloudLog.warn(`A user failed to sign in with email address: ${this.credentials.email}`);
        this.visible = 'login';
        this.authService.signOut();
        this.errorService.alertAndLog(error, 'Het opgegeven email adres en/of wachtwoord is onjuist.');
      }
      this.showSpinner = false;
    }
  }

  async requestSmsCode() {
    this.disableSendSmsButton = true;
    this.verificationId = null;
    this.smsCode = null;
    this.invalidSms = false;
    try {
      this.verificationId = await this.multiFactorAuthService.requestSmsCode(this.errorResolver);
      this.initSmsInterval();
    } catch (error) {
      this.visible = 'login';
      this.cloudLog.warn(`Failed to request MFA SMS code for ${this.credentials.email}`);
      this.errorService.alertAndLog(error, 'Er is een fout opgetreden tijdens het verificatieproces. Probeer het alstublieft opnieuw.');
    } finally {
      this.disableSendSmsButton = false;
    }
  }

  async verifySmsCode() {
    if (!this.verificationId || this.invalidSms || !this.smsCode){
      return;
    }
    this.showSpinner = true;
    try {
      await this.multiFactorAuthService.verifySmsCode(this.verificationId, this.smsCode, this.errorResolver);
    } catch (error) {
      if (error.code === 'auth/invalid-verification-code') {
        this.visible = 'multiFactorAuthentication';
        this.invalidSms = true;
      } else {
        this.visible = 'login';
        this.cloudLog.warn(`Failed to verify MFA SMS code for ${this.credentials.email}`);
        this.errorService.alertAndLog(error, 'Er is een fout opgetreden tijdens het verificatieproces. Probeer het alstublieft opnieuw.');
      }
      this.showSpinner = false;
    }
  }

  loginEmployees() {
    this.authService
      .signInWithMicrosoft()
      .then((email) => {
        this.configService
          .getAllowedGoogleDomains()
          .then((allowedGoogleDomains: Array<string>) => {
            const userGoogleDomain = email.split('@')[1];

            if (!allowedGoogleDomains.includes(userGoogleDomain)) {
              this.authService.signOut().then(() => {
                this.dialogService.openErrorAlert(
                  'Foutmelding',
                  `Inloggen met Google accounts van het "${userGoogleDomain}" domein is niet toegestaan.`
                );
              });
            }
          })
          .finally(() => {});
      })
      .catch((error) => {
        this.cloudLog.warn(`A user has failed to sign in through popup. ${error}`);
        console.error(`error signInWithPopup ${error.code}: ${error.message}`);
      });
  }

  async forgotPassword() {
    this.showSpinner = true;
    try {
      await this.authService.sendPasswordResetEmail(this.forgotPasswordEmail);
      this.visible = 'login';
      this.dialogService.openInfoAlert(
        'Wachtwoord reset',
        'Het verzoek om uw wachtwoord te resetten is ontvangen. ' +
          'Er is een email verstuurd naar het opgegeven email adres ' +
          'met hierin een link naar een pagina waarop het wachtwoord kan worden gereset.'
      );
    } catch (error) {
      this.visible = 'resetPassword';
      this.errorService.alertAndLog(error, 'Er bestaat geen account met het opgegeven email adres.');
    } finally {
      this.showSpinner = false;
    }
  }

  initSmsInterval() {
    this.resendSmsCountdown = 30;
    if (this.runResendSmsIntervalOnce) {
      this.resendSmsCountdown = 1;
    }
    this.clearSmsInterval();
    this.resendSmsInterval = setInterval(() => {
      this.resendSmsCountdown--;
      if (this.resendSmsCountdown <= 0) {
        this.resendSmsCountdown = null;
        this.clearSmsInterval();
      }
    }, this.resendSmsIntervalTime);
  }

  clearSmsInterval() {
    clearInterval(this.resendSmsInterval);
    this.resendSmsCountdown = null;
  }
}
