<div class="general-form-header">
    <h1>Welkom bij de Wmo Portal <span *ngIf="county && !user.isClient"> van gemeente {{county.name}}</span></h1>
    <div class="fill"></div>
</div>

<div class="header-row" *ngIf="user && user.isCountyOrderEditor">
  <button *ngIf="user.isCountyOrderEditor && (county && !county.iWMO)" class="cta" id="new-order-button" routerLink="/order">
    + Nieuwe aanvraag
  </button>
  <app-client-search (outputSearchTerms)="filterClientsOrdersTable($event)"></app-client-search>
</div>

<div class="row header-row" *ngIf="user && user.isClient && county && county.clientViewAccess === true">
  <div class="input-icon-container no-margin-bottom fill">
      <mat-icon>search</mat-icon>
      <input id="single-client-filter" type="text" placeholder="Snelzoeken op product" (keyup)="filterClientOrdersTable($event.target.value)" />
  </div>
</div>

<ng-container *ngIf="!user || !user.isClient">
  <app-clients-with-orders-table #waitingOrders
      startExpanded="true"
      tableTitle="Goedkeuring vereist"
      noOrdersMessage="Er zijn geen aanvragen die goedgekeurd hoeven te worden."
      errorMessage="De aanvragen konden niet worden opgehaald."
      orderState="waiting">
  </app-clients-with-orders-table>

  <app-clients-with-orders-table #activeOrders
      tableTitle="Lopende aanvragen"
      noOrdersMessage="Er zijn geen lopende aanvragen."
      errorMessage="De aanvragen konden niet worden opgehaald."
      orderState="active">
  </app-clients-with-orders-table>
</ng-container>

<ng-container *ngIf="user && user.isClient">
  <div class="no-client-access" *ngIf="county && county.clientViewAccess === false">
    <p class="icon-container">
      <mat-icon>warning</mat-icon>
    </p>
    <p class="text-p">
      Binnen uw gemeente is het niet toegestaan om aanvragen in te zien.
      Neem contact op met uw gemeente indien u vragen heeft over uw aanvraag.
    </p>
  </div>

  <ng-container *ngIf="county && county.clientViewAccess === true">
    <app-single-client-orders #clientOrders
      noOrdersMessage="Er zijn geen lopende aanvragen."
      errorMessage="De aanvragen konden niet worden opgehaald."
      orderState="client">
    </app-single-client-orders>
  </ng-container>
</ng-container>
