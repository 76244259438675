import { Component, DoCheck, Input } from '@angular/core';

@Component({
  selector: 'app-error-messages',
  templateUrl: './error-messages.component.html',
  styleUrls: ['./error-messages.component.scss']
})
export class ErrorMessagesComponent implements DoCheck {

  @Input() formControls: any;
  @Input() marginBottom: boolean;

  errorMessage: string;

  constructor() { }

  ngDoCheck() {
    const errors = [];
    if (this.formControls.errors) {
      const errorKeys = Object.keys(this.formControls.errors);
      errorKeys.forEach(errorKey => {
        const errorVal = this.formControls.errors[errorKey];
        errors.push(this.getCustomError(errorVal, errorKey));
      });
      this.errorMessage = errors.join(' ');
    }
  }

  getCustomError(errorVal, errorKey) {
    switch (errorKey) {
      case 'required':
        return 'Dit veld is verplicht.';
      case 'maxlength':
        return `Dit veld mag maximaal ${errorVal.requiredLength} tekens bevatten, maar bevat ${errorVal.actualLength} tekens.`;
      case 'minlength':
        return `Dit veld mag minimaal ${errorVal.requiredLength} tekens bevatten, maar bevat ${errorVal.actualLength} tekens.`;
      case 'pattern':
        return 'De ingevoerde waarde is ongeldig.';
      case 'email':
        return 'Het ingevoerde email adres is ongeldig.';
      case 'mustMatch':
        return 'De ingevoerde waarde is niet hetzelfde.';
      case 'onlyNumeric':
        return 'De ingevoerde waarde is ongeldig. Dit veld mag enkel cijfers bevatten.';
      default:
        return 'De ingevoerde waarde is ongeldig.';
    }
  }

}
