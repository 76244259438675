import { Component, OnInit } from '@angular/core';
import { FaqItem } from '../../models/faq-item';
import { FaqService } from '../../services/faq.service';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-faq-items',
  templateUrl: './faq-items.component.html',
  styleUrls: ['./faq-items.component.scss'],
  animations: [
    trigger('rotateIcon', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(-180deg)' })),
      transition('rotated => default', animate('225ms ease-out')),
      transition('default => rotated', animate('225ms ease-in'))
    ])
  ]
})
export class FaqItemsComponent implements OnInit {
  faqItems: FaqItem[] = [];
  activeFaqItemId: string = '';

  constructor(private faqService: FaqService) {}

  async ngOnInit(): Promise<void> {
    this.faqItems = await this.faqService.getFaqItems();
    this.activeFaqItemId = this.faqItems[0].id;
  }

  showFaqIndex(id) {
    this.activeFaqItemId = this.activeFaqItemId === id ? '' : id;
  }
}
