import {environment} from '@app/environment';


export const info = (message: string, ...additionalData: Array<any>): void => {
  log(message, additionalData, console.info);
};


export const warn = (message: string, ...additionalData: Array<any>): void => {
  log(message, additionalData, console.warn);
};


export const error = (message: string, ...additionalData: Array<any>): void => {
  log(message, additionalData, console.error);
};


const log = (message: string, additionalData: Array<any>, logFunction: (message: string) => void): void => {
  if (environment.production) {
    return;
  }

  let logMessage = '[DEV] ' + message;

  if (additionalData.length > 0) {
    logMessage += ' | ' + additionalData.map(v => JSON.stringify(v)).join(', ');
  }

  logFunction(logMessage);
};
