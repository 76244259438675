import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import * as _ from 'lodash';

import { CountyEmployee } from '@app/shared/models/county-employee';
import { UserRole } from '@app/shared/models/user-role';
import { UserService } from '@app/shared/services/user.service';
import * as globals from '@app/shared/globals';
import * as util from '@app/shared/util';
import { DialogService } from '@app/shared/services/dialog.service';
import { CountyEmployeeService } from '@app/modules/county/services/county-employee.service';
import { ErrorService } from '@app/shared/services/error.service';
import { ContractService } from '@app/modules/contract/services/contract.service';

@Component({
  selector: 'app-employee-dialog',
  templateUrl: './employee-dialog.component.html',
  styleUrls: ['./employee-dialog.component.scss']
})
export class EmployeeDialogComponent implements OnInit {
  public showEmployeeDialogSpinner = false;

  public countyId: string;
  public contractId: string;
  public userId?: string;
  public countyEmployee?: CountyEmployee;
  private countyName: string;

  public util = util;
  public isOwnAccount = false;
  public showEditButton = false;
  public showAddButton = false;

  public multiFactorAuthRequired = false;

  public employeeForm = this.formBuilder.group({
    name: this.formBuilder.group({
      initials: [null, [Validators.required, Validators.maxLength(10), Validators.pattern(globals.initialsRegex)]],
      firstName: [null, [Validators.pattern(globals.lettersAndAccentsRegex)]],
      prefix: [null, [Validators.pattern(globals.lettersAndAccentsRegex)]],
      lastName: [null, [Validators.required, Validators.pattern(globals.lettersAndAccentsRegex)]],
    }),
    contactDetails: this.formBuilder.group({
      emailAddress: [null, [Validators.required, Validators.email]],
      mobileNumber: [null, [Validators.pattern('^$|^06[0-9]{8}$')]]
    }),
    roles: [null, [Validators.required]],
  });

  constructor(
    private dialogRef: MatDialogRef<EmployeeDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private errorService: ErrorService,
    @Inject(MAT_DIALOG_DATA) data: {
      countyId: string,
      countyName?: string,
      userId?: string,
      countyEmployee?: CountyEmployee,
      contractId: string
    },
    private dialogService: DialogService,
    private countyEmployeeService: CountyEmployeeService,
    private contractService: ContractService
  ) {
    this.countyId = data.countyId;
    this.contractId = data.contractId;

    if (!data.userId || !data.countyEmployee) {
      this.showAddButton = true;
      return;
    }

    this.userId = data.userId;
    this.countyEmployee = data.countyEmployee;
    this.countyName = data.countyName;
    this.isOwnAccount = false;
    this.showEditButton = true;
    this.showAddButton = false;

    this.employeeForm.patchValue({
      name: {
        initials: data.countyEmployee.name.initials,
        firstName: data.countyEmployee.name.firstName,
        prefix: data.countyEmployee.name.prefix,
        lastName: data.countyEmployee.name.lastName,
      },
      contactDetails: {
        emailAddress: data.countyEmployee.contactDetails.emailAddress,
        mobileNumber: data.countyEmployee.contactDetails.mobileNumber,
      },
      roles: Object.keys(data.countyEmployee.roles.counties[this.countyId]).filter(role => data.countyEmployee.roles.counties[this.countyId][role]),
    });
  }

  public async ngOnInit(): Promise<void> {
    if (this.userId) {
      this.isOwnAccount = (this.userId === (await this.userService.getUser()).userId);

      if (this.isOwnAccount) {
        this.employeeForm.controls.roles.disable();
      }
    }

    const contract = await this.contractService.getContract(this.contractId);
    if (contract.multiFactorAuth) {
      this.multiFactorAuthRequired = true;
    }
  }

  public async addEmployee() {
    if (this.employeeForm.invalid) {
      util.markFormGroupTouched(this.employeeForm);
      return;
    }

    this.showEmployeeDialogSpinner = true;
    const isEmployeeInExistingCountyError = await this.isEmployeeInExistingCounty();
    if (isEmployeeInExistingCountyError.message === 'The employee existed in the county') {
      this.dialogService.openErrorAlert(
        'Medewerker bestaat al',
        `De medewerker die u probeert toe te voegen bestaat al in deze gemeente.`,
        null, true
      );
      this.showEmployeeDialogSpinner = false;
      return;
    }
    else if (isEmployeeInExistingCountyError.message === 'The employee exists in other counties') {
      this.dialogService.openConfirmationAlert(
        'Medewerker bestaat in een andere gemeente',
        `Er bestaat al een account met het opgegeven emailadres. Wilt u dit account toegang geven tot uw gemeente?`,
        null, true
      ).onClosed(async (response) => {
        if (response === true) {
          this.closeDialogAndAddEmployee(true);
        }
      });
    }
    else if (isEmployeeInExistingCountyError) {
      this.errorService.alertAndLog(isEmployeeInExistingCountyError, 'Het toevoegen van de werknemer is mislukt.');
    }
    else {
      this.closeDialogAndAddEmployee(false);
    }

    this.showEmployeeDialogSpinner = false;
  }

  closeDialogAndAddEmployee(employeeInExistingCounty: boolean) {
    this.dialogRef.close({
      action: 'add',
      countyEmployee: this.createCountyEmployee(),
      employeeInExistingCounty
    });
  }

  public async editEmployee() {
    if (this.employeeForm.invalid) {
      util.markFormGroupTouched(this.employeeForm);
      return;
    }

    this.showEmployeeDialogSpinner = true;
    const isTaskQueueEmpty = await this.isTaskQueueEmpty();
    if (!isTaskQueueEmpty) {
      this.dialogService.openErrorAlert('Medewerker kan op dit moment niet worden bijgewerkt',
        `Het contract van de medewerker heeft een aantal taken in behandeling en kan daarom momenteel niet worden bijgewerkt. Probeer het later opnieuw.`, null, true);
      this.showEmployeeDialogSpinner = false;
      return;
    }

    this.dialogRef.close({
      action: 'edit',
      userId: this.userId,
      countyEmployee: this.getEditedCountyEmployee(),
    });
  }

  public closeDialog(): void {
    this.dialogRef.close({
      action: 'cancel',
    });
  }

  public removeEmployeeFromCounty(): void {
    this.dialogRef.close({
      action: 'removeFromCounty',
      userId: this.userId,
    });
  }

  private createCountyEmployee(): CountyEmployee {
    const formValue = this.employeeForm.value;

    const countyEmployee: CountyEmployee = {
      countyId: util.checkString(this.countyId),
      name: {
        initials: util.checkString(formValue.name.initials),
        firstName: util.checkOptString(formValue.name.firstName),
        prefix: util.checkOptString(formValue.name.prefix),
        lastName: util.checkString(formValue.name.lastName),
      },
      contactDetails: {
        emailAddress: util.checkString(formValue.contactDetails.emailAddress),
        mobileNumber: util.checkOptString(formValue.contactDetails.mobileNumber),
      },
      roles: {
        [UserRole.CLIENT]: false,
        [UserRole.COUNTY_DEPOT_VIEWER]: formValue.roles.includes(UserRole.COUNTY_DEPOT_VIEWER),
        [UserRole.COUNTY_ORDER_VIEWER]: formValue.roles.includes(UserRole.COUNTY_ORDER_VIEWER),
        [UserRole.COUNTY_ORDER_EDITOR]: formValue.roles.includes(UserRole.COUNTY_ORDER_EDITOR),
        [UserRole.COUNTY_VIEWER]: formValue.roles.includes(UserRole.COUNTY_VIEWER),
        [UserRole.COUNTY_ADMIN]: formValue.roles.includes(UserRole.COUNTY_ADMIN),
        [UserRole.ADMIN]: false,
        [UserRole.SUPER_ADMIN]: false,
      }
    };

    util.removeNullAndEmptyStringValues(countyEmployee);
    return countyEmployee;
  }

  private getEditedCountyEmployee(): CountyEmployee {
    const formValue = this.employeeForm.getRawValue();
    const countyEmployee = _.cloneDeep(this.countyEmployee) as CountyEmployee;

    countyEmployee.name.initials = util.checkString(formValue.name.initials);
    countyEmployee.name.firstName = util.checkOptString(formValue.name.firstName);
    countyEmployee.name.prefix = util.checkOptString(formValue.name.prefix);
    countyEmployee.name.lastName = util.checkString(formValue.name.lastName);
    countyEmployee.contactDetails.emailAddress = util.checkString(formValue.contactDetails.emailAddress);
    countyEmployee.contactDetails.mobileNumber = util.checkOptString(formValue.contactDetails.mobileNumber);

    countyEmployee.roles = {
      [UserRole.CLIENT]: false,
      [UserRole.COUNTY_DEPOT_VIEWER]: formValue.roles.includes(UserRole.COUNTY_DEPOT_VIEWER),
      [UserRole.COUNTY_ORDER_VIEWER]: formValue.roles.includes(UserRole.COUNTY_ORDER_VIEWER),
      [UserRole.COUNTY_ORDER_EDITOR]: formValue.roles.includes(UserRole.COUNTY_ORDER_EDITOR),
      [UserRole.COUNTY_VIEWER]: formValue.roles.includes(UserRole.COUNTY_VIEWER),
      [UserRole.COUNTY_ADMIN]: formValue.roles.includes(UserRole.COUNTY_ADMIN),
      [UserRole.ADMIN]: false,
      [UserRole.SUPER_ADMIN]: false,
    };

    // convert new model to old model
    countyEmployee.countyId = this.countyId;
    delete countyEmployee.activeCounty;
    delete countyEmployee.counties;
    delete countyEmployee.rolesString;

    util.removeNullAndEmptyStringValues(countyEmployee);
    return countyEmployee;
  }

  translateAndJoinRoles(roles: string[]): string {
    return (roles || []).map(role => ({
      county_admin: 'Beheerder',
      county_order_editor: 'Consulent',
      county_order_viewer: 'Backoffice',
      county_depot_viewer: 'Depotbeheerder',
      county_viewer: 'Inzien',
    })[role] || role
    ).join(', ');
  }

  async comfirmRemoveEmployeeFromCounty() {
    let fullName = this.employeeForm.value.name.initials ? (this.employeeForm.value.name.initials + ' ') : '';
    fullName += this.employeeForm.value.name.prefix ? (this.employeeForm.value.name.prefix + ' ') : '';
    fullName += this.employeeForm.value.name.lastName ? this.employeeForm.value.name.lastName : '';

    this.dialogService.openRemoveConfirmationAlert('Deactiveren',
      `Weet u zeker dat u medewerker ${fullName} uit ${this.countyName} wilt verwijderen? Deze actie kan niet ongedaan gemaakt worden.`, null, true).onClosed(async (response) => {
        if (response === true) {
          this.removeEmployeeFromCounty();
        }
      });
  }

  async isEmployeeInExistingCounty() {
    try {
      await this.countyEmployeeService.existCountyEmployee(this.employeeForm.value.contactDetails.emailAddress, this.countyId);
      return false;
    }
    catch (error) {
      return (error);
    }
  }

  async isTaskQueueEmpty() {
    try {
      const remainingTasks = await this.contractService.monitorEmployeesMFA(this.contractId);
      if (remainingTasks === 0) {
        return true;
      }
      else {
        return false;
      }
    }
    catch (error) {
      console.error(error);
      return true;
    }
  }

}
