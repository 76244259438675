import { Injectable } from '@angular/core';
import { NewsItem } from '../models/news-item';
import { CloudLogger, CloudLoggingService } from '@app/shared/services/cloud-logging.service';
import { AuthService, AuthUser } from '@app/shared/services/auth.service';

import { Observable } from 'rxjs';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { FirestoreObservableQuery } from '@app/shared/util/firestore-observable-query';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AngularFirePerformance } from '@angular/fire/compat/performance';

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  collectionName = `news-items`;
  newsItems$: Observable<any>;
  newsItemsCollection: AngularFirestoreCollection<NewsItem> = this.firestore.collection<NewsItem>(this.collectionName);

  private cloudLog: CloudLogger;
  private requestAdditionalService$: (data: any) => Observable<any>;

  constructor(
    cloudLoggingService: CloudLoggingService,
    functions: AngularFireFunctions,
    private authService: AuthService,
    private firestore: AngularFirestore,
    private performance: AngularFirePerformance
  ) {
    const faqItemsQuery = new FirestoreObservableQuery<any>(firestore, {
      collection: this.collectionName
    });

    this.newsItems$ = faqItemsQuery.observable$;
    this.authService.user$.subscribe({
    next: (user: AuthUser) => {
      faqItemsQuery.setCleanup(!user);
    }});

    this.cloudLog = cloudLoggingService.createLogger('news.service');
    this.requestAdditionalService$ = functions.httpsCallable('requestAdditionalService');
  }

  async getNewsItems(): Promise<NewsItem[]> {
    const faqItems: NewsItem[] = [];
    const snapshot = await this.newsItemsCollection.ref.orderBy('dateCreated', 'desc').get();
    if (!snapshot.empty) {
      snapshot.forEach((doc) => {
        const id = doc.id;
        const { dateCreated, newsText, newsTitle } = doc.data();
        faqItems.push({
          id,
          dateCreated: dateCreated.toDate(),
          newsText,
          newsTitle
        });
      });
    }
    return faqItems;
  }

  async saveNewsItem(item: NewsItem): Promise<NewsItem> {
    let objectToSave: NewsItem = { ...item };
    if (item.id === '') {
      objectToSave.id = this.firestore.createId();
      objectToSave.dateCreated = new Date();
    }
    await this.newsItemsCollection.doc(objectToSave.id).set(objectToSave);
    return objectToSave;
  }

  async deleteNewsItem(itemId: string): Promise<void> {
    await this.firestore.doc(`news-items/${itemId}`).delete();
  }

  async hasNewNewsItem(): Promise<boolean> {
    const snapshot = await this.newsItemsCollection.ref.orderBy('dateCreated', 'desc').limit(1).get();
    if (snapshot.empty) {
      return false;
    }

    let result: boolean = false;

    snapshot.forEach((doc) => {
      const id = doc.id;
      const { dateCreated } = doc.data();
      const date = dateCreated.toDate();
      const now = new Date();
      const diff = now.getTime() - date.getTime();
      const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
      if (diffDays <= 8) {
        result = true;
      }
    });
    return result;
  }
}
