<div class="general-form-container">
  <div class="general-form-subheader">
    <h2>Veelgestelde vragen</h2>
  </div>
  <div class="low-row">
    <ul>
      <li *ngFor="let faqItem of faqItems">
        <button (click)="deleteFaqItem(faqItem.id)" class="remove-icon-only-cta">
          <mat-icon>delete</mat-icon>
        </button>
        <button (click)="showEditor(faqItem)" class="remove-icon-only-cta">
          <mat-icon>edit</mat-icon>
        </button>
        <span>{{ faqItem.question }}</span>
      </li>
    </ul>
  </div>
  <div class="low-row subject-edit-controls">
    <button class="cta" (click)="showEditor()">+ Toevoegen</button>
  </div>
</div>
<ng-template #faqEditor>
  <div class="general-form-subheader">
    <h2>{{ activeFaqItem.id === '' ? 'Nieuw veelgestelde vraag' : 'Bewerk veelgestelde vraag' }}</h2>
  </div>
  <div class="row">
    <div class="input-container">
      <input type="text" [(ngModel)]="activeFaqItem.question" required />
      <label>Vraag</label>
    </div>
  </div>
  <div class="row">
    <div class="input-container">
      <textarea [(ngModel)]="activeFaqItem.answer" required></textarea>
      <label>Antwoord</label>
    </div>
  </div>
  <div class="row">
    <button (click)="saveActiveFaq()" [disabled]="!isFormValid()" class="cta">{{ activeFaqItem.id === '' ? 'Toevoegen' : 'Bewerken' }}</button>
  </div>
</ng-template>
