<div *ngIf="visible=='login'" class="login">
  <form (ngSubmit)="onSubmit()" id="login-form" #credentialsForm="ngForm">
    <h1>Inloggen</h1>
    <div class="form-group">
      <label for="email">Email adres</label>
      <input autocomplete="on" type="email" [(ngModel)]="credentials.email" name="email" id="email" class="form-control" required
        #email="ngModel" email>
      <div id="email-error" [hidden]="email.valid || email.pristine" class="alert alert-danger">
        Email adres is verplicht
      </div>
    </div>

    <div class="form-group">
      <label for="password">Wachtwoord</label>
      <input autocomplete="on" type="password" [(ngModel)]="credentials.password" name="password" id="password" class="form-control" required
        #password="ngModel">
      <div id="password-error" [hidden]="password.valid || password.pristine" class="alert alert-danger">
        Wachtwoord is verplicht
      </div>
    </div>

    <button type="submit" id="login-button" class="btn" [disabled]="!credentialsForm.form.valid">Inloggen</button>

    <p class="forgot-passsword-link-container">
      <a href="/activeren">Account activeren</a> | 
      <a id="show-reset-password-screen-button" href="javascript:void(0)" (click)="setVisible('resetPassword')">Wachtwoord vergeten?</a>
    </p>
  </form>
</div>

<!-- MFA -->
<div *ngIf="visible=='multiFactorAuthentication'" class="login-multi-factor-authentication">
  <form id="multi-factor-authentication-form" #credentialsForm="ngForm">
    <h1>Inloggen</h1>
    <p>
      Verstuur een verificatiecode naar {{phoneHint}} door op "Verstuur verificatiecode" te klikken.
      Vul de verificatiecode in en klik op "Verifieren".
      Indien u geen verificatiecode ontvangt kunt u na 30 seconden opnieuw proberen om de verificatiecode te versturen.
    </p>

    <button class="btn" type="button" id="request-sms-code-button" (click)="requestSmsCode()" [disabled]="resendSmsCountdown || disableSendSmsButton">
      <span *ngIf="!verificationId">Verstuur verificatiecode</span>
      <span *ngIf="verificationId">Verstuur opnieuw</span>
      <span *ngIf="resendSmsCountdown"> ({{resendSmsCountdown}})</span>
    </button>

    <div class="recaptcha-container" [class.hidden]="verificationId">
      <div class="recaptcha" id="sign-in-recaptcha"></div>
    </div>

    <div class="form-group">
      <label for="smsCode">Verificatie code</label>
      <input autocomplete="off" type="text" [(ngModel)]="smsCode" name="smsCode" id="smsCode" class="form-control" required maxlength="6"
      [disabled]="!verificationId"
      (keyup.enter)="verifySmsCode()"
       (ngModelChange)="invalidSms = false" />
      <div [hidden]="!invalidSms" class="alert alert-danger">
        Onjuiste verificatiecode
      </div>
    </div>

    <button id="verify-sms-code-button" type="button" class="btn" (click)="verifySmsCode()" [disabled]="!verificationId || invalidSms || !smsCode">
      Verifieren
    </button>
    <p class="forgot-passsword-link-container">
      <a href="javascript:void(0)" (click)="setVisible('login')">Annuleren</a>
    </p>
  </form>
</div>

<!-- Password reset -->
<div *ngIf="visible=='resetPassword'" class="forgot-password">
  <form (ngSubmit)="forgotPassword()" id="forgot-password-form" #forgotPasswordForm="ngForm">
    <h1>Wachtwoord vergeten</h1>
  <div class="form-group">
    <label for="forgot-password-input">
      Wat is het email adres van uw account?
    </label>
    <input autocomplete="on" type="email" [(ngModel)]="forgotPasswordEmail" 
      name="forgot-password-input" id="forgot-password-input"
      class="form-control" required email>
  </div>

  <button  type="submit" class="btn smaller-text" id="forgot-password-button" [disabled]="forgotPasswordForm.invalid" >
    Vraag wachtwoord aan
  </button>
    <p class="forgot-passsword-link-container">
      <a href="javascript:void(0)" (click)="setVisible('login')">Annuleren</a>
    </p>
  </form>
</div>

<!-- Employee sign in -->
<div *ngIf="visible=='login-employees'" class="login-employees">
  <h1>Login voor medewerkers</h1>
  <button class="btn login-medux" (click)="loginEmployees()">Inloggen met je Medux account</button>
  <p class="forgot-passsword-link-container">
      <a href="javascript:void(0)" (click)="setVisible('login')">Annuleren</a>
  </p>
</div>

<app-spinner *ngIf="showSpinner"></app-spinner>

<div class="sign-in-footer" *ngIf="visible!='login-employees'">
  <a href="javascript:void(0)" id="show-login-employees-screen-button" (click)="setVisible('login-employees')">Login voor Medipoint medewerkers</a>
  <p class="version">
    <!--
      Version {{version}}
    -->
</p>
</div>
