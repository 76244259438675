import { Injectable } from '@angular/core';
import { CloudLogger, CloudLoggingService } from '@app/shared/services/cloud-logging.service';
import { AuthService, AuthUser } from '@app/shared/services/auth.service';

import { Observable } from 'rxjs';
import { Subject } from '@app/modules/contact/models/subject';
import { Attachment } from '@app/modules/contact/models/attachment';

import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { FirestoreObservableQuery } from '@app/shared/util/firestore-observable-query';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { trace } from '@angular/fire/compat/performance';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  collectionName = `subjects`;
  subjects$: Observable<any>;
  subjectsCollection: AngularFirestoreCollection<Subject> = this.firestore.collection<Subject>(this.collectionName);

  private cloudLog: CloudLogger;
  private requestAdditionalService$: (data: any) => Observable<any>;

  constructor(
    cloudLoggingService: CloudLoggingService,
    functions: AngularFireFunctions,
    private authService: AuthService,
    private firestore: AngularFirestore,
  ) {
    const subjectsQuery = new FirestoreObservableQuery<any>(firestore, {
      collection: this.collectionName
    });

    this.subjects$ = subjectsQuery.observable$;
    this.authService.user$.subscribe((user: AuthUser) => {
      subjectsQuery.setCleanup(!user);
    });

    this.cloudLog = cloudLoggingService.createLogger('contact.service');
    this.requestAdditionalService$ = functions.httpsCallable('requestAdditionalService');
  }

  async addSubject(newSubject: string): Promise<void> {
    const id = this.firestore.createId();
    const subject: Subject = {
      name: newSubject,
      subjectId: id,
      iconFile: ''
    };
    await this.subjectsCollection.doc(id).set(subject);
  }

  async deleteSubject(subjectId: string): Promise<void> {
    await this.firestore.doc(`subjects/${subjectId}`).delete();
  }

  async requestAdditionalService(subject: string, remark: string, attachments: Attachment[] | null, formBody: any): Promise<void> {
    const payload = { subject, remark, attachStreams:attachments, formBody };
    await this.requestAdditionalService$(payload).pipe(trace('requestAdditionalService')).toPromise();
  }
}
