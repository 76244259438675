<form [formGroup]="categoryForm">

    <h2 *ngIf="mode === 'addCategory'">Nieuwe categorie</h2>
    <h2 *ngIf="mode === 'editCategory'">Wijzig categorie</h2>
    <h2 *ngIf="mode === 'addProduct'">Nieuw product</h2>
    <h2 *ngIf="mode === 'editProduct'">Wijzig product</h2>

    <div class="general-form-container">

        <div class="row">
            <div class="input-container"
                [ngClass]="{'invalid': (categoryForm.controls.alladinId.touched && categoryForm.controls.alladinId.invalid)}">
                <app-error-messages [formControls]="categoryForm.controls.alladinId"></app-error-messages>
                <input type="text" formControlName="alladinId" required />
                <label>Alladin ID</label>
            </div>
        </div>

        <div class="row">
            <div class="input-container"
                [ngClass]="{'invalid': (categoryForm.controls.name.touched && categoryForm.controls.name.invalid)}">
                <app-error-messages [formControls]="categoryForm.controls.name"></app-error-messages>
                <input type="text" formControlName="name" required />
                <label>Naam</label>
            </div>
        </div>

        <div class="row">
            <div class="input-container"
                [ngClass]="{'invalid': (categoryForm.controls.description.touched && categoryForm.controls.description.invalid)}">
                <app-error-messages [formControls]="categoryForm.controls.description"></app-error-messages>
                <textarea formControlName="description" required> </textarea>
                <label>Omschrijving</label>
            </div>
        </div>

        <div>

            <div class="image-container" *ngIf="(categoryForm.value.image.storageFilePath | downloadUrl:'contract-images' | async)">
                <img [src]="(categoryForm.value.image.storageFilePath | downloadUrl:'contract-images' | async) || ''" />
            </div>

            <div class="upload-button"
                [class.no-margin-bottom]="(categoryForm.controls.image.touched && categoryForm.controls.image.invalid)">
                <input type="file" name="image" id="image" (change)="addFile($event)" accept="image/png, image/jpeg" />
                <label for="image" class="cta">
                    <mat-icon>cloud_upload</mat-icon>
                    <span>Afbeelding uploaden *</span>
                </label>
            </div>

            <div class="row" *ngIf="(categoryForm.controls.image.touched && categoryForm.controls.image.invalid)">
                <span class="error-span small-margin-top">
                    Upload een afbeelding.
                </span>
            </div>

        </div>

    </div>

    <div class="row buttons-row">

        <div class="buttons-container">

            <button (click)="submitForm('cancel')" class="secondary-cta">Annuleren</button>
            <button (click)="submitForm('remove')" class="remove-cta" *ngIf="mode.startsWith('edit')">Verwijderen</button>
            <button (click)="submitForm('add')" class="cta" *ngIf="mode.startsWith('add')">Toevoegen</button>
            <button (click)="submitForm('edit')" class="cta" *ngIf="mode.startsWith('edit')">Wijzigen</button>

        </div>

    </div>

</form>

<app-spinner class="absolute" *ngIf="showSpinner" loadingMessage="{{loadingMessage}}"></app-spinner>
