import * as environment from '@app/environment';

import {Pipe, PipeTransform} from '@angular/core';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {Observable, of} from 'rxjs';


@Pipe({name: 'downloadUrl'})
export class DownloadUrl implements PipeTransform {

  constructor(private storage: AngularFireStorage) {}

  transform(storageFilePath: string, bucketName?: string): Observable<string> {
    if (!storageFilePath || !bucketName) {
      return of('');
    }

    return new Observable<string>(subscriber => {
      const projectId = environment.environment.firebase.projectId;
      const fullBucketName = projectId + '-' + bucketName;
      const storage = this.storage.storage.app.storage(fullBucketName);

      storage.ref(storageFilePath).getDownloadURL()
        .then((downloadUrl: string) => {
          subscriber.next(downloadUrl);
        })
        .catch(error => {
          subscriber.error(error);
        });
    });
  }
}
