<ng-container *ngIf="!formSent">
  <form>
    <div class="general-form-container">
      <div class="row">
        <div class="input-container">
          <input type="text" name="name" id="name" (change)="handleInput($event)" required />
          <label>Naam</label>
        </div>
        <div class="input-container">
          <input type="email" email name="email" id="email" (change)="handleInput($event)" required />
          <label>Email</label>
        </div>
        <div class="input-container">
          <input type="tel" name="phone" id="phone" (change)="handleInput($event)" required />
          <label>Telefoonnummer</label>
        </div>
      </div>
      <div class="row">
        <div class="input-container">
          <textarea name="question" id="question" (change)="handleInput($event)" required></textarea>
          <label>Uw vraag</label>
        </div>
      </div>
      <div class="row">
        <div class="input-container">
          <input type="file" name="attachment" (change)="onFileChange($event)" id="attachment" />
          <label for="attachment">Eventuele bijlagen</label>
        </div>
      </div>
    </div>
  </form>
  <div id="general-form-actions">
    <div class="general-form-actions-container">
      <button class="cta" [disabled]="!isFormValid()" (click)="sendForm($event)">Verzenden</button>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="formSent">
  <div class="content-container">Bedankt voor uw vraag. Een medewerker neemt spoedig contact met u op.</div>
</ng-container>
