import { Component, OnInit } from '@angular/core';
import { NewsItem } from '../../models/news-item';
import { NewsService } from '../../services/news.service';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
  animations: [
    trigger('rotateIcon', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(-180deg)' })),
      transition('rotated => default', animate('225ms ease-out')),
      transition('default => rotated', animate('225ms ease-in'))
    ])
  ]
})
export class NewsComponent implements OnInit {
  newsItems: NewsItem[] = [];
  shownItemCount = 3;
  constructor(private newsService: NewsService) {}

  async ngOnInit(): Promise<void> {
    this.newsItems = await this.newsService.getNewsItems();
  }

  setShowAll() {
    this.shownItemCount = this.newsItems.length;
  }

  isNew(createdDate: Date) {
    const now = new Date().getTime();
    const createdTime = createdDate.getTime();
    const days = Math.ceil((now - createdTime) / (24 * 3600 * 1000));
    return days <= 8; // created within the last 8 days
  }
}
