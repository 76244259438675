<div class="general-form-header">
    <h1>Vestiging {{establishmentForm.value.name}}</h1>
</div>

<form [formGroup]="establishmentForm">

    <div class="general-form-container">

        <div class="row">
            <div class="input-container"
                [ngClass]="{'invalid': (establishmentForm['controls'].name.touched && establishmentForm['controls'].name.invalid)}">
                <app-error-messages [formControls]="establishmentForm['controls'].name"></app-error-messages>
                <input type="text" formControlName="name" name="name" id="name" required />
                <label>Vestiging naam</label>
            </div>
        </div>

        <div class="low-row">
            <div class="input-container"
                [ngClass]="{'invalid': (establishmentForm['controls'].alladinId.touched && establishmentForm['controls'].alladinId.invalid)}">
                <app-error-messages [formControls]="establishmentForm['controls'].alladinId"></app-error-messages>
                <input type="text" formControlName="alladinId" name="alladinId" id="alladinId" />
                <label>Zuilnummer</label>
            </div>
        </div>

    </div>

    <div class="general-form-container">

        <div class="general-form-subheader">
            <h2>Adres</h2>
        </div>

        <app-address-autocomplete [addressForm]="establishmentForm['controls'].address"></app-address-autocomplete>

    </div>

    <div class="general-form-container no-padding-bottom" formGroupName="contactPerson">

        <div class="general-form-subheader">
            <h2>Contactpersoon</h2>
        </div>

        <div class="row">

            <div class="input-container"
                [ngClass]="{'invalid': (establishmentForm['controls'].contactPerson['controls'].initials.touched && establishmentForm['controls'].contactPerson['controls'].initials.invalid)}">
                <app-error-messages [formControls]="establishmentForm['controls'].contactPerson['controls'].initials"></app-error-messages>
                <input #initials type="text"
                (blur)="establishmentForm.patchValue({ contactPerson: {initials: util.formatInitials(initials.value)}} )"
                formControlName="initials" name="initials" id="initials" required />
                <label for="contactInitials">Voorletters</label>
            </div>

            <div class="input-container"
            [ngClass]="{'invalid': (establishmentForm['controls'].contactPerson['controls'].firstName.touched && establishmentForm['controls'].contactPerson['controls'].firstName.invalid)}">
                <app-error-messages [formControls]="establishmentForm['controls'].contactPerson['controls'].firstName"></app-error-messages>
                <input type="text" formControlName="firstName" name="firstName" id="firstName" />
                <label for="firstName">Voornaam</label>
            </div>

        </div>

        <div class="row">

            <div class="input-container prefix"
            [ngClass]="{'invalid': (establishmentForm['controls'].contactPerson['controls'].prefix.touched && establishmentForm['controls'].contactPerson['controls'].prefix.invalid)}">
                <app-error-messages [formControls]="establishmentForm['controls'].contactPerson['controls'].prefix"></app-error-messages>
                <input type="text" formControlName="prefix" name="prefix" id="prefix" />
                <label for="prefix">Tussenvoegsel(s)</label>
            </div>

            <div class="input-container"
                [ngClass]="{'invalid': (establishmentForm['controls'].contactPerson['controls'].lastName.touched && establishmentForm['controls'].contactPerson['controls'].lastName.invalid)}">
                <app-error-messages [formControls]="establishmentForm['controls'].contactPerson['controls'].lastName"></app-error-messages>
                <input type="text" formControlName="lastName" name="lastName" id="lastName" required />
                <label for="lastName">Achternaam</label>
            </div>

        </div>

        <div class="low-row">

            <div class="input-container"
                [ngClass]="{'invalid': (establishmentForm['controls'].contactPerson['controls'].emailAddress.touched && establishmentForm['controls'].contactPerson['controls'].emailAddress.invalid)}">
                <app-error-messages marginBottom = true  [formControls]="establishmentForm['controls'].contactPerson['controls'].emailAddress"></app-error-messages>
                <input type="email" formControlName="emailAddress" name="emailAddress" id="emailAddress" />
                <label for="emailAddress">Emailadres</label>
            </div>

            <div class="input-container"
                [ngClass]="{'invalid': (establishmentForm['controls'].contactPerson['controls'].phoneNumber.touched && establishmentForm['controls'].contactPerson['controls'].phoneNumber.invalid)}">
                <app-error-messages marginBottom = true  [formControls]="establishmentForm['controls'].contactPerson['controls'].phoneNumber"></app-error-messages>
                <input type="text" formControlName="phoneNumber" name="phoneNumber" id="phoneNumber" />
                <label matTooltip="Een geldig Nederlands telefoonnummer bestaat uit 10 cijfers.">
                    Telefoonnummer <span class="info"><mat-icon>info</mat-icon></span>
                </label>
            </div>

        </div>

        <div class="row">
            <span
                [ngClass]="{'toggle-error-span': true, 'invalid': (submitted && establishmentForm['controls'].contactPerson.errors && establishmentForm['controls'].contactPerson.errors.atLeastOne)}">
                Minimaal een email adres of telefoonnummer is verplicht.
            </span>
        </div>

    </div>

    <div id="general-form-actions" [ngClass]="{'fixed': (footerFixed && submitted)}" style="width: 100%;">
        <div class="general-form-actions-container" [class.three-buttons]='isThisAnEditPage'>

            <button class="secondary-cta" routerLink="/establishments">
              Annuleren
            </button>

            <button class="cta" (click)="submitForm()">
              Opslaan
            </button>

        </div>
    </div>

    <div id="general-form-actions-placeholder" [class.three-buttons]='isThisAnEditPage' [ngClass]="{'fixed': (footerFixed && submitted)}"></div>

</form>

<app-spinner *ngIf="showEstablishmentSpinner" loadingMessage="{{loadingMessage}}"></app-spinner>
