<form [formGroup]="addSubjectForm" (ngSubmit)="submitForm('add')">

  <div class="general-form-subheader">
    <h2>Onderwerpregel toevoegen</h2>
  </div>

  <div class="row">
    <div class="input-container"
      [ngClass]="{'invalid': (addSubjectForm.controls.subject.touched && addSubjectForm.controls.subject.invalid)}">
      <input type="text" formControlName="subject" required />
      <label for="subject">Onderwerpregel</label>
    </div>
  </div>


  <div class="low-row buttons-row">
    <div class="buttons-container two-buttons">
      <button (click)="submitForm('cancel')" class="secondary-cta">
        Annuleren
      </button>
      <button (click)="submitForm('add')" class="cta">
        Toevoegen
      </button>
    </div>
  </div>

</form>