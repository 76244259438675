import * as globals from '@app/shared/globals';
import { DialogService } from '@app/shared/services/dialog.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, AbstractControl, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CategoryDialogComponent } from '@app/modules/contract/components/category-dialog/category-dialog.component';
import { Category } from '@app/modules/contract/models/category';
import * as util from '@app/shared/util';
import * as moment from 'moment';

/* Services */
import { ContractService } from '@app/modules/contract/services/contract.service';
import { CountyService } from '@app/modules/county/services/county.service';
import { EstablishmentService } from '@app/modules/establishment/services/establishment.service';
import { CountyEmployeeService } from '@app/modules/county/services/county-employee.service';
import { ErrorService } from '@app/shared/services/error.service';
import { TitleService } from '@app/shared/services/title.service';
import { AuthService } from '@app/shared/services/auth.service';

/* Models */
import { Contract } from '@app/modules/contract/models/contract';
import { County } from '@app/modules/county/models/county';
import { Establishment } from '@app/modules/establishment/models/establishment';

@Component({
  selector: 'app-contract-page',
  templateUrl: './contract-page.component.html',
  styleUrls: ['./contract-page.component.scss']
})

export class ContractPageComponent implements OnInit, OnDestroy {
  contractId: string;
  contractForm: UntypedFormGroup;
  showContractSpinner = true;
  loadingMessage: string;
  footerFixed = false;
  /* Establishments */
  establishmentsData: Establishment[];
  establishments: any;
  establishmentsTableColumns = ['select', 'name'];
  showEstablishmentsSpinner = true;
  @ViewChild('establishmentsPaginator', { static: false }) establishmentsPaginator: MatPaginator;

  /* Counties */
  countiesData: County[];
  counties: any;
  countiesTableColumns = ['name'];
  showCountiesSpinner = true;
  @ViewChild('countiesPaginator', { static: false }) countiesPaginator: MatPaginator;

  /* MFA */
  mfaStatus: number;
  useMonitorEmployeesMfaIntervalOnce = false; // used only for testing
  monitorEmployeesMfaInterval: any;
  monitorEmployeesMfaIntervalTime = 5000;
  previousMultiFactorAuth: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    public router: Router,
    public dialog: MatDialog,
    public contractService: ContractService,
    private countyService: CountyService,
    private countyEmployeeService: CountyEmployeeService,
    public establishmentService: EstablishmentService,
    public errorService: ErrorService,
    private titleService: TitleService,
    private authService: AuthService
  ) {
    this.buildForm(null);
  }

  ngOnDestroy() {
    clearInterval(this.monitorEmployeesMfaInterval);
  }

  async ngOnInit() {

    this.contractId = this.route.snapshot.paramMap.get('contractId');

    // @todo could be observable
    let establishmentsMap;
    try {
      establishmentsMap = await this.establishmentService.getEstablishments();

    } catch (error) {
      this.errorService.alertAndLog(error, 'Het laden van de vestigingen is mislukt.');
      this.router.navigate(['contracts']);
      return;
    }

    this.establishments = new MatTableDataSource(util.mapOjectToArray(establishmentsMap, 'establishmentId'));
    this.establishments.paginator = this.establishmentsPaginator;
    this.showEstablishmentsSpinner = false;

    if (this.contractId) {
      this.footerFixed = true;
      let contract;
      try {
        contract = await this.contractService.getContract(this.contractId);
        this.previousMultiFactorAuth = contract.multiFactorAuth ? contract.multiFactorAuth : undefined;
        await this.monitorEmployeesMFA();
        if (this.mfaStatus !== 0) {
          this.turnOnMonitorEmployeesMFAInterval();
        }
      } catch (error) {
        this.errorService.alertAndLog(error, 'Het opslaan van het contract is mislukt.');
        this.router.navigate(['contracts']);
        return;
      }

      this.buildForm(contract);

      let countiesMap;
      try {
        countiesMap = await this.countyService.getCountiesWithContract(this.contractId);

      } catch (error) {
        this.errorService.alertAndLog(error, 'Het laden van de gemeentes is mislukt');
        this.router.navigate(['contracts']);
        return;
      }

      this.counties = new MatTableDataSource(util.mapOjectToArray(countiesMap, 'countyId'));
      this.counties.paginator = this.countiesPaginator;
      this.showCountiesSpinner = false;
      this.titleService.setTitle(`Contract ${contract.name}`);
    } else {
      this.buildForm(null);
      this.titleService.setTitle('Nieuwe contract');
    }

    this.showContractSpinner = false;
  }

  filterEstablishments(filterValue: string) {
    this.establishments.filter = filterValue.trim().toLowerCase();
  }

  filterCounties(filterValue: string) {
    this.counties.filter = filterValue.trim().toLowerCase();
  }

  buildForm(contract: Contract) {
    this.contractForm = new UntypedFormGroup({
      name: new UntypedFormControl(
        (contract && contract.name) || null,
        [Validators.required, Validators.pattern(globals.lettersAndAccentsRegex)]),
      startDate: new UntypedFormControl(
        (contract && moment(contract.startDate)) || null,
        [Validators.required]),
      endDate: new UntypedFormControl(
        (contract && contract.endDate && moment(contract.endDate)) || null),
      multiFactorAuth: new UntypedFormControl(
        (contract && contract.multiFactorAuth) || null),
      establishmentId: new UntypedFormControl(
        (contract && contract.establishmentId) || null,
        [Validators.required]),
      archived: new UntypedFormControl(
        (contract && contract.archived) || null),
      categories:
        (contract && contract.categories) ? this.getCategories(contract.categories) : new UntypedFormArray([]),
      productSelectionDisabled: new UntypedFormControl(
        (contract && contract.productSelectionDisabled) || null),
    });
  }

  getCategories(items: Category[]) {
    const categories = new UntypedFormArray([]);
    items.forEach(item => {
      const formGroup = this.buildFormGroup(item);
      categories.push(formGroup);
    });
    return categories;
  }

  // ToDo: build the form element based on a class and populate using patch
  buildFormGroup(item: Category) {

    const group: { [id: string]: AbstractControl } = {};

    if (item !== undefined) {

      if (item.hasOwnProperty('type')) {
        group.type = new UntypedFormControl(item.type);
      }

      if (item.hasOwnProperty('alladinId')) {
        group.alladinId = new UntypedFormControl(item.alladinId);
      }

      if (item.hasOwnProperty('name')) {
        group.name = new UntypedFormControl(item.name);
      }

      if (item.hasOwnProperty('description')) {
        group.description = new UntypedFormControl(item.description);
      } else {
        group.description = new UntypedFormControl(null);
      }

      if (item.hasOwnProperty('image')) {
        group.image = new UntypedFormGroup({
          name: new UntypedFormControl(item.image.name),
          storageFilePath: new UntypedFormControl(item.image.storageFilePath)
        });
      }

      if (item.categories) {
        group.categories = new UntypedFormArray([]);
        for (const category of item.categories) {

          const categoryFormGroup = new UntypedFormGroup({
            alladinId: new UntypedFormControl(category.alladinId),
            name: new UntypedFormControl(category.name),
            description: new UntypedFormControl(category.description),
          });

          if (category.image) {
            const imageFormGroup = new UntypedFormGroup({
              name: new UntypedFormControl(category.image.name),
              storageFilePath: new UntypedFormControl(category.image.storageFilePath)
            });
            categoryFormGroup.addControl('image', imageFormGroup);
          }

          (group.categories as UntypedFormArray).push(categoryFormGroup);
        }
      }

      if (item.categories) {
        group.categories = this.formBuilder.array(item.categories.map(el => this.buildFormGroup(el)));
      }
    }

    return this.formBuilder.group(group);

  }

  disableProductSelection(event: MouseEvent): void {
    const checked = (event.currentTarget as HTMLInputElement).checked;
    if (checked && this.contractForm.value.categories.length > 0) {
      this.dialogService.openConfirmationAlert(
        'Contract aanpassen',
        `Weet u zeker dat u het contract wilt instellen op algemene aanvraag/mobiliteitsbehoefte?`,
        null,
        true
      ).onClosed(async (response) => {
        if (response === false) {
          this.contractForm.get('productSelectionDisabled').reset();
        }
      });
    }
  }

  openCategoryDialog(categories, category, mode, index) {

    const dialogRef = this.dialog.open(CategoryDialogComponent, {
      width: '80%',
      maxWidth: '750px',
      data: {
        mode,
        category,
        contractId: this.contractId
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.action === 'add') {
          categories.push(result.categoryForm);
        }
        else if (result.action === 'edit') {
          category.patchValue(result.categoryForm.value);
        }
        else if (result.action === 'remove') {
          categories.removeAt(index);
        }
      }
    });
  }

  async addContract() {

    if (this.contractForm.invalid) {
      this.scrollToFirstError();
      util.markFormGroupTouched(this.contractForm);
      this.dialogService.openErrorAlert('Foutmelding', 'Nog niet alle gegevens zijn ingevuld. ' +
        'Vul de ontbrekende gegevens in en probeer het contract opnieuw toe te voegen.');
    } else {
      const contract = this.createContractDoc(this.contractForm.value);
      /*
      @TODO is .archived necessary, the archived is removed when editing the contract and we dont have a frontend
      option to archive contracts
      */
      contract.archived = false;

      this.showContractSpinner = true;

      try {
        await this.contractService.createContract(contract);
        this.showFormSuccessDialog();
        this.router.navigate(['contracts']);
      }
      catch (error) {
        this.errorService.alertAndLog(error, 'Het toevoegen van het contract is mislukt.');
      }
      finally {
        this.showContractSpinner = false;
      }
    }

  }

  async updateContract() {

    if (this.contractForm.invalid) {
      this.scrollToFirstError();
      util.markFormGroupTouched(this.contractForm);
      this.dialogService.openErrorAlert('Foutmelding', 'Nog niet alle gegevens zijn ingevuld. ' +
        'Vul de ontbrekende gegevens in en probeer het contract opnieuw op te wijzigen.');
    } else {
      const contract = this.createContractDoc(this.contractForm.value);
      this.showContractSpinner = true;

      try {
        // 1) MFA changed
        if (this.previousMultiFactorAuth !== contract.multiFactorAuth) {
          // 1.1) check for MFA task queue status
          this.loadingMessage = 'Controleren op lopende tweestapsverificatietaken...';
          await this.monitorEmployeesMFA();

          // 1.1.1) no MFA employee changes in queue
          if (this.mfaStatus === 0) {
            this.loadingMessage = 'Contract bijwerken...';
            await this.contractService.updateContract(this.contractId, contract);
            this.loadingMessage = 'Contractmedewerkers bijwerken...';
            await this.handleContractEmployeesMFA();
            await this.monitorEmployeesMFA();
            this.previousMultiFactorAuth = contract.multiFactorAuth;
            this.showFormSuccessDialog('Let op: Het duurt even voordat alle medewerkers die aan het contract zijn gekoppeld tweestaps-authenticatie hebben.');
            this.router.navigate(['contracts']);
          }
          // 1.1.2) MFA employee changes present in queue
          else {
            await this.handleTaskQueueBusy();
          }
        }

        // 2) MFA remained the same
        else {
          this.loadingMessage = '';
          await this.contractService.updateContract(this.contractId, contract);
          this.showFormSuccessDialog();
          this.router.navigate(['contracts']);
        }
      }
      catch (error) {
        this.previousMultiFactorAuth = null;
        // error for too many auth requests, this should not happen anymore but left in just in case
        if (error.message.includes('Exceeded quota for updating account information.')) {
          this.errorService.alertAndLog(error, 'Het wijzigen van het contract is mislukt. (Exceeded quota)');
        }
        // error if the FCF returns the task queue is still running
        else if (error.message.includes('The mfa taskqueue is running.')) {
          await this.handleTaskQueueBusy(error);
        }
        else {
          this.errorService.alertAndLog(error, 'Het wijzigen van het contract is mislukt.');
        }
      }
      finally {
        this.turnOnMonitorEmployeesMFAInterval();
        this.showContractSpinner = false;
        this.loadingMessage = null;
      }
    }

  }

  createContractDoc(formData: any): Contract {
    const doc: Contract = {
      name: formData.name,
      startDate: formData.startDate.toDate(),
      endDate: formData.endDate && formData.endDate.toDate(),
      establishmentId: formData.establishmentId,
      categories: formData.categories,
      archived: formData.archived,
      multiFactorAuth: formData.multiFactorAuth,
      productSelectionDisabled: formData.productSelectionDisabled
    };

    util.removeNullAndEmptyStringValues(doc);
    return doc;
  }

  showFormSuccessDialog(successMessage?: string) {
    this.dialogService.openInfoAlert('Succes',
      successMessage ? `Het contract is opgeslagen. ${successMessage}` : 'Het contract is opgeslagen.');
  }

  scrollToFirstError() {

    const firstElementWithError = document.querySelector(
      'textarea.ng-invalid, ' +
      'input.ng-invalid, ' +
      'select.ng-invalid, ' +
      '.toggle-error-span.invalid');

    if (firstElementWithError) {
      firstElementWithError.scrollIntoView();
      window.scrollBy(0, -150);
      this.footerFixed = true;
    }

  }

  scrollToMFACheckbox() {
    const mfaCheckboxContainer = document.getElementById('mfa-checkbox-container');
    if (mfaCheckboxContainer) {
      mfaCheckboxContainer.scrollIntoView();
      window.scrollBy(0, -150);
      this.footerFixed = true;
    }
  }

  moveCategory(categories, category, index, direction) {
    categories.removeAt(index, 1);
    if (direction === 'up') {
      categories.insert(index - 1, category);
    } else if (direction === 'down') {
      categories.insert(index + 1, category);
    }
  }

  multiFactorAuthCheckboxListener(event) {
    if (event.target.checked) {
      this.dialogService.openInfoAlert('Waarschuwing',
        `Door verificatie in twee stappen af te dwingen zullen werknemers waarvoor
      geen telefoonnummer is geconfigureerd toegang verliezen tot hun account.`,
        null, false, 'Wijzigingen opslaan'
      );
    }
  }

  async handleContractEmployeesMFA() {
    let changeType = 'REMOVE';
    if (this.contractForm.value.multiFactorAuth === true) {
      changeType = 'ADD';
    }

    const employeeIds: string[] = [];
    const countyIds: string[] = [];
    const promises = [];

    // 1) get counties associated with contract
    const countiesWithContract = await this.countyService.getCountiesWithContract(this.contractId);
    // 2) loop through counties associated with contract
    Object.keys(countiesWithContract).forEach((countyId) => {
      // 3) get county employees associated with contract
      promises.push(this.countyEmployeeService.getCountyEmployees(countyId));
    });

    const countyEmployees = await Promise.all(promises);

    // 4) loop through county employees associated with contract by country
    countyEmployees.forEach(countyEmployeesByCounty => {
      // 5) get employee IDs and remove duplicates
      Object.keys(countyEmployeesByCounty).forEach((countyEmployeeId) => {
        if (!employeeIds.includes(countyEmployeeId)) {
          employeeIds.push(countyEmployeeId);
        }
      });
    });

    await this.authService.refreshToken();
    await this.contractService.handleContractEmployeesMFA(this.contractId, employeeIds, changeType);
  }

  async monitorEmployeesMFA() {
    try {
      this.mfaStatus = await this.contractService.monitorEmployeesMFA(this.contractId);
      if (this.mfaStatus === 0) {
        clearInterval(this.monitorEmployeesMfaInterval);
      }
    }
    catch (error) {
      console.error(error.message);
    }
  }

  turnOnMonitorEmployeesMFAInterval() {
    clearInterval(this.monitorEmployeesMfaInterval);
    this.monitorEmployeesMfaInterval = setInterval(() => {
      this.monitorEmployeesMFA();
      if (this.useMonitorEmployeesMfaIntervalOnce) {
        clearInterval(this.monitorEmployeesMfaInterval);
      }
    }, this.monitorEmployeesMfaIntervalTime);
  }

  async handleTaskQueueBusy(error?) {
    this.scrollToMFACheckbox();

    if (error) {
      this.errorService.alertAndLog(error, 'Uw contractwijzigingen zijn opgeslagen, behalve tweestaps-authenticatie, omdat deze nog steeds wordt bijgewerkt voor een eerder verzoek. Probeer het later opnieuw.');
      this.contractForm.controls.multiFactorAuth.setValue(this.previousMultiFactorAuth);
    }
    else {
      this.dialogService.openErrorAlert('Contract kan op dit moment niet worden bijgewerkt',
        'Het contract kan op dit moment niet worden bijgewerkt omdat de authenticatie in twee stappen van de medewerker nog steeds wordt bijgewerkt vanaf een eerder verzoek. Probeer het zo meteen opnieuw.');
    }

    await this.monitorEmployeesMFA();
    if (this.mfaStatus !== 0) {
      this.turnOnMonitorEmployeesMFAInterval();
    }
  }

}
