<form *ngIf="county" [disabled]="isFormEnabled">
  <div class="general-form-list">
    <div class="general-form-list-header">
      <h2>Meldingen over nieuwe offertes</h2>
    </div>
    <h3 class="sub-header">
      Wanneer u notificaties wilt ontvangen over goed te keuren offertes kunt u hieronder dit instellen door “Aan” te selecteren en
      vervolgens het e-mailadres in te vullen. Vergeet niet op “Opslaan” te klikken om uw instellingen op te slaan.
    </h3>
    <div class="row three-columns-row">
      <div
        class="checkbox-container"
        [ngClass]="{ invalid: !isFormValid() }"
      >
        <input
          type="radio"
          id="notifyOn"
          name="notifyOn"
          [(ngModel)]="county.notifyOn"
          [value]="true"
        /><label for="notifyOn">Aan</label>
        <input
          type="radio"
          id="notifyOff"
          name="notifyOn"
          [(ngModel)]="county.notifyOn"
          [value]="false"
        /><label for="notifyOff">Uit</label>
      </div>
      <div
        class="input-container"
        [ngClass]="{
          invalid: !isFormValid()
        }"
      >
        <input
          [hidden]="!county.notifyOn"
          type="text"
          placeholder="Meldingen email adres"
          name="notifyEmail"
          [(ngModel)]="county.notifyEmail"
        />
      </div>
      <button class="cta" [disabled]="!isFormValid()" (click)="submitForm()">Opslaan</button>
    </div>
  </div>
</form>
