import { Component, Input, forwardRef, OnInit } from '@angular/core';
// tslint:disable-next-line:max-line-length
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, UntypedFormGroup,  AbstractControl, ValidationErrors } from '@angular/forms';
import * as util from '@app/shared/util';

@Component({
  selector: 'app-contact-person',
  templateUrl: './contact-person.component.html',
  styleUrls: ['./contact-person.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContactPersonComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ContactPersonComponent),
      multi: true
    }
  ]
})
export class ContactPersonComponent implements ControlValueAccessor, OnInit {

  public util = util;

  @Input() contactPersonForm: UntypedFormGroup;
  @Input() userIsClient: boolean;

  constructor() { }

  ngOnInit() {
    if (this.userIsClient) {
      this.setDisabledState(true);
    }
  }

  // Start of CVA's needed to connect to main form (https://blog.angularindepth.com/angular-nested-reactive-forms-using-cvas-b394ba2e5d0d)
  public onTouched: any = () => { };

  writeValue(val: any): void {
    // tslint:disable-next-line:no-unused-expression
    val && this.contactPersonForm.setValue(val, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.contactPersonForm.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.contactPersonForm.disable() : this.contactPersonForm.enable();
  }

  validate(c: AbstractControl): ValidationErrors | null{
    return this.contactPersonForm.valid ? null : { invalidForm: {valid: false, message: 'contactPersonForm fields are invalid'}};
  }
  // End of CVA's

  // This method is called from the parent form to mark all fields as touched
  markTouched() {
    this.markFormGroupTouched(this.contactPersonForm);
  }

  markFormGroupTouched(formGroup: UntypedFormGroup) {
    (Object as any).values(formGroup.controls).forEach(control => {
      if (control.controls) { // control is a FormGroup
        this.markFormGroupTouched(control);
      } else { // control is a FormControl
        control.markAsTouched();
      }
    });
  }

  reset() {
    this.contactPersonForm.reset();
  }

}
