import { Component, EventEmitter, OnInit, Output, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/shared/services/auth.service';
import { MediaWatcherService } from '@app/shared/services/media-watcher';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-headerv2',
  templateUrl: './headerv2.component.html',
  styleUrls: ['./headerv2.component.scss']
})
export class Headerv2Component implements OnInit, OnDestroy {
  @Output() toggleSidebarForMe = new EventEmitter();
  private unsubscribeAll: Subject<any> = new Subject<any>();
  isMenuShow: boolean;

  constructor(public authService: AuthService,
              private mediaWatcherService: MediaWatcherService,
              private router: Router) {}

  ngOnInit(): void {
    this.mediaWatcherService.onMediaChange$.pipe(takeUntil(this.unsubscribeAll)).subscribe(({ matchingAliases }) => {
      const sideBarOpen  = matchingAliases.includes('lg');
      if (sideBarOpen){
        this.isMenuShow = false;
        this.toggleSidebarForMe.emit(true);
      }else{
      this.isMenuShow = true;
      }
   });
  }

  toggleSidebar(isOpen?: boolean) {
    this.toggleSidebarForMe.emit(isOpen);
  }
  navigateToContact() {
    this.router.navigate(['/client-contact']);
  }
  ngOnDestroy(): void {
    this.unsubscribeAll.next(null);
    this.unsubscribeAll.complete();
  }
}
