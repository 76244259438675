<mat-toolbar>
  <mat-toolbar-row class="justify-content-between">
      <button mat-icon-button (click)="toggleSidebar()" *ngIf="isMenuShow">
        <mat-icon>menu</mat-icon>
      </button>
      <span class="profile-spacer"></span>
      <div class="row mr-2 ml-auto"  *ngIf="(authService.user$) | async as user">
        <ul class="row m-0 align-items-center">
          <li>
            <button
              mat-button
              [matMenuTriggerFor]="menu"
              class="user mt-2 d-flex align-items-end"
            >
            <span title="Ingelogd als {{user.email}}"><span *ngIf="user.email" class="as-email">{{user.email}}</span></span>
              <mat-icon class="user-image-icon ml-1"
                >keyboard_arrow_down</mat-icon
              >
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item id="signin-button" routerLink="sign-in" *ngIf="!((authService.user$) | async)">Inloggen</button>
              <button mat-menu-item id="signout-button" (click)="authService.signOut()" *ngIf="(authService.user$) | async">
                <mat-icon>exit_to_app</mat-icon>
                Uitloggen</button>
            </mat-menu>
          </li>
        </ul>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
  