import { Injectable } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class MediaWatcherService
{
    private onMediaChange: ReplaySubject<{ matchingAliases: string[]; matchingQueries: any }> = new ReplaySubject<{ matchingAliases: string[]; matchingQueries: any }>(1);
    private screens: any;
    /**
     * Constructor
     */
    constructor(
        private breakpointObserver: BreakpointObserver,
    )
    {
    this.screens = { sm: '(min-width: 600px)', md: '(min-width: 960px)', lg: '(min-width: 1280px)', xl: '(min-width: 1440px)'};
    this.breakpointObserver.observe(Object.values(this.screens)).pipe(
                map((state) => {

                    // Prepare the observable values and set their defaults
                    const matchingAliases: string[] = [];
                    const matchingQueries: any = {};

                    // Get the matching breakpoints and use them to fill the subject
                    const matchingBreakpoints = Object.entries(state.breakpoints).filter(([query, matches]) => matches) ?? [];
                    for ( const [query] of matchingBreakpoints )
                    {
                        // Find the alias of the matching query
                        const matchingAlias = Object.entries(this.screens).find(([alias, q]) => q === query)[0];

                        // Add the matching query to the observable values
                        if ( matchingAlias )
                        {
                            matchingAliases.push(matchingAlias);
                            matchingQueries[matchingAlias] = query;
                        }
                    }

                    // Execute the observable
                    this.onMediaChange.next({
                        matchingAliases,
                        matchingQueries
                    });
                }
        )).subscribe(result => {
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for _onMediaChange
     */
    get onMediaChange$(): Observable<{ matchingAliases: string[]; matchingQueries: any }>
    {
        return this.onMediaChange.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * @description On media query change
     * @param query observe
     */
    onMediaQueryChange$(query: string | string[]): Observable<BreakpointState>
    {
        return this.breakpointObserver.observe(query);
    }
}
