import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ErrorService } from '@app/shared/services/error.service';
import { ClientService } from '../../services/client.service';
import { TitleService } from '@app/shared/services/title.service';
import { Client } from '@app/shared/models/client';
import { OrderService } from '@app/modules/order/services/order.service';
import * as moment from 'moment';
import * as msgLog from '@app/shared/util/error-text';

@Component({
  selector: 'app-client-page',
  templateUrl: './client-page.component.html',
  styleUrls: ['./client-page.component.scss']
})
export class ClientPageComponent implements OnInit, OnDestroy {
  clientId: string;
  showClientSpinner = true;
  clientDetails: any;
  footerFixed = true;
  private clientSubscription: Subscription;

  constructor(
    public clientService: ClientService,
    private errorService: ErrorService,
    private titleService: TitleService,
    private orderService: OrderService,
    private route: ActivatedRoute,
    public router: Router,
  ) { }

  async ngOnInit() {
    this.titleService.setTitle(`Profiel`);
    this.clientId = this.route.snapshot.paramMap.get('clientId');

    if (this.clientId) {
      this.getClientDetails();
      window.addEventListener('scroll', () => {
        const windowScrollTop = window.scrollY;
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;
        const documentHeightWithoutWindowHeight = documentHeight - windowHeight;
        this.scrollEventListener(windowScrollTop, windowHeight, documentHeight, documentHeightWithoutWindowHeight);
      });
    }
    else {
      this.router.navigate(['']);
    }
  }

  getClientDetails() {
    this.clientSubscription = this.clientService.getClient$(this.clientId).subscribe(async (client: Client) => {
      this.showClientSpinner = true;
      this.clientDetails = null;

      try {
        this.clientDetails = (await this.orderService.getClientOrders(client.misaClientId)).client;
        if (this.clientDetails.birthDate) {
          this.clientDetails.birthDate = moment(this.clientDetails.birthDate).format('DD-MM-YYYY');
        }
        if (!this.clientDetails.contactPerson) {
          this.clientDetails.contactPerson = {};
        }
      }
      catch (error) {
        let message: string;
        message = msgLog.mapErrorTypeToContent(error.message,
          'Er is een probleem opgetreden bij het ophalen van uw profielgegevens.');
        this.errorService.alertAndLog(error, message).onClosed(async () => {
          this.router.navigate(['']);
        });
      }
      finally {
        this.showClientSpinner = false;
      }
    });
  }

  ngOnDestroy() {
    if (this.clientSubscription) {
      this.clientSubscription.unsubscribe();
    }
  }

  scrollEventListener(windowScrollTop, windowHeight, documentHeight, documentHeightWithoutWindowHeight) {
    if (documentHeight === windowHeight && windowScrollTop === 0) {
      this.footerFixed = true;
    }
    else if (windowScrollTop >= (documentHeightWithoutWindowHeight - 70)) {
      this.footerFixed = false;
    }
    else {
      this.footerFixed = true;
    }
  }
}
