<div class="general-form-header">
    <h1>Gemeenten</h1>
    <a routerLink="/county" class="secondary-cta" href="/county">+ Nieuwe gemeente</a>
</div>

<div class="general-form-container">

    <div class="input-icon-container margin-bottom">
        <mat-icon>search</mat-icon>
        <input matInput (keyup)="filterTable($event.target.value)" placeholder="Zoek" type="text" />
    </div>

    <table class="lister-table counties" mat-table [dataSource]="countiesTable">

        <ng-container matColumnDef="name">
            <th class="name" mat-header-cell *matHeaderCellDef> Naam </th>
            <td class="name" mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="contract">
            <th mat-header-cell *matHeaderCellDef> Contract </th>
            <td mat-cell *matCellDef="let element"> {{(element.contract | async)?.name}} </td>
        </ng-container>

        <ng-container matColumnDef="edit">
            <th class="edit" mat-header-cell *matHeaderCellDef></th>
            <td class="edit cta-td" mat-cell *matCellDef="let element">
              <a class="cta"
                  routerLink="/county/{{element.countyId}}">
                  Bijwerken
            </a>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="countiesTableColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: countiesTableColumns;"></tr>

    </table>

    <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>

</div>

<app-spinner *ngIf="showCountiesSpinner" loadingMessage=""></app-spinner>
