import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NotificationService } from '@app/shared/services/notification.service';
import { DialogService } from '@app/shared/services/dialog.service';
import { ManualService } from '../../services/manuals.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { UserManual } from '../../models/user-manual';

@Component({
  selector: 'app-about-manuals-admin',
  templateUrl: './about-manuals-admin.component.html',
  styleUrls: ['./about-manuals-admin.component.scss'],
  animations: [
    trigger('rotateIcon', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(-180deg)' })),
      transition('rotated => default', animate('225ms ease-out')),
      transition('default => rotated', animate('225ms ease-in'))
    ])
  ]
})
export class AboutManualsAdminComponent implements OnInit {
  manualFiles: UserManual[] = [];
  @ViewChild('manualEditor') manualEditor: TemplateRef<any>;
  dialogRef: MatDialogRef<any, any>;
  newManual: UserManual = { title: '', downloadURL: '', id: '' };
  maxAllowedFileCount: number = 10;
  newFile: File;
  constructor(
    public dialog: MatDialog,
    private manualService: ManualService,
    private dialogService: DialogService,
    private notificationService: NotificationService
  ) {}

  async ngOnInit(): Promise<void> {
    this.manualFiles = await this.manualService.getUserManuals();
  }

  showEditor() {
    this.newManual = { id: '', title: '', downloadURL: '' };
    this.newFile = null;
    this.dialogRef = this.dialog.open(this.manualEditor, {
      width: '80%',
      maxWidth: '750px'
    });
  }

  async deleteManual(id: string): Promise<void> {
    try {
      const manualToDelete = this.manualFiles.find((x) => x.id === id);
      await this.manualService.deleteManual(manualToDelete);
      this.manualFiles = this.manualFiles.filter((x) => x.id !== id);
      this.notificationService.openSnackBar('Handleiding is verwijderd');
    } catch (error) {
      this.dialogService.openErrorAlert(`Foutmelding`, 'Fout bij verwijderen van handleiding.');
      console.log(error);
    }
  }

  onFileChange(e: Event) {
    this.newFile = (e.target as HTMLInputElement).files[0];
  }

  isFormValid(): boolean {
    return this.newManual.title.length > 0 && this.newFile!==null;
  }

  async saveActiveManual(): Promise<void> {
    const insertedManual = await this.manualService.saveManual(this.newManual, this.newFile);
    if (insertedManual !== null) {
      this.manualFiles.push(insertedManual);
    } else {
      this.dialogService.openErrorAlert(`Foutmelding`, 'Fout bij toevoegen van handleiding.');
    }
    this.dialogRef.close();
  }
}
