<div class="top-toolbar-container">
  <mat-icon aria-hidden="false" aria-label="Close icon" (click)="closeDialog()">close</mat-icon>
</div>
<div class="title">Verzoek indienen</div>
<div class="subtitle">Maak een keuze voor het gewenste verzoek:</div>
<div>
  <mat-radio-group aria-label="Select an option" [(ngModel)]="selectedType">
    <table width="100%">
      <tr>
        <td *ngIf="data.repair">
          <button class="request-type-button" (click)="selectType('repair')">
            <img src="/assets/images/request-types/icon-reparatie-nodig.svg" alt="" />
            <p>Reparatie nodig</p>
            <mat-icon class="circular-bg-green-bg">arrow_forward</mat-icon>
          </button>
        </td>
        <td *ngIf="data.pickup">
          <button class="request-type-button" (click)="selectType('collection')">
            <img src="/assets/images/request-types/icon-laten-ophalen.svg" alt="" />
            <p>Laten ophalen</p>
            <mat-icon class="circular-bg-green-bg">arrow_forward</mat-icon>
          </button>
        </td>
        <td *ngIf="data.adjustment">
          <button class="request-type-button" (click)="selectType('adjustment')">
            <img src="/assets/images/request-types/icon-aanpassingen.svg" alt="" />
            <p>Aanpassingen</p>
            <mat-icon class="circular-bg-green-bg">arrow_forward</mat-icon>
          </button>
        </td>
      </tr>
      <tr *ngIf="false">
        <td *ngIf="data.repair">
          <img src="/assets/images/request-types/icon-reparatie-nodig.svg" alt="" />
          <p>Reparatie nodig</p>
        </td>
        <td *ngIf="data.pickup">
          <img src="/assets/images/request-types/icon-laten-ophalen.svg" alt="" />
          <p>Laten ophalen</p>
        </td>
        <td *ngIf="data.adjustment">
          <img src="/assets/images/request-types/icon-aanpassingen.svg" alt="" />
          <p>Aanpassingen</p>
        </td>
      </tr>
      <tr *ngIf="false">
        <td *ngIf="data.repair">
          <mat-radio-button value="repair"></mat-radio-button>
        </td>
        <td *ngIf="data.pickup">
          <mat-radio-button value="collection"></mat-radio-button>
        </td>
        <td *ngIf="data.adjustment">
          <mat-radio-button value="adjustment"></mat-radio-button>
        </td>
      </tr>
    </table>
  </mat-radio-group>
</div>
