import { Component, OnInit, OnDestroy } from '@angular/core';
import { Establishment } from '@app/modules/establishment/models/establishment';
import { ConfigService } from '@app/shared/services/config.service';
import { EstablishmentService } from '@app/modules/establishment/services/establishment.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit, OnDestroy {

  establishmentEmailAddress: string;
  establishmentPhoneNumber: string;
  private establishmentOfSignedInUserSubscription: Subscription;
  establishmentOfSignedInUser: Establishment;

  constructor(
    public configService: ConfigService,
    private establishmentService: EstablishmentService
  ) { }

  ngOnInit() {
    this.establishmentOfSignedInUserSubscription = this.establishmentService.establishmentOfSignedInUser$.subscribe(establishment => {
      this.establishmentOfSignedInUser = establishment;
    });
  }

  ngOnDestroy() {
    this.establishmentOfSignedInUserSubscription.unsubscribe();
  }

}
