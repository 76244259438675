<div class="single-client-table-container" [ngClass]="{'hidden': tableDataSource?.data.length == 0}">
  <table mat-table [dataSource]="tableDataSource" multiTemplateDataRows>
    <tr class="details-row" mat-row *matRowDef="let item; columns: ['clientOrders']"></tr>

    <ng-container matColumnDef="clientOrders">
      <td>
        <div class="client-details single-client">

          <div class="order" mat-cell *matCellDef="let order;">
            <app-single-client-order
            (productName)="order.productName = $event"
            (productId)="order.id = $event"
            [misaOrderId]="order.misaOrderId"
            (orderLoaded)="onOrderFetchHandler($event)"
            >
          </app-single-client-order>
          </div>

        </div>
      </td>
    </ng-container>

  </table>
  <mat-paginator [pageSize]="20" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
</div>

<div *ngIf="!ordersLoading && (!tableDataSource || tableDataSource?.data.length == 0)">
  <p class="no-orders-text" id="no-orders-message" *ngIf="!ordersLoadingFailed">{{noOrdersMessage}}</p>
  <p class="no-orders-text" id="orders-loading-failed-message" *ngIf="ordersLoadingFailed">{{errorMessage}}</p>
</div>

<app-spinner *ngIf="ordersLoading"></app-spinner>
