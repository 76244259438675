import { NewOrder } from '@app/modules/order/models/new-order';
import { ChangedOrder } from '@app/modules/order/models/changed-order';
import { Order } from '@app/modules/order/models/order';
import * as util from '@app/shared/util';

import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { trace } from '@angular/fire/compat/performance';
import { AngularFireFunctions } from '@angular/fire/compat/functions';


const timeoutMillis = 300 * 1000;

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  private perf = firebase.performance();
  private addOrder$: (data: any) => Observable<any>;
  private changeOrder$: (data: any) => Observable<any>;
  private approveOrder$: (data: any) => Observable<any>;
  private cancelOrder$: (data: any) => Observable<any>;
  private endOrder$: (data: any) => Observable<any>;
  private getClientOrders$: (data: any) => Observable<any>;
  private getOrder$: (data: any) => Observable<any>;
  private rejectOrder$: (data: any) => Observable<any>;
  private requestContact$: (data: any) => Observable<any>;
  private requestItemAdjustment$: (data: any) => Observable<any>;
  private requestItemRepair$: (data: any) => Observable<any>;

  constructor(private functions: AngularFireFunctions, private firestore: AngularFirestore) {
    this.addOrder$ = functions.httpsCallable('addOrder');
    this.changeOrder$ = functions.httpsCallable('changeOrder');
    this.approveOrder$ = functions.httpsCallable('approveOrder');
    this.cancelOrder$ = functions.httpsCallable('cancelOrder');
    this.endOrder$ = functions.httpsCallable('endOrder');
    this.getClientOrders$ = functions.httpsCallable('getClientOrders');
    this.getOrder$ = functions.httpsCallable('getOrder');
    this.rejectOrder$ = functions.httpsCallable('rejectOrder');
    this.requestContact$ = functions.httpsCallable('requestContact');
    this.requestItemAdjustment$ = functions.httpsCallable('requestItemAdjustment');
    this.requestItemRepair$ = functions.httpsCallable('requestItemRepair');
  }

  generateTemporaryOrderId(): string {
    return this.firestore.createId();
  }

  async getOrder(misaOrderId: string, withDeliverFilter: boolean = false, allowAllRole: boolean = false, withiWMOFilter: boolean = false): Promise<Order> {
    const response = await this.getOrder$({ misaOrderId, withDeliverFilter, allowAllRole, withiWMOFilter })
      .pipe(trace('getOrder'))
      .toPromise();
    return this.convertToModel(response);
  }

  async createOrder(order: NewOrder): Promise<Object> {
    const orderCopy: any = _.cloneDeep(order);
    orderCopy.client.birthDate = util.convertDateToString(orderCopy.client.birthDate);
    return await this.addOrder$(orderCopy).pipe(trace('addOrder')).toPromise();
  }

  async changeOrder(order: ChangedOrder): Promise<void> {
    const orderCopy: any = _.cloneDeep(order);
    orderCopy.client.birthDate = util.convertDateToString(orderCopy.client.birthDate);

    await this.changeOrder$(orderCopy).pipe(trace('changeOrder')).toPromise();
  }

  async approveOrder(misaOrderId: string, attachStream?: any): Promise<void> {
    await this.approveOrder$({ misaOrderId, attachStream }).pipe(trace('approveOrder')).toPromise();
  }

  async cancelOrder(misaOrderId: string): Promise<void> {
    await this.cancelOrder$({ misaOrderId }).pipe(trace('cancelOrder')).toPromise();
  }

  async endOrder(misaOrderId: string, endDate: Date, remarks?: string,endReason?: string): Promise<void> {
    const newEndData = util.convertDateToString(endDate);

    await this.endOrder$({ misaOrderId, endDate:newEndData, remarks, endReason })
      .pipe(trace('endOrder'))
      .toPromise();
  }

  async rejectOrder(misaOrderId: string, attachStream?: any): Promise<void> {
    await this.rejectOrder$({ misaOrderId, attachStream }).pipe(trace('rejectOrder')).toPromise();
  }

  async requestContact(misaOrderId: string, attachStream?: any): Promise<void> {
    console.log('requestContact', misaOrderId);
    await this.requestContact$({ misaOrderId, attachStream }).pipe(trace('requestContact')).toPromise();
  }

  async getClientOrders(misaClientId: string): Promise<any> {
    return await this.getClientOrders$({ misaClientId }).pipe(trace('getClientOrders')).toPromise();
  }

  async getClientsWithActiveOrders(): Promise<any[]> {
    const trace = this.perf.trace('getClientsWithActiveOrders');
    trace.start();

    try {
      const callable = this.functions.httpsCallable('getClientsWithActiveOrders');
      const response = await callable({ timeout: timeoutMillis }).toPromise();
      return response;
    } finally {
      trace.stop();
    }
  }

  async getClientsWithRecentOrders(): Promise<any[]> {
    const trace = this.perf.trace('getClientsWithRecentOrders');
    trace.start();

    try {
      const callable = this.functions.httpsCallable('getClientsWithRecentOrders');
      const response = await callable({ timeout: timeoutMillis }).toPromise();

      return response;
    } finally {
      trace.stop();
    }
  }

  async getClientsWithWaitingOrders(): Promise<any[]> {
    const trace = this.perf.trace('getClientsWithWaitingOrders');
    trace.start();

    try {
      const callable = this.functions.httpsCallable('getClientsWithWaitingOrders');
      const response = await callable({ timeout: timeoutMillis }).toPromise();

      return response;
    } finally {
      trace.stop();
    }
  }

  async requestItemAdjustment(countyId: string, misaOrderId: string, misaItemId: string, remarks: string): Promise<void> {
    await this.requestItemAdjustment$({ countyId, misaOrderId, misaItemId, remarks })
      .pipe(trace('requestItemAdjustment'))
      .toPromise();
  }

  async requestItemRepair(countyId: string, misaOrderId: string, misaItemId: string, remarks: string): Promise<void> {
    await this.requestItemRepair$({ countyId, misaOrderId, misaItemId, remarks })
      .pipe(trace('requestItemRepair'))
      .toPromise();
  }

  private convertToModel(data: any): Order {
    data.approvalDate = this.convertToDate(data.approvalDate);
    if (data.client) {
      data.client.birthDate = this.convertToDate(data.client.birthDate);
    }

    data.dateDelivered = this.convertToDate(data.dateDelivered);
    data.dateRetrieved = this.convertToDate(data.dateRetrieved);

    for (const document of util.checkOptArray(data.documents) || []) {
      document.creationDate = this.convertToDate(document.creationDate);
    }

    data.fittingDate = this.convertToDate(data.fittingDate);
    data.orderCreationDate = this.convertToDate(data.orderCreationDate);
    data.plannedDeliveryDate = this.convertToDate(data.plannedDeliveryDate);
    data.plannedRetrievalDate = this.convertToDate(data.plannedRetrievalDate);
    data.serviceDate = this.convertToDate(data.serviceDate);
    data.serviceDateEnd = this.convertToDate(data.serviceDateEnd);

    return data as Order;
  }

  private convertToDate(input?: string): Date | undefined {
    return util.isString(input) ? new Date(input) : undefined;
  }
}
