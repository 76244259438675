
export interface Name {
  displayName?: string;
  initials: string;
  firstName?: string;
  prefix?: string;
  lastName?: string;
  partnerNamePrefix?: string;
  partnerName?: string;
  nameUsage?: string;
}

export const formatName = (name: Name): string => {
  if (name.displayName) {
    return name.displayName;
  }

  const nameParts: string[] = [];

  if (name.initials) {
    nameParts.push(name.initials);
  }

  if (name.firstName) {
    nameParts.push(`(${name.firstName})`);
  }

  switch (name.nameUsage) {
    case 'FAMILY_NAME_FOLLOWED_BY_PARTNER_NAME':
      {
        if (name.lastName) {
          if (name.prefix) {
            nameParts.push(name.prefix);
          }

          nameParts.push(name.lastName);
        }

        if (name.lastName && name.partnerName) {
          nameParts.push('-');
        }

        if (name.partnerName) {
          if (name.partnerNamePrefix) {
            nameParts.push(name.partnerNamePrefix);
          }

          nameParts.push(name.partnerName);
        }

        break;
      }
    case 'PARTNER_NAME_ONLY':
      {
        if (name.partnerName) {
          if (name.partnerNamePrefix) {
            nameParts.push(name.partnerNamePrefix);
          }

          nameParts.push(name.partnerName);
        }

        break;
      }
    case 'FAMILY_NAME_ONLY':
      {
        if (name.lastName) {
          if (name.prefix) {
            nameParts.push(name.prefix);
          }

          nameParts.push(name.lastName);
        }

        break;
      }
    case 'PARTNER_NAME_FOLLOWED_BY_FAMILY_NAME':
    default:
      {
        if (name.partnerName) {
          if (name.partnerNamePrefix) {
            nameParts.push(name.partnerNamePrefix);
          }

          nameParts.push(name.partnerName);
        }

        if (name.partnerName && name.lastName) {
          nameParts.push('-');
        }

        if (name.lastName) {
          if (name.prefix) {
            nameParts.push(name.prefix);
          }

          nameParts.push(name.lastName);
        }

        break;
      }
  }

  return nameParts.join(' ').trim();
};

export const formatLastName = (name: Name): string => {
  const lastNameParts: string[] = [];

  switch (name.nameUsage) {
    case 'FAMILY_NAME_FOLLOWED_BY_PARTNER_NAME':
      {
        if (name.lastName) {
          lastNameParts.push(name.lastName);
        }
        if (name.lastName && name.partnerName) {
          lastNameParts.push('-');
        }
        if (name.partnerName) {
          lastNameParts.push(name.partnerName);
        }
        break;
      }
    case 'PARTNER_NAME_ONLY':
      {
        if (name.partnerName) {
          lastNameParts.push(name.partnerName);
        }
        break;
      }
    case 'FAMILY_NAME_ONLY':
      {
        if (name.lastName) {
          lastNameParts.push(name.lastName);
        }
        break;
      }
    case 'PARTNER_NAME_FOLLOWED_BY_FAMILY_NAME':
    default:
      {
        if (name.partnerName) {
          lastNameParts.push(name.partnerName);
        }
        if (name.partnerName && name.lastName) {
          lastNameParts.push('-');
        }
        if (name.lastName) {
          lastNameParts.push(name.lastName);
        }
        break;
      }
  }

  return lastNameParts.join(' ').trim();
};
