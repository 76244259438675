import * as category from '@app/modules/contract/models/category';
import { Contract } from '@app/modules/contract/models/contract';
import { ContractService } from '@app/modules/contract/services/contract.service';
import { County } from '@app/modules/county/models/county';
import { CountyService } from '@app/modules/county/services/county.service';
import { RequestDialogComponent } from '@app/modules/order/components/request-dialog/request-dialog.component';
import { Order } from '@app/modules/order/models/order';
import { OrderDocument } from '@app/modules/order/models/order-document';
import { OrderService } from '@app/modules/order/services/order.service';
import { OrderDocumentService } from '@app/modules/order/services/order-document.service';
import { formatName } from '@app/shared/models/name';
import { DownloadUrl } from '@app/shared/pipes/download-url.pipe';
import { DialogService } from '@app/shared/services/dialog.service';
import { ErrorService } from '@app/shared/services/error.service';
import { SignedInUser, UserService } from '@app/shared/services/user.service';
import * as util from '@app/shared/util';
import * as msgLog from '@app/shared/util/error-text';

import { trigger, style, state, transition, animate } from '@angular/animations';
import { OnInit, Component, Input } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { RequestTypeSelectorComponent } from '@app/modules/client/components/request-type-selector/request-type-selector.component';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ReplaySubject, of } from 'rxjs';

import { CloudLogger, CloudLoggingService } from '@app/shared/services/cloud-logging.service';
import { UrgentDialogComponent } from '../../urgent-dialog/urgent-dialog.component';
import { WorkHour } from '@app/shared/models/workHour';
import { ConfigService } from '@app/shared/services/config.service';
import { outsideWorkingkHours } from '@app/shared/util';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
  animations: [
    trigger('rotateIcon', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(-180deg)' })),
      transition('rotated => default', animate('225ms ease-out')),
      transition('default => rotated', animate('225ms ease-in'))
    ])
  ]
})
export class OrderComponent implements OnInit {
  private cloudLog: CloudLogger;

  productImagePlaceholderUrl = '/assets/images/medipoint-logo-small.png';

  contract?: Contract = null;
  downloadingQuote = false;
  expanded = '';
  formatName = formatName;
  loadingOrder = true;
  processing = false;
  user: SignedInUser;
  county?: County = null;
  order?: Order = null;

  enableJudgeColumn = false;
  greenJudgeColumn = false;
  product?: any;
  redJudgeColumn = false;
  weeksSinceDelivery?: number;
  showInfoMessage = false;
  workHour: any;


  @Input()
  misaOrderId: string;

  constructor(
    private cloudLoggingService: CloudLoggingService,
    private contractService: ContractService,
    private countyService: CountyService,
    private dialogService: DialogService,
    private downloadUrl: DownloadUrl,
    private errorService: ErrorService,
    private orderService: OrderService,
    private orderDocumentService: OrderDocumentService,
    private userService: UserService,
    private configService: ConfigService,
    private dialog: MatDialog,
    public router: Router
  ) { }

  async ngOnInit(): Promise<void> {
    this.cloudLog = this.cloudLoggingService.createLogger(`order.component(misaOrderId: ${this.misaOrderId})`);
    try {
      this.user = util.checkObject(await this.userService.getUser());
      this.county = await this.countyService.getCounty(this.user.countyId);
      this.contract = await this.contractService.getContract(this.county.contractId);
    } catch (error) {
      this.loadingOrder = false;
      return;
    }

    await this.load();
  }

  async load(): Promise<void> {
    this.downloadingQuote = false;
    this.expanded = '';
    this.loadingOrder = true;
    this.order = null;
    this.processing = false;

    try {
      await this.fetchOrder();
    } catch (error) {
      this.cloudLog.error(`Error getting order from MISA (${error})`);
    } finally {
      this.loadingOrder = false;
    }
  }

  async downloadQuote(documentId: string) {
    this.downloadingQuote = true;

    try {
      await this.orderDocumentService.downloadDocumentAsFile(documentId);
    } catch (reason) {
      let message: string;
      message = msgLog.mapErrorTypeToContent(reason.message, 'Het bestand kon niet worden gedownload.');
      this.errorService.alertAndLog(reason, message);
    } finally {
      this.downloadingQuote = false;
    }
  }

  async cancelOrder() {
    this.processing = true;

    try {
      await this.orderService.cancelOrder(this.misaOrderId);

      this.dialogService.openInfoAlert('Succes', 'De aanvraag is geannuleerd.').onClosed(async () => {
        await this.load();
      });
    } catch (error) {
      let message: string;
      message = msgLog.mapErrorTypeToContent(error.message, 'Het annuleren van de aanvraag is mislukt.');
      this.errorService.alertAndLog(error, message);
    } finally {
      this.processing = false;
    }
  }

  editOrder() {
    this.router.navigate([`/order/${this.misaOrderId}`]);
  }

  async submitDecision(form: any): Promise<void> {
    this.processing = true;
    const decision = form.value.decision;
    const attachStream: any = null;

    //
    // MDX-73 - Attachment removed from emails
    // As per the request (MDX-73) attachment has been removed from the approve/need info/disapprove emails
    //
    // const latestQuote = this.getLatestQuote();
    // if (latestQuote) {
    //   attachStream = await this.orderDocumentService.downloadDocumentAsBase64(latestQuote.id);
    // }

    if (decision === 'approve') {
      try {
        await this.orderService.approveOrder(this.misaOrderId, attachStream);

        this.dialogService.openInfoAlert('Succes', 'De offerte is goedgekeurd.').onClosed(async () => {
          this.processing = false;
          await this.load();
        });
      } catch (error) {
        let message: string;
        message = msgLog.mapErrorTypeToContent(error.message, 'Het goedkeuren van de offerte is mislukt.');
        this.errorService.alertAndLog(error, message).onClosed(async () => {
          this.processing = false;
        });
      }
    } else if (decision === 'contact') {
      try {
        await this.orderService.requestContact(this.misaOrderId, attachStream);

        this.dialogService.openInfoAlert('Succes', 'Een contactverzoek is verstuurd en de aanvraag is gepauzeerd.').onClosed(async () => {
          this.processing = false;
          await this.load();
        });
      } catch (error) {
        let message: string;
        message = msgLog.mapErrorTypeToContent(
          error.message,
          'Het contactverzoek kon niet worden verstuurd en de aanvraag is niet gepauzeerd.'
        );
        this.errorService.alertAndLog(error, message).onClosed(async () => {
          this.processing = false;
        });
      }
    } else if (decision === 'reject') {
      try {
        await this.orderService.rejectOrder(this.misaOrderId, attachStream);

        this.dialogService.openInfoAlert('Succes', 'De offerte is afgekeurd en de aanvraag is geannuleerd.').onClosed(async () => {
          this.processing = false;
          await this.load();
        });
      } catch (error) {
        let message: string;
        message = msgLog.mapErrorTypeToContent(
          error.message,
          'Het afkeuren van de offerte en het annuleren van de aanvraag is mislukt.'
        );
        this.errorService.alertAndLog(error, message).onClosed(async () => {
          this.processing = false;
        });
      }
    } else {
      this.processing = false;
    }
  }

  hasSubstate(...substates: string[]) {
    return substates.some((substate) => substate === this.order.substate);
  }

  isExpanded(column: string): boolean {
    return this.expanded === column;
  }

  toggleExpanded(expandedColumn: string): void {
    this.expanded = this.expanded === expandedColumn ? '' : expandedColumn;
  }

  hideExpanded(): void {
    this.expanded = '';
  }

  getLatestQuote(): OrderDocument {
    if (this.order.documents && this.order.documents.length > 0) {
      return (this.order.documents as OrderDocument[])
        .filter((document) => util.isDate(document.creationDate))
        .sort((lhs, rhs) => {
          const lhsTime = lhs.creationDate.getTime();
          const rhsTime = rhs.creationDate.getTime();

          return (rhsTime > lhsTime ? 1 : 0) - (rhsTime < lhsTime ? 1 : 0);
        })
        .find((document) => document.documentType === 'COUNTY_QUOTE');
    }
  }

  private async fetchOrder(): Promise<void> {
    const order = util.checkObject(await this.orderService.getOrder(this.misaOrderId));
    const product = order.products.length > 0 ? order.products[0] : null;

    this.order = order;

    this.enableJudgeColumn = ['QUOTE_WAITING_FOR_APPROVAL', 'AWAITING_APPROVAL_SERVICE', 'AWAITING_APPROVAL_DELIVERY'].includes(
      order.substate
    );

    this.greenJudgeColumn = [
      'QUOTE_APPROVED',
      'ITEM_IN_VIEW',
      'ORDER_PLACED',
      'ORDER_RECEIVED',
      'PREPARING_SERVICE',
      'ORDER_RETURNED',
      'EXECUTING_SERVICE',
      'READY_FOR_DELIVERY',
      'DELIVERY_PLANNED',
      'SERVICE_RETRIEVAL_PLANNED',
      'RETRIEVAL_ON_NEW_DELIVERY',
      'TEMPORARY_STORAGE',
      'DELIVERED',
      'RETRIEVED',
      'PLANNING_SERVICE_DATE',
      'SERVICE_DATE_PLANNED'
    ].includes(order.substate);

    this.product = product
      ? {
        description: undefined,
        id: product.id,
        imageUrl$: undefined,
        name: undefined,
        productId: product.productId
      }
      : undefined;

    this.redJudgeColumn = ['QUOTE_WAITING_FOR_APPROVAL', 'AWAITING_APPROVAL_SERVICE', 'AWAITING_APPROVAL_DELIVERY'].includes(
      order.substate
    );

    this.weeksSinceDelivery = order.dateDelivered ? moment().diff(moment(order.dateDelivered), 'weeks') : 0;

    this.setProductInfo();
  }

  private setProductInfo(): void {
    const productId = this.product.productId;
    const product = category.findProductInCategories(productId, this.contract.categories);

    if (!product) {
      this.product.imageUrl$ = of(this.productImagePlaceholderUrl);
      return;
    }

    this.product.description = product.description;
    this.product.name = product.name;

    const urlSubject = new ReplaySubject<string>(1);

    this.downloadUrl.transform(product.image.storageFilePath, 'contract-images').subscribe(urlSubject);

    this.product.imageUrl$ = urlSubject.asObservable();
  }

  getProductIdName(product): string {
    return product.productId ? `${product.productId}${product.name ? ' - ' + product.name : ''}` : 'Algemene aanvraag';
  }


  isRequestEnabled(): boolean {
    return this.userService.canRequestAdjustment(this.user, this.county) || this.userService.canRequestPickup(this.user, this.county) || this.userService.canRequestRepair(this.user, this.county);
  }

  doRequest(): void {
    this.configService.getWorkDayHour().then((data: WorkHour) => {
      this.workHour = data;
      this.showInfoMessage = outsideWorkingkHours(data);
    });
    this.configService.getHolidays().then((holidays: Array<any>) => {
     const findHoliday = holidays.find(holiday => util.datesAreOnSameDay( new Date(), new Date(holiday.seconds * 1000)));
     if (findHoliday) {
       this.showInfoMessage = true;
     }
    });

    const dialogSelectorRef = this.dialog.open(RequestTypeSelectorComponent, {
      width: '400px',
      data: { repair: this.userService.canRequestRepair(this.user, this.county), adjustment: this.userService.canRequestAdjustment(this.user, this.county), pickup: this.userService.canRequestPickup(this.user, this.county) }
    });

    dialogSelectorRef.afterClosed().subscribe((result) => {
      if (this.showInfoMessage && result === 'repair') {
        this.openUrgentDialog(result, this.product.id);
      }else if (result && result !== '') {
        this.openRequestDialog(result, this.product.id);
      }
    });
  }

  openRequestDialog(requestType: string, productId: string) {
    const requestDialogRef = this.dialog.open(RequestDialogComponent, {
      width: '80%',
      maxWidth: '750px',
      data: {
        requestType
      }
    });

    requestDialogRef.afterClosed().subscribe((result) => {
      if (result && result.action === 'add') {
        this.processing = true;
        switch (requestType) {
          case 'repair':
            this.doRepairRequest(result, this.misaOrderId,  productId);
            break;
          case 'collection':
            this.doCollectionRequest(result, this.misaOrderId);
            break;
          case 'adjustment':
            this.doAdjustmentRequest(result, this.misaOrderId, productId);
            break;
          default:
            this.errorService.alertAndLog('Unknown requestType');
            break;
        }
      }
    });
  }
  openUrgentDialog(requestType: string, productId: string) {
    const urgentDialogRef = this.dialog.open(UrgentDialogComponent, {
      width: '70%',
      maxWidth: '550px',
      data: {
        requestType
      }
    });
    urgentDialogRef.afterClosed().subscribe((result) => {
      if (result){
        this.openRequestDialog(requestType, productId);
      }
    });
  }
  doRepairRequest(result: any, misaOrderId: string, productId: string) {
    const remarks = result.categoryForm.controls.remarks.value;

    this.orderService
      .requestItemRepair(this.user.countyId, misaOrderId, productId, remarks)
      .then(() => {
        this.dialogService.openInfoAlert('Reparatieverzoek indienen', 'Uw reparatieverzoek is ontvangen.');
      })
      .catch((reason) => {
        this.errorService.alertAndLog(reason);
      })
      .finally(() => {
        this.processing = false;
      });
  }

  doAdjustmentRequest(result: any, misaOrderId: string, productId: string) {
    const remarks = JSON.stringify(result.categoryForm);

    this.orderService
      .requestItemAdjustment(this.user.countyId, misaOrderId, productId, remarks)
      .then(() => {
        this.dialogService.openInfoAlert('Aanpassingsverzoek indienen', 'Uw aanpassingsverzoek is ontvangen.');
      })
      .catch((reason) => {
        this.errorService.alertAndLog(reason);
      })
      .finally(() => {
        this.processing = false;
      });
  }

  doCollectionRequest(result: any, misaOrderId: string) {
    const endDate = result.categoryForm.controls.collectionDate.value.toDate();
    const remarks = result.categoryForm.controls.remarks.value ? result.categoryForm.controls.remarks.value : null;
    const endReason = result.categoryForm.controls.endReason.value ? result.categoryForm.controls.endReason.value : null;

    this.orderService
      .endOrder(misaOrderId, endDate, remarks, endReason)
      .then(() => {
        this.dialogService.openInfoAlert('Ophaalverzoek indienen', 'Uw ophaalverzoek is ontvangen.');
      })
      .catch((reason) => {
        let message: string;
        message = msgLog.mapErrorTypeToContent(reason.message, 'Het goedkeuren van de offerte is mislukt.');
        this.errorService.alertAndLog(reason, message);
      })
      .finally(() => {
        this.processing = false;
      });
  }
}
