<h2 *ngIf="tableTitle" (click)="expandToggle === true ? toggleExpanded() : void" [class.expandable]="expandToggle === true">
  {{tableTitle}}
  <span class="alert-counter" *ngIf="(!clientsLoading || loadInBackground) && numTotalOrders > 0">{{numTotalOrders}}</span>
  <mat-icon *ngIf="expandToggle === true" [@rotateIcon]="expanded ? 'rotated' : 'default'">expand_more</mat-icon>
</h2>

<ng-container *ngIf="expanded">
  <div [ngClass]="{'hidden': tableDataSource?.data.length == 0}">
    <table class="clients-orders-table" mat-table [dataSource]="tableDataSource" multiTemplateDataRows>

      <tr class="client-row" mat-row *matRowDef="let item; columns: tableColumns;" (click)="item.expanded = !item.expanded; item.orderLoadingTriggered = true" [ngClass]="{'expanded': item.expanded}"></tr>
      <tr class="details-row" [class.hidden]="!item.expanded" mat-row *matRowDef="let item; columns: ['expandedDetails']"></tr>

      <ng-container matColumnDef="formattedName">
        <td mat-cell *matCellDef="let item">
          {{item.formattedName}}
          <span *ngIf="item.misaOrderIds && item.misaOrderIds.length > 0">({{item.misaOrderIds.length}})</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="info">
        <td mat-cell *matCellDef="let item">
          <ng-container *ngIf="orderState !== 'countyClients'">{{item.formattedAddress}}</ng-container>
          <ng-container *ngIf="orderState === 'countyClients'">{{item.formattedBirthDate}}</ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="iconExpand">
        <td mat-cell *matCellDef="let item">
          <mat-icon [@rotateIcon]="item.expanded ? 'rotated' : 'default'">expand_more</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="expandedDetails">
        <td colspan="3" mat-cell *matCellDef="let item;">
          <div class="client-details">

            <div class="client-data">
              <span *ngIf="item.ageInYears">{{item.ageInYears}} jaar ({{item.formattedBirthDate}})</span>
              <span class="client-info text-warning" *ngIf="item.archived">Inactief</span>
              <span class="client-info" *ngIf="!item.archived">Actief</span>
              <span class="client-info" *ngIf="item.emailAddress">{{item.emailAddress}}</span>
              <span class="client-info" *ngIf="item.phoneNumber">{{item.phoneNumber}} (vast)</span>
              <span class="client-info" *ngIf="item.mobileNumber">{{item.mobileNumber}} (mobiel)</span>
              <span class="client-info text-warning" *ngIf="item.deceased">Overleden</span>
              <span class="client-info" *ngIf="!item.hasContactPerson">Geen contactpersoon</span>
            </div>

            <div class="contact-data">
              <span class="contact-person" *ngIf="item.hasContactPerson">
                <span class="title">
                  Contactpersoon<span *ngIf="item.contactPersonType"> ({{item.contactPersonType}})</span>:
              </span>
                <span *ngIf="item.contactPerson.initials"> {{item.contactPerson.initials}}</span>
                <span *ngIf="item.contactPerson.firstName"> {{item.contactPerson.firstName}}</span>
                <span *ngIf="item.contactPerson.prefix"> {{item.contactPerson.prefix}}</span>
                <span *ngIf="item.contactPerson.lastName"> {{item.contactPerson.lastName}}</span>
                <span *ngIf="item.contactPerson.emailAddress || item.contactPerson.phoneNumber"> (</span>
                <span *ngIf="item.contactPerson.emailAddress">{{item.contactPerson.emailAddress}}</span>
                <span *ngIf="item.contactPerson.emailAddress && item.contactPerson.phoneNumber"> &bull; </span>
                <span *ngIf="item.contactPerson.phoneNumber">{{item.contactPerson.phoneNumber}}</span>
                <span *ngIf="item.contactPerson.emailAddress || item.contactPerson.phoneNumber">)</span>
              </span>
            </div>

            <div class="client-buttons" id="client-buttons-{{item.misaClientId}}"
            *ngIf="(user && user.isCountyOrderEditor && county && !county.iWMO && item.misaClientId)
            ||
            (user && user.isCountyOrderEditor && orderState === 'countyClients' && item.emailAddress && item.misaClientId)">

              <button *ngIf="orderState === 'countyClients' && item.emailAddress && item.misaClientId && !item.archived && !county.iWMO && county.clientViewAccess"
              (click)="deactivateClient(item.misaClientId)" class="remove-cta small-cta archive-button">
              Cliënt toegang blokkeren
              </button>

              <button *ngIf="orderState === 'countyClients' && item.emailAddress && item.misaClientId && item.archived && !county.iWMO && county.clientViewAccess"
              (click)="activateClient(item.misaClientId)" class="small-cta unarchive-button">
              Cliënt toegang geven
              </button>
              <a class="small-cta new-order-button" routerLink="/order" [queryParams]="{clientId: item.misaClientId}"
              *ngIf="county && !county.iWMO && item.misaClientId">
                + Nieuwe aanvraag
              </a>

            </div>

            <ng-container *ngIf="item.expanded || item.orderLoadingTriggered">
              <div class="order" *ngFor="let misaOrderId of item.misaOrderIds">
                <app-order [misaOrderId]="misaOrderId"></app-order>
              </div>
            </ng-container>

          </div>
        </td>
      </ng-container>

    </table>
  </div>

  <div *ngIf="!clientsLoading && (!tableDataSource || tableDataSource?.data.length == 0)">
    <p class="no-orders-text" *ngIf="!clientsLoadingFailed">{{noOrdersMessage}}</p>
    <p class="orders-error-text" *ngIf="clientsLoadingFailed">{{errorMessage}}</p>
  </div>

  <div class="spinner-container" *ngIf="clientsLoading && !loadInBackground">
    <app-spinner class="absolute horizontal"></app-spinner>
  </div>
</ng-container>

<mat-paginator [class.hidden]="!expanded || tableDataSource?.data.length == 0" [pageSize]="20" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>

<app-spinner *ngIf="showSpinner"></app-spinner>