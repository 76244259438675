import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-client-faq-page',
  templateUrl: './client-faq-page.component.html',
  styleUrls: ['./client-faq-page.component.scss']
})
export class ClientFaqPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  expand1:boolean = true;
  expand2:boolean = true;
  expand3:boolean = true;

  visible1:boolean = false;
  visible2:boolean = false;
  visible3:boolean = false;

  onclick(row)
  {
    switch(row)
    {
      case 1:
        this.expand1 = !this.expand1; //not equal to
        this.visible1 = !this.visible1; //not equal to
        break;

      case 2:
        this.expand2 = !this.expand2; //not equal to
        this.visible2 = !this.visible2; //not equal to
        break;

      case 3:
        this.expand3 = !this.expand3; //not equal to
        this.visible3 = !this.visible3; //not equal to
        break;
    }
  }
}
