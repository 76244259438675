import { Config } from '@app/shared/models/config';
import { CloudLogger, CloudLoggingService } from '@app/shared/services/cloud-logging.service';
import { ConfigService } from '@app/shared/services/config.service';
import { NotificationService } from '@app/shared/services/notification.service';
import * as devLog from '@app/shared/util/dev-log';
import * as util from '@app/shared/util';

import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import firebase from 'firebase/compat/app';
import { AngularFireAuth } from '@angular/fire/compat/auth';

export class AuthUser {
  userId?: string;
  email?: string;
  emailVerified?: boolean;
  hasMultiFactorAuth?: boolean;

  constructor(userId?: string, email?: string, enrolledFactors?: any, emailVerified?: boolean) {
    this.userId = userId;
    this.email = email;
    this.emailVerified = emailVerified;
    if (enrolledFactors && enrolledFactors.length === 1 && enrolledFactors[0].displayName === 'Phone number for 2FA') {
      this.hasMultiFactorAuth = true;
    } else {
      this.hasMultiFactorAuth = false;
    }
  }
}

@Injectable({ providedIn: 'root' })
export class AuthService {
  user: AuthUser = new AuthUser();
  user$: Observable<AuthUser | null>;

  private cloudLog: CloudLogger;
  private authUser: firebase.User;

  constructor(
    private afAuth: AngularFireAuth,
    private cloudLoggingService: CloudLoggingService,
    private configService: ConfigService,
    private notificationService: NotificationService
  ) {
    this.cloudLog = this.cloudLoggingService.createLogger('auth.service');

    const auth$ = new Observable((subscriber) => {
      this.afAuth.authState.subscribe({
        next: async (user: firebase.User) => {
          this.authUser = user;

          if (user) {
            let email: string = user.email;
            if (email === null) {
              email = user.providerData[0].email;
            }
            this.user = new AuthUser(user.uid, email, user.multiFactor.enrolledFactors, user.emailVerified);
            subscriber.next(this.user);

            devLog.info('Decoded auth token', this.decodeIdToken(await this.authUser.getIdToken()));
          } else {
            this.user = new AuthUser();
            subscriber.next(null);
          }
        }
      });
    });

    const authSubject = new ReplaySubject<AuthUser>(1);
    auth$.subscribe(authSubject);

    this.user$ = authSubject.asObservable();

    // this.configService.config$.subscribe({
    //   next: (config: Config) => {
    //     if (config.siteIsUnderMaintenance && this.user.userId) {
    //       this.signOut();
    //     }
    //   }
    // });
  }

  async getUser(): Promise<AuthUser | null> {
    return util.takeOneAsPromise(this.user$);
  }

  async signInWithEmailPassword(email: string, password: string): Promise<void> {
    if (this.user.userId) {
      throw new Error('Already signed in.');
    }

    await this.afAuth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
    await this.afAuth.signInWithEmailAndPassword(email, password);
    this.notificationService.openSnackBar('Ingelogd');

    this.cloudLog.info(`Signed in.`);
  }

  async signInWithGoogle(): Promise<string> {
    if (this.user.userId) {
      throw new Error('Already signed in.');
    }

    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('email');

    await this.afAuth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
    const userCredential = await this.afAuth.signInWithPopup(provider);
    this.notificationService.openSnackBar('Ingelogd');

    this.cloudLog.info(`Signed in with a Google account.`);

    return userCredential.additionalUserInfo.profile['email'];
  }

  async signInWithOkta(): Promise<string> {
    if (this.user.userId) {
      throw new Error('Already signed in.');
    }

    const provider = new firebase.auth.OAuthProvider('oidc.okta');
    provider.addScope('email');

    await this.afAuth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
    const userCredential = await this.afAuth.signInWithPopup(provider);
    this.notificationService.openSnackBar('Ingelogd');

    this.cloudLog.info(`Signed in with a Okta account.`);

    return userCredential.additionalUserInfo.profile['email'];
  }

  async signInWithMicrosoft(): Promise<string> {
    if (this.user.userId) {
      throw new Error('Already signed in.');
    }

    const provider = new firebase.auth.OAuthProvider('microsoft.com');
    provider.addScope('email');
    provider.addScope('profile');
    provider.addScope('openid');
    provider.setCustomParameters({
      tenant: 'medux.nl' // This is specific for employees
    });

    await this.afAuth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
    const userCredential = await this.afAuth.signInWithPopup(provider);
    this.notificationService.openSnackBar('Ingelogd');

    this.cloudLog.info(`Signed in with a Microsoft account.`);

    return userCredential.additionalUserInfo.profile['mail'];
  }

  async signOut(): Promise<void> {
    if (!this.user.userId) {
      throw new Error('Not signed in.');
    }

    const userId = this.user.userId;

    await this.afAuth.signOut();
    this.notificationService.openSnackBar('Uitgelogd');

    this.cloudLog.info(`Signed out.`, { userId });
    this.user = new AuthUser();
  }

  async refreshToken(): Promise<void> {
    if (this.authUser) {
      const forceRefresh = true;
      const idToken = await this.authUser.getIdToken(forceRefresh);

      this.cloudLog.info(`Token refreshed.`);
      devLog.info('Decoded auth token', this.decodeIdToken(idToken));
    }
  }

  private decodeIdToken(idToken: string): any {
    return JSON.parse(util.base64DecodeUnicode(idToken.split('.')[1]));
  }

  async sendPasswordResetEmail(forgotPasswordEmail: string) {
    await this.afAuth.sendPasswordResetEmail(forgotPasswordEmail);
  }
}
