import {DepotItemSummary} from '@app/modules/depot/models/depot-item';
import {DepotService} from '@app/modules/depot/services/depot.service';
import {ErrorService} from '@app/shared/services/error.service';
import {TitleService} from '@app/shared/services/title.service';
import {UserService} from '@app/shared/services/user.service';

import {animate, state, style, transition, trigger} from '@angular/animations';
import {Component, ViewChild, OnInit} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {Router} from '@angular/router';


@Component({
  selector: 'app-depot-page',
  templateUrl: './depot-page.component.html',
  styleUrls: ['./depot-page.component.scss'],
  animations: [
    trigger('expandedInfo', [
      state('collapsed', style({ height: '0px' })),
      state('expanded', style({ height: '*' })),
    ]),
    trigger('rotateIcon', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(-180deg)' })),
      transition('rotated => default', animate('225ms ease-out')),
      transition('default => rotated', animate('225ms ease-in'))
    ]),
  ]
})
export class DepotPageComponent implements OnInit {

  @ViewChild(MatPaginator, {static: true})
  tablePaginator: MatPaginator;

  depotTable: MatTableDataSource<DepotItemSummary>;

  depotTableColumns = [
    'id',
    'name',
    'purchaseDate',
    'status',
    'expand'
  ];

  showDepotSpinner = true;

  private countyId: string;

  constructor(
    private depotService: DepotService,
    private errorService: ErrorService,
    private router: Router,
    private titleService: TitleService,
    private userService: UserService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.titleService.setTitle('Depot');
    this.countyId = (await this.userService.getUser()).countyId;

    await this.load();
  }

  async load(): Promise<void> {
    this.showDepotSpinner = true;

    let depotItems;
    try {
      depotItems = await this.depotService.getDepotItems(this.countyId);
      depotItems.sort((lhs, rhs) => {
        return lhs.name.localeCompare(rhs.name);
      });

    } catch (error) {
      this.errorService.alertAndLog(error).onClosed(async () => {
        this.router.navigate(['/']);
      });
    }

    this.depotTable = new MatTableDataSource(depotItems);
    this.depotTable.paginator = this.tablePaginator;
    this.showDepotSpinner = false;
  }

  filterTable(filterValue: string) {
    this.depotTable.filter = filterValue.trim().toLowerCase();
  }

  translateItemStatus(status: string) {
    return {
      'IN_WORKSHOP': 'In werkplaats',
      'AVAILABLE': 'Beschikbaar',
      'RETURNED': 'Geretourneerd',
      'IN_CLEANING': 'In wasserij',
      'RESERVED': 'Gereserveerd',
      'TO_BE_INSPECTED': 'In afwachting van inspectie',
      'TO_BE_EVALUATED': 'In afwachting van keuring / schouwing',
      'BUYBACK': 'Teruggekocht',
      'REMOVED': 'Verwijderd',
      'IN_USE': 'In gebruik',
      'TO_BE_REPAIRED': 'In afwachting van reparatie',
      'TO_BE_ADJUSTED': 'In afwachting van aanpassing',
    }[status] || status;
  }
}
