// Third party libraries
import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UntypedFormGroup, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import firebase from 'firebase/compat/app';

// Custom libraries
import { CloudLogger, CloudLoggingService } from '@app/shared/services/cloud-logging.service';
import { AuthService } from '@app/shared/services/auth.service';
import { DialogService } from '@app/shared/services/dialog.service';
import * as util from '@app/shared/util';
import { contractImagesBucket } from '@app/environment';

@Component({
  selector: 'app-category-dialog',
  templateUrl: './category-dialog.component.html',
  styleUrls: ['./category-dialog.component.scss']
})
export class CategoryDialogComponent {

  contractId: string;
  mode = 'add';
  showSpinner = false;

  categoryForm = new UntypedFormGroup({
    type: new UntypedFormControl(null, [Validators.required]),
    alladinId: new UntypedFormControl(null, [Validators.required, Validators.maxLength(10)]),
    name: new UntypedFormControl(null, [Validators.required]),
    description: new UntypedFormControl(null, [Validators.required]),
    image: new UntypedFormGroup({
      name: new UntypedFormControl(null, [Validators.required]),
      storageFilePath: new UntypedFormControl(null, [Validators.required])
    }),
    categories: new UntypedFormArray([])
  });

  private cloudLog: CloudLogger;

  private maxFileSize = 2 * 1024 * 1024; // 2MiB

  private allowedExtensions = ['PNG', 'JPG', 'JPEG', 'PNG', 'BMP'];
  private formattedAllowedExtensions = this.allowedExtensions.toString().replace(new RegExp(',', 'g'), ', ');

  constructor(
    private authService: AuthService,
    public dialogRef: MatDialogRef<CategoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public cloudLoggingService: CloudLoggingService,
    private dialogService: DialogService
  ) {
    this.cloudLog = this.cloudLoggingService.createLogger('category-dialog.component');
    this.contractId = data.contractId;
    this.mode = data.mode;
    this.categoryForm.patchValue(data.category.value);
  }

  submitForm(action) {

    if (this.categoryForm.valid || action === 'cancel' || action === 'remove') {
      const result = {
        action,
        categoryForm: this.categoryForm
      };
      this.dialogRef.close(result);
    }

    else {
      util.markFormGroupTouched(this.categoryForm);
    }

  }

  async addFile(event) {
    this.showSpinner = true;
    const file = event.target.files[0];
    try {
      this.checkFileExtension(file);
      this.checkFileSize(file);

      const storageFilePath = await this.uploadFile(file);
      this.setImage(file, storageFilePath);
    }

    catch (error) {
      if (error.message === 'invalidFileExtension') {

        let fileExtension: string = file.name.split(`.`);
        fileExtension = fileExtension[fileExtension.length - 1].toUpperCase();

        this.dialogService.openErrorAlert('Foutmelding',
          `Het is niet toegestaan om bestanden met het type ${fileExtension} toe te voegen.
        Voeg een bestand toe met het volgende type ${this.formattedAllowedExtensions}`);
      }
      else if (error.message === 'exceededFileSize') {
        this.dialogService.openErrorAlert('Foutmelding', `Afbeelding is groter dan ${this.maxFileSize / 1048576} MB.`);
      }
    }

    finally {
      this.showSpinner = false;
    }
  }

  checkFileExtension(file) {
    const fileNameArray = file.name.split('.');
    const fileExtension = fileNameArray[fileNameArray.length - 1].toUpperCase();

    if (this.allowedExtensions.indexOf(fileExtension) < 0) {
      throw new Error('invalidFileExtension');
    }
    return;
  }

  checkFileSize(file) {
    if (file.size > this.maxFileSize) {
      throw new Error('exceededFileSize');
    }
    return;
  }

  async uploadFile(file): Promise<string> {
    return new Promise((resolve, reject) => {
      const index = file.name.lastIndexOf('.');
      const fileName = file.name.substring(0, index);
      const fileExtension = file.name.substring(index);
      const uploadTime = new Date().getTime();
      const storagePath = `${this.contractId}/${fileName}-${uploadTime}${fileExtension}`;

      const storageRef = firebase.app().storage(contractImagesBucket).ref();
      const uploadTask = storageRef.child(storagePath).put(file);

      uploadTask.on('state_changed', (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
      },
        (error) => {
          this.cloudLog.error(`Error occured when trying to upload image for contract categories.`);
          reject(error);
        },
        () => {
          resolve(storagePath);
        });
    });
  }

  setImage(file, storageFilePath) {
    this.categoryForm.patchValue({
      image: {
        name: file.name,
        storageFilePath
      }
    });
  }

}
