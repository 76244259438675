<footer>
  <div class="footer-container">

    <ng-container>
      <p >

        

      </p>
    </ng-container>

    <ng-container *ngIf="configService.config$ | async as config">
      <ul id="footer-links" *ngIf="config.footerLinks">
        
        <li *ngIf="config.footerLinks.termsAndConditions">
          <a id="footer-link-terms-and-conditions" href="{{config.footerLinks.termsAndConditions}}"
            target="_blank">Algemene voorwaarden</a>
        </li>
        <li *ngIf="config.footerLinks.privacyPolicy">
          <a id="footer-link-privacy-policy" href="{{config.footerLinks.privacyPolicy}}"
            target="_blank">Privacybeleid</a>
        </li>
      </ul>
    </ng-container>

  </div>
</footer>
