<div class="container">
  <div class="top-toolbar-container">
    <mat-icon aria-hidden="false" aria-label="Close icon" (click)="closeDialog()">close</mat-icon>
  </div>
  <div class="child"> 
      <div style="margin-left:-65px">
        <mat-icon class="warning">error</mat-icon>
      </div>
  </div>
  <div class="title child">Let op</div>
  <div class="subtitle child">
    Wilt u een spoedreparatie indienen, die niet kan wachten tot de volgende werkdag? <br />
    Bel dan met onze storingsdienst zo dat wij u meteen van dienst kunnen zijn: <br />
    
    <div class="phone" [innerHtml]="getContactDetails()"></div>
  </div>
  <div class="subtitle child">
    <button class="small-cta" (click)="createRequest()">Doorgaan</button>
  </div>
</div>
