import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DialogType } from '@app/shared/models/dialog-type';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent {

  title: string;
  body: string;
  messageList: string;
  isError: boolean;
  isConfirmation: boolean;
  isConfirmationRemove: boolean;
  closeDialogButtonText: string;

  constructor(
    private dialogRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.title = data.title || '';
    this.body = data.body || '';
    this.messageList = data.messageList || [];
    this.closeDialogButtonText = data.closeDialogButtonText || 'Sluiten';

    this.isError = data.dialogType === DialogType.ERROR ? true : false;
    this.isConfirmation = data.dialogType === DialogType.CONFIRMATION ? true : false;
    this.isConfirmationRemove = data.dialogType === DialogType.CONFIRMATION_REMOVE ? true : false;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  closeDialogWithResponse(response: boolean) {
    this.dialogRef.close(response);
  }
}



