import { AuthService } from '@app/shared/services/auth.service';
import { UserService, SignedInUser } from '@app/shared/services/user.service';
import { CountyService } from '@app/modules/county/services/county.service';
import { ContractService } from '@app/modules/contract/services/contract.service';
import { ErrorService } from '@app/shared/services/error.service';
import { ConfigService } from '@app/shared/services/config.service';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { County } from '@app/modules/county/models/county';

import * as environment from '@app/environment';
import { NewsService } from '@app/modules/about/services/news.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ModalOnboardFormComponent } from '@app/shared/components/modal-onboard-form/modal-onboard-form.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnDestroy, OnInit {
  currentUrl: string;

  isClient = false;
  isCountyDepotViewer = false;
  isCountyOrderViewer = false;
  isCountyOrderEditor = false;
  isCountyViewer = false;
  isCountyAdmin = false;
  isAdmin = false;
  isSuperAdmin = false;
  countyId?: string;
  userId?: string;
  environmentName?: string;
  hasMultiFactorAuth?: boolean;
  emailVerified?: boolean;

  private routerSubscription: Subscription;
  private userSubscription: Subscription;
  countySubciprion: Subscription;
  countyOfSignedInUser: County;
  hasNewNewsItem: boolean;

  constructor(
    public authService: AuthService,
    private userService: UserService,
    private countyService: CountyService,
    private contractService: ContractService,
    private errorService: ErrorService,
    private newsService: NewsService,
    private router: Router,
    public configService: ConfigService,
    public dialog: MatDialog
  ) {
    this.routerSubscription = router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
      }
    });

    this.userSubscription = this.userService.user$.subscribe((user?: SignedInUser) => {
      this.isClient = (user && user.isClient) || false;
      this.isCountyDepotViewer = (user && user.isCountyDepotViewer) || false;
      this.isCountyOrderViewer = (user && user.isCountyOrderViewer) || false;
      this.isCountyOrderEditor = (user && user.isCountyOrderEditor) || false;
      this.isCountyAdmin = (user && user.isCountyAdmin) || false;
      this.isCountyViewer = user && user.isCountyViewer || false;
      this.isAdmin = (user && user.isAdmin) || false;
      this.isSuperAdmin = (user && user.isSuperAdmin) || false;
      this.countyId = user && user.countyId;
      this.userId = user && user.userId;
      this.hasMultiFactorAuth = user && user.hasMultiFactorAuth;
      this.emailVerified = user && user.emailVerified;
      if (this.isClient){
        this.checkOnboarding();
      }
    });

    this.countySubciprion = this.countyService.countyOfSignedInUser$.subscribe((county?: County) => {
      this.countyOfSignedInUser = county;
      if (county) {
        this.checkForEmployeeMultiFactorAuth();
      }
    });
  }

  checkOnboarding(){
    const isOnboardingShownBefore: boolean = localStorage.getItem('onboarding-shown') === '1';
    console.log(isOnboardingShownBefore);
    if (!isOnboardingShownBefore){
      this.showOnboarding();
      localStorage.setItem('onboarding-shown', '1');
    }
  }

  async ngOnInit(): Promise<void> {
    this.environmentName = environment.environment.name;
    this.hasNewNewsItem = await this.newsService.hasNewNewsItem();
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
    this.countySubciprion.unsubscribe();
  }

  async checkForEmployeeMultiFactorAuth() {
    if (
      (this.isCountyAdmin || this.isCountyDepotViewer || this.isCountyOrderEditor || this.isCountyOrderViewer) &&
      !this.hasMultiFactorAuth
    ) {
      const contract = await this.contractService.getContract(this.countyOfSignedInUser.contractId);
      if (contract.multiFactorAuth === true) {
        this.errorService.alertAndLog(
          'Employee has no mobile phone number and contract requires MFA',
          'Een mobiel telefoonnummer is vereist om in te loggen; aan uw account is geen mobiel nummer gekoppeld. Neem contact op met uw beheerder.'
        );
        this.router.navigate(['sign-in']);
        this.authService.signOut();
      }
    }
  }

  showOnboarding(): void {
    const dialogRef = this.dialog.open(ModalOnboardFormComponent, {
      width: '30%',
      panelClass: 'remove-moval-padding',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
