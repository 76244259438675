import { OrderService } from '@app/modules/order/services/order.service';
import { UserService, SignedInUser } from '@app/shared/services/user.service';
import { CountyService } from '@app/modules/county/services/county.service';
import { formatAddress } from '@app/shared/models/address';
import { formatName, formatLastName } from '@app/shared/models/name';
import * as util from '@app/shared/util';

import { Component, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { trigger, state, style, transition, animate } from '@angular/animations';
import * as moment from 'moment';

import { County } from '@app/modules/county/models/county';
import { Subscription } from 'rxjs';
import { DepotService } from '@app/modules/depot/services/depot.service';
import { OrderProduct } from '@app/modules/order/models/order-product';

@Component({
  selector: 'app-client-table-with-requests',
  templateUrl: './client-table-with-requests.component.html',
  styleUrls: ['./client-table-with-requests.component.scss'],
  animations: [
    trigger('rotateIcon', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(-180deg)' })),
      transition('rotated => default', animate('225ms ease-out')),
      transition('default => rotated', animate('225ms ease-in'))
    ]),
    trigger('clientInfoRotateIcon', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(-180deg)' })),
      transition('rotated => default', animate('225ms ease-out')),
      transition('default => rotated', animate('225ms ease-in'))
    ])
  ]
})
export class ClientTableWithRequestsComponent implements OnInit, OnDestroy {
  @Input()
  noOrdersMessage: string;

  @Input()
  errorMessage: string;

  @Input()
  prefetchedClientsData: Array<any>;

  @ViewChild(MatPaginator, { static: true })
  private tablePaginator: MatPaginator;

  expanded = true;

  tableDataSource: MatTableDataSource<any>;
  tableColumns = ['formattedName', 'info', 'iconExpand'];
  showSpinner = false;

  currentActiveCountyId: string;
  county: County;
  userSubscription: Subscription;
  user: SignedInUser;
  clientInfoExpanded = false;

  constructor(
    private orderService: OrderService,
    private userService: UserService,
    private countyService: CountyService,
    private depotService: DepotService
  ) { }

  async ngOnInit(): Promise<void> {
    this.userSubscription = this.userService.user$.subscribe({
      next: async (user) => {
      this.user = user;
      if (!this.currentActiveCountyId || (user && this.currentActiveCountyId !== user.countyId)) {
        this.currentActiveCountyId = user.countyId;
        if (this.user && this.user.countyId) {
          this.county = await this.countyService.getCounty(this.user.countyId);
        } else {
          this.county = null;
        }
      }
    }});
    await this.populateTable(this.prefetchedClientsData);
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }

  async expandClient(event: MouseEvent, item: any) {
    item.expanded = !item.expanded;
    item.ordersLoading = true;

    const results = await Promise.all(item.misaOrderIds.map(async (misaOrderId) => this.loadOrder(misaOrderId)));
    item.deliveredOrders = results.filter((x) => x !== null);
    item.ordersLoading = false;
  }


  async loadOrder(misaOrderId: string): Promise<any> {
    const order = await this.orderService.getOrder(misaOrderId, true, true);
    if (!order || !order.products) {
      return null;
    }
    const depotItems = await Promise.all(order.products.map(async (product) => this.loadDepotItemWithProduct(order.dateDelivered, product)));
    const isUserAllowed: boolean =
      order.substate === 'DELIVERED' && (this.userService.canRequestPickup(this.user, this.county) || this.userService.canRequestRepair(this.user, this.county) || this.userService.canRequestAdjustment(this.user, this.county));

    return {
      ...order,
      id: misaOrderId,
      products: depotItems,
      isRequestEnabled: isUserAllowed,
      documents: order.documents,
    };
  }

  async loadDepotItemWithProduct(dateDelivered: Date | undefined, product: OrderProduct): Promise<any> {
    const depotItem = await this.depotService.getDepotItem(this.user.countyId, product.id, true);
    const nameSplitted = depotItem.name.split(' - ');
    let formattedDeliveryDate = '';
    if (dateDelivered) {
      formattedDeliveryDate = moment(dateDelivered).format('DD-MM-YYYY');
    }
    const filteredMaintenanceHistory = depotItem.maintenanceHistory.filter((x) => (x.action === 'REPAIR' || x.action === 'ADJUST') && (x.rawstatus !== 'AWAITING_APPROVAL_DELIVERY' && x.rawstatus !== 'DELIVERED' && x.rawstatus !== 'COMPLETED'));
    const filteredDeployHistory = depotItem.deployHistory.filter((x) => x.action === 'PICKUP_ORDER' && x.rawstatus !== 'COMPLETED');

    return {
      ...product,
      filteredMaintenanceHistory,
      filteredDeployHistory,
      depotItem,
      formattedDeliveryDate,
      name: nameSplitted[0],
      category: nameSplitted[1]
    };
  }

  populateTable(clients: any[]): void {
    const dataSource = clients
      .map((clientAndOrders) => {
        const client = util.tryGet(clientAndOrders, 'client');

        return {
          ageInYears: client.birthDate ? moment.utc().diff(moment(client.birthDate), 'years') : null,
          archived: client.archived,
          contactPerson: client.contactPerson,
          contactPersonType:
            client.contactPerson && client.contactPerson.type ? this.translateContactPersonType(client.contactPerson.type) : null,
          expanded: false,
          formattedName: client.name ? formatName(client.name) : '',
          formattedLastName: client.name ? formatLastName(client.name) : '',
          formattedBirthDate: client.birthDate ? moment(client.birthDate).format('DD-MM-YYYY') : '',
          formattedAddress: client.address ? formatAddress(client.address) : '',
          hasContactPerson: Object.keys(client.contactPerson).length > 0,
          misaOrderIds: clientAndOrders.misaOrderIds || [],
          numContactPersons: 1,
          misaClientId: client.misaClientId,
          userId: client.userId,
          emailAddress: client.contactDetails && client.contactDetails.emailAddress ? client.contactDetails.emailAddress : null,
          deceased: !!client.deceased,
          phoneNumber: client.contactDetails && client.contactDetails.phoneNumber,
          mobileNumber: client.contactDetails && client.contactDetails.mobileNumber,
          deliveredOrders: [],
          ordersLoading: true
        };
      })
      .sort((lhs, rhs) => {
        return lhs.formattedLastName.localeCompare(rhs.formattedLastName);
      });

    this.tableDataSource = new MatTableDataSource(dataSource);
    this.tableDataSource.paginator = this.tablePaginator;

    this.tableDataSource.filterPredicate = (data: any, filterValue: string) => {
      const filterValueObject = JSON.parse(filterValue);
      let filteredData;
      if (!filterValueObject.partialLastName && !filterValueObject.birthDate) {
        filteredData = data;
      } else if (filterValueObject.partialLastName && !filterValueObject.birthDate) {
        filteredData = data.formattedLastName.toLowerCase().includes(filterValueObject.partialLastName.toLowerCase());
      } else if (!filterValueObject.partialLastName && filterValueObject.birthDate) {
        filteredData = data.formattedBirthDate.includes(filterValueObject.birthDate);
      } else {
        filteredData =
          data.formattedLastName.toLowerCase().includes(filterValueObject.partialLastName.toLowerCase()) &&
          data.formattedBirthDate.includes(filterValueObject.birthDate);
      }
      return filteredData;
    };
  }

  translateContactPersonType(type: string): string | null {
    switch (type) {
      case 'PARTNER':
        return 'partner';
      case 'CHILD':
        return 'kind';
      case 'PARENT':
        return 'ouder';
      case 'ADMINISTRATOR':
        return 'beheerder';
      case 'FAMILY_MEMBER':
        return 'familielid';
      case 'RELATIVE':
        return 'betrekkelijk';
      case 'GUARDIAN':
        return 'voogd';
      case 'CAREGIVER':
        return 'verzorger';
      case 'CLIENT_SUPPORTER':
        return 'klant / ondersteuning';
      case 'FRIEND_ACQUAINTANCE':
        return 'vriend / kennis';
      case 'OTHER':
        return 'anders';
      case 'FINANCIAL_AUTHORIZED':
        return 'financieel geautoriseerd';
      case 'FOSTER_PARENT':
        return 'pleegouder';
      case 'HEIR':
        return 'erfgenaam';
      default:
        return null;
    }
  }
}
