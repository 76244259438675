<ng-container *ngIf="order && !loadingOrder">
  <div class="order-columns">

    <div class="column expandable image-column" (click)="toggleExpanded('product')">
      <div class="header">
        Afbeelding
      </div>
      <div class="content">
        <ng-container *ngTemplateOutlet="productImage; context: {order: order, classes: ''}"></ng-container>
      </div>
    </div>

    <div class="column order-column expandable" (click)="toggleExpanded('product')">
      <div class="header">
        <span>Aanvraag</span>
        <mat-icon [@rotateIcon]="isExpanded('product') ? 'rotated' : 'default'">expand_more</mat-icon>
      </div>
      <div class="content">
        <ng-container *ngTemplateOutlet="productImage; context: {order: order, classes: 'tablet-small-visible'}">
        </ng-container>
        <ul>
          <li>
            {{product.productId}}{{product.name ? ' - ' + product.name: ''}}
          </li>
          <li *ngIf="hasSubstate('PLANNING_FITTING_DATE')">
            De aanvraag wordt behandeld
          </li>
          <li *ngIf="hasSubstate('FITTING_DATE_PLANNED')">
            Er is een pasafspraak gepland
          </li>
          <li *ngIf="hasSubstate('PROCESSING_ADVICE', 'QUOTE_BEING_MADE')">
            In behandeling adviseur
          </li>
          <li *ngIf="order.orderCreationDate" class="order-creation-date">
            Aangevraagd op: <span class="nowrap">{{order.orderCreationDate | date:'dd-MM-yyyy'}}</span>
          </li>
          <li *ngIf="order.fittingDate" class="fitting-date">
            Pasdatum: <span class="nowrap">{{order.fittingDate | date:'dd-MM-yyyy'}}</span>
          </li>
        </ul>
      </div>
      <div class="arrow" *ngIf="isExpanded('product')">
        <div></div>
      </div>
    </div>

    <ng-container
      *ngTemplateOutlet="productExpanded; context: {order: order, classes: 'expanded tablet-small-visible'}">
    </ng-container>

    <div class="column judge-column" [ngClass]="{red: redJudgeColumn, green: greenJudgeColumn}">
      <div class="header">
        <span>Akkoord gemeente</span>
      </div>
      <div class="content">
        <ul>
          <li class="no-data" aria-hidden="true"></li>
          <li *ngIf="hasSubstate('QUOTE_WAITING_FOR_APPROVAL')">
            Wacht op akkoord offerte
          </li>
          <li *ngIf="hasSubstate('AWAITING_APPROVAL_DELIVERY')">
            Geleverd, maar wacht op akkoord
          </li>
          <li *ngIf="hasSubstate('QUOTE_APPROVED')">
            Offerte geaccordeerd
          </li>
          <li *ngIf="order.approvalDate" class="approval-date">
            Akkoord op: <span class="nowrap">{{order.approvalDate | date:'dd-MM-yyyy'}}</span>
          </li>
        </ul>
      </div>
    </div>

    <div class="column workplace-column">
      <div class="header">In werkplaats</div>
      <div class="content">
        <ul>
          <li class="no-data" aria-hidden="true"></li>
          <li *ngIf="hasSubstate('ORDER_PLACED', 'ORDER_RECEIVED', 'ORDER_RETURNED')">
            Voorziening of onderdelen in bestelling
          </li>
          <li *ngIf="hasSubstate('PREPARING_SERVICE')">
            Wordt voorbereid
          </li>
          <li *ngIf="hasSubstate('EXECUTING_SERVICE')">
            In behandeling
          </li>
          <li *ngIf="order.serviceDate" class="service-date">
            Gestart op: <span class="nowrap">{{order.serviceDate | date:'dd-MM-yyyy'}}</span>
          </li>
          <li *ngIf="order.serviceDateEnd" class="service-date-end">
            Klaar op: <span class="nowrap">{{order.serviceDateEnd | date:'dd-MM-yyyy'}}</span>
          </li>
        </ul>
      </div>
    </div>

    <div class="column delivery-column">
      <div class="header">Levering</div>
      <div class="content">
        <ul>
          <li class="no-data" aria-hidden="true"></li>
          <li *ngIf="hasSubstate('READY_FOR_DELIVERY', 'DELIVERY_PLANNED')">
            Klaar voor levering
          </li>
          <li *ngIf="order.plannedDeliveryDate" class="planned-delivery-date">
            Geplande leverdatum: <span class="nowrap">{{order.plannedDeliveryDate | date:'dd-MM-yyyy'}}</span>
          </li>
          <li *ngIf="order.dateDelivered" class="date-delivered">
            Geleverd op: <span class="nowrap">{{order.dateDelivered | date:'dd-MM-yyyy'}}</span>
          </li>
        </ul>
      </div>
    </div>

    <div class="column with-client-column" [class.expandable]="hasSubstate('DELIVERED')" [class.green]="hasSubstate('DELIVERED')"
      (click)="hasSubstate('DELIVERED') ? toggleExpanded('withClient') : void">

      <div class="header">
        <span>Bij client</span>
        <mat-icon [@rotateIcon]="isExpanded('withClient') ? 'rotated' : 'default'" *ngIf="hasSubstate('DELIVERED')">expand_more</mat-icon>
      </div>
      <div class="content">
        <ul>
          <li class="no-data" aria-hidden="true"></li>
          <li *ngIf="hasSubstate('ITEM_IN_VIEW')">
            Voorziening op zicht bij cliënt
          </li>
          <li *ngIf="hasSubstate('DELIVERED')">
            Geleverd
          </li>
          <li *ngIf="hasSubstate('RETRIEVED')">
            De voorziening is opgehaald
          </li>
          <li *ngIf="order.dateDelivered" class="weeks-since-delivery">
            In bezit
            <span *ngIf="weeksSinceDelivery == 1">
              sinds {{weeksSinceDelivery}} week
            </span>
            <span *ngIf="weeksSinceDelivery > 1">
              sinds {{weeksSinceDelivery}} weken
            </span>
          </li>
          <li *ngIf="order.plannedRetrievalDate" class="planned-retrieval-date">
            Geplande ophaaldatum: <span class="nowrap">{{order.plannedRetrievalDate | date:'dd-MM-yyyy'}}</span>
          </li>
        </ul>
      </div>
      <div class="arrow" *ngIf="isExpanded('withClient')">
        <div></div>
      </div>
    </div>

    <ng-container
      *ngTemplateOutlet="withClientExpanded; context: {order: order, classes: 'expanded tablet-small-visible'}">
    </ng-container>

  </div>

  <ng-container
    *ngTemplateOutlet="productExpanded; context: {order: order, classes: 'expanded tablet-small-hidden'}">
  </ng-container>

  <ng-container
    *ngTemplateOutlet="withClientExpanded; context: {order: order, classes: 'expanded tablet-small-hidden'}">
  </ng-container>

</ng-container>

<div class="spinner-container" *ngIf="!order && loadingOrder">
  <app-spinner class="absolute horizontal"></app-spinner>
</div>

<!--
[Zendesk ticket] 1198
[Comment] Orders which can not be found or result in null should not be displayed in the UI 
<p class="error-message" *ngIf="!order && !loadingOrder">
  De aanvraag (met ID: <strong>{{misaOrderId}}</strong>) kon niet worden geladen.
</p>
-->

<ng-template #productExpanded let-order="order" let-classes="classes">
  <div [class]="classes" *ngIf="isExpanded('product')">
    <mat-icon class="icon-close" (click)="hideExpanded()">close</mat-icon>

    <h3 class="product-name">{{product.name}}</h3>
    <p class="product-description">{{product.description}}</p>

    <div *ngIf="user.isCountyOrderEditor">
      <button class="remove-cta small-cta" (click)="cancelOrder()" [disabled]="order.cancelable === false">
        Aanvraag annuleren
      </button>
      <span class="error-span" *ngIf="order.cancelable === false">
        Het is niet (meer) mogelijk om deze aanvraag te annuleren.
      </span>
    </div>
    <app-spinner class="absolute small" *ngIf="processing"></app-spinner>

  </div>
</ng-template>

<ng-template #productImage let-order="order" let-classes="classes">
  <div [class]="'image-container ' + classes">
    <div *ngIf="product.imageUrl$ | async">
      <img [src]="(product.imageUrl$ | async)" />
    </div>
    <app-spinner class="absolute horizontal" *ngIf="!(product.imageUrl$ | async)"></app-spinner>
  </div>
</ng-template>

<ng-template #withClientExpanded let-order="order" let-classes="classes">
  <div [class]="classes" *ngIf="isExpanded('withClient')">
    <mat-icon class="icon-close" (click)="hideExpanded()">close</mat-icon>
    <h3>Bij Client</h3>
    <div class="button-container three-buttons">
      <button *ngIf="county.clientRepairRequest" class="small-cta repair-button" (click)="showRepairRequest()">
        Reparatieverzoek indienen
      </button>
      <button *ngIf="county.clientModificationRequest" class="small-cta adjustment-button" (click)="showAdjustmentRequest()">
        Aanpassingsverzoek indienen
      </button>
      <button *ngIf="county.clientPickUpRequest" class="small-cta collection-button" (click)="showCollectionRequest()">
        Ophaalverzoek indienen
      </button>
    </div>
    <app-spinner class="absolute small" *ngIf="processing"></app-spinner>
  </div>
</ng-template>
