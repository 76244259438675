<form [formGroup]="requestForm">
  <div class="general-form-subheader">
    <h2 *ngIf="requestType === 'repair'">
      <div *ngIf="requestType === 'repair' && showInforMessage; else other">
        Kan uw reparatie volgens het reguliere proces opgepakt worden?
      </div>
      <ng-template #other>
        <!--
          Kan uw reparatie volgens het reguliere proces opgepakt worden?
        -->
      </ng-template>

      <div *ngIf="requestType === 'repair' && !showInforMessage; else other">
        Reparatieverzoek indienen
      </div>
    </h2>
    <h2 *ngIf="requestType === 'adjustment'">Aanpassingsverzoek indienen</h2>
    <h2 *ngIf="requestType === 'collection'">Ophaalverzoek indienen</h2>
  </div>
  <div *ngIf="requestType === 'repair' && showInforMessage">
   <!--
  <h3>Omschrijf kort het defect en waarom u een reparatieverzoek in wilt dienen.</h3>
   --> 
  </div>
  <div>
    <div class="row" *ngIf="requestType === 'repair' || requestType === 'adjustment'">
      <div class="input-container" [ngClass]="{ invalid: requestForm.controls.remarks.touched && requestForm.controls.remarks.invalid }">
        <p class="character-count">
          <span *ngIf="requestForm.controls.remarks.value">{{ requestForm.controls.remarks.value.length }}</span>
          <span *ngIf="!requestForm.controls.remarks.value">0</span>
          / 500
        </p>
        <app-error-messages [formControls]="requestForm.controls.remarks"></app-error-messages>
        <textarea maxlength="500" formControlName="remarks" required> </textarea> 
        <label *ngIf="requestType === 'repair' && showInforMessage" style="font-size:1.5em;">
            Omschrijf kort het defect en waarom u een reparatieverzoek in wilt dienen. Wij pakken uw reparatie zo spoedig mogelijk op.
        </label>
        <label *ngIf="requestType === 'repair' && !showInforMessage"  style="font-size:1.5em;">
          Omschrijf kort het defect en waarom u een reparatieverzoek wilt indienen.
        </label>
      
       <label *ngIf="requestType === 'adjustment'">
          Omschrijf kort de gewenste aanpassing en waarom u een aanpassingsverzoek wilt indienen
        </label>
      </div>
    </div>

    <div class="row" *ngIf="requestType === 'adjustment'">
      <div
        class="input-container"
        [ngClass]="{ invalid: requestForm.controls.isConsulent.touched && requestForm.controls.isConsulent.invalid }"
      >
        <app-error-messages [formControls]="requestForm.controls.isConsulent"></app-error-messages>
        <mat-radio-group formControlName="isConsulent" aria-label="Is het gewenst dat u als consulent aanwezig bent bij de afspraak?">
          <mat-radio-button value="Ja">Ja</mat-radio-button>
          <mat-radio-button value="Nee">Nee</mat-radio-button>
        </mat-radio-group>
        <label>Is het gewenst dat u als consulent aanwezig bent bij de afspraak?</label>
      </div>
    </div>

    <div class="row" *ngIf="requestType === 'adjustment' && requestForm.controls.isConsulent.value === 'Ja'">
      <div
        class="input-container"
        [ngClass]="{ invalid: requestForm.controls.consulentName.touched && requestForm.controls.consulentName.invalid }"
      >
        <app-error-messages [formControls]="requestForm.controls.consulentName"></app-error-messages>
        <input id="consulentName" placeholder="Naam consulent" formControlName="consulentName" name="consulentName" />
        <label>Naam consulent</label>
      </div>
    </div>
    <div class="row" *ngIf="requestType === 'adjustment' && requestForm.controls.isConsulent.value === 'Ja'">
      <div
        class="input-container"
        [ngClass]="{ invalid: requestForm.controls.consulentContact.touched && requestForm.controls.consulentContact.invalid }"
      >
        <app-error-messages [formControls]="requestForm.controls.consulentContact"></app-error-messages>
        <input
          id="consulentContact"
          placeholder="Telefoonnummer en/of e-mailadres"
          formControlName="consulentContact"
          name="consulentContact"
        />
        <label>Telefoonnummer en/of e-mailadres</label>
      </div>
    </div>

    <div class="row" *ngIf="requestType === 'adjustment'">
      <div
        class="input-container"
        [ngClass]="{ invalid: requestForm.controls.isSomeoneElse.touched && requestForm.controls.isSomeoneElse.invalid }"
      >
        <app-error-messages [formControls]="requestForm.controls.isSomeoneElse"></app-error-messages>
        <mat-radio-group formControlName="isSomeoneElse" aria-label="Is het gewenst dat er nog iemand anders aanwezig is bij de afspraak?">
          <mat-radio-button value="Ja">Ja</mat-radio-button>
          <mat-radio-button value="Nee">Nee</mat-radio-button>
        </mat-radio-group>
        <label>Is het gewenst dat er nog iemand anders aanwezig is bij de afspraak?</label>
      </div>
    </div>

    <div class="row" *ngIf="requestType === 'adjustment' && requestForm.controls.isSomeoneElse.value === 'Ja'">
      <div
        class="input-container"
        [ngClass]="{ invalid: requestForm.controls.otherPersonName.touched && requestForm.controls.otherPersonName.invalid }"
      >
        <app-error-messages [formControls]="requestForm.controls.otherPersonName"></app-error-messages>
        <input id="otherPersonName" placeholder="Naam andere persoon" formControlName="otherPersonName" name="otherPersonName" />
        <label>Naam andere persoon</label>
      </div>
    </div>
    <div class="row" *ngIf="requestType === 'adjustment' && requestForm.controls.isSomeoneElse.value === 'Ja'">
      <div
        class="input-container"
        [ngClass]="{ invalid: requestForm.controls.otherPersonContact.touched && requestForm.controls.otherPersonContact.invalid }"
      >
        <app-error-messages [formControls]="requestForm.controls.otherPersonContact"></app-error-messages>
        <input
          id="otherPersonContact"
          placeholder="Telefoonnummer en/of e-mailadres"
          formControlName="otherPersonContact"
          name="otherPersonContact"
        />
        <label>Telefoonnummer en/of e-mailadres</label>
      </div>
    </div>
    <div class="row date-row low-row" *ngIf="requestType === 'collection'">
      <div class="date-input-container">
        <div
          class="input-container"
          [ngClass]="{ invalid: requestForm.controls.collectionDate.touched && requestForm.controls.collectionDate.invalid }"
        >
          <app-error-messages [formControls]="requestForm.controls.collectionDate"></app-error-messages>
          <input
            [matDatepicker]="collectionDatePicker"
            [matDatepickerFilter]="collectionDatePickerFilter"
            id="collectionDate"
            placeholder="Ophaaldatum"
            formControlName="collectionDate"
            name="collectionDate"
            required
          />
          <label>Gewenste ophaaldatum</label>
        </div>
        <mat-datepicker-toggle matSuffix [for]="collectionDatePicker"></mat-datepicker-toggle>
        <mat-datepicker startView="month" #collectionDatePicker></mat-datepicker>
      </div>
    </div>

    <div class="row" *ngIf="requestType === 'collection'">
      <div
        class="input-container"
        [ngClass]="{ invalid: requestForm.controls.endReason.touched && requestForm.controls.endReason.invalid }"
      >
        <app-error-messages [formControls]="requestForm.controls.endReason"></app-error-messages>
        <mat-select class="custom-mat-select" formControlName="endReason" required>
          <mat-option *ngFor="let reason of reasonList" [value]="reason.title">
            {{ reason.title }}
          </mat-option>
        </mat-select>
        <label class="control-label" for="reason"
          >Reden voor het ophaalverzoek
          <span class="required-asterisk">*</span>
        </label>
      </div>
    </div>

    <div class="row" *ngIf="requestType === 'collection'">
      <div class="input-container" [ngClass]="{ invalid: requestForm.controls.remarks.touched && requestForm.controls.remarks.invalid }">
        <p class="character-count">
          <span *ngIf="requestForm.controls.remarks.value">{{ requestForm.controls.remarks.value.length }}</span>
          <span *ngIf="!requestForm.controls.remarks.value">0</span>
          / 500
        </p>
        <app-error-messages [formControls]="requestForm.controls.remarks"></app-error-messages>
        <textarea maxlength="500" formControlName="remarks"> </textarea>
        <label>Opmerkingen</label>
      </div>
    </div>
  </div>

  <div class="low-row buttons-row">
    <div class="buttons-container">
      <button (click)="submitForm('cancel')" class="secondary-cta">Annuleren</button>
      <button *ngIf="requestType === 'repair'" (click)="submitForm('add')" class="cta">Reparatieverzoek indienen</button>
      <button *ngIf="requestType === 'adjustment'" (click)="submitForm('add')" class="cta">Aanpassingsverzoek indienen</button>
      <button *ngIf="requestType === 'collection'" (click)="submitForm('add')" class="cta">Ophaalverzoek indienen</button>
    </div>
  </div>
</form>
