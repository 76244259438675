import {CountyEmployee} from '@app/shared/models/county-employee';
import {UserRole} from '@app/shared/models/user-role';
import {CloudLogger, CloudLoggingService} from '@app/shared/services/cloud-logging.service';
import {FirestoreObservableQuery, OrCombinedFirestoreQuery} from '@app/shared/util/firestore-observable-query';
import * as util from '@app/shared/util';

import {Injectable} from '@angular/core';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import {Observable} from 'rxjs';
import { trace } from '@angular/fire/compat/performance';

export type CountyEmployees = {[key: string]: CountyEmployee};


@Injectable({
  providedIn: 'root'
})
export class CountyEmployeeService {

  private cloudLog: CloudLogger;
  private addCountyEmployee$: (data: any) => Observable<any>;
  private updateCountyEmployee$: (data: any) => Observable<any>;
  private removeEmployeeFromCounty$: (data: any) => Observable<any>;
  private existCountyEmployee$: (data: any) => Observable<any>;
  private updateActiveCountyRequest$: (data: any) => Observable<any>;

  constructor(
    cloudLoggingService: CloudLoggingService,
    functions: AngularFireFunctions,
    private firestore: AngularFirestore,
  ) {
    this.cloudLog = cloudLoggingService.createLogger('county-employee.service');
    this.addCountyEmployee$ = functions.httpsCallable('addCountyEmployee');
    this.updateCountyEmployee$ = functions.httpsCallable('updateCountyEmployee');

    this.removeEmployeeFromCounty$ = functions.httpsCallable('deleteCountyEmployee');
    this.existCountyEmployee$ = functions.httpsCallable('existCountyEmployee');
    this.updateActiveCountyRequest$ = functions.httpsCallable('updateActiveCountyRequest');
  }

  async getCountyEmployees(countyId: string): Promise<CountyEmployees> {
    return util.takeOneAsPromise(this.getCountyEmployees$(countyId));
  }

  getCountyEmployees$(countyId: string): Observable<CountyEmployees> {
    return new OrCombinedFirestoreQuery([
      new FirestoreObservableQuery<CountyEmployee>(this.firestore, {
        collection: 'users',
        queryFunction: ref => {
          return ref.where('counties', 'array-contains', countyId).where(`roles.counties.${countyId}.` + UserRole.COUNTY_DEPOT_VIEWER, '==', true);
        }
      }),
      new FirestoreObservableQuery<CountyEmployee>(this.firestore, {
        collection: 'users',
        queryFunction: ref => {
          return ref.where('counties', 'array-contains', countyId).where(`roles.counties.${countyId}.` + UserRole.COUNTY_ORDER_VIEWER, '==', true);
        }
      }),
      new FirestoreObservableQuery<CountyEmployee>(this.firestore, {
        collection: 'users',
        queryFunction: ref => {
          return ref.where('counties', 'array-contains', countyId).where(`roles.counties.${countyId}.` + UserRole.COUNTY_ORDER_EDITOR, '==', true);
        }
      }),
      new FirestoreObservableQuery<CountyEmployee>(this.firestore, {
        collection: 'users',
        queryFunction: ref => {
          return ref.where('counties', 'array-contains', countyId).where(`roles.counties.${countyId}.` + UserRole.COUNTY_ADMIN, '==', true);
        }
      }),
      new FirestoreObservableQuery<CountyEmployee>(this.firestore, {
        collection: 'users',
        queryFunction: ref => {
          return ref.where('counties', 'array-contains', countyId).where(`roles.counties.${countyId}.` + UserRole.COUNTY_VIEWER, '==', true);
        }
      })
    ]).observable$.pipe(trace('CountyEmployeeService.getCountyEmployees$'));
  }

  async createCountyEmployee(user: CountyEmployee, employeeInExistingCounty: boolean): Promise<string> {
    const payload = {countyEmployee: user};
    const userId: string = await this.addCountyEmployee$(payload)
      .pipe(trace('addCountyEmployee'))
      .toPromise() as string;

    if (employeeInExistingCounty) {
      this.cloudLog.info(`Assigning a new county (${user.countyId}) to ${userId}`);
    }
    else {
      this.cloudLog.info(`Sending password reset email to county employee ${userId}`);
      await firebase.auth().sendPasswordResetEmail(user.contactDetails.emailAddress);
      this.cloudLog.info(`Created county employee ${userId}`);
    }

    return userId;
  }

  async updateCountyEmployee(userId: string, user: CountyEmployee): Promise<void> {
    await this.updateCountyEmployee$({userId, countyEmployee: user})
      .pipe(trace('updateCountyEmployee'))
      .toPromise();

    this.cloudLog.info(`Updated county employee ${userId}`);
  }

  async removeEmployeeFromCounty(userId: string, countyId: string): Promise<void> {
    await this.removeEmployeeFromCounty$({userId, countyId})
      .pipe(trace('deleteCountyEmployee'))
      .toPromise();

    this.cloudLog.info(`Deleted county employee ${userId} from county ${countyId}`);
  }

  async existCountyEmployee(emailAddress: string, countyId: string): Promise<void> {
    const payload = {emailAddress, countyId};
    await this.existCountyEmployee$(payload)
      .pipe(trace('existCountyEmployee'))
      .toPromise();
  }

  async updateActiveCountyRequest(userId: string, newCounty: string): Promise<void> {
    await this.updateActiveCountyRequest$({userId, newCounty})
      .pipe(trace('updateActiveCountyRequest'))
      .toPromise();

    this.cloudLog.info(`Changed employee ${userId} active county to ${newCounty}`);
  }

}
