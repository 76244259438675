import { DepotItem, DepotItemSummary } from '@app/modules/depot/models/depot-item';
import * as util from '@app/shared/util';

import { Injectable } from '@angular/core';
import { AngularFirePerformance } from '@angular/fire/compat/performance';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { trace } from '@angular/fire/compat/performance';

@Injectable({
  providedIn: 'root'
})
export class DepotService {
  private getDepot$: (data: any) => Observable<any>;
  private getDepotItem_$: (data: any) => Observable<any>;

  constructor(functions: AngularFireFunctions, private performance: AngularFirePerformance) {
    this.getDepot$ = functions.httpsCallable('getDepot');
    this.getDepotItem_$ = functions.httpsCallable('getDepotItem');
  }

  getDepotItems$(countyId: string): Observable<Array<DepotItemSummary>> {
    return this.getDepot$({ countyId })
      .pipe(trace('getDepot'))
      .pipe(
        map((items: any[]) =>
          items.map((item) => {
            item.purchaseDate = item.purchaseDate && util.isoTimestampToDate(item.purchaseDate);

            return item;
          })
        )
      );
  }

  async getDepotItems(countyId: string): Promise<Array<DepotItemSummary>> {
    return util.takeOneAsPromise(this.getDepotItems$(countyId));
  }

  getDepotItem$(countyId: string, itemId: string, ignoreHasDepotChk: boolean): Observable<DepotItem> {
    return this.getDepotItem_$({ countyId, misaItemId: itemId, ignoreHasDepotChk })
      .pipe(trace('getDepotItem'))
      .pipe(
        map((item: any) => {
          item.deployHistory.forEach((entry) => {
            entry.dateDelivered = entry.dateDelivered && util.isoTimestampToDate(entry.dateDelivered);
            entry.dateInput = entry.dateInput && util.isoTimestampToDate(entry.dateInput);
            entry.dateRetrieved = entry.dateRetrieved && util.isoTimestampToDate(entry.dateRetrieved);
          });

          item.maintenanceHistory.forEach((entry) => {
            entry.dateAppointment = entry.dateAppointment && util.isoTimestampToDate(entry.dateAppointment);
            entry.dateDelivered = entry.dateDelivered && util.isoTimestampToDate(entry.dateDelivered);
            entry.dateInput = entry.dateInput && util.isoTimestampToDate(entry.dateInput);
          });

          item.purchaseDate = item.purchaseDate && util.isoTimestampToDate(item.purchaseDate);

          return item;
        })
      );
  }

  async getDepotItem(countyId: string, itemId: string, ignoreHasDepotChk: boolean = false): Promise<DepotItem> {
    return util.takeOneAsPromise(this.getDepotItem$(countyId, itemId, ignoreHasDepotChk));
  }
}
