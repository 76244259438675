import {Establishment} from '@app/modules/establishment/models/establishment';
import {AuthService, AuthUser} from '@app/shared/services/auth.service';
import {CloudLogger, CloudLoggingService} from '@app/shared/services/cloud-logging.service';
import {FirestoreObservableDocument} from '@app/shared/util/firestore-observable-document';
import {FirestoreObservableQuery} from '@app/shared/util/firestore-observable-query';
import { UserService, SignedInUser } from '@app/shared/services/user.service';
import { CountyService } from '@app/modules/county/services/county.service';
import { County } from '@app/modules/county/models/county';
import { ContractService } from '@app/modules/contract/services/contract.service';
import { Contract } from '@app/modules/contract/models/contract';
import * as util from '@app/shared/util';
import * as devLog from '@app/shared/util/dev-log';

import {Injectable} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import {Observable, Subscription, ReplaySubject} from 'rxjs';
import { AngularFirePerformance } from '@angular/fire/compat/performance';
import { trace } from '@angular/fire/compat/performance';


export type Establishments = {[key: string]: Establishment};


@Injectable({
  providedIn: 'root'
})
export class EstablishmentService {

  private userSubscription: Subscription;

  establishments$: Observable<Establishments>;
  establishmentOfSignedInUser$: Observable<Establishment | null>;

  private cloudLog: CloudLogger;
  private perf = firebase.performance();

  constructor(
    private authService: AuthService,
    private cloudLoggingService: CloudLoggingService,
    private firestore: AngularFirestore,
    private performance: AngularFirePerformance,
    private userService: UserService,
    private countyService: CountyService,
    private contractService: ContractService,
  ) {

    this.cloudLog = this.cloudLoggingService.createLogger('establishment.service');

    const establishmentsQuery = new FirestoreObservableQuery<Establishment>(firestore, {
      collection: 'establishments',
      postQueryFilter: (establishment: Establishment) => {
        return !establishment.archived;
      }
    });

    this.establishments$ = establishmentsQuery.observable$
      .pipe(trace('EstablishmentService.establishments$'));

    this.authService.user$.subscribe((user: AuthUser) => {
      establishmentsQuery.setCleanup(!user);
    });

    const establishmentOfSignedInUserSub$ = new Observable(subscriber => {
      this.userService.user$.subscribe(async (user?: SignedInUser) => {
        if (user && user.countyId) {
          try {
            const county: County = await this.countyService.getCounty(user.countyId);
            const contract: Contract = await this.contractService.getContract(county.contractId);
            const establishment: Establishment = await this.getEstablishment(contract.establishmentId);
            subscriber.next(establishment);
          }
          catch (error){
            devLog.info(`Cannot get establishment for ${user.userId}`, error);
            subscriber.next(null);
          }
        }
        else {
          subscriber.next(null);
        }
      });
    });

    const authSubject = new ReplaySubject<Establishment>(1);
    establishmentOfSignedInUserSub$.subscribe(authSubject);
    this.establishmentOfSignedInUser$ = authSubject.asObservable();

  }

  async getEstablishments(): Promise<Establishments> {
    return util.takeOneAsPromise(this.establishments$);
  }

  getEstablishment$(establishmentId: string): Observable<Establishment> {
    return new FirestoreObservableDocument<Establishment>(this.firestore, {
      documentPath: ['establishments', establishmentId]
    }).observable$.pipe(trace('EstablishmentService.getEstablishment$'));
  }

  async getEstablishment(establishmentId: string): Promise<Establishment> {
    return util.takeOneAsPromise(this.getEstablishment$(establishmentId));
  }

  async createEstablishment(data: Establishment): Promise<string> {
    const trace = this.perf.trace('EstablishmentService.createEstablishment');
    trace.start();

    try {
      // @todo validate input
      const reference = await this.getCollection().add(data);
      const establishmentId = reference.id;

      this.cloudLog.info(`Created establishment ${establishmentId}`);
      return establishmentId;

    } finally {
      trace.stop();
    }
  }

  async updateEstablishment(establishmentId: string, establishment: Establishment): Promise<void> {
    const trace = this.perf.trace('EstablishmentService.updateEstablishment');
    trace.start();

    try {
      // @todo validate input
      const reference = this.getReference(establishmentId);

      if (!(await reference.get().toPromise()).exists) {
        throw new Error('Establishment does not exist');
      }

      await reference.set(establishment);
      this.cloudLog.info(`Updated establishment ${establishmentId}`);

    } finally {
      trace.stop();
    }
  }

  private getReference(establishmentId: string): AngularFirestoreDocument<Establishment> {
    return this.getCollection().doc(establishmentId);
  }

  private getCollection(): AngularFirestoreCollection<Establishment> {
    return this.firestore.collection<Establishment>('establishments');
  }
}
