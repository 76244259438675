import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

/* Pages */
import { AdministratorsPageComponent } from '@app/modules/admin/pages/administrators-page.component';
import { CountiesPageComponent } from '@app/modules/county/pages/counties-page/counties-page.component';
import { CountySelectPageComponent } from '@app/modules/county/pages/county-select-page/county-select-page.component';
import { CountyPageComponent } from '@app/modules/county/pages/county-page/county-page.component';
import { PasswordResetPageComponent } from '@app/modules/home/pages/password-reset-page/password-reset-page.component';
import { SignInPageComponent } from '@app/modules/home/pages/sign-in-page/sign-in-page.component';
import { OrderPageComponent } from '@app/modules/order/pages/order-page/order-page.component';
import { ContractsPageComponent } from '@app/modules/contract/pages/contracts-page/contracts-page.component';
import { ContractPageComponent } from '@app/modules/contract/pages/contract-page/contract-page.component';
import { EstablishmentsPageComponent } from '@app/modules/establishment/pages/establishments-page/establishments-page.component';
import { EstablishmentPageComponent } from '@app/modules/establishment/pages/establishment-page/establishment-page.component';
import { ClientsPageComponent } from '@app/modules/client/pages/clients-page/clients-page.component';
import { OutstandingRequestsComponent } from '@app/modules/client/pages/uitstaande-voorzieningen/uitstaande-voorzieningen.component';
import { ClientPageComponent } from '@app/modules/client/pages/client-page/client-page.component';
import { OrdersPageComponent } from '@app/modules/order/pages/orders-page/orders-page.component';
import { DepotPageComponent } from '@app/modules/depot/pages/depot-page/depot-page.component';
import { MaintenancePageComponent } from '@app/modules/home/pages/maintenance-page/maintenance-page.component';
import { ContactPageComponent } from '@app/modules/contact/pages/contact-page/contact-page.component';
import { AboutPageComponent } from '@app/modules/about/pages/about-page/about-page.component';
import { AboutContactPageComponent } from '@app/modules/about/pages/about-contact-page/about-contact-page.component';
import { AboutUserManualsPageComponent } from '@app/modules/about/pages/about-user-manuals/about-user-manuals.component';
import { ClientActivationPageComponent } from '@app/modules/home/pages/client-activation-page/client-activation-page.component';
import { RouteGuardService as Guard } from '@app/shared/services/route-guard.service';
import { AboutNewsPageComponent } from './modules/about/pages/about-news-page/about-news-page.component';
import { ClientHomePageComponent } from './modules/client/pages/client-home-page/client-home-page.component';
import { ClientFaqPageComponent } from './modules/client/pages/client-faq-page/client-faq-page.component';
import { ClientContactPageComponent } from './modules/client/pages/client-contact-page/client-contact-page.component';

const routes: Routes = [
  { canActivate: [Guard], path: 'sign-in', component: SignInPageComponent },
  { canActivate: [Guard], path: 'about', component: AboutPageComponent },
  { canActivate: [Guard], path: 'about/about-contact', component: AboutContactPageComponent },
  { canActivate: [Guard], path: 'about/user-manuals', component: AboutUserManualsPageComponent },
  { canActivate: [Guard], path: 'about/news', component: AboutNewsPageComponent },
  { canActivate: [Guard], path: 'orders', component: OrdersPageComponent },
  { canActivate: [Guard], path: 'order', component: OrderPageComponent },
  { canActivate: [Guard], path: 'order/:misaOrderId', component: OrderPageComponent },
  { canActivate: [Guard], path: 'counties', component: CountiesPageComponent },
  { canActivate: [Guard], path: 'county-select', component: CountySelectPageComponent },
  { canActivate: [Guard], path: 'county', component: CountyPageComponent },
  { canActivate: [Guard], path: 'county/:countyId', component: CountyPageComponent },
  { canActivate: [Guard], path: 'administrators', component: AdministratorsPageComponent },
  { canActivate: [Guard], path: 'establishments', component: EstablishmentsPageComponent },
  { canActivate: [Guard], path: 'establishment', component: EstablishmentPageComponent },
  { canActivate: [Guard], path: 'establishment/:establishmentId', component: EstablishmentPageComponent },
  { canActivate: [Guard], path: 'clients', component: ClientsPageComponent },
  { canActivate: [Guard], path: 'uitstaande-voorzieningen', component: OutstandingRequestsComponent },
  { canActivate: [Guard], path: 'client/:clientId', component: ClientPageComponent },
  { canActivate: [Guard], path: 'contracts', component: ContractsPageComponent },
  { canActivate: [Guard], path: 'contract', component: ContractPageComponent },
  { canActivate: [Guard], path: 'contract/:contractId', component: ContractPageComponent },
  { canActivate: [Guard], path: 'depot', component: DepotPageComponent },
  { canActivate: [Guard], path: 'maintenance', component: MaintenancePageComponent },
  { canActivate: [Guard], path: 'reset-password', component: PasswordResetPageComponent },
  { canActivate: [Guard], path: 'contact', component: ContactPageComponent },
  { canActivate: [Guard], path: 'activeren', component: ClientActivationPageComponent },
  { canActivate: [Guard], path: 'client-contact', component: ClientContactPageComponent },
  { canActivate: [Guard], path: 'client-home', component: ClientHomePageComponent },
  { canActivate: [Guard], path: 'client-faq', component: ClientFaqPageComponent },
  { canActivate: [Guard], path: '**', component: SignInPageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
