import { Component, ViewChild, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';

import { CountyService } from '@app/modules/county/services/county.service';
import { CountyEmployeeService } from '@app/modules/county/services/county-employee.service';
import { County } from '@app/modules/county/models/county';
import { TitleService } from '@app/shared/services/title.service';
import { ErrorService } from '@app/shared/services/error.service';
import { UserService, SignedInUser } from '@app/shared/services/user.service';
import { NotificationService } from '@app/shared/services/notification.service';

import * as util from '@app/shared/util';
import { MatSort } from '@angular/material/sort';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-county-select-page',
  templateUrl: './county-select-page.component.html',
  styleUrls: ['./county-select-page.component.scss']
})
export class CountySelectPageComponent implements OnInit, OnDestroy {

  countiesTable: MatTableDataSource<any>;
  countiesTableColumns = ['radio', 'name'];
  showSpinner = true;

  private countiesReceived = false;

  private userSubscription: Subscription;
  userCountyId: string;
  signedInUser: SignedInUser;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) tablePaginator: MatPaginator;

  constructor(
    private errorService: ErrorService,
    private countyService: CountyService,
    private countyEmployeeService: CountyEmployeeService,
    private notificationService: NotificationService,
    private titleService: TitleService,
    private userService: UserService,
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Gemeenten');
    this.userSubscription = this.userService.user$
      .pipe(
        takeWhile(() => !this.countiesReceived)
      )
      .subscribe({
    next: (user?: SignedInUser) => {
      this.signedInUser = user;
      if (this.signedInUser) {
        this.getUserCounties();
      }},
      error: (e) => {
        console.error(e);
      }
    });
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  async getUserCounties() {
    const countiesObject = {};
    const countyErrors = [];

    for (const countyId of this.signedInUser.counties) {
      try {
        const county = await this.countyService.getCounty(countyId);
        countiesObject[countyId] = county;
      }
      catch (error) {
        countyErrors.push(error);
      }
    }

    const counties = util.mapOjectToArray<County>(countiesObject, 'countyId');
    this.countiesTable = new MatTableDataSource(counties);
    this.countiesTable.sort = this.sort;
    this.countiesTable.paginator = this.tablePaginator;
    this.showSpinner = false;

    if (this.countiesReceived) {
      this.notificationService.openSnackBar('De lijst met gemeentes is bijgewerkt');
    } else {
      this.countiesReceived = true;
    }

    if (countyErrors.length > 0) {
      this.errorService.alertAndLog('countyErrors', 'Er is een fout opgetreden bij het ophalen van enkele van uw gemeenten.');
    }
  }

  filterTable(filterValue: string) {
    this.countiesTable.filter = filterValue.trim().toLowerCase();
  }

  async changeCounty(countyId: string) {
    this.showSpinner = true;
    try {
      await this.countyEmployeeService.updateActiveCountyRequest(this.signedInUser.userId, countyId);
      this.countiesReceived = false;
      // note: this will update the user subscription, so there's no need to hide the spinner here
    }
    catch (error) {
      this.errorService.alertAndLog(error, 'Er is een fout opgetreden bij het wijzigen van de gemeente.');
      this.showSpinner = false;
    }
  }

}
