import { DialogService, DialogRef } from '@app/shared/services/dialog.service';

import { Injectable } from '@angular/core';


@Injectable({providedIn: 'root'})
export class ErrorService {

  constructor(public dialogService: DialogService) {}

  alertAndLog(error: any, message?: string, additionalMessages?: Array<string>): DialogRef {
    if (message) {
      console.error(message);
    }

    console.error(error);
    // @todo log to Cloud

    return this.dialogService.openErrorAlert('Foutmelding',
      message || 'Er is iets mis gegaan.', additionalMessages);
  }
}
