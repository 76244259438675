<div class="general-form-container">
  <table>
    <tr *ngFor="let newsItem of newsItems.slice(0, shownItemCount)">
      <td nowrap>{{ newsItem.dateCreated | date: 'dd-MM-yyyy' }}</td>
      <td>
        <span *ngIf="isNew(newsItem.dateCreated)" class="is-new">NIEUW</span><br *ngIf="isNew(newsItem.dateCreated)"/>
        <span class="news-title">{{ newsItem.newsTitle }}</span
        ><br />
        {{ newsItem.newsText }}
      </td>
    </tr>
    <tr>
      <td colspan="2" align="right" *ngIf="shownItemCount < newsItems.length">
        <div class="more-updates-link" (click)="setShowAll()">Meer updates</div>
      </td>
    </tr>
  </table>
</div>
