<div class="page-heading">
  <h1>Contact</h1>
  <h2 class="black-text">Waar gaat uw vraag over? Maak onderstaand een keuze:</h2>
</div>

<div class="container">
  <div class="row" (click)="onclick(1)">
    <div class="row-content">
      <h2 [ngClass]="{ 'black-text': expand1 }">Ik heb een vraag over een levering, voorziening of een operationele vraag.</h2>
      <mat-icon [ngClass]="{ 'black-text': expand1 }" class="arrow-right">keyboard_arrow_right</mat-icon>
    </div>

    <div class="row-expand row-1" *ngIf="expand1">
      <h2 class="black-text">Onze Wmo vestigingen helpen u graag verder:</h2>
      <table class="expand-table">
        <tr>
          <th>Telefoonnummer</th>
          <th>Email adres</th>
        </tr>

        <tr>
          <td>{{ getContactPhone() }}</td>
          <td [innerHtml]="getContactEmail()"></td>
        </tr>
      </table>
    </div>
  </div>

  <div class="row" (click)="onclick(2)">
    <div class="row-content">
      <h2 [ngClass]="{ 'black-text': expand2 }">Ik heb een vraag over de werking van de WMO portal</h2>
      <mat-icon [ngClass]="{ 'black-text': expand2 }" class="arrow-right">keyboard_arrow_right</mat-icon>
    </div>

    <div [ngClass]="{ 'contact-expanded': expand2 }" class="row-expand" *ngIf="expand2">
      <ng-container *ngIf="!formSent">
        <h2 class="black-text">Vul het onderstaande formulier in</h2>
        <form>
          <div class="general-form-container">
            <div class="row">
              <div class="input-container">
                <label>Naam*</label>
                <input type="text" name="name" id="name" (change)="handleInput($event)" required />
              </div>
              <div class="input-container">
                <label>Email*</label>
                <input type="email" email name="email" id="email" (change)="handleInput($event)" required />
              </div>
              <div class="input-container">
                <label>Telefoonnummer*</label>
                <input type="tel" name="phone" id="phone" (change)="handleInput($event)" required />
              </div>
            </div>
            <div class="row">
              <div class="input-container">
                <label>Uw vraag*</label>
                <textarea name="question" id="question" (change)="handleInput($event)" required></textarea>
              </div>
            </div>
            <div class="row">
              <div class="input-container">
                <label for="attachment">Eventuele bijlagen</label>
                <div class="file-upload">
                  <input type="file" name="attachment" (change)="onFileChange($event)" id="attachment" />
                </div>
              </div>
            </div>
          </div>
        </form>
        <div id="general-form-actions">
          <div class="general-form-actions-container">
            <button class="cta" [disabled]="!isFormValid()" (click)="sendForm($event)">Verzenden</button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="formSent">
        <div class="content-container">Bedankt voor uw vraag. Een medewerker neemt spoedig contact met u op.</div>
      </ng-container>
    </div>
  </div>

  <div class="row" (click)="onclick(3)">
    <div class="row-content">
      <h2 [ngClass]="{ 'black-text': expand3 }">Ik wil mijn persoonlijke gegevens (NAW, e-mailadres, telefoonnummer) laten wijzigen</h2>
      <mat-icon [ngClass]="{ 'black-text': expand3 }" class="arrow-right">keyboard_arrow_right</mat-icon>
    </div>

    <div class="row-expand" *ngIf="expand3">
      <h2 class="black-text">Onze Wmo vestigingen helpen u graag verder:</h2>
      <table class="expand-table">
        <tr>
          <th>Telefoonnummer</th>
          <th>Email adres</th>
        </tr>
        <tr>
          <td>{{ getContactPhone() }}</td>
          <td [innerHtml]="getContactEmail()"></td>
        </tr>
      </table>
    </div>
  </div>
</div>
