<div class="top-toolbar-container">
  <mat-icon aria-hidden="false" aria-label="Close icon" (click)="closeDialog()">close</mat-icon>
</div>
<div class="title">{{title}} downloads</div>
<div>
  <table mat-table [dataSource]="dataSourceBlo" class="mat-elevation-z8">
    <ng-container matColumnDef="fileName">
      <th mat-header-cell *matHeaderCellDef>
        <div class="subtitle">BLO Documenten</div>
      </th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <mat-icon aria-hidden="false" (click)="downloadFile(element)">download</mat-icon>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <table mat-table [dataSource]="dataSourceAf" class="mat-elevation-z8">
    <ng-container matColumnDef="fileName">
      <th mat-header-cell *matHeaderCellDef>
        <div class="subtitle">Afleverset Documenten</div>
      </th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <mat-icon aria-hidden="false" (click)="downloadFile(element)">download</mat-icon>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
<div class="low-row buttons-row">
  <div class="buttons-container">
    <button  class="secondary-cta">Annuleren</button>
    <!--<button    class="cta">Ophaalverzoek indienen</button>
    -->
  </div>
</div>
