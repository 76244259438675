<form [formGroup]="employeeForm">

    <div class="general-form-container in-dialog">

        <div class="general-form-subheader">
            <h2>
                Medewerker
                {{employeeForm.value.name.initials?(employeeForm.value.name.initials+' '):''}}
                {{employeeForm.value.name.prefix?(employeeForm.value.name.prefix+' '):''}}
                {{employeeForm.value.name.lastName?employeeForm.value.name.lastName:''}}
            </h2>
        </div>

        <div class="row" formGroupName="name">

            <div class="input-container"
                [ngClass]="{'invalid': (employeeForm['controls'].name['controls'].initials.touched && employeeForm['controls'].name['controls'].initials.invalid)}">
                <app-error-messages [formControls]="employeeForm['controls'].name['controls'].initials"></app-error-messages>
                <input type="text" formControlName="initials"
                    (blur)="employeeForm.patchValue({name: {initials: util.formatInitials(employeeForm['controls'].name['controls'].initials.value)}})"
                    required />
                <label for="contactInitials">Voorletters</label>
            </div>

            <div class="input-container">
                <app-error-messages [formControls]="employeeForm['controls'].name['controls'].firstName"></app-error-messages>
                <input type="text" formControlName="firstName" />
                <label for="firstName">Voornaam</label>
            </div>

        </div>

        <div class="row" formGroupName="name">

            <div class="input-container prefix">
                <app-error-messages [formControls]="employeeForm['controls'].name['controls'].prefix"></app-error-messages>
                <input type="text" formControlName="prefix" />
                <label for="prefix">Tussenvoegsel(s)</label>
            </div>

            <div class="input-container"
                [ngClass]="{'invalid': (employeeForm['controls'].name['controls'].lastName.touched && employeeForm['controls'].name['controls'].lastName.invalid)}">
                <app-error-messages [formControls]="employeeForm['controls'].name['controls'].lastName"></app-error-messages>
                <input type="text" formControlName="lastName" required />
                <label for="lastName">Achternaam</label>
            </div>

        </div>

        <div class="row" formGroupName="contactDetails">

            <div class="input-container"
                [ngClass]="{'invalid': (employeeForm['controls'].contactDetails['controls'].emailAddress.touched && employeeForm['controls'].contactDetails['controls'].emailAddress.invalid)}">
                <app-error-messages [formControls]="employeeForm['controls'].contactDetails['controls'].emailAddress">
                </app-error-messages>
                <input type="text" formControlName="emailAddress" required />
                <label>Email adres</label>
            </div>

            <div class="input-container"
                [ngClass]="{'invalid': (employeeForm['controls'].contactDetails['controls'].mobileNumber.touched && employeeForm['controls'].contactDetails['controls'].mobileNumber.invalid)}">
                <app-error-messages marginBottom=true
                    [formControls]="employeeForm['controls'].contactDetails['controls'].mobileNumber"></app-error-messages>
                <input type="text" formControlName="mobileNumber" [required]="multiFactorAuthRequired" />
                <label matTooltip="Een geldig Nederlands mobiel nummer bestaat uit 10 cijfers en begint met 06">
                    Telefoonnummer (mobiel) <span class="info">
                        <mat-icon>info</mat-icon>
                    </span>
                </label>
            </div>

        </div>

        <div class="row">

            <div class="input-container"
                [ngClass]="{'invalid': (employeeForm['controls'].roles.touched && employeeForm['controls'].roles.invalid)}">
                <app-error-messages [formControls]="employeeForm['controls'].roles"></app-error-messages>

              <mat-select disableRipple class="custom-mat-select" [formControl]="employeeForm['controls'].roles"
                    multiple>

                    <mat-select-trigger>
                      <span class="multiple-mat-select-text">
                        {{translateAndJoinRoles(employeeForm['controls'].roles.value)}}
                      </span>
                    </mat-select-trigger>

                    <mat-option [value]="'county_admin'">
                        <span
                            matTooltip="Beheerders kunnen de medewerkers en de instellingen voor de gemeente beheren.">
                            Beheerder<mat-icon>info</mat-icon>
                        </span>
                    </mat-option>

                    <mat-option [value]="'county_order_editor'">
                        <span matTooltip="Consulenten kunnen aanvragen en cliënten bekijken, aanmaken en wijzigen. Het is van een geleverde voorziening ook mogelijk om een ophaal-, reparatie of aanpassingsverzoek in te dienen.">
                            Consulent<mat-icon>info</mat-icon>
                        </span>
                    </mat-option>

                    <mat-option [value]="'county_order_viewer'">
                        <span
                            matTooltip="Backoffice medewerkers kunnen aanvragen en cli&euml;nten bekijken maar niet aanmaken of wijzigen.">
                            Backoffice<mat-icon>info</mat-icon>
                        </span>
                    </mat-option>
                    <mat-option [value]="'county_depot_viewer'">
                        <span
                            matTooltip="Depotbeheerders kunnen de producten in het depot bekijken.">
                            Depotbeheerder<mat-icon>info</mat-icon>
                        </span>
                    </mat-option>
                    <mat-option [value]="'county_viewer'">
                        <span
                            matTooltip="Inzien medewerkers kunnen lopende aanvragen, cliënten, voorziening bekijken /inzien maar niet muteren.">
                            Inzien<mat-icon>info</mat-icon>
                        </span>
                    </mat-option>
                </mat-select>

                <label class="required">Rollen</label>
            </div>

        </div>

    </div>

    <div *ngIf="showEditButton" class="remove-employee-from-county-cta-container">
      <button [disabled]="isOwnAccount" id="remove-employee-from-county-button" class="remove-cta" (click)="comfirmRemoveEmployeeFromCounty()">
        Deactiveren
      </button>
    </div>

    <div class="dialog-cta-container two-buttons">
        <button class="secondary-cta" id="close-button" (click)="closeDialog()">Annuleren</button>
        <button *ngIf="showAddButton" class="cta" id="add-employee-button" (click)="addEmployee()">Toevoegen</button>
        <button *ngIf="showEditButton" id="edit-employee-button" class="cta" (click)="editEmployee()">Wijzigen</button>
    </div>

</form>

<app-spinner *ngIf="showEmployeeDialogSpinner"></app-spinner>