<div class="general-form-header">
  <h1>Gemeenten</h1>
</div>

<div class="general-form-container">

  <div class="input-icon-container margin-bottom">
    <mat-icon>search</mat-icon>
    <input matInput (keyup)="filterTable($event.target.value)" placeholder="Zoeken" type="text" />
  </div>

  <table class="lister-table county-select" mat-table [dataSource]="countiesTable" multiTemplateDataRows
  matSort matSortStart="asc" matSortActive="name" matSortDirection="asc" matSortDisableClear>

    <ng-container matColumnDef="radio">
      <th class="radio" mat-header-cell *matHeaderCellDef> Actief </th>
      <td class="radio" mat-cell *matCellDef="let element">
        <input type="radio"
        [checked]="signedInUser && signedInUser.countyId === element.countyId"
        name="selectedCounty" id="county-{{element.countyId}}" (change)="changeCounty(element.countyId)">
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th class="name" mat-header-cell *matHeaderCellDef mat-sort-header> Gemeente </th>
      <td class="name" mat-cell *matCellDef="let element">
        <label for="county-{{element.countyId}}">{{element.name}}</label>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="countiesTableColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: countiesTableColumns;"></tr>

  </table>

  <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>

</div>

<app-spinner *ngIf="showSpinner" loadingMessage="{{loadingMessage}}"></app-spinner>