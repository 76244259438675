import { Injectable } from '@angular/core';
import { FaqItem } from '../models/faq-item';
import { CloudLogger, CloudLoggingService } from '@app/shared/services/cloud-logging.service';
import { AuthService, AuthUser } from '@app/shared/services/auth.service';

import { Observable } from 'rxjs';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { FirestoreObservableQuery } from '@app/shared/util/firestore-observable-query';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AngularFirePerformance } from '@angular/fire/compat/performance';

@Injectable({
  providedIn: 'root'
})
export class FaqService {
  collectionName = `faq-items`;
  faqItems$: Observable<any>;
  faqItemsCollection: AngularFirestoreCollection<FaqItem> = this.firestore.collection<FaqItem>(this.collectionName);

  private cloudLog: CloudLogger;
  private requestAdditionalService$: (data: any) => Observable<any>;

  constructor(
    cloudLoggingService: CloudLoggingService,
    functions: AngularFireFunctions,
    private authService: AuthService,
    private firestore: AngularFirestore,
    private performance: AngularFirePerformance
  ) {
    const faqItemsQuery = new FirestoreObservableQuery<any>(firestore, {
      collection: this.collectionName
    });

    this.faqItems$ = faqItemsQuery.observable$;
    this.authService.user$.subscribe({
    next: (user: AuthUser) => {
      faqItemsQuery.setCleanup(!user);
    }
    });

    this.cloudLog = cloudLoggingService.createLogger('faq.service');
    this.requestAdditionalService$ = functions.httpsCallable('requestAdditionalService');
  }

  async getFaqItems(): Promise<FaqItem[]> {
    const faqItems: FaqItem[] = [];
    const snapshot = await this.faqItemsCollection.ref.orderBy('dateCreated', 'asc').get();
    if (!snapshot.empty) {
      snapshot.forEach((doc) => {
        const id = doc.id;
        const { question, answer, dateCreated } = doc.data();
        faqItems.push({
          id,
          question,
          answer,
          dateCreated: dateCreated.toDate()
        });
      });
    }
    return faqItems;
  }

  async saveFaqItem(item: FaqItem): Promise<FaqItem> {
    let objectToSave:FaqItem={...item};
    if (item.id === '') {
      objectToSave.id = this.firestore.createId();
      objectToSave.dateCreated = new Date();
    }
    await this.faqItemsCollection.doc(objectToSave.id).set(objectToSave);
    return objectToSave;
  }

  async deleteFaqItem(itemId: string): Promise<void> {
    await this.firestore.doc(`faq-items/${itemId}`).delete();
  }
}
