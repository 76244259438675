import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { FirestoreObservableDocument } from '@app/shared/util/firestore-observable-document';

import { OrderHistoryItemsCollection, OrderHistoryItems, OrderHistoryItemRemark, OrderHistoryItemDocuments, OrderHistoryItemDocument } from '@app/modules/order/models/order-history-items';

import { CloudLogger, CloudLoggingService } from '@app/shared/services/cloud-logging.service';
import { AngularFirePerformance, trace } from '@angular/fire/compat/performance';

import * as moment from 'moment';
import * as util from '@app/shared/util';

@Injectable({
  providedIn: 'root'
})
export class OrderHistoryItemsService {
  private cloudLog: CloudLogger;

  collectionName = `order-history-items`;

  orderHistoryItemsCollection: AngularFirestoreCollection<any> = this.firestore.collection<OrderHistoryItemsCollection>(this.collectionName);

  constructor(
    private firestore: AngularFirestore,
    private performance: AngularFirePerformance,
    private cloudLoggingService: CloudLoggingService,
  ) {
    this.cloudLog = this.cloudLoggingService.createLogger('order-history-items.service');
  }

  getOrderHistoryItems$(misaOrderId: string): Observable<OrderHistoryItems> {
    return new FirestoreObservableDocument<OrderHistoryItems>(this.firestore, {
      documentPath: [this.collectionName, misaOrderId],
    }).observable$.pipe(trace('OrderHistoryItemsService.getOrderHistoryItems$'));
  }

  async getOrderHistoryItems(misaOrderId: string): Promise<OrderHistoryItems> {
    return util.takeOneAsPromise(this.getOrderHistoryItems$(misaOrderId));
  }

  async addCountyId(misaOrderId: string, countyId: string): Promise<void> {
    await this.orderHistoryItemsCollection.doc(misaOrderId).set(
      { countyId },
      { merge: true }
    );

    this.cloudLog.info(`Added countyId to history for misaOrderId: ${misaOrderId}`, { countyId });
  }

  async addRemark(misaOrderId: string, remark: string): Promise<void> {
    console.log(`addRemark`, misaOrderId, remark);

    const id = `${new Date().getTime()}`;
    const dateCreated = moment(new Date().getTime()).format('DD-MM-YYYY');
    const remarkEntry: OrderHistoryItemRemark = {
      remark,
      dateCreated
    };

    await this.orderHistoryItemsCollection.doc(misaOrderId).set(
      { remarks: { [id]: remarkEntry } },
      { merge: true }
    );

    this.cloudLog.info(`Added remark to history for misaOrderId: ${misaOrderId}`, { remark });
  }

  async addDocuments(misaOrderId: string, documents: OrderHistoryItemDocument[]): Promise<void> {
    const id = `${new Date().getTime()}`;
    const dateCreated = moment(new Date().getTime()).format('DD-MM-YYYY');
    const documentsEntry: OrderHistoryItemDocuments = {
      documents,
      dateCreated
    };

    await this.orderHistoryItemsCollection.doc(misaOrderId).set(
      { documents: { [id]: documentsEntry } },
      { merge: true }
    );

    this.cloudLog.info(`Added documents to history for misaOrderId: ${misaOrderId},`, documentsEntry);
  }

}
