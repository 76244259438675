import { AddressAutocompleteService } from '@app/shared/services/address-autocomplete.service';
import * as util from '@app/shared/util';

import { Component, Input, forwardRef } from '@angular/core';

import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  UntypedFormGroup,
  AbstractControl,
  ValidationErrors
} from '@angular/forms';

@Component({
  selector: 'app-address-autocomplete',
  templateUrl: './address-autocomplete.component.html',
  styleUrls: ['./address-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressAutocompleteComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AddressAutocompleteComponent),
      multi: true
    }
  ]
})
export class AddressAutocompleteComponent implements ControlValueAccessor {

  @Input() addressForm: UntypedFormGroup;

  apiStatus: string;
  keyUpTimeout: any;

  constructor(
    private addressAutocompleteService: AddressAutocompleteService
  ) { }

  // Start of CVA's needed to connect to main form (https://blog.angularindepth.com/angular-nested-reactive-forms-using-cvas-b394ba2e5d0d)
  public onTouched: any = () => { };

  writeValue(val: any): void {
    // tslint:disable-next-line:no-unused-expression
    val && this.addressForm.setValue(val, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.addressForm.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.addressForm.disable() : this.addressForm.enable();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.addressForm.valid ? null : { invalidForm: { valid: false, message: 'addressForm fields are invalid' } };
  }
  // End of CVA's

  // This method is called from the parent form to mark all fields as touched
  markTouched() {
    util.markFormGroupTouched(this.addressForm);
  }

  reset() {
    this.addressForm.reset();
  }

  checkAddressForApiWithTimer() {

    clearTimeout(this.keyUpTimeout);
    this.keyUpTimeout = setTimeout(() => {
      this.checkAddressForApi();
    }, 1000);

  }

  checkAddressForApi() {

    clearTimeout(this.keyUpTimeout);
    const postalCode = this.addressForm.value.postalCode;
    const houseNumber = this.addressForm.value.houseNumber;
    const houseNumberAddition = this.addressForm.value.houseNumberAddition;

    if (postalCode && houseNumber && !this.addressForm.controls.postalCode.invalid && !this.addressForm.controls.houseNumber.invalid) {
      this.getAddressFromApi(postalCode, houseNumber, houseNumberAddition);
    }

  }

  async getAddressFromApi(postalCode, houseNumber, houseNumberAddition) {
    this.apiStatus = 'loading';
    try {
      const receivedAddress = await this.addressAutocompleteService.autocompleteAddress(postalCode, houseNumber, houseNumberAddition);
      const receivedAddressValues = {
        city: receivedAddress.city,
        street: receivedAddress.street,
      };
      this.addressForm.patchValue(receivedAddressValues);
      this.apiStatus = 'responded';
    }
    catch (error) {
      console.error(`Postcode.nl cannot find address for ${postalCode} ${houseNumber} (${error})`);
      this.apiStatus = 'no-response';
    }
  }

  postalCodeKeyUpListener(event: KeyboardEvent) {
    this.processPostalCode((event.target as HTMLInputElement).value);
  }

  postalCodePasteListener(event: ClipboardEvent) {
    this.processPostalCode(event.clipboardData.getData('text/plain'));
  }

  processPostalCode(postalCode: string) {
    if (postalCode) {
      const editedPostalCodeValue = postalCode.toUpperCase().replace(new RegExp(' ', 'g'), '');
      this.addressForm.patchValue({ postalCode: editedPostalCodeValue });
      this.checkAddressForApiWithTimer();
    }
  }

}
