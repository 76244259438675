import { MultiProductDialogComponent } from './modules/order/components/multi-product-dialog/multi-product-dialog.component';
import { UrgentDialogComponent } from '@app/modules/order/components/urgent-dialog/urgent-dialog.component';
import { environment } from '@app/environment';
import { AdministratorsPageComponent } from '@app/modules/admin/pages/administrators-page.component';
import { AngularMaterialModule } from '@app/modules/angular-material/angular-material.module';
import { OrdersPageComponent } from '@app/modules/order/pages/orders-page/orders-page.component';
import { CountiesPageComponent } from '@app/modules/county/pages/counties-page/counties-page.component';
import { CountyPageComponent } from '@app/modules/county/pages/county-page/county-page.component';
import { EstablishmentPageComponent } from '@app/modules/establishment/pages/establishment-page/establishment-page.component';
import { EstablishmentsPageComponent } from '@app/modules/establishment/pages/establishments-page/establishments-page.component';
import { PasswordResetPageComponent } from '@app/modules/home/pages/password-reset-page/password-reset-page.component';
import { SignInPageComponent } from '@app/modules/home/pages/sign-in-page/sign-in-page.component';
import { AddressAutocompleteComponent } from '@app/shared/components/address-autocomplete/address-autocomplete.component';
import { DocumentUploaderComponent } from '@app/modules/order/components/document-uploader/document-uploader.component';
import { OrderPageComponent } from '@app/modules/order/pages/order-page/order-page.component';
import { AlertDialogComponent } from '@app/shared/components/alert-dialog/alert-dialog.component';
import { ErrorMessagesComponent } from '@app/shared/components/error-messages/error-messages.component';
import { HeaderComponent } from '@app/shared/components/header/header.component';
import { SpinnerComponent } from '@app/shared/components/spinner/spinner.component';
import { EmployeeDialogComponent } from '@app/modules/county/components/employee-dialog.component';
import { ClientsPageComponent } from '@app/modules/client/pages/clients-page/clients-page.component';
import { OutstandingRequestsComponent } from '@app/modules/client/pages/uitstaande-voorzieningen/uitstaande-voorzieningen.component';
import { ClientPageComponent } from '@app/modules/client/pages/client-page/client-page.component';
import { MaintenancePageComponent } from '@app/modules/home/pages/maintenance-page/maintenance-page.component';
import { DownloadUrl } from '@app/shared/pipes/download-url.pipe';
import { ClientsTableComponent } from '@app/modules/order/components/clients-with-orders-table/clients-with-orders-table.component';
import { OrderComponent } from '@app/modules/order/components/clients-with-orders-table/order-component/order.component';
import { SingleClientOrderComponent } from '@app/modules/order/components/single-client-orders/single-client-order-component/single-client-order.component';
import { SingleClientOrdersComponent } from '@app/modules/order/components/single-client-orders/single-client-orders.component';
import { RequestDialogComponent } from '@app/modules/order/components/request-dialog/request-dialog.component';
import { FaqItemsComponent } from '@app/modules/about/components/faq-items/faq-items.component';
import { FaqItemsAdminComponent } from '@app/modules/about/components/faq-items-admin/faq-items-admin.component';
import { NewsComponent } from '@app/modules/about/components/news/news.component';
import { AboutContactDetailComponent } from '@app/modules/about/components/about-contact-detail/about-contact-detail.component';
import { AboutContactFormComponent } from '@app/modules/about/components/about-contact-form/about-contact-form.component';
import { AboutNewsAdminComponent } from '@app/modules/about/components/about-news-admin/about-news-admin.component';
import { AboutManualsAdminComponent } from '@app/modules/about/components/about-manuals-admin/about-manuals-admin.component';
import { AboutUserManualsPageComponent } from '@app/modules/about/pages/about-user-manuals/about-user-manuals.component';
import { CountyNotificationSettingsComponent } from '@app/modules/county/components/notification-settings/notification-settings.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ClientTableWithRequestsComponent } from '@app/modules/client/components/client-table-with-requests/client-table-with-requests.component';
import { ClientOrderWithRequestComponent } from '@app/modules/client/components/client-order-with-request/client-order-with-request.component';
// Firebase
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFirePerformanceModule } from '@angular/fire/compat/performance';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/compat/functions';

// App
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Contract
import { CategoryDialogComponent } from '@app/modules/contract/components/category-dialog/category-dialog.component';
import { ContractsPageComponent } from '@app/modules/contract/pages/contracts-page/contracts-page.component';
import { ContractPageComponent } from '@app/modules/contract/pages/contract-page/contract-page.component';
import { ContactPersonComponent } from '@app/shared/components/contact-person/contact-person.component';
import { ClientFormComponent } from '@app/shared/components/client-form/client-form.component';
import { AboutPageComponent } from '@app/modules/about/pages/about-page/about-page.component';
import { AboutContactPageComponent } from '@app/modules/about/pages/about-contact-page/about-contact-page.component';

import { ClientForm } from '@app/shared/forms/client.form';
import { AddressForm } from '@app/shared/forms/address.form';
import { ContactPersonForm } from '@app/shared/forms/contact-person.form';
import { ContactPerson } from '@app/shared/models/contact-person';
import { FooterComponent } from '@app/shared/components/footer/footer.component';

import { DepotItemComponent } from '@app/modules/depot/components/depot-item/depot-item.component';
import { DepotPageComponent } from '@app/modules/depot/pages/depot-page/depot-page.component';

import { ClientSearchComponent } from '@app/shared/components/client-search/client-search.component';
import { CountySelectPageComponent } from '@app/modules/county/pages/county-select-page/county-select-page.component';

import { MatSortModule } from '@angular/material/sort';

import { ContactPageComponent } from '@app/modules/contact/pages/contact-page/contact-page.component';
import { SubjectDialogComponent } from '@app/modules/contact/components/subject-dialog/subject-dialog.component';

import { ClientActivationFormComponent } from '@app/modules/home/components/client-activation-form/client-activation-form.component';
import { ClientActivationPageComponent } from '@app/modules/home/pages/client-activation-page/client-activation-page.component';
import { RequestTypeSelectorComponent } from '@app/modules/client/components/request-type-selector/request-type-selector.component';

import { AboutNewsPageComponent } from './modules/about/pages/about-news-page/about-news-page.component';
import { TopBarComponent } from './shared/components/top-bar/top-bar.component';

import { ModalOnboardFormComponent } from './shared/components/modal-onboard-form/modal-onboard-form.component';
import { ClientFaqPageComponent } from './modules/client/pages/client-faq-page/client-faq-page.component';
import { ClientContactPageComponent } from './modules/client/pages/client-contact-page/client-contact-page.component';
import { ClientHomePageComponent } from '@app/modules/client/pages/client-home-page/client-home-page.component';
import { MediaWatcherModule } from './shared/services/media-watcher';
import { SidenavComponent } from './shared/components/sidenav/sidenav.component';
import { Headerv2Component } from './shared/components/headerv2/headerv2.component';
import { OrderDocumentsComponent } from './modules/client/components/order-documents/order-documents.component';
import { ScrollingModule } from '@angular/cdk/scrolling';


// @todo break this up into modules such as order, client, count... ?!
@NgModule({
    declarations: [
        AppComponent,
        PasswordResetPageComponent,
        SignInPageComponent,
        OrdersPageComponent,
        HeaderComponent,
        OrderPageComponent,
        AlertDialogComponent,
        SpinnerComponent,
        DocumentUploaderComponent,
        AddressAutocompleteComponent,
        ErrorMessagesComponent,
        ContractsPageComponent,
        ContractPageComponent,
        CountiesPageComponent,
        CountyPageComponent,
        AdministratorsPageComponent,
        CategoryDialogComponent,
        EstablishmentPageComponent,
        EstablishmentsPageComponent,
        EmployeeDialogComponent,
        ClientsPageComponent,
        OutstandingRequestsComponent,
        ClientPageComponent,
        ContactPersonComponent,
        DepotPageComponent,
        DepotItemComponent,
        ClientFormComponent,
        MaintenancePageComponent,
        DownloadUrl,
        FooterComponent,
        ClientsTableComponent,
        ClientTableWithRequestsComponent,
        ClientOrderWithRequestComponent,
        OrderComponent,
        SingleClientOrdersComponent,
        SingleClientOrderComponent,
        RequestDialogComponent,
        MultiProductDialogComponent,
        UrgentDialogComponent,
        ClientSearchComponent,
        CountySelectPageComponent,
        ContactPageComponent,
        SubjectDialogComponent,
        AboutPageComponent,
        FaqItemsComponent,
        NewsComponent,
        AboutContactPageComponent,
        AboutContactDetailComponent,
        AboutContactFormComponent,
        FaqItemsAdminComponent,
        AboutNewsAdminComponent,
        AboutManualsAdminComponent,
        AboutUserManualsPageComponent,
        CountyNotificationSettingsComponent,
        ClientActivationFormComponent,
        ClientActivationPageComponent,
        RequestTypeSelectorComponent,
        OrderDocumentsComponent,
        AboutNewsPageComponent,
        TopBarComponent,
        ModalOnboardFormComponent,
        ClientFaqPageComponent,
        ClientContactPageComponent,
        ClientHomePageComponent,
        SidenavComponent,
        Headerv2Component,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        AngularMaterialModule,
        AppRoutingModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule,
        AngularFireAuthModule,
        AngularFirePerformanceModule,
        AngularFireStorageModule,
        AngularFireFunctionsModule,
        MatSortModule,
        MediaWatcherModule,
        ScrollingModule
    ],
    providers: [
        { provide: REGION, useValue: 'europe-west1' },
        ClientForm,
        AddressForm,
        ContactPersonForm,
        ContactPerson,
        DownloadUrl
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
