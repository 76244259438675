<div [formGroup]="contactPersonForm">
    <!--
    <div class="row">
        <div class="input-container"
            [ngClass]="{'invalid': (contactPersonForm.controls.type.touched && contactPersonForm.controls.type.invalid)}">
            <app-error-messages [formControls]="contactPersonForm.controls.type">
            </app-error-messages>
            <select name="type" id="type" formControlName="type" required>
                <option value="STANDARD">Standaard</option>
                <option value="PARTNER">Partner</option>
                <option value="CHILD">Kind</option>
                <option value="PARENT">Ouder</option>
                <option value="ADMINISTRATOR">Beheerder</option>
                <option value="FAMILY_MEMBER">Familielid</option>
                <option value="RELATIVE">Betrekkelijk</option>
                <option value="GUARDIAN">Voogd</option>
                <option value="CAREGIVER">Verzorger</option>
                <option value="CLIENT_SUPPORTER">Klant / ondersteuning</option>
                <option value="FRIEND_ACQUAINTANCE">Vriend / kennis</option>
                <option value="OTHER">Anders</option>
                <option value="FINANCIAL_AUTHORIZED">Financieel geautoriseerd</option>
                <option value="FOSTER_PARENT">Pleegouder</option>
                <option value="HEIR">Erfgenaam</option>
                <option value="GP">Huisarts</option>
            </select>
            <label for="type">Soort</label>
        </div>
    </div>
  -->

  <!--  <div class="row">

        <div class="input-container"
            [ngClass]="{'invalid': (contactPersonForm.controls.initials.touched && contactPersonForm.controls.initials.invalid)}">
            <app-error-messages [formControls]="contactPersonForm.controls.initials">
            </app-error-messages>
            <input #initials type="text"
            (blur)="contactPersonForm.patchValue( {initials: util.formatInitials(initials.value)} )"
            formControlName="initials" name="initials" id="initials" required />
            <label for="contactInitials">Voorletters</label>
        </div>
    </div>  -->

    <div class="row">

        <div class="input-container">
          <app-error-messages [formControls]="contactPersonForm.controls.firstName">
          </app-error-messages>
          <input type="text" formControlName="firstName" name="firstName" id="firstName" />
          <label for="firstName">Voornaam</label>
        </div>

        <div class="input-container prefix">
            <app-error-messages [formControls]="contactPersonForm.controls.prefix">
            </app-error-messages>
            <input type="text" formControlName="prefix" name="prefix" id="prefix" />
            <label for="prefix">Tussenvoegsel(s)</label>
        </div>

        <div class="input-container"
            [ngClass]="{'invalid': (contactPersonForm.controls.lastName.touched && contactPersonForm.controls.lastName.invalid)}">
            <app-error-messages [formControls]="contactPersonForm.controls.lastName">
            </app-error-messages>
            <input type="text" formControlName="lastName" name="lastName" id="lastName" required />
            <label for="lastName">Achternaam</label>
        </div>

    </div>

    <div class="row">

        <div class="input-container"
            [ngClass]="{'invalid': (contactPersonForm.controls.emailAddress.touched && contactPersonForm.controls.emailAddress.invalid)}">
            <app-error-messages marginBottom=true
                [formControls]="contactPersonForm.controls.emailAddress">
            </app-error-messages>
            <input type="email" formControlName="emailAddress" name="emailAddress" id="emailAddress" />
            <label for="emailAddress">Emailadres</label>
        </div>

        <div class="input-container"
            [ngClass]="{'invalid': (contactPersonForm.controls.phoneNumber.touched && contactPersonForm.controls.phoneNumber.invalid)}">
            <app-error-messages marginBottom=true
                [formControls]="contactPersonForm.controls.phoneNumber">
            </app-error-messages>
            <input type="text" formControlName="phoneNumber" name="phoneNumber" required />
            <label matTooltip="Een geldig Nederlands telefoonnummer bestaat uit 10 cijfers.">
                Telefoonnummer <span class="info"><mat-icon>info</mat-icon></span>
            </label>
        </div>

    </div>

</div>
