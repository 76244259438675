import {Injectable} from '@angular/core';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {Observable} from 'rxjs';
import { AngularFirePerformance } from '@angular/fire/compat/performance';
import { trace } from '@angular/fire/compat/performance';


@Injectable({providedIn: 'root'})
export class AddressAutocompleteService {

  private autocompleteAddress$: (data: any) => Observable<any>;

  constructor(
    functions: AngularFireFunctions,
    private performance: AngularFirePerformance
  ) {
    this.autocompleteAddress$ = functions.httpsCallable('autocompleteAddress');
  }

  async autocompleteAddress(postcode: string, houseNumber: string,
    houseNumberAddition?: string): Promise<{[key: string]: any}> {

      return await this.autocompleteAddress$({postcode, houseNumber, houseNumberAddition})
        .pipe(trace('addOrder'))
        .toPromise();
    }
}
