// initials and type are not included in the form but kept as optional because they might be added back

import { Injectable } from '@angular/core';
@Injectable()
export class ContactPerson {
  emailAddress?: string;
  firstName?: string;
  initials?: string;
  lastName: string;
  phoneNumber: string;
  prefix?: string;
  type?: string;
}
