
export class Address {
  city: string;
  houseNumber: number;
  houseNumberAddition?: string;
  postalCode: string;
  street: string;
}


export const formatAddress = (address: Address): string => {
  let formattedAddress = `${address.street} ${address.houseNumber}`;

  if (address.houseNumberAddition) {
    formattedAddress += ` ${address.houseNumberAddition}`;
  }

  if (formattedAddress) {
    formattedAddress = `${formattedAddress},`;
  }

  formattedAddress += ` ${address.postalCode} ${address.city}`;

  return formattedAddress.trim();
}
;
