<form [formGroup]="searchClientForm">
  <div class="inputs">
    <div class="input-container">
      <input type="text" id="client-search-last-name" (keyup)="clearSearchIfEmpty()" placeholder="Achternaam" formControlName="partialLastName"  />
    </div>

    <div class="input-container" *ngIf="useDatePicker === false">
      <input type="text" id="client-search-birthday-text" (keyup)="clearSearchIfEmpty()" placeholder="Geboortedatum (dd-mm-jjjj)" formControlName="birthDate" />
    </div>

    <div class="input-container" *ngIf="useDatePicker === true">
      <input [matDatepicker]="birthDatePicker" id="client-search-birthday-datepicker" placeholder="Geboortedatum" formControlName="birthDate" />
      <mat-datepicker-toggle matSuffix [for]="birthDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #birthDatePicker startView="multi-year"></mat-datepicker>
    </div>

  </div>
  <button class="cta" (click)="emitSearchTerms()">Zoek cliënt</button>
</form>