import {Administrator} from '@app/modules/admin/models/administrator';
import {AdminService, Administrators} from '@app/modules/admin/services/admin.service';
import { TitleService } from '@app/shared/services/title.service';
import {WithUserId} from '@app/shared/models/user';
import {UserRole} from '@app/shared/models/user-role';
import {ErrorService} from '@app/shared/services/error.service';
import {NotificationService} from '@app/shared/services/notification.service';
import {UserService, SignedInUser} from '@app/shared/services/user.service';
import * as util from '@app/shared/util';

import {Component, ViewChild, OnDestroy, OnInit} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import { take } from 'rxjs/operators';


interface AdministratorListingItem {
  userId: string;
  displayName: string;
  emailAddress: string;
  role: UserRole;
};


@Component({
  selector: 'app-administrators-page',
  templateUrl: './administrators-page.component.html',
  styleUrls: ['./administrators-page.component.scss']
})
export class AdministratorsPageComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator, {static: true})
  tablePaginator: MatPaginator;

  adminsTable: MatTableDataSource<any>;
  adminsTableColumns = ['name', 'email', 'role'];

  private administratorsSubscription: Subscription;
  showSpinner = true;

  private userSubscription: Subscription;
  private userId: string;

  constructor(
    private notificationService: NotificationService,
    private adminService: AdminService,
    private errorService: ErrorService,
    private router: Router,
    private userService: UserService,
    private titleService: TitleService
  ) {
    this.userSubscription = this.userService.user$.subscribe({
    next: (user?: SignedInUser) => {
      this.userId = user && user.userId;
    }});
  }

  ngOnInit() {
    this.titleService.setTitle('Beheer');

    this.administratorsSubscription = this.adminService.administrators$
      .pipe(take(1))
      .subscribe({
      next: (administratorsMap: Administrators) => {
        const administrators = util.mapOjectToArray<Administrator>(administratorsMap, 'userId');
        const adminListing = AdministratorsPageComponent.createUserListing(administrators).sort((lhs, rhs) => {
          return lhs.displayName.localeCompare(rhs.displayName);
        });

        this.adminsTable = new MatTableDataSource(adminListing);
        this.adminsTable.paginator = this.tablePaginator;
        this.showSpinner = false;
      },
      error: error => {
        this.errorService.alertAndLog(error, 'Het laden van de beheerders is mislukt.')
          .onClosed(async () => {
            this.router.navigate(['']);
          });
      }
    });
  }

  ngOnDestroy() {
    this.administratorsSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
  }

  filterTable(filterValue: string) {
    this.adminsTable.filter = filterValue.trim().toLowerCase();
  }

  async setAdminOrSuperAdmin(userId: string, displayName: string, role: UserRole, roleDisplayName: string): Promise<void> {
    this.showSpinner = true;

    try {
      await this.adminService.setAdminOrSuperAdmin(userId, role);
      this.notificationService.openSnackBar(`De rol van ${displayName} werd gewijzigd in ${roleDisplayName}`);

    } catch (error) {
      this.errorService.alertAndLog(error, 'De rol van de beheerder kon niet worden gewijzigd.');
    }

    this.showSpinner = false;
  }

  private static createUserListing(users: Array<Administrator & WithUserId>): Array<AdministratorListingItem> {
    return users.map(user => {
      let role;

      if (user.roles[UserRole.ADMIN]) {
        role = UserRole.ADMIN;
      }

      if (user.roles[UserRole.SUPER_ADMIN]) {
        role = UserRole.SUPER_ADMIN;
      }

      return <AdministratorListingItem> {
        userId: user.userId,
        displayName: user.name.displayName,
        emailAddress: user.contactDetails.emailAddress,
        role,
      };
    });
  }

}
