import { WorkHour } from './../../../../shared/models/workHour';
import { Component, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DepotService } from '@app/modules/depot/services/depot.service';
import * as util from '@app/shared/util';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { RequestTypeSelectorComponent } from '@app/modules/client/components/request-type-selector/request-type-selector.component';
import { RequestDialogComponent } from '@app/modules/order/components/request-dialog/request-dialog.component';
import { SignedInUser, UserService } from '@app/shared/services/user.service';
import { OrderService } from '@app/modules/order/services/order.service';
import { DialogService } from '@app/shared/services/dialog.service';
import { ErrorService } from '@app/shared/services/error.service';
import * as msgLog from '@app/shared/util/error-text';
import * as moment from 'moment';
import { CountyService } from '@app/modules/county/services/county.service';
import { County } from '@app/modules/county/models/county';
import { ConfigService } from '@app/shared/services/config.service';
import { UrgentDialogComponent } from '@app/modules/order/components/urgent-dialog/urgent-dialog.component';
import { outsideWorkingkHours } from '@app/shared/util';
import { Config } from '@app/shared/models/config';
import { OrderDocumentsComponent } from '../order-documents/order-documents.component';

@Component({
  selector: 'app-client-order-with-request',
  templateUrl: './client-order-with-request.component.html',
  styleUrls: ['./client-order-with-request.component.scss']
})
export class ClientOrderWithRequestComponent implements OnInit, OnDestroy {
  @Input()
  misaOrder: any;
  user: SignedInUser;
  processing = false;
  county: County;
  workHour: WorkHour;
  showInfoMessage = false;
  isPublicHoliday = false;

  constructor(
    private depotService: DepotService,
    private userService: UserService,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private orderService: OrderService,
    private errorService: ErrorService,
    private countyService: CountyService,
    private configService: ConfigService
  ) {
    this.configService.config$.subscribe({
      next: (config: Config) => {
        this.workHour = config.hours;
      }
    });
   }

  async ngOnInit(): Promise<void> {
    this.user = util.checkObject(await this.userService.getUser());
    this.county = await this.countyService.getCounty(this.user.countyId);
    this.configService.getWorkDayHour().then((data: WorkHour) => {
      this.workHour = data;
      this.showInfoMessage = outsideWorkingkHours(data);
    });
    this.configService.getHolidays().then((holidays: Array<any>) => {
     const findHoliday = holidays.find(holiday => util.datesAreOnSameDay( new Date(), new Date(holiday.seconds  * 1000)));
     if (findHoliday){
        this.isPublicHoliday = true;
        this.showInfoMessage = true;
      }
    });

  }
  ngOnDestroy(): void { }


  openDialog(prd: any) {
    this.showInfoMessage = this.isPublicHoliday || outsideWorkingkHours(this.workHour);
    const dialogSelectorRef = this.dialog.open(RequestTypeSelectorComponent, {
      width: '400px',
      data: { repair: this.userService.canRequestRepair(this.user, this.county), adjustment: this.userService.canRequestAdjustment(this.user, this.county), pickup: this.userService.canRequestPickup(this.user, this.county) }
    });

    dialogSelectorRef.afterClosed().subscribe({
    next: (result) => {
      console.log(result);
      if (this.showInfoMessage && result === 'repair') {
        this.openUrgentDialog(result, prd.id);
      }else if (result && result !== '') {
        this.openRequestDialog(result, prd.id);
      }
    }});
  }

  formatDate(date?: Date) {
    if (!date) {
      return '';
    }
    return moment(date).format('DD-MM-YYYY');
  }

  openRequestDialog(requestType: string, productId: string) {
    const requestDialogRef = this.dialog.open(RequestDialogComponent, {
      width: '80%',
      maxWidth: '750px',
      data: {
        requestType
      }
    });

    requestDialogRef.afterClosed().subscribe({
    next: (result) => {
      if (result && result.action === 'add') {
        this.processing = true;
        switch (requestType) {
          case 'repair':
            this.doRepairRequest(result, this.misaOrder.id, productId);
            break;
          case 'collection':
            this.doCollectionRequest(result, this.misaOrder.id);
            break;
          case 'adjustment':
            this.doAdjustmentRequest(result, this.misaOrder.id, productId);
            break;
          default:
            this.errorService.alertAndLog('Unknown requestType');
            break;
        }
      }
    }});
  }

  doRepairRequest(result: any, misaOrderId: string, productId: string) {
    const remarks = result.categoryForm.controls.remarks.value;

    this.orderService
      .requestItemRepair(this.user.countyId, misaOrderId, productId, remarks)
      .then(() => {
        this.dialogService.openInfoAlert('Reparatieverzoek indienen', 'Uw reparatieverzoek is ontvangen.');
      })
      .then(() => this.reloadData())
      .catch((reason) => {
        this.errorService.alertAndLog(reason);
      })
      .finally(() => {
        this.processing = false;
      });
  }

  doAdjustmentRequest(result: any, misaOrderId: string, productId: string) {
    const remarks = JSON.stringify(result.categoryForm);

    this.orderService
      .requestItemAdjustment(this.user.countyId, misaOrderId, productId, remarks)
      .then(() => {
        this.dialogService.openInfoAlert('Aanpassingsverzoek indienen', 'Uw aanpassingsverzoek is ontvangen.');
      })
      .then(() => this.reloadData())
      .catch((reason) => {
        this.errorService.alertAndLog(reason);
      })
      .finally(() => {
        this.processing = false;
      });
  }

  doCollectionRequest(result: any, misaOrderId: string) {
    const endDate = result.categoryForm.controls.collectionDate.value.toDate();
    const remarks = result.categoryForm.controls.remarks.value ? result.categoryForm.controls.remarks.value : null;
    const endReason = result.categoryForm.controls.endReason.value ? result.categoryForm.controls.endReason.value : null;

    this.orderService
      .endOrder(misaOrderId, endDate, remarks, endReason)
      .then(() => {
        this.dialogService.openInfoAlert('Ophaalverzoek indienen', 'Uw ophaalverzoek is ontvangen.');
      })
      .then(() => this.reloadData())
      .catch((reason) => {
        let message: string;
        message = msgLog.mapErrorTypeToContent(reason.message, 'Het goedkeuren van de offerte is mislukt.');
        this.errorService.alertAndLog(reason, message);
      })
      .finally(() => {
        this.processing = false;
      });
  }

  async reloadData(): Promise<void> {
    const order = await this.orderService.getOrder(this.misaOrder.id, false, true);
    if (order) {
      const prods = [];
      order.products.map(async (product) => {
        const depotItem = await this.depotService.getDepotItem(this.user.countyId, product.id, true);
        const nameSplitted = depotItem.name.split(' - ');
        let formattedDeliveryDate = '';
        const deliveryHistory = order.dateDelivered;
        if (deliveryHistory) {
          formattedDeliveryDate = moment(deliveryHistory).format('DD-MM-YYYY');
        }
        const filteredMaintenanceHistory = depotItem.maintenanceHistory.filter((x) => (x.action === 'REPAIR' || x.action === 'ADJUST') && (x.rawstatus !== 'AWAITING_APPROVAL_DELIVERY' && x.rawstatus !== 'DELIVERED' && x.rawstatus !== 'COMPLETED'));
        const filteredDeployHistory = depotItem.deployHistory.filter((x) => x.action === 'PICKUP_ORDER' && x.rawstatus !== 'COMPLETED');
        const isDownloadEnabled = order.documents.length > 0 ? true : false;

        prods.push({
          ...product,
          filteredMaintenanceHistory,
          filteredDeployHistory,
          depotItem,
          formattedDeliveryDate,
          name: nameSplitted[0],
          category: nameSplitted[1],
          isDownloadEnabled
        });
      });


      const isUserAllowed: boolean =
        order.substate === 'DELIVERED' && (this.userService.canRequestPickup(this.user, this.county) || this.userService.canRequestRepair(this.user, this.county) || this.userService.canRequestAdjustment(this.user, this.county));
      this.misaOrder = {
        ...order,
        id: this.misaOrder.id,
        products: prods,
        isRequestEnabled: isUserAllowed
      };
    }
  }

  openUrgentDialog(requestType: string, productId: string) {
    const urgentDialogRef = this.dialog.open(UrgentDialogComponent, {
      width: '70%',
      maxWidth: '550px',
      data: {
        requestType
      }
    });
    urgentDialogRef.afterClosed().subscribe({
    next: (result) => {
      if (result){
        this.openRequestDialog(requestType, productId);
      }
    }});
  }

  openDocuments(misaOrder: any) {
    console.log('misaOrder:',this.misaOrder);
    console.log(misaOrder);
    let title = misaOrder.products.length > 0 ? misaOrder.products[0].name : '';
    console.log(title);

    this.processing = true;
    const dialogSelectorRef = this.dialog.open(OrderDocumentsComponent, {
      width: '600px',
      data: {
        documents: misaOrder.documents,
        misaOrderId: this.misaOrder.id,
        title,
      }
    });
    this.processing = false;
  }
}
