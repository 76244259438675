
<div class="top-bar" >
    <div class="header-element logged-in-notice"
    *ngIf="(authService.user$) | async as user">
      <span title="Ingelogd als {{user.email}}"><span *ngIf="user.email" class="as-email">{{user.email}}</span></span>
    </div>
    <div class="header-element logged-in" *ngIf="(authService.user$) | async as user">
      <mat-icon class="menu-button login-btn" [matMenuTriggerFor]="menu">arrow_drop_down_sharp</mat-icon>
      <mat-menu #menu="matMenu">
          <button mat-menu-item id="signin-button" routerLink="sign-in" *ngIf="!((authService.user$) | async)">Inloggen</button>
          <button mat-menu-item id="signout-button" (click)="authService.signOut()" *ngIf="(authService.user$) | async">Uitloggen</button>
      </mat-menu>
    </div>
</div>