import { OrderForm } from '@app/shared/forms/order.form';
import { Address } from '@app/shared/models/address';
import { ContactDetails } from '@app/shared/models/contact-details';
import { ContactPerson } from '@app/shared/models/contact-person';
import { Name } from '@app/shared/models/name';
import { Product } from '@app/shared/models/product';
import * as util from '@app/shared/util';

export class NewOrder {
  private client: {
    contactDetails: ContactDetails;
    countyCustomerNumber?: string;
    name: Name;
    birthDate: Date;
    gender: string;
    deceased?: false;
    encryptedSocialSecurityNumber?: string;
    socialSecurityNumber?: string;
  };
  private address: Address;
  private contactPerson?: ContactPerson;
  private product?: Product;
  private products?: Product[]=[];
  private applicantId: string;
  private countyId: string;
  private orderReferenceNumber?: string;
  private remarks?: string;
  private documents?: {
    fileName: string;
    storageFilePath: string;
    type: string;
  }[];

  // ToDo: Move all properties to seperate models with their own constructors
  constructor(orderForm: OrderForm, countyId: string, productSelectionDisabled?: boolean) {
    this.client = {
      name: {
        initials: util.checkString(orderForm.value.client.name.initials),
        firstName: util.checkOptString(orderForm.value.client.name.firstName),
        prefix: util.checkOptString(orderForm.value.client.name.prefix),
        lastName: util.checkOptString(orderForm.value.client.name.lastName),
        partnerNamePrefix: util.checkOptString(orderForm.value.client.name.partnerNamePrefix),
        partnerName: util.checkOptString(orderForm.value.client.name.partnerName),
        nameUsage: util.checkString(orderForm.value.client.name.nameUsage)
      },
      contactDetails: {
        emailAddress: util.checkOptString(orderForm.value.client.contactDetails.emailAddress),
        phoneNumber: util.checkOptString(orderForm.value.client.contactDetails.phoneNumber),
        mobileNumber: util.checkOptString(orderForm.value.client.contactDetails.mobileNumber)
      },
      encryptedSocialSecurityNumber: util.checkOptString(orderForm.value.client.encryptedSocialSecurityNumber),
      socialSecurityNumber: util.checkOptString(orderForm.value.client.socialSecurityNumber),
      countyCustomerNumber: util.checkOptString(orderForm.value.client.countyCustomerNumber),
      birthDate: util.checkDate(orderForm.value.client.birthDate.toDate()),
      gender: util.checkString(orderForm.value.client.gender)
    };

    this.address = {
      street: util.checkString(orderForm.value.client.addresses[0].street),
      houseNumber: util.checkNumber(orderForm.value.client.addresses[0].houseNumber),
      houseNumberAddition: util.checkOptString(orderForm.value.client.addresses[0].houseNumberAddition),
      postalCode: util.checkString(orderForm.value.client.addresses[0].postalCode),
      city: util.checkString(orderForm.value.client.addresses[0].city)
    };
    if (!productSelectionDisabled) {
      /* 
      this.product = {
        alladinId: util.checkOptString(orderForm.value.product.alladinId),
        name: util.checkString(orderForm.value.product.name),
        description: util.checkString(orderForm.value.product.description),
        image: {
          name: util.checkString(orderForm.value.product.image.name),
          storageFilePath: util.checkString(orderForm.value.product.image.storageFilePath)
        }
      };
      */
      console.log(orderForm.value.products);
      orderForm.value.products.forEach(item => {
        this.products.push({
          alladinId: util.checkOptString(item.alladinId),
          name: util.checkString(item.name),
          description: util.checkString(item.description),
          image: {
            name: util.checkString(item.image.name),
            storageFilePath: util.checkString(item.image.storageFilePath)
          }
        })
      });
    
    }else{
        this.products.push({
          alladinId: 'none',
          name: 'none',
          description: 'none',
          image: {
            name: '',
            storageFilePath: ''
          }
        });
    }

    this.countyId = util.checkString(countyId);
    this.orderReferenceNumber = util.checkOptString(orderForm.value.orderReferenceNumber);
    this.remarks = util.checkOptString(orderForm.value.remarks);

    if (orderForm.value.client.contactPersons[0]) {
      this.contactPerson = {
        // commented out because it might be added back in later
        // initials: util.checkString(orderForm.value.client.contactPersons[0].initials),
        firstName: util.checkOptString(orderForm.value.client.contactPersons[0].firstName),
        prefix: util.checkOptString(orderForm.value.client.contactPersons[0].prefix),
        lastName: util.checkString(orderForm.value.client.contactPersons[0].lastName),
        phoneNumber: util.checkString(orderForm.value.client.contactPersons[0].phoneNumber),
        emailAddress: util.checkOptString(orderForm.value.client.contactPersons[0].emailAddress)
      };
    }

    if (orderForm.value.documents) {
      this.documents = [];
      orderForm.value.documents.forEach((document: any) => {
        this.documents.push({
          fileName: util.checkString(document.fileName),
          storageFilePath: util.checkString(document.storageFilePath),
          type: util.checkString(document.type)
        });
      });
    }
  }
}
