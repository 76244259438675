import {Component, ViewChild, OnDestroy, OnInit} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';

import {EstablishmentService} from '@app/modules/establishment/services/establishment.service';
import {Establishment} from '@app/modules/establishment/models/establishment';
import {ErrorService} from '@app/shared/services/error.service';
import { TitleService } from '@app/shared/services/title.service';
import {NotificationService} from '@app/shared/services/notification.service';
import * as util from '@app/shared/util';
import { takeWhile } from 'rxjs/operators';


interface WithEstablishmentId {
  establishmentId: string;
}


@Component({
  selector: 'app-establishments-page',
  templateUrl: './establishments-page.component.html',
  styleUrls: ['./establishments-page.component.scss']
})
export class EstablishmentsPageComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator, {static: true})
  tablePaginator: MatPaginator;

  establishmentsTable: MatTableDataSource<Establishment & WithEstablishmentId>;
  establishmentsTableColumns = ['name', 'address', 'contactPerson', 'edit'];
  showEstablishmentsSpinner = true;

  private establishmentsSubscription: Subscription;
  private establishmentsReceived = false;

  constructor(
    private errorService: ErrorService,
    private establishmentService: EstablishmentService,
    private notificationService: NotificationService,
    private router: Router,
    private titleService: TitleService
  ) {}

  ngOnInit() {
    this.titleService.setTitle('Vestigingen');

    this.establishmentsSubscription = this.establishmentService.establishments$
      .pipe(takeWhile(() => !this.establishmentsReceived))
      .subscribe({
      next: establishmentsMap => {
      const establishments = util.mapOjectToArray(establishmentsMap, 'establishmentId')
        .sort((lhs, rhs) => lhs.name.localeCompare(rhs.name));

      this.establishmentsTable = new MatTableDataSource(establishments);
      this.establishmentsTable.paginator = this.tablePaginator;
      this.showEstablishmentsSpinner = false;

      if (this.establishmentsReceived) {
        this.notificationService.openSnackBar('De lijst met vestigingen is bijgewerkt');
      } else {
        this.establishmentsReceived = true;
      }
    },
    error: error => {
      this.errorService.alertAndLog(error, 'Het laden van de vestigingen is mislukt.').onClosed(async () => {
        this.router.navigate(['']);
      });
    }});
  }

  ngOnDestroy() {
    this.establishmentsSubscription.unsubscribe();
  }

  filterTable(filterValue: string) {
    this.establishmentsTable.filter = filterValue.trim().toLowerCase();
  }
}
