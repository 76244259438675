<div class="general-form-header">
  <h1>Profiel</h1>
</div>

<div class="general-form-container" *ngIf="clientDetails">
  <div class="general-form-subheader">
    <h2>1. Naam</h2>
  </div>

  <div class="row">
    <div class="input-container">
      <span class="read-only-value">
        <span *ngIf="!clientDetails.name.initials">--</span>
        <span *ngIf="clientDetails.name.initials">{{clientDetails.name.initials}}</span>
      </span>
      <label>Voorletters</label>
    </div>

    <div class="input-container">
      <span class="read-only-value">
        <span *ngIf="!clientDetails.name.firstName">--</span>
        <span *ngIf="clientDetails.name.firstName">{{clientDetails.name.firstName}}</span>
      </span>
      <label>Voornaam</label>
    </div>
  </div>

  <div class="row">
    <div class="input-container">
      <span class="read-only-value">
        <span *ngIf="!clientDetails.name.prefix">--</span>
        <span *ngIf="clientDetails.name.prefix">{{clientDetails.name.prefix}}</span>
      </span>
      <label>Tussenvoegsel(s)</label>
    </div>

    <div class="input-container">
      <span class="read-only-value">
        <span *ngIf="!clientDetails.name.lastName">--</span>
        <span *ngIf="clientDetails.name.lastName">{{clientDetails.name.lastName}}</span>
      </span>
      <label>Achternaam (eigen naam)</label>
    </div>
  </div>

  <div class="row">
    <div class="input-container">
      <span class="read-only-value">
        <span *ngIf="!clientDetails.name.partnerNamePrefix">--</span>
        <span *ngIf="clientDetails.name.partnerNamePrefix">{{clientDetails.name.partnerNamePrefix}}</span>
      </span>
      <label>Tussenvoegsels partner</label>
    </div>

    <div class="input-container">
      <span class="read-only-value">
        <span *ngIf="!clientDetails.name.partnerName">--</span>
        <span *ngIf="clientDetails.name.partnerName">{{clientDetails.name.partnerName}}</span>
      </span>
      <label>Achternaam partner</label>
    </div>
  </div>

  <div class="low-row">
    <div class="input-container">
      <span class="read-only-value" [ngSwitch]="clientDetails.name.nameUsage">
        <span *ngSwitchCase="'FAMILY_NAME_ONLY'">Achternaam</span>
        <span *ngSwitchCase="'PARTNER_NAME_ONLY'">Partnernaam</span>
        <span *ngSwitchCase="'PARTNER_NAME_FOLLOWED_BY_FAMILY_NAME'">Partnernaam gevolgd door achternaam</span>
        <span *ngSwitchCase="'FAMILY_NAME_FOLLOWED_BY_PARTNER_NAME'">Achternaam gevolgd door partnernaam</span>
        <span *ngSwitchDefault>{{clientDetails.name.nameUsage || '--'}}</span>
      </span>
      <label>Gebruik partnernaam</label>
    </div>
  </div>

</div>

<div class="general-form-container" *ngIf="clientDetails">
  <div class="general-form-subheader">
    <h2>2. Contactgegevens</h2>
  </div>

  <div class="row">
    <div class="input-container">
      <span class="read-only-value">
        <span *ngIf="!clientDetails.contactDetails.emailAddress">--</span>
        <span *ngIf="clientDetails.contactDetails.emailAddress">{{clientDetails.contactDetails.emailAddress}}</span>
      </span>
      <label>Emailadres</label>
    </div>
  </div>

  <div class="low-row">
    <div class="input-container">
      <span class="read-only-value">
        <span *ngIf="!clientDetails.contactDetails.phoneNumber">--</span>
        <span *ngIf="clientDetails.contactDetails.phoneNumber">{{clientDetails.contactDetails.phoneNumber}}</span>
      </span>
      <label>Telefoonnummer (vast)</label>
    </div>

    <div class="input-container">
      <span class="read-only-value">
        <span *ngIf="!clientDetails.contactDetails.mobileNumber">--</span>
        <span *ngIf="clientDetails.contactDetails.mobileNumber">{{clientDetails.contactDetails.mobileNumber}}</span>
      </span>
      <label>Telefoonnummer (mobiel)</label>
    </div>
  </div>

</div>

<div class="general-form-container" *ngIf="clientDetails">
  <div class="general-form-subheader">
    <h2>3. Persoonsgegevens</h2>
  </div>

  <div class="low-row">
    <div class="input-container">
      <span class="read-only-value">
        <span *ngIf="!clientDetails.birthDate">--</span>
        <span *ngIf="clientDetails.birthDate">{{clientDetails.birthDate}}</span>
      </span>
      <label>Geboortedatum</label>
    </div>

    <div class="input-container">
      <span class="read-only-value" [ngSwitch]="clientDetails.gender">
        <span *ngSwitchCase="'MALE'">Man</span>
        <span *ngSwitchCase="'FEMALE'">Vrouw</span>
        <span *ngSwitchCase="'UNKNOWN'">Onbekend</span>
        <span *ngSwitchDefault>{{clientDetails.gender || '--'}}</span>
      </span>
      <label>Geslacht</label>
    </div>
  </div>
</div>

<div class="general-form-container" *ngIf="clientDetails">
  <div class="general-form-subheader">
    <h2>4. Adres</h2>
  </div>

  <div class="row">
    <div class="input-container">
      <span class="read-only-value">
        <span *ngIf="!clientDetails.address.postalCode">--</span>
        <span *ngIf="clientDetails.address.postalCode">{{clientDetails.address.postalCode}}</span>
      </span>
      <label>Postcode</label>
    </div>

    <div class="input-container">
      <span class="read-only-value">
        <span *ngIf="!clientDetails.address.houseNumber">--</span>
        <span *ngIf="clientDetails.address.houseNumber">
          {{clientDetails.address.houseNumber}}{{clientDetails.address.houseNumberAddition}}
        </span>
      </span>
      <label>Huisnummer</label>
    </div>
  </div>

  <div class="low-row">
    <div class="input-container">
      <span class="read-only-value">
        <span *ngIf="!clientDetails.address.street">--</span>
        <span *ngIf="clientDetails.address.street">{{clientDetails.address.street}}</span>
      </span>
      <label>Straat</label>
    </div>

    <div class="input-container">
      <span class="read-only-value">
        <span *ngIf="!clientDetails.address.city">--</span>
        <span *ngIf="clientDetails.address.city">{{clientDetails.address.city}}</span>
      </span>
      <label>Plaats</label>
    </div>
  </div>
</div>

<div class="general-form-container" *ngIf="clientDetails">
  <div class="general-form-subheader">
    <h2>5. Contactpersoon</h2>
  </div>

  <!--commented out because it might be added back in later -->
  <!--<div class="row">
    <div class="input-container">
      <span class="read-only-value" [ngSwitch]="clientDetails.contactPerson.type">
        <span *ngSwitchCase="'STANDARD'">Standaard</span>
        <span *ngSwitchCase="'PARTNER'">Partner</span>
        <span *ngSwitchCase="'CHILD'">Kind</span>
        <span *ngSwitchCase="'PARENT'">Ouder</span>
        <span *ngSwitchCase="'ADMINISTRATOR'">Beheerder</span>
        <span *ngSwitchCase="'FAMILY_MEMBER'">Familielid</span>
        <span *ngSwitchCase="'RELATIVE'">Betrekkelijk</span>
        <span *ngSwitchCase="'GUARDIAN'">Voogd</span>
        <span *ngSwitchCase="'CAREGIVER'">Verzorger</span>
        <span *ngSwitchCase="'CLIENT_SUPPORTER'">Klant / ondersteuning</span>
        <span *ngSwitchCase="'FRIEND_ACQUAINTANCE'">Vriend / kennis</span>
        <span *ngSwitchCase="'OTHER'">Anders</span>
        <span *ngSwitchCase="'FINANCIAL_AUTHORIZED'">Financieel geautoriseerd</span>
        <span *ngSwitchCase="'FOSTER_PARENT'">Pleegouder</span>
        <span *ngSwitchCase="'HEIR'">Erfgenaam</span>
        <span *ngSwitchCase="'GP'">Huisarts</span>
        <span *ngSwitchDefault>{{clientDetails.contactPerson.type || '--'}}</span>
      </span>
      <label>Soort</label>
    </div>
  </div>-->

  <div class="row">
    <!--commented out because it might be added back in later -->
    <!--
    <div class="input-container">
      <span class="read-only-value">
        <span *ngIf="!clientDetails.contactPerson.initials">--</span>
        <span *ngIf="clientDetails.contactPerson.initials">{{clientDetails.contactPerson.initials}}</span>
      </span>
      <label>Voorletters</label>
    </div>
    -->
    <div class="input-container">
      <span class="read-only-value">
        <span *ngIf="!clientDetails.contactPerson.firstName">--</span>
        <span *ngIf="clientDetails.contactPerson.firstName">{{clientDetails.contactPerson.firstName}}</span>
      </span>
      <label>Voornaam</label>
    </div>
  </div>

  <div class="row">
    <div class="input-container">
      <span class="read-only-value">
        <span *ngIf="!clientDetails.contactPerson.prefix">--</span>
        <span *ngIf="clientDetails.contactPerson.prefix">{{clientDetails.contactPerson.prefix}}</span>
      </span>
      <label>Tussenvoegsel(s)</label>
    </div>

    <div class="input-container">
      <span class="read-only-value">
        <span *ngIf="!clientDetails.contactPerson.lastName">--</span>
        <span *ngIf="clientDetails.contactPerson.lastName">{{clientDetails.contactPerson.lastName}}</span>
      </span>
      <label>Achternaam</label>
    </div>
  </div>


  <div class="low-row">
    <div class="input-container">
      <span class="read-only-value">
        <span *ngIf="!clientDetails.contactPerson.emailAddress">--</span>
        <span *ngIf="clientDetails.contactPerson.emailAddress">{{clientDetails.contactPerson.emailAddress}}</span>
      </span>
      <label>Emailadres</label>
    </div>

    <div class="input-container">
      <span class="read-only-value">
        <span *ngIf="!clientDetails.contactPerson.phoneNumber">--</span>
        <span *ngIf="clientDetails.contactPerson.phoneNumber">{{clientDetails.contactPerson.phoneNumber}}</span>
      </span>
      <label>Telefoonnummer</label>
    </div>
  </div>
</div>


<div id="general-form-actions" [ngClass]="{'fixed': footerFixed}" style="width: 100%;">
  <div class="general-form-actions-container">
    <button class="secondary-cta" routerLink="/">
      Aanvragen
    </button>
  </div>
</div>
<div id="general-form-actions-placeholder" [ngClass]="{'fixed': footerFixed}"></div>

<app-spinner *ngIf="showClientSpinner"></app-spinner>
