<div class="form-container">
  <form [formGroup]="activationForm" (ngSubmit)="onSubmit()">
    <h2>Account activeren</h2>
    <p class="subtitle">
      Heeft u een brief van ons ontvangen? Dan kunt u via onderstaand formulier uw account activeren. Als uw gemeente hier niet tussen
      staat, heeft uw gemeente geen toestemming gegeven voor inzage in de Wmo portal.
    </p>
    <div class="form-group">
      <label class="control-label" for="cbsCode">Gemeente</label>
      <mat-select class="custom-mat-select" formControlName="cbsCode">
        <mat-option *ngFor="let county of countyList" [value]="county.cbsCode">
          {{ county.name }}
        </mat-option>
      </mat-select>
    </div>
    <div class="form-group">
      <label class="control-label" for="misaClientId">Klantnummer</label>
      <input
        autocomplete="off"
        type="number"
        placeholder="123456789"
        class="form-control"
        id="misaClientId"
        formControlName="misaClientId"
      />
    </div>
    <div class="form-group">
      <label class="control-label" for="partialLastName">Achternaam</label>
      <input
        autocomplete="off"
        type="text"
        placeholder="Achternaam"
        class="form-control"
        id="partialLastName"
        formControlName="partialLastName"
      />
    </div>
    <div class="form-group">
      <label class="control-label" for="birthDate">Geboortedatum</label>
      <input
        [matDatepicker]="birthDatePicker"
        id="birthDate"
        placeholder="DD-M-YYYY"
        formControlName="birthDate"
        (click)="birthDatePicker.open()"
        autocomplete="off"
        required
      />
      <mat-datepicker-toggle matSuffix [for]="birthDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #birthDatePicker disabled="false"></mat-datepicker>
    </div>
    <div class="form-group">
      <label class="control-label" for="emailAddress">E-mailadres</label>
      <input
        autocomplete="off"
        type="email"
        placeholder="naam@mail.nl"
        class="form-control"
        id="emailAddress"
        formControlName="emailAddress"
        matTooltip="Wij gebruiken dit e-mailadres voor de activatie e-mail en zullen dit adres ook toevoegen of updaten in uw accountgegevens."
      />
    </div>
    <div class="form-group">
      <input type="checkbox" class="form-control" id="termsAggreed" formControlName="termsAggreed" />
      <label for="termsAggreed"
        >Ik ga akkoord met de
        <a href="https://www.medipoint.nl/over-medipoint/algemene-voorwaarden" target="_blank">algemene voorwaarden</a>.</label
      >
    </div>
    <button type="submit" id="submit-button" class="btn" [disabled]="!activationForm.valid">Activeer account</button>
  </form>
</div>
<app-spinner *ngIf="isFormBusy" loadingMessage=""></app-spinner>
