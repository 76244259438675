import { NotificationService } from './../../../../shared/services/notification.service';
import { LogEntry } from '@google-cloud/logging/build/src/entry';
import { MultiProductDialogComponent } from './../../components/multi-product-dialog/multi-product-dialog.component';
// Libraries
import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, NgZone } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import * as devLog from '@app/shared/util/dev-log';
import * as msgLog from '@app/shared/util/error-text';
import * as _ from 'lodash';

import { filter, map, pairwise, throttleTime } from 'rxjs/operators';
// Components
import { DocumentUploaderComponent } from '@app/modules/order/components/document-uploader/document-uploader.component';

// Models
import { Contract } from '@app/modules/contract/models/contract';
import { NewOrder } from '@app/modules/order/models/new-order';
import { ChangedOrder } from '@app/modules/order/models/changed-order';
import { Order } from '@app/modules/order/models/order';
import { Client } from '@app/shared/models/client';
import { MisaClient } from '@app/shared/models/misaClient';
import { ContactPerson } from '@app/shared/models/contact-person';
import { formatName } from '@app/shared/models/name';
import { County } from '@app/modules/county/models/county';
import { UserRole } from '@app/shared/models/user-role';
import { OrderHistoryItems } from '@app/modules/order/models/order-history-items';

// Forms
import { ContactPersonForm } from '@app/shared/forms/contact-person.form';
import { AddressForm } from '@app/shared/forms/address.form';

// Services
import { OrderService } from '@app/modules/order/services/order.service';
import { ClientService } from '@app/modules/client/services/client.service';
import { UserService, SignedInUser } from '@app/shared/services/user.service';
import { CountyService } from '@app/modules/county/services/county.service';
import { ContractService } from '@app/modules/contract/services/contract.service';
import { TitleService } from '@app/shared/services/title.service';
import { ErrorService } from '@app/shared/services/error.service';
import { DialogService } from '@app/shared/services/dialog.service';
import { OrderHistoryItemsService } from '@app/modules/order/services/order-history-items.service';

// Other
import * as util from '@app/shared/util';
import { OrderForm } from '@app/shared/forms/order.form';
import { Category } from '@app/modules/contract/models/category';
import { ClientSearchQuery } from '@app/shared/models/client-search-query';

import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';
import { ClientForm } from '@app/shared/forms/client.form';
import { ClientFormComponent } from '@app/shared/components/client-form/client-form.component';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';

@Component({
  selector: 'app-order-page',
  templateUrl: './order-page.component.html',
  styleUrls: ['./order-page.component.scss']
})
export class OrderPageComponent implements OnInit, OnDestroy {
  Object = Object;

  @ViewChild(DocumentUploaderComponent) filesForm;

  orderForm = new OrderForm();
  tempOrderId: string;
  loadingMessage = '';
  visible = 'spinner';
  user: SignedInUser;
  county: County;
  contract: Contract;
  orderDate: Date = new Date();
  footerFixed = false;
  misaOrderId: string;
  productId: string;
  productOfExistingOrder: Category;
  isSubmitted: boolean;
  isLoading = false;
  title = '';
  orderHistoryItems: OrderHistoryItems;

  // Clients table
  showClientsSpinner = false;
  clients: any;
  clientsFilter: string;
  clientsMap: any;
  clientsTable: MatTableDataSource<Client>;
  clientsData: any;
  clientsTableColumns = ['select', 'name', 'birthDate'];
  expandedClient: any;
  @ViewChild(MatPaginator, {static: false})
  clientsPaginator: MatPaginator;
  misaClientId: string;
  formatName = formatName;

  orginalClientDetails: any = {};
  @ViewChild('clientFormSection') clientFormSection: ClientFormComponent;

  private orderHistoryItemsSubscription: Subscription;
  public orderHistoryItemsRemarksExpanded: boolean;
  public orderHistoryItemsDocumentsExpanded: boolean;

  public iWmoCountiesEditingOrder = false;

  accordionData: any[] = [];
  selectedProdcut: any[] = [];
  isProductExits = true;

  constructor(
    private clientService: ClientService,
    private dialogService: DialogService,
    private route: ActivatedRoute,
    private router: Router,
    private errorService: ErrorService,
    private userService: UserService,
    private countyService: CountyService,
    private contractService: ContractService,
    private orderService: OrderService,
    private contactPersonForm: ContactPersonForm,
    private titleService: TitleService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private orderHistoryItemsService: OrderHistoryItemsService,
    readonly cd: ChangeDetectorRef,
    private notificationService: NotificationService,
  ) {
    this.tempOrderId = this.orderService.generateTemporaryOrderId();
  }

  async ngOnInit() {
    this.misaOrderId = this.route.snapshot.paramMap.get('misaOrderId');
    this.title = this.misaOrderId ? 'Aanvraag bewerken' : 'Nieuwe aanvraag Wmo-hulpmiddelen';
    this.titleService.setTitle(this.title);
    this.user = await this.userService.getUser();
    const order = this.misaOrderId ? await this.orderService.getOrder(this.misaOrderId) : null;
    this.county = await this.countyService.getCounty(this.user.countyId);
    this.contract = await this.contractService.getContract(this.county.contractId);
    if (this.contract.productSelectionDisabled) {
      this.orderForm.controls.product.disable();
    }
    console.log(this.county);
    console.log(this.contract);
    this.isProductExits = this.contract.productSelectionDisabled ? true : false;
    if (this.contract.categories && this.contract.categories.length > 0 && this.misaOrderId){
      this.isProductExits = false;
    }
    console.log('isProductExits', this.isProductExits);

    this.contract.categories.forEach(item => {
      const tmpItem = _.cloneDeep(item);
      tmpItem.panelOpenState = false;
      if (tmpItem.categories.length === 0){
        tmpItem.selected = false;
        tmpItem.categories.push(tmpItem);
      } else {
        tmpItem.categories.forEach(productItem => {
          productItem.selected = false;
        });
      }
      tmpItem.selectedCount = 0;
      this.accordionData.push(tmpItem);
    });
    this.addCheckboxesToForm();
    this.isLoading = true;

    if (this.misaOrderId) {
      if (this.county.iWMO) {
        this.iWmoCountiesEditingOrder = true;
      }
      this.isSubmitted = true;
      this.loadExistingOrder(order);
    } else {
      this.misaClientId = this.route.snapshot.queryParams.clientId;
      if (this.misaClientId) {
        try {
          const selectedMisaClient = (await this.orderService.getClientOrders(this.misaClientId)).client;
          this.changeMisaClient(selectedMisaClient);
        } catch (error) {
          console.log(`client ${this.misaClientId} info could not be retrieved`, error);
        }
      }
    }

    this.visible = 'form';

    if (this.misaOrderId) {
      this.orderHistoryItemsSubscription = this.orderHistoryItemsService
        .getOrderHistoryItems$(this.misaOrderId)
        .subscribe((orderHistoryItems?: OrderHistoryItems) => {
          this.orderHistoryItems = orderHistoryItems ? orderHistoryItems : null;
        });
    }
  }

  getFormNumber(formNumber: number) {
    return this.isSubmitted ? formNumber - 1 : formNumber;
  }

  ngOnDestroy() {
    if (this.orderHistoryItemsSubscription) {
      this.orderHistoryItemsSubscription.unsubscribe();
    }
  }


  async submitForm(clientDetailsChangeConfirmed?: boolean) {
    let newMisaOrderId: any;
    let changedSections: string[] = [];
    if (!this.county.iWMO) {
      if ((this.misaClientId || this.misaOrderId) && !clientDetailsChangeConfirmed && !this.orderForm.invalid) {
        const clientDetailsChanges = this.getChangesOfClientDetails();
        if (clientDetailsChanges) {
          this.showChangeClientDetailsDialog(clientDetailsChanges);
          return;
        }
      }
    }
    this.visible = 'spinner';
    try {
      if (!this.county.iWMO && this.orderForm.invalid) {
        await util.markFormGroupTouched(this.orderForm);
        util.scrollToFirstError();
        this.dialogService.openErrorAlert(
          'Foutmelding',
          'Nog niet alle gegevens zijn ingevuld. ' + 'Vul de ontbrekende gegevens in en probeer de aanvraag opnieuw te verzenden.'
        );
        return;
      }

      if (this.isSubmitted) {
        changedSections = this.getChangedSections(this.orderForm);

        if (!this.county.iWMO) {
          const clientChanges = this.getChangedSectionsOfClientDetails();
          this.Object.keys(clientChanges).forEach((key) => {
            changedSections.push(key);
          });
        }

        if (changedSections.length === 0) {
          this.dialogService.openInfoAlert('', 'U heeft geen wijziging gemaakt in de aanvraag.');
          return;
        }
        const order = new ChangedOrder(this.orderForm, this.user.countyId, changedSections, this.county.iWMO);
        util.removeNullAndEmptyStringValues(order);
        order.misaOrderId = this.misaOrderId;
        await this.orderService.changeOrder(order);
        try {
          await this.updateOrderHistoryItems(this.misaOrderId);
        } catch (orderHistoryItemsError) {
          devLog.error(`Could not create order history items for ${this.misaOrderId}`, orderHistoryItemsError);
        }
      } else {
        const order = new NewOrder(this.orderForm, this.user.countyId, this.contract.productSelectionDisabled);
        util.removeNullAndEmptyStringValues(order);
        newMisaOrderId = await this.orderService.createOrder(order);
        devLog.info(`new order created: ${newMisaOrderId}`);
        newMisaOrderId.forEach(async item => {
          try {
            await this.updateOrderHistoryItems(item);
          } catch (orderHistoryItemsError) {
            devLog.error(`Could not create order history items for ${item}`, orderHistoryItemsError);
          }
        });
      }

      this.dialogService.openInfoAlert('Aanvraag ontvangen', 'Uw aanvraag is ontvangen.').onClosed(async () => {
        this.router.navigate(['/orders']);
      });
    } catch (error) {
      let message: string;
      message = msgLog.mapErrorTypeToContent(error.message, 'Het plaatsen van de aanvraag is mislukt.');
      this.errorService.alertAndLog(error, message);
    } finally {
      this.visible = 'form';
    }
  }

  getChangedSections(form: UntypedFormGroup): string[] {
    let changedSections: string[] = [];
    for (const control in form.controls) {
      if (form.controls.hasOwnProperty(control)) {
        if (form.controls[control].dirty) {
          switch (control) {
            case 'client':
              break;
            case 'documents':
              changedSections.push('Bestanden');
              break;
            case 'product':
              changedSections.push('Hulpmiddel');
              break;
            default:
              changedSections.push('Overige informatie');
              break;
          }
        }
      }
    }

    changedSections = changedSections.filter((item, pos, self) => {
      return self.indexOf(item) === pos;
    });

    return changedSections;
  }

  createClient(formClient: any): MisaClient {
    const misaClient: MisaClient = {
      addresses: formClient.addresses,
      birthDate: formClient.birthDate,
      contactDetails: formClient.contactDetails,
      contactPersons: formClient.contactPersons,
      countyId: this.userService.user.countyId,
      gender: formClient.gender,
      name: formClient.name,
      roles: {
        [UserRole.CLIENT]: true,
        [UserRole.COUNTY_DEPOT_VIEWER]: false,
        [UserRole.COUNTY_ORDER_VIEWER]: false,
        [UserRole.COUNTY_ORDER_EDITOR]: false,
        [UserRole.COUNTY_ADMIN]: false,
        [UserRole.ADMIN]: false,
        [UserRole.SUPER_ADMIN]: false
      }
    };

    util.removeNullAndEmptyStringValues(misaClient);
    return misaClient;
  }

  changeMisaClient(selectedMisaClient) {
    this.misaClientId = selectedMisaClient.misaClientId;
    this.orderForm.controls.client.reset();

    const contactPersons = this.orderForm.controls.client.get('contactPersons') as UntypedFormArray;
    contactPersons.controls = [];
    if (Object.keys(selectedMisaClient.contactPerson).length > 0) {
      contactPersons.push(this.contactPersonForm.form);
    }

    const selectedMisaClientName = selectedMisaClient.name;
    selectedMisaClientName.nameUsage = selectedMisaClientName.nameUsage ? selectedMisaClientName.nameUsage : 'FAMILY_NAME_ONLY';

    this.orderForm.controls.client.patchValue({
      name: selectedMisaClientName,
      birthDate: moment(selectedMisaClient.birthDate),
      countyCustomerNumber: selectedMisaClient.countyCustomerNumber,
      gender: selectedMisaClient.gender,
      contactDetails: selectedMisaClient.contactDetails,
      addresses: [selectedMisaClient.address],
      contactPersons: [selectedMisaClient.contactPerson],
      encryptedSocialSecurityNumber: selectedMisaClient.encryptedSocialSecurityNumber
    });
    // save original client details to confirm changes if they change
    this.orginalClientDetails = { ...this.orderForm.value.client };

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        clientId: this.misaClientId
      },
      replaceUrl: true
    });
  }

  async loadExistingOrder(order: Order) {
    this.orderForm.reset();
    this.orderForm.patchValue(order);

    this.orderForm.controls.client.get('birthDate').setValue(moment(order.client.birthDate));

    // sets the value to ddl in Naam
    const nameUsageValue = order.client.name.nameUsage ? order.client.name.nameUsage : 'FAMILY_NAME_ONLY';
    this.orderForm.controls.client.get('name').get('nameUsage').setValue(nameUsageValue);

    // populates the form in Adres
    const addressesFormArray = this.orderForm.controls.client.get('addresses') as UntypedFormArray;
    addressesFormArray.removeAt(0);
    const addressesForm: UntypedFormGroup = new AddressForm();
    addressesForm.patchValue(order.client.address);
    addressesFormArray.push(addressesForm);

    // populates the form in Contactpersoon
    const contactPerson = order.client.contactPerson;
    // if no contact person is present, MISA returns an empty object, which is most likely an error on their part
    if (contactPerson && Object.entries(contactPerson).length > 0) {
      const contactPersonsFormArray = this.orderForm.controls.client.get('contactPersons') as UntypedFormArray;
      const contactPersonForm: UntypedFormGroup = new ContactPersonForm(new ContactPerson()).form;
      contactPersonForm.patchValue(contactPerson || {});
      contactPersonsFormArray.push(contactPersonForm);
    }

    // select product from list
    try{
      this.productId = order.products[0].productId.trim();
    }catch (err){
      this.isProductExits = true;

    }

    this.orderForm.get('product').clearValidators();
    this.orderForm.get('product').updateValueAndValidity();
    this.productOfExistingOrder = this.getProductOfExistingOrder(this.contract.categories);

    // save original client details to confirm changes if they change
    this.orginalClientDetails = { ...this.orderForm.value.client };
    if (this.county.iWMO) {
      this.cd.detectChanges();
      this.orderForm.controls.client.disable();
      this.clientFormSection.clientForm.disable();
      this.orderForm.controls.orderReferenceNumber.disable();
      this.orderForm.controls.product.disable();
      this.orderForm.controls.remarks.disable();
    }
  }

  getSubmitButtonText() {
    if (this.misaClientId && this.orderForm.get('client').dirty) {
      return 'Client bijwerken en aanvraag versturen';
    }
    if (this.isSubmitted) {
      return 'Aanvraag bijwerken';
    }
    if (!this.misaClientId) {
      return 'Client aanmaken en aanvraag versturen';
    }
    return 'Aanvraag versturen';
  }

  filterClients(filterValue: string) {
    if (this.clientsTable) {
      this.clientsTable.filter = filterValue.trim().toLowerCase();
    }
  }

  getProductOfExistingOrder(categories): Category {
    let product: Category;
    categories.forEach((category) => {
      if (category.categories && category.categories.length > 0) {
        const subcategory = this.getProductOfExistingOrder(category.categories);
        if (subcategory && subcategory.alladinId === this.productId) {
          product = subcategory;
          return;
        }
      } else if (category.alladinId === this.productId) {
        product = category;
        return;
      }
    });
    return product;
  }

  async searchForClients(clientSearchQuery: ClientSearchQuery) {
    if (clientSearchQuery.partialLastName || clientSearchQuery.birthDate) {
      this.showClientsSpinner = true;
      try {
        const clientsData = await this.clientService.searchClients(clientSearchQuery);
        const clients = [];
        clientsData.forEach((client) => {
          clients.push(client.client);
        });
        this.populateMisaClientsTable(clients);
      } catch (error) {
        this.errorService.alertAndLog(error, 'Het ophalen van de clienten is mislukt');
      } finally {
        this.showClientsSpinner = false;
      }
    }
  }

  populateMisaClientsTable(clients) {
    clients.forEach((client, index) => {
      client.formattedName = formatName(client.name);
      client.formattedBirthDate = client.birthDate ? moment(client.birthDate).format('DD-MM-YYYY') : '';
      client.misaClientId = client.misaClientId;
    });
    clients.sort((lhs, rhs) => lhs.formattedName.localeCompare(rhs.formattedName));
    this.clientsTable = new MatTableDataSource(clients);
    this.clientsTable.paginator = this.clientsPaginator;
  }

  getChangesOfClientDetails(): string[] | null {
    let changes = [];

    const oldDetails = this.orginalClientDetails;
    const newDetails = this.orderForm.value.client;

    // Naam
    changes.push(this.checkForChanges(`Voorletters`, oldDetails.name.initials, newDetails.name.initials));
    changes.push(this.checkForChanges(`Voornaam`, oldDetails.name.firstName, newDetails.name.firstName));
    changes.push(this.checkForChanges(`Tussenvoegsel(s)`, oldDetails.name.prefix, newDetails.name.prefix));
    changes.push(this.checkForChanges(`Achternaam`, oldDetails.name.lastName, newDetails.name.lastName));
    changes.push(this.checkForChanges(`Tussenvoegsels partner`, oldDetails.name.partnerNamePrefix, newDetails.name.partnerNamePrefix));
    changes.push(this.checkForChanges(`Achternaam partner`, oldDetails.name.partnerName, newDetails.name.partnerName));
    changes.push(
      this.checkForChanges(
        `Gebruik partnernaam`,
        this.translateNameUsage(oldDetails.name.nameUsage),
        this.translateNameUsage(newDetails.name.nameUsage)
      )
    );

    // Contactgegevens
    changes.push(this.checkForChanges(`Emailadres`, oldDetails.contactDetails.emailAddress, newDetails.contactDetails.emailAddress));
    changes.push(
      this.checkForChanges(`Telefoonnummer (vast)`, oldDetails.contactDetails.phoneNumber, newDetails.contactDetails.phoneNumber)
    );
    changes.push(
      this.checkForChanges(`Telefoonnummer (mobiel)`, oldDetails.contactDetails.mobileNumber, newDetails.contactDetails.mobileNumber)
    );

    // Persoonsgegevens
    changes.push(this.checkForChanges(`Gemeente relatienummer`, oldDetails.countyCustomerNumber, newDetails.countyCustomerNumber));
    changes.push(this.checkForChanges(`Geboortedatum`, oldDetails.birthDate.format('DD-M-YYYY'), newDetails.birthDate.format('DD-M-YYYY')));
    changes.push(this.checkForChanges(`Geslacht`, oldDetails.gender, newDetails.gender));

    // Adres
    changes.push(this.checkForChanges(`Postcode`, oldDetails.addresses[0].postalCode, newDetails.addresses[0].postalCode));
    changes.push(this.checkForChanges(`Huisnummer`, oldDetails.addresses[0].houseNumber, newDetails.addresses[0].houseNumber));
    changes.push(
      this.checkForChanges(
        `Huisnummer toevoeging`,
        oldDetails.addresses[0].houseNumberAddition,
        newDetails.addresses[0].houseNumberAddition
      )
    );
    changes.push(this.checkForChanges(`Straat`, oldDetails.addresses[0].street, newDetails.addresses[0].street));
    changes.push(this.checkForChanges(`Plaats`, oldDetails.addresses[0].city, newDetails.addresses[0].city));

    // Contactpersoon
    if (oldDetails.contactPersons[0]) {
      changes.push(
        this.checkForChanges(
          `Contactpersoon soort`,
          this.translateContactPersonType(oldDetails.contactPersons[0].type),
          this.translateContactPersonType(newDetails.contactPersons[0].type)
        )
      );
      changes.push(
        this.checkForChanges(`Contactpersoon voorletters`, oldDetails.contactPersons[0].initials, newDetails.contactPersons[0].initials)
      );
      changes.push(
        this.checkForChanges(`Contactpersoon voornaam`, oldDetails.contactPersons[0].firstName, newDetails.contactPersons[0].firstName)
      );
      changes.push(
        this.checkForChanges(`Contactpersoon tussenvoegsel(s)`, oldDetails.contactPersons[0].prefix, newDetails.contactPersons[0].prefix)
      );
      changes.push(
        this.checkForChanges(`Contactpersoon achternaam`, oldDetails.contactPersons[0].lastName, newDetails.contactPersons[0].lastName)
      );
      changes.push(
        this.checkForChanges(
          `Contactpersoon emailadres`,
          oldDetails.contactPersons[0].emailAddress,
          newDetails.contactPersons[0].emailAddress
        )
      );
      changes.push(
        this.checkForChanges(
          `Contactpersoon telefoonnummer`,
          oldDetails.contactPersons[0].phoneNumber,
          newDetails.contactPersons[0].phoneNumber
        )
      );
    }

    // clear empty values
    changes = changes.filter((change) => {
      return change != null;
    });

    return changes.length > 0 ? changes : null;
  }

  getChangedSectionsOfClientDetails(): { [section: string]: string[] } {
    const changes: { [section: string]: string[] } = {};

    const oldDetails = this.orginalClientDetails;
    const newDetails = this.orderForm.value.client;

    // Naam
    changes.Naam = [];

    changes.Naam.push(this.checkForChanges(`Voorletters`, oldDetails.name.initials, newDetails.name.initials));
    changes.Naam.push(this.checkForChanges(`Voornaam`, oldDetails.name.firstName, newDetails.name.firstName));
    changes.Naam.push(this.checkForChanges(`Tussenvoegsel(s)`, oldDetails.name.prefix, newDetails.name.prefix));
    changes.Naam.push(this.checkForChanges(`Achternaam`, oldDetails.name.lastName, newDetails.name.lastName));
    changes.Naam.push(this.checkForChanges(`Tussenvoegsels partner`, oldDetails.name.partnerNamePrefix, newDetails.name.partnerNamePrefix));
    changes.Naam.push(this.checkForChanges(`Achternaam partner`, oldDetails.name.partnerName, newDetails.name.partnerName));
    changes.Naam.push(
      this.checkForChanges(
        `Gebruik partnernaam`,
        this.translateNameUsage(oldDetails.name.nameUsage),
        this.translateNameUsage(newDetails.name.nameUsage)
      )
    );

    // Contactgegevens
    changes.Contactgegevens = [];

    changes.Contactgegevens.push(
      this.checkForChanges(`Emailadres`, oldDetails.contactDetails.emailAddress, newDetails.contactDetails.emailAddress)
    );
    changes.Contactgegevens.push(
      this.checkForChanges(`Telefoonnummer (vast)`, oldDetails.contactDetails.phoneNumber, newDetails.contactDetails.phoneNumber)
    );
    changes.Contactgegevens.push(
      this.checkForChanges(`Telefoonnummer (mobiel)`, oldDetails.contactDetails.mobileNumber, newDetails.contactDetails.mobileNumber)
    );

    // Persoonsgegevens
    changes.Persoonsgegevens = [];
    changes.Persoonsgegevens.push(
      this.checkForChanges(`Gemeente relatienummer`, oldDetails.countyCustomerNumber, newDetails.countyCustomerNumber)
    );
    changes.Persoonsgegevens.push(
      this.checkForChanges(`Geboortedatum`, oldDetails.birthDate.format('DD-M-YYYY'), newDetails.birthDate.format('DD-M-YYYY'))
    );
    changes.Persoonsgegevens.push(this.checkForChanges(`Geslacht`, oldDetails.gender, newDetails.gender));

    // Adres
    changes.Adres = [];
    changes.Adres.push(this.checkForChanges(`Postcode`, oldDetails.addresses[0].postalCode, newDetails.addresses[0].postalCode));
    changes.Adres.push(this.checkForChanges(`Huisnummer`, oldDetails.addresses[0].houseNumber, newDetails.addresses[0].houseNumber));
    changes.Adres.push(
      this.checkForChanges(
        `Huisnummer toevoeging`,
        oldDetails.addresses[0].houseNumberAddition,
        newDetails.addresses[0].houseNumberAddition
      )
    );
    changes.Adres.push(this.checkForChanges(`Straat`, oldDetails.addresses[0].street, newDetails.addresses[0].street));
    changes.Adres.push(this.checkForChanges(`Plaats`, oldDetails.addresses[0].city, newDetails.addresses[0].city));

    // Contactpersoon
    changes.Contactpersoon = [];
    if (oldDetails.contactPersons.length !== newDetails.contactPersons.length) {
      changes.Contactpersoon.push(`Contactpersoon`);
    }

    if (oldDetails.contactPersons[0]) {
      changes.Contactpersoon.push(
        this.checkForChanges(
          `Contactpersoon soort`,
          this.translateContactPersonType(oldDetails.contactPersons[0].type),
          this.translateContactPersonType(newDetails.contactPersons[0].type)
        )
      );
      changes.Contactpersoon.push(
        this.checkForChanges(`Contactpersoon voorletters`, oldDetails.contactPersons[0].initials, newDetails.contactPersons[0].initials)
      );
      changes.Contactpersoon.push(
        this.checkForChanges(`Contactpersoon voornaam`, oldDetails.contactPersons[0].firstName, newDetails.contactPersons[0].firstName)
      );
      changes.Contactpersoon.push(
        this.checkForChanges(`Contactpersoon tussenvoegsel(s)`, oldDetails.contactPersons[0].prefix, newDetails.contactPersons[0].prefix)
      );
      changes.Contactpersoon.push(
        this.checkForChanges(`Contactpersoon achternaam`, oldDetails.contactPersons[0].lastName, newDetails.contactPersons[0].lastName)
      );
      changes.Contactpersoon.push(
        this.checkForChanges(
          `Contactpersoon emailadres`,
          oldDetails.contactPersons[0].emailAddress,
          newDetails.contactPersons[0].emailAddress
        )
      );
      changes.Contactpersoon.push(
        this.checkForChanges(
          `Contactpersoon telefoonnummer`,
          oldDetails.contactPersons[0].phoneNumber,
          newDetails.contactPersons[0].phoneNumber
        )
      );
    }

    this.Object.keys(changes).forEach((key) => {
      if (changes[key].filter((x) => x !== null).length === 0) {
        delete changes[key];
      }
    });

    return changes;
  }

  checkForChanges(valueName: string, oldValue: any, newValue: any): string | null {
    if (!oldValue && !newValue) {
      return null;
    }
    if (oldValue === newValue) {
      return null;
    }
    if (!oldValue) {
      return `${valueName}: ${newValue}`;
    }
    if (!newValue) {
      return `${valueName}: ${oldValue} in niets`;
    }
    return `${valueName}: ${oldValue} in ${newValue}`;
  }

  showChangeClientDetailsDialog(changes: string[]) {
    this.dialogService
      .openConfirmationAlert('Wijzig cliëntgegevens', `Wilt u de volgende gegevens van de cliënt wijzigen?`, changes, true)
      .onClosed(async (response) => {
        if (response === true) {
          this.submitForm(response);
        }
      });
  }

  translateNameUsage(value: string) {
    switch (value) {
      case 'FAMILY_NAME_ONLY':
        return 'Achternaam';
      case 'PARTNER_NAME_ONLY':
        return `Partnernaam`;
      case 'PARTNER_NAME_FOLLOWED_BY_FAMILY_NAME':
        return `Partnernaam gevolgd door achternaam`;
      case 'FAMILY_NAME_FOLLOWED_BY_PARTNER_NAME':
        return `Achternaam gevolgd door partnernaam`;
      default:
        return value;
    }
  }

  translateContactPersonType(value: string) {
    switch (value) {
      case 'STANDARD':
        return 'Standaard';
      case 'PARTNER':
        return 'Partner';
      case 'CHILD':
        return 'Kind';
      case 'PARENT':
        return 'Ouder';
      case 'ADMINISTRATOR':
        return 'Beheerder';
      case 'FAMILY_MEMBER':
        return 'Familielid';
      case 'RELATIVE':
        return 'Betrekkelijk';
      case 'GUARDIAN':
        return 'Voogd';
      case 'CAREGIVER':
        return 'Verzorger';
      case 'CLIENT_SUPPORTER':
        return 'Klant / ondersteuning';
      case 'FRIEND_ACQUAINTANCE':
        return 'Vriend / kennis';
      case 'OTHER':
        return 'Anders';
      case 'FINANCIAL_AUTHORIZED':
        return 'Financieel geautoriseerd';
      case 'FOSTER_PARENT':
        return 'Pleegouder';
      case 'HEIR':
        return 'Erfgenaam';
      case 'GP':
        return 'Huisarts';
      default:
        return value;
    }
  }

  async updateOrderHistoryItems(misaOrderId?: string): Promise<void> {
    if (!misaOrderId) {
      misaOrderId = this.misaOrderId;
    } else {
      await this.orderHistoryItemsService.addCountyId(misaOrderId, this.user.countyId);
    }
    if (this.orderForm.value.remarks) {
      await this.orderHistoryItemsService.addRemark(misaOrderId, this.orderForm.value.remarks);
    }
    if (this.orderForm.value.documents.length > 0) {
      await this.orderHistoryItemsService.addDocuments(misaOrderId, this.orderForm.value.documents);
    }
  }
  async openConfirmDialog(){
    if (this.orderForm.invalid){
      await util.markFormGroupTouched(this.orderForm);
      util.scrollToFirstError();
      this.dialogService.openErrorAlert(
        'Foutmelding',
        'Nog niet alle gegevens zijn ingevuld. ' + 'Vul de ontbrekende gegevens in en probeer de aanvraag opnieuw te verzenden.'
      );

      return;
    }
    if (this.contract.productSelectionDisabled){
      this.submitForm();
    }else if (!this.misaOrderId){
      this.selectedProdcut = [];
      this.accordionData.forEach(item => {
        item.categories.forEach(subItem => {
          if (subItem.selected){
            this.selectedProdcut.push(subItem);
          }
        });
      });
      if (this.selectedProdcut.length === 0){
        this.notificationService.openSnackBar('Selecteer minstens één product');
        return;
      }
      const dialogRef = this.dialog.open(MultiProductDialogComponent, {
        width: '80%',
        maxWidth: '750px',
        data: {
          products: this.selectedProdcut
        }
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result && result.submit) {
          this.orderForm.value.products = result.selected;
          this.submitForm();
        }
      });
    } else {
     this.submitForm();
    }
  }

  private addCheckboxesToForm() {
    this.contract.categories.forEach((item, index) => this.productsFormArray.push(new UntypedFormControl(index)));
  }
  get productsFormArray() {
    return this.orderForm.controls.products as UntypedFormArray;
  }

  changeProduct(product){
    product.selected = !product.selected;
    this.accordionData.forEach(item => {
      const count = item.categories.filter(f => f.selected);
      item.selectedCount = count.length;
    });
  }

}
