import { Validators, UntypedFormGroup, UntypedFormArray, UntypedFormControl, FormBuilder } from '@angular/forms';
import { ClientForm } from './client.form';

export class OrderForm extends UntypedFormGroup {
    constructor() {
        super({
            client: new ClientForm(),
            product: new UntypedFormControl(null), // new ProductForm() does not work,
            products: new UntypedFormArray([]),
            // because the formControlName parameter in HTML must refer to a FormControl and not to a FormGroup
            orderReferenceNumber: new UntypedFormControl(null, [Validators.maxLength(20)]),
            remarks: new UntypedFormControl(null, [Validators.maxLength(1000)]),
            documents: new UntypedFormArray([])
        });
    }
}
