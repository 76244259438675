import { Component, OnInit, OnDestroy } from '@angular/core';
import { Establishment } from '@app/modules/establishment/models/establishment';
import { ConfigService } from '@app/shared/services/config.service';
import { EstablishmentService } from '@app/modules/establishment/services/establishment.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-about-contact-detail',
  templateUrl: './about-contact-detail.component.html',
  styleUrls: ['./about-contact-detail.component.scss']
})
export class AboutContactDetailComponent implements OnInit, OnDestroy {
  private establishmentOfSignedInUserSubscription: Subscription;
  establishmentOfSignedInUser: Establishment;

  constructor(public configService: ConfigService, private establishmentService: EstablishmentService) {}

  ngOnInit() {
    this.establishmentOfSignedInUserSubscription = this.establishmentService.establishmentOfSignedInUser$.subscribe((establishment) => {
      this.establishmentOfSignedInUser = establishment;
    });
  }

  ngOnDestroy() {
    this.establishmentOfSignedInUserSubscription.unsubscribe();
  }

  getContactDetails() {
    const details: string[] = [];
    if (this.establishmentOfSignedInUser) {
      if (this.establishmentOfSignedInUser.contactPerson.phoneNumber) {
        details.push(this.establishmentOfSignedInUser.contactPerson.phoneNumber);
      }
      if (this.establishmentOfSignedInUser.contactPerson.emailAddress) {
        details.push(
          `<a href="mailto:${this.establishmentOfSignedInUser.contactPerson.emailAddress}">${this.establishmentOfSignedInUser.contactPerson.emailAddress}</a>`
        );
      }
    }

    return details.join(' / ');
  }
}
