import * as util from '@app/shared/util';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import * as fileSaver from 'file-saver';
import { trace } from '@angular/fire/compat/performance';

@Injectable({
  providedIn: 'root'
})
export class OrderDocumentService {
  private getDocument$: (data: any) => Observable<any>;
  private getDocumentWithOrder$: (data: any) => Observable<any>;

  constructor(functions: AngularFireFunctions) {
    this.getDocument$ = functions.httpsCallable('getDocument');
    this.getDocumentWithOrder$ = functions.httpsCallable('getDocumentWithOrder');
  }

  async getDocument(misaDocumentId: string): Promise<any> {
    return await this.getDocument$({ misaDocumentId }).pipe(trace('getDocument')).toPromise();
  }

  async getDocumentWithOrderId(misaDocumentId: string, misaOrderId: string): Promise<any> {
    return await this.getDocumentWithOrder$({ misaDocumentId, misaOrderId }).pipe(trace('getDocumentWithOrder')).toPromise();
  }
  async downloadDocumentAsBase64(misaDocumentId: string): Promise<any> {
    const document = await this.getDocument(misaDocumentId);
    const documentName = document.name || 'bestand';
    const documentExtension = '.' + document.fileFormat.toLowerCase();
    const base64Content = util.tryGet(document, 'content');
    return { content: base64Content, type: documentExtension, filename: documentName };
  }

  async downloadDocumentAsFile(misaDocumentId: string): Promise<void> {
    const document = await this.getDocument(misaDocumentId);
    const documentName = document.name || 'bestand';
    const documentExtension = '.' + document.fileFormat.toLowerCase();
    const base64Content = util.tryGet(document, 'content');
    fileSaver.saveAs(this.base64ToBlob(base64Content), documentName + documentExtension);
  }
  async downloadDocumentAsFileWithOrderId(misaDocumentId: string, orderId: string): Promise<void> {
    const documents = await this.getDocumentWithOrderId(misaDocumentId, orderId);
    documents.forEach(doc => {
      if (doc.document.content){
        console.log(doc.document);
        const documentName = doc.document.name || 'bestand';
        const documentExtension = '.' + doc.document.fileFormat.toLowerCase();
        const base64Content = util.tryGet(doc.document, 'content');
        fileSaver.saveAs(this.base64ToBlob(base64Content), documentName + documentExtension);
      }
    });
  }

  private base64ToBlob(base64Data: string): Blob {
    const sliceSize = 512;
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      byteArrays.push(new Uint8Array(byteNumbers));
    }

    return new Blob(byteArrays);
  }
}
