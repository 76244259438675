import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { NewsItem } from '../../models/news-item';
import { NewsService } from '../../services/news.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { NotificationService } from '@app/shared/services/notification.service';
import { DialogService } from '@app/shared/services/dialog.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-about-news-admin',
  templateUrl: './about-news-admin.component.html',
  styleUrls: ['./about-news-admin.component.scss'],
  animations: [
    trigger('rotateIcon', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(-180deg)' })),
      transition('rotated => default', animate('225ms ease-out')),
      transition('default => rotated', animate('225ms ease-in'))
    ])
  ]
})
export class AboutNewsAdminComponent implements OnInit {
  newsItems: NewsItem[] = [];
  activeNewsItem: NewsItem;
  dialogRef: MatDialogRef<any, any>;
  @ViewChild('newsEditor') newsEditor: TemplateRef<any>;
  constructor(
    private newsService: NewsService,
    public dialog: MatDialog,
    private dialogService: DialogService,
    private notificationService: NotificationService
  ) {}

  async ngOnInit(): Promise<void> {
    this.newsItems = await this.newsService.getNewsItems();
  }

  showEditor(item?: NewsItem) {
    if (!item) {
      item = { id: '', newsText: '', newsTitle: '', dateCreated: '' };
    }
    this.activeNewsItem = { ...item };
    this.dialogRef = this.dialog.open(this.newsEditor, {
      width: '80%',
      maxWidth: '750px'
    });
  }

  isFormValid(): boolean {
    return this.activeNewsItem.newsTitle.length > 0 && this.activeNewsItem.newsText.length > 0;
  }

  async saveActiveNews() {
    if (this.isFormValid()) {
      const savedItem: NewsItem = await this.newsService.saveNewsItem(this.activeNewsItem);
      const index = this.newsItems.findIndex((x) => x.id === savedItem.id);
      if (index >= 0) {
        this.newsItems[index] = savedItem;
      } else {
        this.newsItems.push(savedItem);
      }
      this.dialogRef.close();
    } else {
      this.dialogService.openErrorAlert(`Foutmelding`, 'Alle velden zijn verplicht.');
    }
  }

  async deleteActiveNews(itemId: string): Promise<void> {
    try {
      await this.newsService.deleteNewsItem(itemId);
      this.newsItems = this.newsItems.filter((x) => x.id !== itemId);
      this.notificationService.openSnackBar('Vraag is verwijderd');
    } catch (error) {
      this.dialogService.openErrorAlert(`Foutmelding`, 'Fout bij verwijderen van vraag.');
    }
  }
}
