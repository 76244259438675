import { Component, OnInit } from '@angular/core';
import { ManualService } from '../../services/manuals.service';
import { UserManual } from '../../models/user-manual';

@Component({
  selector: 'app-about-user-manuals',
  templateUrl: './about-user-manuals.component.html',
  styleUrls: ['./about-user-manuals.component.scss']
})
export class AboutUserManualsPageComponent implements OnInit {
  userManuals: UserManual[] = [];

  constructor(private manualService: ManualService) {}
  async ngOnInit(): Promise<void> {
    this.userManuals = await this.manualService.getUserManuals();
  }
}
