import { orderDocumentsBucket } from '@app/environment';
import { CloudLogger, CloudLoggingService } from '@app/shared/services/cloud-logging.service';
import { AuthService } from '@app/shared/services/auth.service';
import { DialogService } from '@app/shared/services/dialog.service';
import * as util from '@app/shared/util';

import { Component, Input, forwardRef } from '@angular/core';
// tslint:disable-next-line:max-line-length
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import firebase from 'firebase/compat/app';
import { ViewChild, ElementRef } from '@angular/core';

// declare var environment;

@Component({
  selector: 'app-document-uploader',
  templateUrl: './document-uploader.component.html',
  styleUrls: ['./document-uploader.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DocumentUploaderComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DocumentUploaderComponent),
      multi: true
    }
  ]
})
export class DocumentUploaderComponent implements ControlValueAccessor {

  @Input() filePath;
  @Input() customMetadata;
  @Input() documentsForm;

  allowedFileTypes = ['PNG', 'DOCX', 'PDF', 'JPG', 'JPEG', 'PNG', 'BMP', 'XLS', 'XLSX', 'CSV', 'TXT', 'DAT', 'MSG'];
  maxFileSize = 104857600; // 104,857,600 bytes = 100 megabytes in binary
  errorList = [];
  fileContents = [];

  visible: string;
  loadingMessage: string;

  private cloudLog: CloudLogger;

  storageRef = firebase.app().storage(orderDocumentsBucket).ref();

  @ViewChild('orderFileInput', { read: true }) orderFileInput: ElementRef;

  constructor(
    private authService: AuthService,
    private cloudLoggingService: CloudLoggingService,
    private dialogService: DialogService
  ) {
    this.cloudLog = this.cloudLoggingService.createLogger('file-uploader.component');
  }

  async addFiles(event) {
    if (this.fileContents.length === 0) {

      this.visible = 'spinner';
      this.loadingMessage = 'De bestanden worden geupload';

      this.fileContents = event.target.files;

      try {
        this.verifyUploadedFiles();
        await this.uploadFiles();
      }
      catch (error){
        if (error.message === 'someOrAllFilesAreInvalid') {
          this.dialogService.openErrorAlert('Foutmelding', `Uw bestanden kunnen niet worden geüpload.`, this.errorList);
        }
        else if (error.message === 'fileUploadError') {
          this.dialogService.openErrorAlert('Foutmelding', `Uw bestanden kunnen niet worden geüpload.`);
        }
      }
      finally {
        this.resetFileInput();
        this.visible = 'form';
      }

    }
  }

  verifyUploadedFiles(): void {

    this.errorList = [];
    const totalFiles = this.fileContents.length;
    let filesChecked = 0;

    Object.keys(this.fileContents).forEach((singleFile) => {
      filesChecked++;
      this.checkFileExtension(singleFile);
      this.checkFileSize(singleFile);

      if (filesChecked === totalFiles) {
        if (this.errorList.length > 0) {
          throw new Error('someOrAllFilesAreInvalid');
        }
      }
    });

    return;
  }

  checkFileExtension(singleFile) {
    const fileName = this.fileContents[singleFile].name;
    let fileExtension: any = fileName.split(`.`);
    fileExtension = fileExtension[fileExtension.length - 1].toUpperCase();

    if (this.allowedFileTypes.indexOf(fileExtension) < 0) {
      this.errorList.push(`${this.fileContents[singleFile].name} is een ongeldig bestandstype`);
    }
  }

  checkFileSize(singleFile) {
    if (this.fileContents[singleFile].size > this.maxFileSize) {
      // 1048576 = 1 megabyte
      this.errorList.push(`${this.fileContents[singleFile].name} is groter dan ${this.maxFileSize / 1048576} MB`);
    }
  }

  uploadFiles() {

    return new Promise((resolve, reject) => {

      this.visible = 'spinner';
      this.loadingMessage = 'De bestanden worden geupload';
      let numberOfFilesProcessed = 0;
      const errors = [];

      // ToDo: replace by Promise.all
      Object.keys(this.fileContents).forEach(async (singleFile) => {
        try {
          await this.uploadFile(singleFile);
        }
        catch (error) {
          errors.push(error);
        }

        numberOfFilesProcessed++;
        if (numberOfFilesProcessed === this.fileContents.length) {
          if (errors.length > 0) {
            reject(errors);
          }
          this.cloudLog.info(`Uploaded ${this.fileContents.length} file(s).`);
          resolve(null);
        }
      });

    });
  }

  async uploadFile(singleFile) {
    return new Promise((resolve, reject) => {

      const uploadDate = new Date().getTime();
      const file = this.fileContents[singleFile];
      const originalFileName = file.name;
      const fileName = `${uploadDate}-${originalFileName}`;
      const fullStoragePath = `${this.filePath}/${fileName}`;

      const uploadTask = this.storageRef.child(fullStoragePath).put(file, {
        customMetadata: util.checkOptObject(this.customMetadata) || {}
      });

      let fileExtension: any = originalFileName.split(`.`);
      fileExtension = fileExtension[fileExtension.length - 1].toUpperCase();

      // listen to upload progress
      uploadTask.on('state_changed', (snapshot) => {
        // error checking, don't delete
        switch (uploadTask.snapshot.state) {
          case firebase.storage.TaskState.PAUSED:
            console.log('Upload is paused');
            break;
        }
      },
      (error) => {
        this.cloudLog.error(`Error occured when trying to upload order documents.`);
        reject(error);
      }
      ,
      () => {
        const fileValues = {
          fileName: originalFileName,
          fileFormat: fileExtension,
          storageFilePath: fullStoragePath
        };
        this.addFileValuesToForm(fileValues);
        resolve(null);
      });

    });
  }

  addFileValuesToForm(fileValues){
    const documentForm: UntypedFormGroup = new UntypedFormGroup({
      type: new UntypedFormControl('', [Validators.required]),
      fileName: new UntypedFormControl('', [Validators.required]),
      fileFormat: new UntypedFormControl('', [Validators.required]),
      storageFilePath: new UntypedFormControl('', [Validators.required])
    });
    documentForm.patchValue(fileValues);

    const documentsArray = this.documentsForm as UntypedFormArray;
    documentsArray.push(documentForm);

  }

  removeFile(index) {
    const documentsArray = this.documentsForm as UntypedFormArray;
    documentsArray.removeAt(index);
  }

  resetFileInput() {
    const ipnutHTMLInputElement = document.getElementById('order-files') as HTMLInputElement;
    ipnutHTMLInputElement.value = '';
    this.errorList = [];
    this.fileContents = [];
  }

  public onTouched: any = () => {};

  writeValue(val: any): void {
    // tslint:disable-next-line:no-unused-expression
    val && this.documentsForm.setValue(val, { emitEvent: false });
  }
  registerOnChange(fn: any): void {
    this.documentsForm.valueChanges.subscribe(fn);
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.documentsForm.disable() : this.documentsForm.enable();
  }
  validate(c: AbstractControl): ValidationErrors | null{
    return this.documentsForm.valid ? null : { invalidForm: {valid: false, message: 'filesForm fields are invalid'}};
  }
  markTouched() {
    util.markFormGroupTouched(this.documentsForm);
  }
  reset() {

    this.resetFileInput();
    const documentsArray = this.documentsForm as UntypedFormArray;
    documentsArray.clear();

  }

}
