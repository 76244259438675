import { AlertDialogComponent } from '@app/shared/components/alert-dialog/alert-dialog.component';

import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DialogType } from '@app/shared/models/dialog-type';

@Injectable({providedIn: 'root'})
export class DialogService {

  openDialogRef: any = null;

  constructor(public matDialog: MatDialog) {}

  openInfoAlert(title: string, body: string, messages?: Array<string>, childOverlay?: boolean, closeDialogButtonText?: string): DialogRef {
    return this.openAlertDialog(title, body, messages || [], DialogType.DEFAULT, childOverlay || false, closeDialogButtonText || null);
  }

  openErrorAlert(title: string, body: string, messages?: Array<string>, childOverlay?: boolean, closeDialogButtonText?: string): DialogRef {
    return this.openAlertDialog(title, body, messages || [], DialogType.ERROR, childOverlay || false, closeDialogButtonText || null);
  }

  openConfirmationAlert(title: string, body: string, messages?: Array<string>, childOverlay?: boolean, closeDialogButtonText?: string): DialogRef {
    return this.openAlertDialog(title, body, messages || [], DialogType.CONFIRMATION, childOverlay || false, closeDialogButtonText || null);
  }

  openRemoveConfirmationAlert(title: string, body: string, messages?: Array<string>, childOverlay?: boolean, closeDialogButtonText?: string): DialogRef {
    return this.openAlertDialog(title, body, messages || [], DialogType.CONFIRMATION_REMOVE, childOverlay || false, closeDialogButtonText || null);
  }

  closeDialog() {
    if (this.openDialogRef) {
      this.openDialogRef.close();
      this.openDialogRef = null;
    }
  }

  private openAlertDialog(title: string, body: string, messageList: Array<string>, dialogType: DialogType, childOverlay: boolean, closeDialogButtonText: string): DialogRef {
    this.closeDialog();
    let maxWidth = '750px';
    if (childOverlay === true) {
      maxWidth = '690px';
    }
    this.openDialogRef = this.matDialog.open(AlertDialogComponent, {
      width: '80%',
      maxWidth,
      data: {title, body, messageList, dialogType, closeDialogButtonText}
    });

    return new DialogRef(this.openDialogRef);
  }
}


export class DialogRef {
  constructor(private matDialogRef: any) {}

  onClosed(callback: (response?: boolean) => Promise<void>) {
    this.matDialogRef.afterClosed().subscribe(callback);
  }
}
