<div class="general-form-header">
  <h1>
    Cli&euml;nten
    <span *ngIf="county && county.name"> van gemeente {{county.name}}</span>
  </h1>
</div>

<div class="header-row">
  <app-client-search (outputSearchTerms)="searchForClients($event)" [useDatePicker]="true"></app-client-search>
</div>

<p *ngIf="searchSubmitted === false && searchError === false">Zoek een cliënt door een naam en/of geboortedatum in te vullen.</p>

<p class="text-warning" *ngIf="searchError === true">Er is een fout opgetreden met uw zoekopdracht. Probeer het opnieuw.</p>

<app-clients-with-orders-table *ngIf="searchSubmitted === true" #countyClientsOrders
  startExpanded="true"
  noOrdersMessage="Er zijn geen resultaten gevonden voor uw zoekopdracht."
  errorMessage="De aanvragen konden niet worden opgehaald."
  orderState="countyClients"
  [expandToggle]="false"
  [prefetchedClientsData]="clientsData"
  >
</app-clients-with-orders-table>

<app-spinner *ngIf="showSpinner" loadingMessage=""></app-spinner>
