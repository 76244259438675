<div class="general-form-header">
  <h1>Nieuws</h1>
  <div class="fill"></div>
</div>

<div *ngIf="signedInUser && !signedInUser.isSuperAdmin && !signedInUser.isAdmin">
  <app-news></app-news>
</div>

<ng-container *ngIf="signedInUser && (signedInUser.isSuperAdmin || signedInUser.isAdmin)">
  <app-about-news-admin></app-about-news-admin>
</ng-container>
