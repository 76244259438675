import { UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms';
import { Address } from '@app/shared/models/address';
import * as globals from '@app/shared/globals';
import { Injectable } from '@angular/core';

@Injectable()
export class AddressForm extends UntypedFormGroup {
    constructor() {
        super({
            street: new UntypedFormControl(null, [
                Validators.required,
                Validators.maxLength(30),
                Validators.pattern(globals.lettersAccentsAndBracketsRegex)]),
            houseNumber: new UntypedFormControl(null, [
                Validators.required, Validators.maxLength(10),
                Validators.pattern('[0-9]*')]),
            houseNumberAddition: new UntypedFormControl(null, [
                Validators.maxLength(10),
                Validators.pattern('[0-9a-zA-Z]*')]),
            postalCode: new UntypedFormControl(null, [
                Validators.required,
                Validators.pattern('[0-9]{4}[A-Z]{2}')]),
            city: new UntypedFormControl(null, [
                Validators.required,
                Validators.maxLength(30),
                Validators.pattern(globals.lettersAccentsAndBracketsRegex)])
        });
    }
}
