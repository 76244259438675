<div class="general-form-header">
  <h1>Download Handleiding</h1>
</div>
<div class="general-form-container hero-text">
  Wilt u weten hoe de Medipoint Wmo portal werkt? Dan bieden de handleidingen een uitkomst. De handleidingen beschrijven per rol stap voor
  stap de functionaliteiten van de portal. Bekijk en lees hier de actuele versies:
</div>
<ul>
  <li *ngFor="let manual of userManuals">
    <a href="{{ manual.downloadURL }}" target="_blank">{{ manual.title }}</a>
  </li>
</ul>
