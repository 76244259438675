import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root'
})
export class MultiFactorAuthService {
  constructor() {}
  private appVerifier: firebase.auth.RecaptchaVerifier;

  getPhoneHint(errorResolver){
    let selectedIndex: string;
    for (const key of Object.keys(errorResolver.hints)) {
      if (errorResolver.hints[key].displayName === 'Phone number for 2FA') {
        selectedIndex = key;
      }
    }
    return errorResolver.hints[selectedIndex].phoneNumber;
  }

  initRecaptcha() {
    (window as any).recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-recaptcha', {
      size: 'invisible'
    });
    this.appVerifier = new firebase.auth.RecaptchaVerifier('sign-in-recaptcha');
  }

  async requestSmsCode(errorResolver: firebase.auth.MultiFactorResolver) {
    let selectedIndex: string;
    for (const key of Object.keys(errorResolver.hints)) {
      if (errorResolver.hints[key].displayName === 'Phone number for 2FA') { // this needs to be the same in the backend
        selectedIndex = key;
      }
    }

    const phoneInfoOptions = {
      multiFactorHint: errorResolver.hints[selectedIndex],
      session: errorResolver.session
    };
    const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
    const verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, this.appVerifier);
    return verificationId;
  }

  async verifySmsCode(verificationId, smsCode: string, errorResolver: firebase.auth.MultiFactorResolver) {
    const cred = firebase.auth.PhoneAuthProvider.credential(verificationId, smsCode);
    const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
    await errorResolver.resolveSignIn(multiFactorAssertion);
  }

}
