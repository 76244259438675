import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';


@Injectable({
  providedIn: 'root'
})
export class TitleService {

  private static defaultTitle = 'Medipoint Portal';

  constructor(private title: Title) {}

  setTitle(newTitle?: string): void {
    if (!newTitle) {
      this.title.setTitle(TitleService.defaultTitle);
    } else {
      this.title.setTitle(`${newTitle} - ${TitleService.defaultTitle}`);
    }
  }
}
