import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Contract } from '@app/modules/contract/models/contract';
import { AuthService, AuthUser } from '@app/shared/services/auth.service';
import { CloudLogger, CloudLoggingService } from '@app/shared/services/cloud-logging.service';
import { FirestoreObservableDocument } from '@app/shared/util/firestore-observable-document';
import { FirestoreObservableQuery } from '@app/shared/util/firestore-observable-query';
import * as util from '@app/shared/util';

import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { Observable } from 'rxjs';
import { trace } from '@angular/fire/compat/performance';


export type Contracts = { [key: string]: Contract };


@Injectable({
  providedIn: 'root'
})
export class ContractService {

  private handleContractEmployeesMFA$: (data: any) => Observable<any>;
  private monitorEmployeesMFA$: (data: any) => Observable<any>;

  contracts$: Observable<Contracts>;

  private cloudLog: CloudLogger;
  private perf = firebase.performance();

  constructor(
    functions: AngularFireFunctions,
    private authService: AuthService,
    private cloudLoggingService: CloudLoggingService,
    private firestore: AngularFirestore,
  ) {
    this.handleContractEmployeesMFA$ = functions.httpsCallable('handleContractEmployeesMFA');
    this.monitorEmployeesMFA$ = functions.httpsCallable('monitorEmployeesMFA');

    this.cloudLog = this.cloudLoggingService.createLogger('contract.service');

    const contractsQuery = new FirestoreObservableQuery<Contract>(firestore, {
      collection: 'contracts',
      postQueryMap: (data: any) => {
        return this.convertToModel(data);
      },
      postQueryFilter: (contract: Contract) => {
        return !contract.archived;
      }
    });

    this.contracts$ = contractsQuery.observable$
      .pipe(trace('ContractService.contracts$'));

    this.authService.user$.subscribe((user: AuthUser) => {
      contractsQuery.setCleanup(!user);
    });
  }

  async getContracts(): Promise<Contracts> {
    return util.takeOneAsPromise(this.contracts$);
  }

  getContract$(contractId: string): Observable<Contract> {
    return new FirestoreObservableDocument<Contract>(this.firestore, {
      documentPath: ['contracts', contractId],
      postQueryMap: (data: any) => {
        return this.convertToModel(data);
      }
    }).observable$.pipe(trace('ContractService.getContract$'));
  }

  async getContract(contractId: string): Promise<Contract> {
    return util.takeOneAsPromise(this.getContract$(contractId));
  }

  async createContract(contract: Contract): Promise<string> {
    const trace = this.perf.trace('ContractService.createContract');
    trace.start();

    try {
      // @todo validate input
      const reference = await this.getCollection().add(contract);
      const contractId = reference.id;

      this.cloudLog.info(`Created contract ${contractId}`);
      return contractId;

    } finally {
      trace.stop();
    }
  }

  async updateContract(contractId: string, contract: Contract): Promise<void> {
    const trace = this.perf.trace('ContractService.updateContract');
    trace.start();

    try {
      // @todo validate input
      const reference = this.getReference(contractId);

      if (!(await reference.get().toPromise()).exists) {
        throw new Error('Contract does not exist');
      }

      await reference.set(contract);

      this.cloudLog.info(`Updated contract ${contractId}`);

    } finally {
      trace.stop();
    }
  }

  private getReference(contractId: string): AngularFirestoreDocument<Contract> {
    return this.getCollection().doc(contractId);
  }

  private getCollection(): AngularFirestoreCollection<Contract> {
    return this.firestore.collection<Contract>('contracts');
  }

  private convertToModel(data: any): Contract {
    data.startDate = data.startDate.toDate();
    data.endDate = data.endDate && data.endDate.toDate();

    return data as Contract;
  }

  async handleContractEmployeesMFA(contractId: string, employeeIds: string[], changeType: string): Promise<any> {
    return await this.handleContractEmployeesMFA$({ contractId, changeType, employeeIds })
      .pipe(trace('handleContractEmployeesMFA'))
      .toPromise();
  }

  async monitorEmployeesMFA(contractId: string): Promise<any> {
    return await this.monitorEmployeesMFA$({ contractId })
      .toPromise();
  }
}
