import { DepotItem } from '@app/modules/depot/models/depot-item';
import { DepotService } from '@app/modules/depot/services/depot.service';
import { UserService } from '@app/shared/services/user.service';
import * as util from '@app/shared/util';

import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-depot-item',
  templateUrl: './depot-item.component.html',
  styleUrls: ['./depot-item.component.scss']
})
export class DepotItemComponent implements OnInit {

  @Input()
  itemId: string;

  item?: DepotItem = null;
  hasError = false;

  constructor(
    private depotService: DepotService,
    private userService: UserService,
  ) { }

  async ngOnInit(): Promise<void> {
    const countyId = (await this.userService.getUser()).countyId;

    try {
      this.item = await this.depotService.getDepotItem(countyId, this.itemId);

      this.item.deployHistory = this.item.deployHistory.filter(historyEntry => {
        return historyEntry.action != 'REPROVISION';
      });

      this.item.maintenanceHistory = this.item.maintenanceHistory.filter(historyEntry => {
        return historyEntry.dateAppointment && historyEntry.dateDelivered;
      });

    } catch (error) {
      this.hasError = true;
    }
  }

  getDeployHistoryEntryFormattedDate(deployHistoryEntry: any): string {
    if (
      ['DELIVERY', 'DELIVERY_THIRD_PARTY'].includes(deployHistoryEntry.action)
      && util.isDate(deployHistoryEntry.dateDelivered)
    ) {
      return moment(deployHistoryEntry.dateDelivered).format('DD/MM/YYYY');
    }

    if (
      ['PICKUP_ORDER'].includes(deployHistoryEntry.action)
      && util.isDate(deployHistoryEntry.dateRetrieved)
    ) {
      return moment(deployHistoryEntry.dateRetrieved).format('DD/MM/YYYY');
    }

    return '--';
  }

  translateAction(action?: string): string {
    return {
      'REPAIR': 'Reparatie',
      'DELIVERY': 'Levering',
      'PICKUP_ORDER': 'Ophalen',
      'REPROVISION': 'Levering',
      'DELIVERY_THIRD_PARTY': 'Levering',
      'MAINTENANCE_CONTRACT': 'Onderhoud',
      'ADJUST': 'Aanpassing'
    }[action] || action || '';
  }
}
