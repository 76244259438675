<div class="page-header-container">
    <div class="faq-header-container">
        <h1>Veelgestelde vragen</h1>
    </div>
    <div class="spacer"></div>
    <div class="contact-btn">
      <a class="cta white-btn" [routerLink]="['/client-contact']">Neem contact op</a>
    </div>
  </div>

<div class="container-faq">
    <div class="header-row">
        <h4 class="faq-main-text">Hoe kan ik mijn persoonsgegevens of adres laten wijzigen?</h4>
        <mat-icon class="hover-hand" (click)="onclick(1)">{{expand1?"add":"remove"}} </mat-icon>
    </div>
    <ng-container *ngIf="visible1">
    <div class="content-row">
        <ol>
            <li>Klik in de menubalk op 'contact'</li>
            <li>Klik vervolgens op 'ik wil mijn persoonlijke gegevens laten wijzigen'</li>
            <li>Je ziet een telefoonnummer voor telefonisch contact met Medipoint</li>
            <li>Of je klikt op het e-mailadres om een mail te sturen naar Medipoint. Medipoint neemt zo spoedig mogelijk contact je op.</li>
        </ol>
    </div>
    </ng-container>
</div>

<div class="container-faq">
    <div class="header-row">
        <h4 class="faq-main-text">Mijn Wmo hulpmiddel heeft onderhoud of reparatie nodig, hoe kan ik dat doorgeven?</h4>
        <mat-icon class="hover-hand" (click)="onclick(2)">{{expand2?"add":"remove"}} </mat-icon>
    </div>
    <ng-container *ngIf="visible2">
    <div class="content-row">
        <ol>
            <li>Ga in de menubalk naar 'home'</li>
            <li>Je ziet 2 balken. Klik in de balk 'mijn voorzieningen' op V (pijl wijzend naar beneden)</li>
            <li>Klik op de groene knop 'verzoek indienen'</li>
            <li>Klik op het icoontje van het verzoek dat je in wil dienen</li>
            <li>Ter bevestiging ontvang je per e-mail een bevestigingsmail van het ingediende verzoek. Medipoint neemt zo spoedig mogelijk contact met je op.</li>
            <li>Deze knoppen zijn enkel beschikbaar wanneer je gemeente toestemming heeft gegeven</li>
        </ol>
    </div>
    </ng-container>
</div>

<div class="container-faq">
    <div class="header-row">
        <h4 class="faq-main-text">Ik heb vragen over mijn aanvraag of Wmo hulpmiddel, waar kan ik terecht?</h4>
        <mat-icon class="hover-hand" (click)="onclick(3)">{{expand3?"add":"remove"}} </mat-icon>
    </div>
    <ng-container *ngIf="visible3">
    <div class="content-row">
        <ol>
            <li>Klik in de menubalk op 'contact'</li>
            <li>Klik vervolgens op 'ik heb een vraag over een levering, voorziening of de geleverde service'</li>
            <li>Je ziet een telefoonnummer voor telefonisch contact met Medipoint</li>
            <li>Of je klikt op het e-mailadres om een mail te sturen naar Medipoint. Medipoint neemt zo spoedig mogelijk contact je op.</li>
        </ol>
    </div>
    </ng-container>
</div>

