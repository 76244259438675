import {Component, ViewChild, OnDestroy, OnInit} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';

import {Contract} from '@app/modules/contract/models/contract';
import {ContractService} from '@app/modules/contract/services/contract.service';
import { TitleService } from '@app/shared/services/title.service';
import {ErrorService} from '@app/shared/services/error.service';
import {EstablishmentService} from '@app/modules/establishment/services/establishment.service';
import {NotificationService} from '@app/shared/services/notification.service';
import * as util from '@app/shared/util';
import { takeWhile } from 'rxjs/operators';


@Component({
  selector: 'app-contracts-page',
  templateUrl: './contracts-page.component.html',
  styleUrls: ['./contracts-page.component.scss']
})
export class ContractsPageComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator, {static: true})
  tablePaginator: MatPaginator;

  contractsTable: MatTableDataSource<any>;
  contractsTableColumns = ['name', 'establishmentName', 'status', 'edit'];
  showContractsSpinner = true;

  private contractsSubscription: Subscription;
  private contractsReceived = false;
  private pipeRun = true;

  constructor(
    private contractService: ContractService,
    private errorService: ErrorService,
    private establishmentsService: EstablishmentService,
    private notificationService: NotificationService,
    private router: Router,
    private titleService: TitleService
  ) {}

  ngOnInit() {
    this.titleService.setTitle('Contracten');

    this.contractsSubscription = this.contractService.contracts$
      .pipe(
        takeWhile(() => this.pipeRun)
      )
      .subscribe({
      next: contractsMap => {
      const contracts = util.mapOjectToArray<Contract>(contractsMap, 'contractId')
        .sort((lhs, rhs) => lhs.name.localeCompare(rhs.name));

      contracts.forEach((contract: Contract) => {
        contract['establishment'] = this.establishmentsService.getEstablishment$(contract.establishmentId);
      });

      this.contractsTable = new MatTableDataSource(contracts);
      this.contractsTable.paginator = this.tablePaginator;
      this.showContractsSpinner = false;

      if (this.contractsReceived) {
        this.notificationService.openSnackBar('De lijst met contracten is bijgewerkt');
        this.pipeRun = false;
      } else {
        this.contractsReceived = true;
      }
    },
    error: error => {
      this.errorService.alertAndLog(error, 'Het laden van de contracten is mislukt').onClosed(async () => {
        this.router.navigate(['']);
      });
    }});
  }

  ngOnDestroy() {
    this.contractsSubscription.unsubscribe();
  }

  filterTable(filterValue: string) {
    this.contractsTable.filter = filterValue.trim().toLowerCase();
  }
}
