<div class="general-form-header">
  <h1>Site is in onderhoud</h1>
</div>

<div class="general-form-container">

  <p class="icon-container">
    <i class="material-icons">warning</i>
  </p>

  <p class="text-p">
    Medipoint Portal is momenteel niet beschikbaar vanwege gepland onderhoud.
  </p>

  <p class="text-p">
      Onze excuses voor het ongemak.
  </p>

</div>