import {Administrator} from '@app/modules/admin/models/administrator';
import {Config} from '@app/shared/models/config';
import {UserRole} from '@app/shared/models/user-role';
import {ConfigService} from '@app/shared/services/config.service';
import {OrCombinedFirestoreQuery, FirestoreObservableQuery} from '@app/shared/util/firestore-observable-query';
import {LazyCachedObservable} from '@app/shared/util/observable';
import * as util from '@app/shared/util';

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import { AngularFirePerformance } from '@angular/fire/compat/performance';
import { trace } from '@angular/fire/compat/performance';

export type Administrators = {[key: string]: Administrator};


@Injectable({providedIn: 'root'})
export class AdminService {

  administrators$: Observable<Administrators>;

  private setAdminRoles$: (data: any) => Observable<any>;

  constructor(
    functions: AngularFireFunctions,
    public configService: ConfigService,
    private firestore: AngularFirestore,
  ) {
    this.administrators$ = new LazyCachedObservable<Administrators>({
      createObservable$: this.createObservable.bind(this),
    }).observable$.pipe(trace('AdminService.administrators$'));

    this.setAdminRoles$ = functions.httpsCallable('setAdminRoles');
  }

  async getAdministrators(): Promise<Administrators> {
    return util.takeOneAsPromise(this.administrators$);
  }

  private createObservable(): Observable<Administrators> {
    return new Observable<Administrators>(subscriber => {
      const configSubscription = this.configService.config$.subscribe({
        next: (config: Config) => {
          new OrCombinedFirestoreQuery<Administrator>(
            config.allowedGoogleDomains.map((domain: string) => {
              return new FirestoreObservableQuery(this.firestore, {
                collection: 'users',
                queryFunction: ref => {
                  return ref
                    .where('googleDomain', '==', domain)
                    .where('roles.client', '==', false);
                }
              });
            })
          ).observable$.subscribe(subscriber);
        },
        error: error => {
          subscriber.error(error);
        }
      });

      return () => {
        configSubscription.unsubscribe();
      };
    });
  }

  async setAdminOrSuperAdmin(userId: string, role: string): Promise<void> {
    if (!role) {
      await this.setAdminRoles$({userId, roles: {
        [UserRole.ADMIN]: false,
        [UserRole.SUPER_ADMIN]: false
      }}).pipe(trace('setAdminRoles')).toPromise();

    } else if (role === UserRole.ADMIN) {
      await this.setAdminRoles$({userId, roles: {
        [UserRole.ADMIN]: true,
        [UserRole.SUPER_ADMIN]: false
      }}).pipe(trace('setAdminRoles')).toPromise();

    } else if (role === UserRole.SUPER_ADMIN) {
      await this.setAdminRoles$({userId, roles: {
        [UserRole.ADMIN]: true,
        [UserRole.SUPER_ADMIN]: true
      }}).pipe(trace('setAdminRoles')).toPromise();
    }
  }
};
