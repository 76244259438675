import {
    ValidatorFn,
    AbstractControl
} from '@angular/forms';

export function socialSecurityNumberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const socialSecurityNumber = control.value;

        interface errorInterface {
            pattern?: Object
        }
        let errors: errorInterface = {};

        // create bsn sum to see if it's a multiple of 11
        let socialSecurityNumberSum = 0;

        if (socialSecurityNumber) {
            for (let i = 0; i < 8; i++) {
                socialSecurityNumberSum += (9 - i) * parseInt(socialSecurityNumber.charAt(i));
            }
            socialSecurityNumberSum -= parseInt(socialSecurityNumber.charAt(8));

            // check if bsn value is the correct length
            if (socialSecurityNumber.length < 9) {
                //console.log(`socialSecurtyNumber is invalid - ${socialSecurityNumber} too short`);
                errors.pattern = { value: control.value };
            }

            if (socialSecurityNumber.length > 9) {
                //console.log(`socialSecurityNumber is invalid - ${socialSecurityNumber} too long`);
                errors.pattern = { value: control.value };
            }

            // check if socialSecurityNumberSum is a multiple of 11
            if (socialSecurityNumberSum % 11 !== 0) {
                //console.log(`socialSecurityNumber is invalid  - ${socialSecurityNumberSum} not an 11 multiple`);
                errors.pattern = { value: control.value };
            }
        }

        if(Object.keys(errors).length > 0) {
            return errors;
        } else {
            return null;
        }
    };
}
