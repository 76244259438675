import { AuthService } from '@app/shared/services/auth.service';
import { DialogService } from '@app/shared/services/dialog.service';
import { ErrorService } from '@app/shared/services/error.service';
import { TitleService } from '@app/shared/services/title.service';
import { mustMatch } from '@app/shared/validators/mustMatch.validator';
import * as util from '@app/shared/util';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import firebase from 'firebase/compat/app';
import { UntypedFormBuilder, Validators } from '@angular/forms';


@Component({
  selector: 'app-password-reset-page',
  templateUrl: './password-reset-page.component.html',
  styleUrls: ['./password-reset-page.component.scss']
})
export class PasswordResetPageComponent implements OnInit {

  mode?: string;
  emailAddress?: string;
  oobCode?: string;
  showSpinner = true;

  passwordForm = new UntypedFormBuilder().group({
    'new-password': [null, [Validators.required, Validators.minLength(6)]],
    'confirm-new-password': [null, [Validators.required, Validators.minLength(6)]]
  }, {
    validator: mustMatch('new-password', 'confirm-new-password')
  });

  private auth: any;

  constructor(
    private authService: AuthService,
    private dialogService: DialogService,
    private errorService: ErrorService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: TitleService
  ) {
    this.auth = firebase.auth();
    this.titleService.setTitle('Reset uw wachtwoord');
  }

  async ngOnInit(): Promise<void> {
    this.mode = this.route.snapshot.queryParams.mode;
    this.oobCode = this.route.snapshot.queryParams.oobCode;

    if (this.mode !== 'resetPassword' || !this.oobCode) {
      this.router.navigate(['']);
    }

    try {
      this.emailAddress = await this.auth.verifyPasswordResetCode(this.oobCode);

    } catch (error) {
      this.errorService.alertAndLog(error,
        'De link om het wachtwoord te resetten is verlopen. ' +
        'Probeer opnieuw om uw wachtwoord te resetten door op de login pagina op "Wachtwoord vergeten?" te klikken.')
        .onClosed(async () => {
          this.router.navigate(['']);
        });
    }

    this.showSpinner = false;
  }

  async onSubmit(): Promise<void> {
    if (this.passwordForm.invalid) {
      await util.markFormGroupTouched(this.passwordForm);
      if (this.passwordForm.get('confirm-new-password').errors && this.passwordForm.get('confirm-new-password').errors.mustMatch) {
        this.dialogService.openErrorAlert('Foutmelding', 'De ingevoerde wachwoorden zijn niet hetzelfde.');
      } else {
        this.dialogService.openErrorAlert('Foutmelding', 'De ingevoerde wachwoorden voldoen niet aan de eisen.');
      }
      return;
    }

    this.showSpinner = true;

    try {
      await this.auth.confirmPasswordReset(this.oobCode, this.getNewPassword());

    } catch (error) {
      this.errorService.alertAndLog(error, 'Het wachtwoord kon niet worden gewijzigd.');
      this.showSpinner = false;
      return;
    }

    this.dialogService.openInfoAlert('Wachtwoord gewijzigd', 'Uw wachtwoord is gewijzigd.')
      .onClosed(async () => {
        this.showSpinner = true;
        try {
          await this.authService.signInWithEmailPassword(this.emailAddress, this.getNewPassword());
        }
        catch (error) {
          if (error.code === 'auth/multi-factor-auth-required'){
            this.router.navigate(['sign-in']);
          }
          else {
            this.errorService.alertAndLog(error, 'Inloggen mislukt, probeer opnieuw in te loggen.').onClosed(async () => {
              this.router.navigate(['sign-in']);
              return;
            });
          }
        }
      });
  }

  private getNewPassword(): string {
    return this.passwordForm.get('new-password').value;
  }

}
