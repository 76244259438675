<div class="general-form-header">
  <h1>Contract {{contractForm?.value.name}}</h1>
</div>

<form *ngIf="contractForm" [formGroup]="contractForm">

  <div class="general-form-container">

    <div class="row">

      <!-- Name -->
      <div class="input-container"
        [ngClass]="{'invalid': (contractForm.controls.name.touched && contractForm.controls.name.invalid)}">
        <app-error-messages [formControls]="contractForm.controls.name"></app-error-messages>
        <input type="text" formControlName="name" name="name" id="name" required />
        <label>Naam</label>
      </div>

    </div>

    <div class="row date-row two-dates">

      <!-- Start date -->
      <div class="date-input-container">
        <div class="input-container"
          [ngClass]="{'invalid': (contractForm.controls.startDate.touched && contractForm.controls.startDate.invalid)}">
          <app-error-messages [formControls]="contractForm.controls.startDate"></app-error-messages>
          <input [matDatepicker]="startDatePicker" placeholder="" formControlName="startDate" name="startDate"
            id="startDate" required />
          <label>Startdatum</label>
        </div>
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker startView="month" [startAt]="startDate"></mat-datepicker>
      </div>

      <!-- End date -->
      <div class="date-input-container">
        <div class="input-container">
          <app-error-messages [formControls]="contractForm.controls.endDate"></app-error-messages>
          <input [matDatepicker]="endDatePicker" placeholder="" formControlName="endDate" id="endDate" name="endDate" />
          <label>Einddatum</label>
        </div>
        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker startView="month" [startAt]="endDate"></mat-datepicker>
      </div>

    </div>

    <!-- MFA -->
    <div class="row checkboxes">
      <!-- @TODO check tooltip translation -->
      <div id="mfa-checkbox-container" class="checkbox-container no-margin-bottom" [matTooltip]="contractId ? null : 'Maak het contract aan voordat u dit inschakelt'">
        <input [attr.disabled]="contractId && !mfaStatus ? null : true" (change)="multiFactorAuthCheckboxListener($event)" type="checkbox" name="multiFactorAuth" id="multiFactorAuth" formControlName="multiFactorAuth" />
        <label [class.disabled]="contractId && !mfaStatus ? null : true" for="multiFactorAuth">
          Verificatie in twee stappen afdwingen
          <span *ngIf="mfaStatus" class="mfa-updating-message">Twee stappen afdwingen wordt bijgewerkt. Een moment geduld aub... (Medewerkers blijven: {{mfaStatus}})</span>
        </label>
      </div>
    </div>

    <div class="row low-row checkboxes">
      <div class="checkbox-container no-margin-bottom">
        <input type="checkbox" name="productSelectionDisabled" id="productSelectionDisabled" formControlName="productSelectionDisabled" (click)="disableProductSelection($event)"/>
        <label for="productSelectionDisabled">
          Algemene aanvraag/Mobiliteitsbehoefte
        </label>
      </div>
    </div>

  </div>

  <!-- Establishments -->
  <div class="general-form-list">

    <div class="general-form-list-header">
      <h2 class="required">Medipoint vestiging</h2>
    </div>

    <app-spinner class="absolute" *ngIf="showEstablishmentsSpinner" loadingMessage=""></app-spinner>

    <div class="input-icon-container">
      <mat-icon>search</mat-icon>
      <input id="establishments-search-input" matInput (keyup)="filterEstablishments($event.target.value)" placeholder="Zoek" type="text" />
    </div>

    <table class="form-mat-table" id="establishments-table"
      [ngClass]="{'invalid': (contractForm.controls.establishmentId.touched && contractForm.controls.establishmentId.invalid)}"
      mat-table [dataSource]="establishments">

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell class="radio-checkbox-cell" *matHeaderCellDef></th>
        <td mat-cell class="radio-checkbox-cell" *matCellDef="let row">
          <input type="radio" name="establishmentId" id="{{row.establishmentId}}" formControlName="establishmentId"
            value="{{row.establishmentId}}" />
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Naam </th>
        <td mat-cell *matCellDef="let element"> <label for="{{element.establishmentId}}">{{element.name}}</label> </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="establishmentsTableColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: establishmentsTableColumns;"></tr>
    </table>

    <mat-paginator #establishmentsPaginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

  </div>

  <!-- Counties -->
  <div class="general-form-list" *ngIf="contractId">

    <div class="general-form-list-header">
      <h2>Gemeentes</h2>
    </div>

    <app-spinner class="absolute" *ngIf="showCountiesSpinner" loadingMessage=""></app-spinner>

    <div class="input-icon-container">
      <mat-icon>search</mat-icon>
      <input id="counties-search-input" matInput (keyup)="filterCounties($event.target.value)" placeholder="Zoek" type="text" />
    </div>

    <table class="form-mat-table" id="counties-table" mat-table [dataSource]="counties">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Naam </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="countiesTableColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: countiesTableColumns;"></tr>

    </table>

    <mat-paginator #countiesPaginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

  </div>

  <!-- Product categories -->
  <div class="general-form-list">

    <div class="general-form-list-header">
      <h2>Producten</h2>
    </div>

    <ng-template #recursiveList let-categories let-prefix="prefix">
      <div class="product-categories">

        <!-- Category -->
        <div class="product-categories-row"
          [attr.data-alladinId]="category.get('alladinId').value"
          [class.category]="category.get('type').value === 'category'"
          [class.product]="category.get('type').value === 'product'"
          [class.expanded]="category.expanded"
          [formGroup]="contractForm.get(prefix + categoryIndex)"
          *ngFor="let category of categories.controls; let categoryIndex = index">

          <span class="line vert"></span>
          <span class="line hor"></span>

          <!-- Category head -->
          <div class="product-category-head" [attr.data-alladinId]="category.get('alladinId').value">

            <div class="icon-container expand-collapse" *ngIf="category.get('type').value === 'category'"
              (click)="category.expanded = !category.expanded">
              <img *ngIf="!category.expanded" src="/assets/images/icon-expand.png" />
              <img *ngIf="category.expanded" src="/assets/images/icon-collapse.png" />
            </div>

            <div class="img" (click)="category.get('type').value === 'category' && category.expanded = !category.expanded">
              <img [src]="(category.get('image').controls.storageFilePath.value | downloadUrl:'contract-images' | async) || ''" />
            </div>

            <div class="product-category-text-and-controls">
              <span (click)="category.get('type').value === 'category' && category.expanded = !category.expanded"
                class="product-category-text" [matTooltip]="category.get('description')?.value">
                {{category.get('alladinId').value + ': ' + category.get('name').value}}
              </span>
              <div class="icon-container move-up-button" [class.disabled]="categoryIndex == 0"
                (click)="categoryIndex == 0 || moveCategory(categories, category, categoryIndex, 'up')">
                <mat-icon>arrow_upward</mat-icon>
              </div>
              <div class="icon-container  move-down-button" [class.disabled]="categoryIndex >= categories.controls.length-1"
                (click)="categoryIndex >= categories.controls.length-1 || moveCategory(categories, category, categoryIndex, 'down')">
                <mat-icon>arrow_downward</mat-icon>
              </div>
              <div class="icon-container" [class.edit-category-button]="category.value.type==='category'"  [class.edit-product-button]="category.value.type==='product'"
                (click)="openCategoryDialog(categories , category, (category.value.type==='product')?'editProduct':'editCategory', categoryIndex)">
                <mat-icon>edit</mat-icon>
              </div>
            </div>

          </div>

          <!-- Category body -->
          <div class="category-body" *ngIf="category.expanded">

            <div class="category-list-body"
              [class.has-items]="category.get('categories')?.controls?.length && category.get('categories')?.controls?.length > 0">

              <div *ngIf="category.get('categories')?.controls?.length">
                <ng-container
                  *ngTemplateOutlet="recursiveList; context:{ $implicit: category.get('categories'), prefix: prefix + categoryIndex + '.categories.'  }">
                </ng-container>
              </div>

              <div class="no-categories-container"
                *ngIf="category.categories && category.categories && categories.length == 0">
                <span>Er zijn geen producten of subcategorien beschikbaar in deze categorie.</span>
              </div>

              <div class="product-categories-button-container">

                <span class="line vert"></span>
                <span class="line hor"></span>

                <button class="small-cta add-category-button"
                  (click)="openCategoryDialog(category.get('categories') , {value: {type: 'category'}}, 'addCategory')">
                  + Categorie toevoegen
                </button>
                <button class="small-cta"
                  (click)="openCategoryDialog(category.get('categories') , {value: {type: 'product'}}, 'addProduct')">
                  + Product toevoegen
                </button>

              </div>

            </div>

          </div>

        </div>

      </div>
    </ng-template>


    <!-- Top categories -->
    <ng-container
      *ngTemplateOutlet="recursiveList; context:{ $implicit: contractForm.get('categories'), prefix: 'categories.' }">
    </ng-container>


    <div class="product-categories-button-container">

      <button class="small-cta add-category-button"
        (click)="openCategoryDialog(contractForm.get('categories') , {value: {type: 'category'}}, 'addCategory')">
        + Categorie toevoegen
      </button>
      <button class="small-cta"
        (click)="openCategoryDialog(contractForm.get('categories') , {value: {type: 'product'}}, 'addProduct')">
        + Product toevoegen
      </button>

    </div>
  </div>
  <!-- Buttons -->
  <div id="general-form-actions" class="product-categories-button-container" style="width: 100%;" [ngClass]="{'fixed': footerFixed}">
    <div class="general-form-actions-container">

      <button class="secondary-cta" routerLink="/contracts">
        Annuleren
      </button>

      <button *ngIf="!contractId" class="cta" (click)="addContract()">
        Contract toevoegen
      </button>

      <button *ngIf="contractId" class="cta" (click)="updateContract()">
        Wijzigingen opslaan
      </button>

    </div>
  </div>

  <div id="general-form-actions-placeholder" [ngClass]="{'fixed': footerFixed}"></div>

</form>

<app-spinner *ngIf="showContractSpinner" loadingMessage="{{loadingMessage}}"></app-spinner>
