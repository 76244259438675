import { Component, OnInit, OnDestroy } from '@angular/core';
import { AttachStream, ContactFormItem } from '@app/modules/about/models/contact-form-item';
import { ContactService } from '@app/modules/about/services/contact.service';
import { Establishment } from '@app/modules/establishment/models/establishment';
import { EstablishmentService } from '@app/modules/establishment/services/establishment.service';
import { ConfigService } from '@app/shared/services/config.service';
import { Subscription } from 'rxjs';




@Component({
  selector: 'app-client-contact-page',
  templateUrl: './client-contact-page.component.html',
  styleUrls: ['./client-contact-page.component.scss']
})
export class ClientContactPageComponent implements OnInit, OnDestroy {
  private establishmentOfSignedInUserSubscription: Subscription;
  establishmentOfSignedInUser: Establishment;

  formData: FormData = new FormData();
  formSent = false;
  attachedFile: File = null;
  attachStream: AttachStream = null;

  public expand1 = false;
  public expand2 = false;
  public expand3 = false;

  constructor(public configService: ConfigService, private establishmentService: EstablishmentService, private contactService: ContactService) {}

  ngOnInit(): void {
    this.establishmentOfSignedInUserSubscription = this.establishmentService.establishmentOfSignedInUser$.subscribe({
    next: (establishment) => {
      this.establishmentOfSignedInUser = establishment;
    }});
    this.formData.append('name', '');
    this.formData.append('email', '');
    this.formData.append('phone', '');
    this.formData.append('question', '');
  }

  ngOnDestroy() {
    this.establishmentOfSignedInUserSubscription.unsubscribe();
  }

  onclick(row) {
    this.expand1 = false;
    this.expand2 = false;
    this.expand3 = false;

    switch (row) {
      case 1:
        this.expand1 = !this.expand1;
        break;

      case 2:
        this.expand2 = !this.expand2;
        break;

      case 3:
        this.expand3 = !this.expand3;
        break;
    }
  }

  getContactEmail() {
    if (this.establishmentOfSignedInUser && this.establishmentOfSignedInUser.contactPerson.emailAddress) {
      return `<a href="mailto:${this.establishmentOfSignedInUser.contactPerson.emailAddress}">${this.establishmentOfSignedInUser.contactPerson.emailAddress}</a>`;
    } else {
      return '-';
    }
  }

  getContactPhone() {
    if (this.establishmentOfSignedInUser && this.establishmentOfSignedInUser.contactPerson.phoneNumber) {
      return this.establishmentOfSignedInUser.contactPerson.phoneNumber;
    } else {
      return '-';
    }
  }

  handleInput(e: Event) {
    const target: HTMLInputElement = e.target as HTMLInputElement;
    this.formData.set(target.name, target.value);
  }

  toBase64 = (file) =>
    new Promise<any>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    })

  async onFileChange(e: Event) {
    const targetElement = e.target as HTMLInputElement;
    if (targetElement.files.length > 0) {
      this.attachedFile = (e.target as HTMLInputElement).files[0];
      let base64Content: string = await this.toBase64(this.attachedFile);
      base64Content = base64Content.replace(`data:${this.attachedFile.type};base64,`, '');
      this.attachStream = {
        type: this.attachedFile.type,
        filename: this.attachedFile.name,
        content: base64Content
      };
    } else {
      this.attachedFile = null;
      this.attachStream = null;
    }
  }

  isEmail(search: string): boolean {
    let serchfind: boolean;
    let regexp = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    serchfind = regexp.test(search);
    return serchfind;
  }

  isFormValid(): boolean {
    let result = true;
    this.formData.forEach((val, key) => {
      if (val === '') {
        result = false;
      }
      if (key === 'email' && !this.isEmail(val.toString())) {
        result = false;
      }
    });
    return result;
  }

  sendForm(e: Event): void {
    const formData: ContactFormItem = {
      name: this.formData.get('name').toString(),
      email: this.formData.get('email').toString(),
      telephone: this.formData.get('phone').toString(),
      content: this.formData.get('question').toString(),
      attachStream: this.attachStream
    };
    this.contactService.sendContactForm(formData);
    this.formSent = true;
  }
}
