<div class="general-form-subheader">
  <h1>Weet je het zeker?</h1>
</div>
<div>
  <h2>Je hebt op dit moment {{selection.selected.length}} producten geselecteerd.</h2>
</div>
<div>
  <div class="row">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="image">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let category">
          <div
              class="icon-container expand-collapse"
              *ngIf="category.type === 'category'"
              (click)="category.expanded = !category.expanded"
            >
              <img *ngIf="!category.expanded" src="/assets/images/icon-expand.png" />
              <img *ngIf="category.expanded" src="/assets/images/icon-collapse.png" />
            </div>
            <div class="img" (click)="category.type === 'category' && (category.expanded = !category.expanded)">
              <img [src]="(category.image.storageFilePath | downloadUrl: 'contract-images' | async) || ''"  width="55" height="64"/>
            </div>
        </td>

      </ng-container>
      <ng-container matColumnDef="number">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let category">
          <span class="product-category-text" [matTooltip]="category.description">{{ category.alladinId }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="detail">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let category">
          <span class="line vert"></span>
          <span class="line hor"></span>
          <span class="line vert-white"></span>
          <!-- Category body -->
          {{ category.name }}
          <div class="category-body" *ngIf="category.expanded">
            <div *ngIf="category.categories">
              <ng-container *ngTemplateOutlet="recursiveList; context: { $implicit: category.categories }"> </ng-container>
            </div>

            <div class="no-categories-container" *ngIf="category.categories && category.categories && categories.length == 0">
              <span>Er zijn geen producten of subcategorien beschikbaar in deze categorie.</span>
            </div>
          </div>

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></tr>
    </table>

  </div>
</div>

<div class="low-row buttons-row">
  <div class="buttons-container">
    <button (click)="submitForm(false)" class="secondary-cta">Annuleren</button>
    <button (click)="submitForm(true)" class="cta">Aanvraag indienen</button>

  </div>
</div>
