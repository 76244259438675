import { ConfigService } from '@app/shared/services/config.service';
import { SignedInUser, UserService } from '@app/shared/services/user.service';
import { CountyService } from '@app/modules/county/services/county.service';
import { County } from '@app/modules/county/models/county';

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, UrlTree, Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class RouteGuardService implements CanActivate {
  constructor(
    private router: Router,
    private configService: ConfigService,
    private userService: UserService,
    private countyService: CountyService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    // if (await this.configService.isSiteUnderMaintenance()) {
    //   return this.handleSiteUnderMaintenance(route);
    // }

    const user = await this.userService.getUser();
    let county: County;
    if (user && user.countyId) {
      county = await this.countyService.getCounty(user.countyId);
    }

    if (user) {
      return this.handleSignedIn(route, user, county);
    } else {
      return this.handleNotSignedIn(route);
    }
  }

  private handleSiteUnderMaintenance(route: ActivatedRouteSnapshot): boolean | UrlTree {
    const urlSegment1 = route.url.length > 0 ? route.url[0].path : '';

    if (urlSegment1 === 'maintenance') {
      return true;
    } else {
      return this.router.parseUrl('maintenance');
    }
  }

  private handleNotSignedIn(route: ActivatedRouteSnapshot): boolean | UrlTree {
    const urlSegment1 = route.url.length > 0 ? route.url[0].path : '';

    if (urlSegment1 === 'sign-in' || urlSegment1 === 'reset-password' || urlSegment1 === 'activeren') {
      return true;
    } else {
      return this.router.parseUrl('sign-in');
    }
  }

  private handleSignedIn(route: ActivatedRouteSnapshot, user: SignedInUser, county?: County): boolean | UrlTree {
    const urlSegment1 = route.url.length > 0 ? route.url[0].path : '';
    const urlSegment2 = route.url.length > 1 ? route.url[1].path : '';


    switch (urlSegment1) {
      case 'contact':
        // @TODO, remove environmentName check once medux confirms that contact form works on prod
        if (user.isSuperAdmin || user.isCountyOrderEditor) {
          return true;
        }
        break;
      case 'administrators':
        if (user.isSuperAdmin) {
          return true;
        }
        break;
      case 'client':
        if (user.isClient && urlSegment2 === user.userId) {
          return true;
        }
        break;
      case 'client-contact':
      case 'client-faq':
        if (user.isClient) {
          return true;
        }
        break;
      case 'uitstaande-voorzieningen':
        if (user.isCountyAdmin || user.isCountyDepotViewer || user.isCountyOrderEditor || user.isCountyOrderViewer || user.isCountyViewer) {
          return true;
        }
        break;
      case 'clients':
        if (user.isCountyOrderViewer || user.isCountyOrderEditor || user.isCountyViewer) {
          return true;
        }
        break;
      case 'contract':
      case 'contracts':
      case 'counties':
        if (user.isAdmin) {
          return true;
        }
        break;
      case 'county-select':
        if (user.isCountyAdmin || user.isCountyDepotViewer || user.isCountyOrderEditor || user.isCountyOrderViewer || user.isCountyViewer) {
          return true;
        }
        break;
      case 'about':
        if (
          user.isSuperAdmin ||
          user.isAdmin ||
          user.isCountyAdmin ||
          user.isCountyDepotViewer ||
          user.isCountyOrderEditor ||
          user.isCountyOrderViewer ||
          user.isCountyViewer
        ) {
          return true;
        }
        break;
      case 'county':
        if (user.isCountyAdmin && urlSegment2 === user.countyId) {
          return true;
        } else if (user.isAdmin) {
          return true;
        }
        break;
      case 'establishment':
      case 'establishments':
        if (user.isAdmin) {
          return true;
        }
        break;
      case 'order':
        if (user.isCountyOrderEditor) {
          // @todo client...?
          return true;
        }
        break;
      case 'orders':
        if (user.isClient || user.isCountyOrderViewer || user.isCountyOrderEditor || user.isCountyViewer || user.isCountyViewer) {
          return true;
        }
        break;
      case 'depot':
        if (user.isCountyDepotViewer) {
          return true;
        }
        break;
      case 'client-home':
      case 'client-faq':
        if (user.isClient) {
          return true;
        }
        break;
    }

    const redirectUrl = this.getRedirectUrl(user, county);
    return redirectUrl ? this.router.parseUrl(redirectUrl) : false;
  }

  private getRedirectUrl(user: SignedInUser, county: County): string {
    if (user.isCountyOrderViewer || user.isCountyOrderEditor || user.isCountyViewer) {
      return 'orders';
    } else if (user.isCountyAdmin) {
      return 'county/' + user.countyId;
    } else if (user.isCountyDepotViewer) {
      return 'county-select';
    } else if (user.isAdmin) {
      return 'contracts';
    } else if (user.isSuperAdmin) {
      return 'administrators';
    } else if (user.isClient && !county.clientViewAccess) {
      return `client/${user.userId}`;
    } else if (user.isClient) {
      return 'client-home';
    }

    return '';
  }
}
