import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { environment } from '@app/environment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MediaWatcherService } from './shared/services/media-watcher';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @ViewChild('matDrawer', { static: true }) matDrawer: MatDrawer;
  sideBarOpen = true;

  private unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private mediaWatcherService: MediaWatcherService,
    private changeDetectorRef: ChangeDetectorRef,
    ) {}

  ngOnInit(): void {
    if (environment.production) {
      if (
        window.location.hostname === 'medux-medipoint-portal.web.com' ||
        window.location.hostname === 'medux-medipoint-portal.firebaseapp.com'
      ) {
        window.location.href = 'https://mijnwmo.medipoint.nl/';
      }
    }
    this.mediaWatcherService.onMediaChange$.pipe(takeUntil(this.unsubscribeAll)).subscribe({
    next: ({ matchingAliases }) => {
      this.sideBarOpen  = matchingAliases.includes('lg');
      // Mark for check
      this.changeDetectorRef.markForCheck();
    }});
  }
  sideBarToggler(isOpen) {
    this.sideBarOpen = !this.sideBarOpen;
    if(isOpen) {
      this.sideBarOpen = isOpen;
    }
  }
}
