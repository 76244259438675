<div class="general-form-container">
  <div class="low-row">
    <ul>
      <li *ngFor="let newsItem of newsItems">
        <button (click)="deleteActiveNews(newsItem.id)" class="remove-icon-only-cta large-icon">
          <mat-icon>delete</mat-icon>
        </button>
        <button (click)="showEditor(newsItem)" class="remove-icon-only-cta">
          <mat-icon>edit</mat-icon>
        </button>
        <span>{{ newsItem.dateCreated | date: 'dd-MM-yyyy' }} - {{ newsItem.newsTitle }}</span>
      </li>
    </ul>
  </div>
  <div class="low-row subject-edit-controls">
    <button class="cta" (click)="showEditor()">+ Toevoegen</button>
  </div>
</div>

<ng-template #newsEditor>
  <div class="general-form-subheader">
    <h2>{{ activeNewsItem.id === '' ? 'Nieuw nieuwsbericht' : 'Bewerk nieuwsbericht' }}</h2>
  </div>
  <div class="row">
    <div class="input-container">
      <input type="text" [(ngModel)]="activeNewsItem.newsTitle" required />
      <label>Titel</label>
    </div>
  </div>
  <div class="row">
    <div class="input-container">
      <textarea [(ngModel)]="activeNewsItem.newsText" required></textarea>
      <label>Detail</label>
    </div>
  </div>
  <div class="row">
    <button (click)="saveActiveNews()" [disabled]="!isFormValid()"  class="cta">{{ activeNewsItem.id === '' ? 'Toevoegen' : 'Bewerken' }}</button>
  </div>
</ng-template>
