import { Validators, UntypedFormControl } from '@angular/forms';
import { socialSecurityNumberValidator } from '@app/shared/validators/socialSecurityNumber.validator';

export class SocialSecurityNumberControl {
    socialSecurityNumber = new UntypedFormControl(null, [
        Validators.required,
        socialSecurityNumberValidator(),
        Validators.minLength(9),
        Validators.maxLength(9)
    ]);
}
