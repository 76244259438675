<div class="general-form-header">
  <h1>Overige aanvragen/diensten</h1>
</div>


<ng-container data-test-id="super-admin-content" *ngIf="signedInUser && signedInUser.isSuperAdmin">
  <div class="general-form-container">
    <div class="general-form-subheader">
      <h2>Onderwerpregels</h2>
    </div>

    <div class="low-row">
      <ul>
        <li *ngFor="let subject of subjects" [value]="subject.subjectId">
          <button class="remove-icon-only-cta large-icon" (click)="deleteSubject(subject.subjectId)">
            <mat-icon>delete</mat-icon>
          </button>
          <span>{{ subject.name }}</span>
        </li>
      </ul>
    </div>

    <div class="low-row subject-edit-controls">
      <button (click)="showAddSubjectDialog()" class="cta">+ Onderwerpregel toevoegen</button>
    </div>
  </div>
</ng-container>

<ng-container data-test-id="county-order-editor-content" *ngIf="signedInUser && !signedInUser.isSuperAdmin">
  <form [formGroup]="contactForm">
    <div class="general-form-container">
      <div class="low-row">
        <div
          class="input-container"
          [ngClass]="{
            invalid:
              signedInUser &&
              !signedInUser.isSuperAdmin &&
              contactForm['controls'].subject.touched &&
              contactForm['controls'].subject.invalid
          }"
        >
          <select name="subject" id="subject" formControlName="subject" required>
            <option value="">Maak een keuze</option>
            <option *ngFor="let subject of subjects" [value]="subject.subjectId">{{ subject.name }}</option>
          </select>
          <label for="subject">Selecteer het onderwerp waar je vraag over gaat</label>
        </div>
      </div>
      <div class="low-row">
        <div class="input-container" [ngClass]="{ invalid: contactForm['controls'].name.touched && contactForm['controls'].name.invalid }">
          <input type="text" id="name" name="name" formControlName="name" required />
          <label for="name">Naam</label>
        </div>
      </div>
      <div class="low-row">
        <div
          class="input-container"
          [ngClass]="{ invalid: contactForm['controls'].email.touched && contactForm['controls'].email.invalid }"
        >
          <input type="text" id="email" name="email" formControlName="email" required />
          <label for="email">E-mail adres</label>
        </div>
      </div>
      <div class="low-row">
        <div
          class="input-container"
          [ngClass]="{ invalid: contactForm['controls'].clientDetails.touched && contactForm['controls'].clientDetails.invalid }"
        >
          <input type="text" id="clientDetails" name="clientDetails" placeholder="Naam of geboortedatum" formControlName="clientDetails" />
          <label for="clientDetails">Cliëntgegevens</label>
        </div>
      </div>
      <div class="low-row">
        <div
          class="input-container"
          [ngClass]="{ invalid: contactForm['controls'].phoneNumber.touched && contactForm['controls'].phoneNumber.invalid }"
        >
        <app-error-messages marginBottom="true" [formControls]="contactForm['controls'].phoneNumber"></app-error-messages>
          <input type="text" id="phoneNumber" name="phoneNumber" placeholder="Telefoonnummer consulent" formControlName="phoneNumber" />
          <label for="phoneNumber">Telefoonnummer consulent</label>
        </div>
      </div>
      <div class="low-row">
        <div
          class="input-container"
          [ngClass]="{ invalid: contactForm['controls'].remark.touched && contactForm['controls'].remark.invalid }"
        >
          <textarea id="remark" name="remark" formControlName="remark" placeholder="Schrijf je opmerking op..." required></textarea>
          <label for="remark">Opmerking</label>
        </div>
      </div>
      <div class="low-row">
        <div class="input-container">
          <div class="upload-button">
            <input type="file" name="contact-files" id="contact-files" (change)="addFiles($event)" />
            <label for="contact-files" class="cta">
              <mat-icon>cloud_upload</mat-icon>
              <span *ngIf="attachments.length === 0">Upload bestanden</span>
              <span *ngIf="attachments.length > 0">Upload meer bestanden</span>
            </label>
          </div>
          <div class="low-row attachment-list" *ngIf="attachments.length > 0">
            <ul>
              <li *ngFor="let file of attachments">
                <button (click)="deleteAttachment(file.id)" class="remove-icon-only-cta">
                  <mat-icon>delete</mat-icon>
                </button>
                <span>{{ file.name }}</span>
              </li>
            </ul>
          </div>
          <div class="invalid" *ngIf="uploadError">{{ uploadError }}</div>
          <label for="contact-files">Bestanden</label>
        </div>
      </div>
    </div>
  </form>
</ng-container>

<div id="general-form-actions" [ngClass]="{ fixed: footerFixed }">
  <div class="general-form-actions-container">
    <button class="secondary-cta" routerLink="/">Annuleren</button>
    <button class="cta" (click)="submitForm()" *ngIf="signedInUser && !signedInUser.isSuperAdmin">Verzenden</button>
  </div>
</div>

<app-spinner *ngIf="showSpinner"></app-spinner>
