import {HasObservable, LazyCachedObservable} from '@app/shared/util/observable';
import * as devLog from '@app/shared/util/dev-log';

import {AngularFirestore} from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import {map} from 'rxjs/operators';


export class FirestoreObservableDocumentOptions<T> {
  documentPath: Array<string>;
  postQueryMap?: (documentData: any) => T;
};


export class FirestoreObservableDocument<T> implements HasObservable<T> {

  observable$: Observable<T>;

  private lazyObservable: LazyCachedObservable<T>;

  constructor(
    firestore: AngularFirestore,
    options: FirestoreObservableDocumentOptions<T>
  ) {
    if (!options.postQueryMap) {
      options.postQueryMap = ((value: any) => value);
    }

    this.lazyObservable = new LazyCachedObservable<T>({
      createObservable$: FirestoreObservableDocument.createObservable$.bind(
        FirestoreObservableDocument, firestore, options)
    });

    this.observable$ = this.lazyObservable.observable$;
  }

  setCleanup(enableCleanup: boolean) {
    this.lazyObservable.setCleanUpAfterLastObserver(enableCleanup);
  }

  private static createObservable$<T>(firestore: AngularFirestore,
    options: FirestoreObservableDocumentOptions<T>): Observable<T> {

      const documentPath = options.documentPath.join('/');
      const document = firestore.doc<T>(documentPath);

      return document.valueChanges().pipe(map(data => {
        devLog.info(`[Observable] Got Firestore document (/${documentPath})`);
        return options.postQueryMap(data);
      }));
    }
};
