import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import * as devLog from '@app/shared/util/dev-log';
import { v4 as uuidv4 } from 'uuid';
import { WorkHour } from './models/workHour';

export const sleep = (delayMS: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, delayMS);
  });
};


export const hasValue = (input: any): boolean => {
  return (input != null);
};


export const checkValue = <T = any>(input?: T | null): T => {
  if (hasValue(input)) {
    return input as T;
  } else {
    throw new Error(`Value is null or undefined: ${JSON.stringify(input)}`);
  }
};


export const isBoolean = (input: any): boolean => {
  return (typeof input === 'boolean');
};


export const checkBoolean = (input: any): boolean => {
  if (isBoolean(input)) {
    return input;
  } else {
    throw new Error(`Not a boolean: ${JSON.stringify(input)}`);
  }
};


export const checkOptBoolean = <T = any>(input: T): boolean | T => {
  if (!hasValue(input) || isBoolean(input)) {
    return input;
  } else {
    throw new Error(`Not a boolean: ${JSON.stringify(input)}`);
  }
};


export const isNumber = (input: any): boolean => {
  return (typeof input === 'number' && input === Number(input) && Number.isFinite(input));
};


export const checkNumber = (input: any): number => {
  if (isNumber(input)) {
    return input;
  } else {
    throw new Error(`Not a number: ${JSON.stringify(input)}`);
  }
};


export const checkOptNumber = <T = any>(input: T): number | T => {
  if (!hasValue(input) || isNumber(input)) {
    return input;
  } else {
    throw new Error(`Not a number: ${JSON.stringify(input)}`);
  }
};


export const isString =  (input: any): boolean => {
  return (typeof input === 'string' || input instanceof String);
};


export const checkString = (input: any): string => {
  if (isString(input)) {
    return input;
  } else {
    throw new Error(`Not a string: ${JSON.stringify(input)}`);
  }
};


export const checkOptString = <T = any>(input: T): string | T => {
  if (!hasValue(input) || isString(input)) {
    return input;
  } else {
    throw new Error(`Not a string: ${JSON.stringify(input)}`);
  }
};


export const isArray = (input: any): boolean => {
  return Array.isArray(input);
};


export const checkArray = <T = any>(input: any): Array<T> => {
  if (isArray(input)) {
    return input;
  } else {
    throw new Error(`Not an array: ${JSON.stringify(input)}`);
  }
};


export const checkOptArray = <T = any>(input: Array<T> | T): Array<T> | T => {
  if (!hasValue(input) || isArray(input)) {
    return input;
  } else {
    throw new Error(`Not an array: ${JSON.stringify(input)}`);
  }
};


export const isObject = (input: any): boolean => {
  return (input === Object(input)) && !isArray(input);
};


export const checkObject = <T = any>(input: any): T => {
  if (isObject(input)) {
    return input;
  } else {
    throw new Error(`Not an object: ${JSON.stringify(input)}`);
  }
};


export const checkOptObject = <T = any>(input: any): T => {
  if (!hasValue(input) || isObject(input)) {
    return input;
  } else {
    throw new Error(`Not an object: ${JSON.stringify(input)}`);
  }
};


export const isDate = (input: any): boolean => {
  return (!!input && Object.prototype.toString.call(input) === '[object Date]' && !isNaN(input));
};


export const checkDate = <T = any>(input: any): T => {
  if (isDate(input)) {
    return input;
  } else {
    throw new Error(`Not a date: ${JSON.stringify(input)}`);
  }
};


export const checkOptDate = <T = any>(input: any): T => {
  if (!hasValue(input) || isDate(input)) {
    return input;
  } else {
    throw new Error(`Not a date: ${JSON.stringify(input)}`);
  }
};


export const tryGet = <T = any>(object: any | undefined, propertyPath: string | Array<string>): T => {
  if (Array.isArray(propertyPath)) {
    let result: any = object;
    for (const property of propertyPath) {
      result = tryGet(result, property);
    }
    return result;
  } else {
    if (typeof object !== 'undefined' && object.hasOwnProperty(propertyPath)) {
      const value = object[propertyPath];

      if (typeof value !== 'undefined' && value !== null) {
        return value;
      } else {
        throw ReferenceError(`Property "${propertyPath}" is undefined`);
      }
    } else {
      throw ReferenceError(`No such property: "${propertyPath}"`);
    }
  }
};


export const tryGetBoolean = (object: any | undefined, propertyPath: string | Array<string>): boolean => {
  return checkBoolean(tryGet(object, propertyPath));
};


export const tryGetNumber = (object: any | undefined, propertyPath: string | Array<string>): number => {
  return checkNumber(tryGet(object, propertyPath));
};


export const tryGetString = (object: any | undefined, propertyPath: string | Array<string>): string => {
  return checkString(tryGet(object, propertyPath));
};


export const tryGetArray = <T = any>(object: any | undefined, propertyPath: string | Array<string>): Array<T> => {
  return checkArray<T>(tryGet(object, propertyPath));
};


export const tryGetObject = <T = any>(object: any | undefined, propertyPath: string | Array<string>): T => {
  return checkObject<T>(tryGet(object, propertyPath));
};


export const tryGetDate = (object: any | undefined, propertyPath: string | Array<string>): Date => {
  return checkDate(tryGet(object, propertyPath));
};


export const isoTimestampToDate = (timestamp: string): Date => {
  const date = new Date(checkString(timestamp));
  if (isNaN(date.getTime())) {
    throw new Error(`Argument string must have ISO 8601 format`);
  }
  return date;
};


/**
 * Example:
 *   const input = {
 *     'adam': {'age': 29},
 *     'batman': {'age': 40}
 *   };
 *
 *   const result = mapObjectToArray(input, 'name');
 *
 *   [
 *     {'name': 'adam', 'age': 29},
 *     {'name': 'batman', 'age': 40}
 *   ]
 */
export const mapOjectToArray = <T = { [key: string]: any }>(
  obj: { [key: string]: T }, keyPropertyName: string): Array<T & any> => {
  return Object.keys(obj).map(key => {
    const item = obj[key];
    item[keyPropertyName] = key;
    return item;
  });
};


export const removeProperties = (input: any, shouldRemovePredicate: (input: any) => boolean): void => {
  if (typeof input === 'object') {
    Object.entries(input).forEach(([key, value]) => {
      if (shouldRemovePredicate(value)) {
        if (Array.isArray(input)) {
          input.splice(parseInt(key, 10), 1);
        } else {
          delete input[key];
        }
      } else if (typeof value === 'object') {
        removeProperties(value, shouldRemovePredicate);
      }
    });
  }
};


export const removeNullAndEmptyStringValues = (input: any): void => {
  removeProperties(input, (value: any) => {
    return typeof value === 'undefined' || value === null || value === '';
  });
};


export const markFormGroupTouched = async (formGroup: UntypedFormGroup): Promise<void> => {
  formGroup.markAllAsTouched();
  await sleep(100); // Wait until the form elements are marked as touched and then resolve
};

export const scrollToFirstError = () => {
  const firstElementWithError = document.querySelector(
    'textarea.ng-invalid,' +
    'input.ng-invalid,' +
    'select.ng-invalid,' +
    '.toggle-error-span.invalid,' +
    '.product-error');
  if (firstElementWithError) {
    firstElementWithError.scrollIntoView();
    window.scrollBy(0, -150);
  } else {
    devLog.error('firstElementWithError undefined');
  }
};


/**
 * As opposed to `Observable.toPromise`, this one does not require the observable to `complete` or `error`,
 * but instead takes the first emitted value, then unsubscribes.
 */
export const takeOneAsPromise = async <T>(observable$: Observable<T>): Promise<T> => {
  if(observable$) {
    return observable$.pipe(first()).toPromise();
  }
};


export const reflect = async <T = any>(promise: Promise<T>):
  Promise<{result: T, status: string} | {reason: any, status: string}> => {

    try {
      const result = await promise;
      return {result, status: 'resolved'};

    } catch (reason) {
      return {reason, status: 'rejected'};
    }
  };


export const formatInitials = (initials?: string): string | undefined => {
  if (!isString(initials)) {
    return undefined;
  }

  const initialsWithoutSpacesAndDots = initials.toUpperCase().replace(/\ /g, '').replace(/\./g, '');
  if (initialsWithoutSpacesAndDots) {
    return [...initialsWithoutSpacesAndDots].join('.') + '.';
  }
  return '';
};

export const base64DecodeUnicode = (input: string): string => {
  return decodeURIComponent(atob(input).replace(/(.)/g, (_m, p) => {
    const code = p.charCodeAt(0).toString(16).toUpperCase();
    return '%' + (code.length < 2 ? '0' + code : code);
  }));
};

export const hasValidator = (form: UntypedFormGroup, controlPath: string, validator: string): boolean => {
  const control = form.get(controlPath);
  const validators = control.validator(control);
  return !!(validators && validators.hasOwnProperty(validator));
};

export const convertDateToString = (oldDate: Date): string => {
  const year = oldDate.getFullYear().toString();
  let month = (oldDate.getMonth() + 1).toString();
  if (month.length === 1) {
    month = `0${month}`;
  }
  let day = oldDate.getDate().toString();
  if (day.length === 1) {
    day = `0${day}`;
  }
  return `${year}-${month}-${day}`;
};

export const createId = (): string => {
  return uuidv4();
};

export const outsideWorkingkHours = (workHour: WorkHour): boolean => {
  const hour = new Date();
  if ((hour.getDay() === 6 || hour.getDay() === 0) && workHour){
     return true;
  }
  if (hour.getHours() < workHour.start || hour.getHours() >= workHour.end ){
     return true;
  }
  return false;
};

export const datesAreOnSameDay = (firstDate: Date, second: Date) => {
  if (
    firstDate.getFullYear() === second.getFullYear() &&
    firstDate.getMonth() === second.getMonth() &&
    firstDate.getDate() === second.getDate()
  ) {
    return true;
  }else {
    return false;

  }
};
