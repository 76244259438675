import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ContactFormItem } from '../models/contact-form-item';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  private contactFormService$: (data: any) => Observable<any>;

  constructor(functions: AngularFireFunctions) {
    this.contactFormService$ = functions.httpsCallable('sendContactForm');
  }

  sendContactForm(formData: ContactFormItem): void {
    this.contactFormService$({ ...formData });
  }
}
