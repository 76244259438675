<div class="general-form-header">
  <h1>Depot</h1>
  <button class="cta refresh-button" (click)="load()">
    <mat-icon>refresh</mat-icon> Refresh
  </button>
</div>

<div class="general-form-container">

  <div class="input-icon-container margin-bottom">
    <mat-icon>search</mat-icon>
    <input matInput (keyup)="filterTable($event.target.value)" placeholder="Zoek" type="text" />
  </div>

  <table class="lister-table depot" mat-table [dataSource]="depotTable" multiTemplateDataRows>

    <ng-container matColumnDef="id">
      <th class="facility-number" mat-header-cell *matHeaderCellDef>Voorziening nummer</th>
      <td class="facility-number" mat-cell *matCellDef="let item">
        <span class="tablet-small-label">
            Voorziening nr:
        </span>
        {{item.id}}
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th class="contract-category-facility-type" mat-header-cell *matHeaderCellDef>Contractscategorie - Voorziening type</th>
      <td class="contract-category-facility-type" mat-cell *matCellDef="let item">
        <span class="tablet-small-label">
          Contractscategorie - Voorziening type:
        </span>
        {{item.name}}
      </td>
    </ng-container>

    <ng-container matColumnDef="purchaseDate">
      <th class="date-of-purchase" mat-header-cell *matHeaderCellDef>Aankoopdatum</th>
      <td class="date-of-purchase" mat-cell *matCellDef="let item">
        <span class="tablet-small-label">
          Aankoopdatum:
        </span>
        {{item.purchaseDate ? (item.purchaseDate | date:'dd-MM-yyyy') : '-'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th class="depot-status" mat-header-cell *matHeaderCellDef>Depot status</th>
      <td class="depot-status" mat-cell *matCellDef="let item">
        <span class="tablet-small-label">
          Depot status:
        </span>
        {{translateItemStatus(item.status)}}
      </td>
    </ng-container>

    <ng-container matColumnDef="expand">
      <th class="expand" mat-header-cell *matHeaderCellDef> </th>
      <td class="expand" mat-cell *matCellDef="let item">
        <span (click)="item.expanded = !item.expanded" [@rotateIcon]="item.expanded ? 'rotated' : 'default'"
          class="expand-toggle">
          <mat-icon>expand_more</mat-icon>
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedContent">
      <td colspan="8" class="expanded-content-td" mat-cell *matCellDef="let item">
        <app-depot-item *ngIf="item.expanded" [itemId]="item.id"></app-depot-item>
      </td>
    </ng-container>

    <tr class="depot-header-row" mat-header-row *matHeaderRowDef="depotTableColumns"></tr>
    <tr class="depot-row" mat-row *matRowDef="let row; columns: depotTableColumns;"></tr>
    <tr class="depot-expanded-content-row" mat-row *matRowDef="let row; columns: ['expandedContent'];">

    </tr>

  </table>

  <mat-paginator [pageSize]="50" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>

</div>

<app-spinner *ngIf="showDepotSpinner" loadingMessage=""></app-spinner>
