import { CountyService } from '@app/modules/county/services/county.service';
import { ClientsTableComponent } from '@app/modules/order/components/clients-with-orders-table/clients-with-orders-table.component';
import { SingleClientOrdersComponent } from '@app/modules/order/components/single-client-orders/single-client-orders.component';
import { TitleService } from '@app/shared/services/title.service';
import { UserService, SignedInUser } from '@app/shared/services/user.service';

import {Component, OnInit, ViewChild, OnDestroy} from '@angular/core';

import { ClientSearchQuery } from '@app/shared/models/client-search-query';
import { Subscription } from 'rxjs';

import { County } from '@app/modules/county/models/county';

@Component({
  selector: 'app-orders-page',
  templateUrl: './orders-page.component.html',
  styleUrls: ['./orders-page.component.scss']
})
export class OrdersPageComponent implements OnInit, OnDestroy {

  county?: County;
  user?: SignedInUser;

  private userSubscription: Subscription;

  @ViewChild('activeOrders')
  activeOrdersComponent: ClientsTableComponent;


  @ViewChild('waitingOrders')
  waitingOrdersComponent: ClientsTableComponent;

  @ViewChild('clientOrders')
  singleClientOrdersComponent: SingleClientOrdersComponent;

  constructor(
    private titleService: TitleService,
    private userService: UserService,
    private countyService: CountyService
  ) {}

  async ngOnInit(): Promise<void> {
    this.userSubscription = this.userService.user$.subscribe({
      next: async (user) => {
      if (user) {
        this.user = user;
        this.county = await this.countyService.getCounty(this.user.countyId);
      }
    }});

    this.titleService.setTitle('Aanvragen');
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }

  filterClientOrdersTable(filterValue: string) {
    this.singleClientOrdersComponent.filterTable(filterValue);
  }

  filterClientsOrdersTable(event: ClientSearchQuery) {
    this.activeOrdersComponent.filterTable(event);
    this.waitingOrdersComponent.filterTable(event);
  }

}
