<div *ngFor="let prd of misaOrder.products">
  <div class="columns">
    <div class="column">
      <div class="column-header">{{ prd.name }}</div>
      <div>Voorzieningsnummer:</div>
      <div class="value">{{ prd.id }}</div>
      <div>Type:</div>
      <div class="value">{{ prd.category }}</div>
      <div>Gemeente categorie:</div>
      <div class="value">{{ prd.productId }} - {{ prd.name }}</div>
      <div *ngIf="prd.formattedDeliveryDate">Datum Geleverd:</div>
      <div class="value" *ngIf="prd.formattedDeliveryDate">{{ prd.formattedDeliveryDate }}</div>
    </div>
    <div class="column">
      <div class="column-header">Lopende verzoeken</div>
      <div class="maintenance-item-container">
        <span *ngIf="(prd.filteredMaintenanceHistory.length + prd.filteredDeployHistory.length) === 0">Er zijn geen lopende verzoeken voor deze cliënt</span>
        <div class="maintenance-item" *ngFor="let requestItem of prd.filteredMaintenanceHistory">
          <div>Type verzoek:</div>
          <div>
            <span *ngIf="requestItem.action === 'REPAIR'" class="maintenance-type-badge repair">R</span>
            <span *ngIf="requestItem.action === 'ADJUST'" class="maintenance-type-badge adjustment">A</span>
            <span class="bold-label">
              <ng-container *ngIf="requestItem.action === 'REPAIR'"> Reparatie </ng-container>
              <ng-container *ngIf="requestItem.action === 'ADJUST'"> Aanpassing </ng-container>
            </span>
          </div>
          <div>Ingediend op:</div>
          <div class="bold-label">{{ formatDate(requestItem.dateInput) }}</div>
          <ng-container *ngIf="requestItem.status!==undefined && requestItem.status.length > 0">
            <div>Status:</div>
            <div class="bold-label">{{requestItem.status}}</div>
          </ng-container>
        </div>
        <div class="maintenance-item" *ngFor="let requestItem of prd.filteredDeployHistory">
          <div>Type verzoek:</div>
          <div>
            <span class="maintenance-type-badge pickup">O</span>
            <span class="bold-label"> Ophalen </span>
          </div>
          <div>Ingediend op:</div>
          <div class="bold-label">{{ formatDate(requestItem.dateInput) }}</div>
          <ng-container *ngIf="requestItem.status!==undefined && requestItem.status.length > 0">
            <div>Status:</div>
            <div class="bold-label">{{requestItem.status}}</div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="column">
      <div class="column-header">Acties</div>
      <button class="small-cta" (click)="openDialog(prd)" [disabled]="!misaOrder.isRequestEnabled">Verzoek indienen</button>  <br>
      <button class="small-cta gray" (click)="openDocuments(misaOrder)">Bekijk downloads </button>
      <p *ngIf="!misaOrder.isRequestEnabled">U heeft geen rechten om een verzoek in te dienen</p>
    </div>
  </div>
</div>
<app-spinner *ngIf="processing"></app-spinner>
