import { Component } from '@angular/core';

@Component({
  selector: 'app-about-contact-page',
  templateUrl: './about-contact-page.component.html',
  styleUrls: ['./about-contact-page.component.scss']
})
export class AboutContactPageComponent {
  selectedOption = '';

  onOptionChanged(e: Event) {
    this.selectedOption = (e.target as HTMLInputElement).value;
  }
}
