
export interface ActivateClientResponse {
  emailAddress: string;
  result: ActivationResult;
  userId: string;
}

export enum ActivationResult {
  ACTIVATED = 'ACTIVATED',
  ALREADY_ACTIVE = 'ALREADY_ACTIVE',
  COUNTY_NOT_MATCH = 'COUNTY_NOT_MATCH',
  CREATED = 'CREATED',
  BIRTHDATE_NOT_MATCH = 'BIRTHDATE_NOT_MATCH',
  IWMO_NOT_ALLOW_CHANGE_ORDER = 'IWMO_NOT_ALLOW_CHANGE_ORDER',
  EMAIL_ALREADY_EXISTS = 'EMAIL_ALREADY_EXISTS'
}

export interface DeactivateClientResponse {
  emailAddress?: string;
  result: DeactivationResult;
  userId?: string;
}

export enum DeactivationResult {
  ALREADY_DEACTIVATED = 'ALREADY_DEACTIVATED',
  DEACTIVATED = 'DEACTIVATED',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
}
