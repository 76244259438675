import { Component, OnInit , Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConfigService } from '@app/shared/services/config.service';


@Component({
  selector: 'app-modal-onboard-form',
  templateUrl: './modal-onboard-form.component.html',
  styleUrls: ['./modal-onboard-form.component.scss']
})


export class ModalOnboardFormComponent implements OnInit {

  constructor(configService: ConfigService,
              private dialogRef: MatDialogRef<ModalOnboardFormComponent>,
              @Inject(MAT_DIALOG_DATA)
              data: {
                requestType: string;
              }) { }

    panelcount = 1;

    changeValues(passedpanel, isback){

      if (passedpanel > 0){
        this.panelcount = passedpanel;
      }

      if (passedpanel === 0){
        if (isback === true && this.panelcount !== 1) {
          console.log('passed back ');
          this.panelcount = this.panelcount - 1;
        }
        else if (isback === false && this.panelcount !== 5)
        {
          this.panelcount = this.panelcount + 1;
        }
      }
    }

  ngOnInit(): void {
  }

  closeMe(): void {
    this.dialogRef.close();
  }

}
