import { MediaWatcherService } from './media-watcher.service';
import { NgModule } from '@angular/core';

@NgModule({
    providers: [
        MediaWatcherService
    ]
})
export class MediaWatcherModule
{
    /**
     * Constructor
     */
    constructor()
    {
    }
}
