<div class = "modal-container-div" ng-controller="ModalOnboardFormComponent">
    <div class="panel1" *ngIf="(panelcount === 1)">
        <div class="panel-container">
            <div class="panel-image">
                <img src="assets/images/onboarding-1.svg"/>
            </div>
            
            <div class="panel-content">
                <h2>Wmo hulpmiddelen</h2>
                <p>In de Mijn Wmo klantportaal van Medipoint vind je een uitgebreid overzicht van je hulpmiddelen 
                    en ingediende service verzoeken via 'mijn voorzieningen'.</p>
            </div>
        </div>
    </div>

    <div class="panel1" *ngIf="(panelcount === 2)">
        <div class="panel-container">
            <div class="panel-image">
                <img src="assets/images/onboarding-2.svg"/>
            </div>
            
            <div class="panel-content">
                <h2>Status bestelling</h2>
                <p>Zie snel en eenvoudig de status van de aangevraagde Wmo hulpmiddel(en) via 'mijn aanvragen'.</p>
            </div>
        </div>
    </div>

    <div class="panel1" *ngIf="(panelcount === 3)">
        <div class="panel-container">
            <div class="panel-image">
                <img src="assets/images/onboarding-3.svg"/>
            </div>
            
            <div class="panel-content">
                <h2>Service verzoek</h2>
                <p>Je kunt nu digitaal een reparatie, aanpassing of inname van je Wmo hulpmiddel(en) 
                    aan Medipoint doorgeven. Dit kan eenvoudig via de groene button 'verzoek indienen'. 
                    In het contract met de gemeente is afgestemd welke verzoeken je als klant zelf in 
                    mag dienen.</p>
            </div>
        </div>
    </div>

    <div class="panel1" *ngIf="(panelcount === 4)">
        <div class="panel-container">
            <div class="panel-image">
                <img src="assets/images/onboarding-4.svg"/>
            </div>
            
            <div class="panel-content">
                <h2>Informatie</h2>
                <p>Extra uitleg en informatie vind je links in de menubalk op de pagina's 'hoe werkt het, 
                    veel gestelde vragen en contact'.</p>
            </div>
        </div>
    </div>

    <div class="panel1" *ngIf="(panelcount === 5)">
        <div class="panel-container">
            <div class="panel-image">
                <img src="assets/images/onboarding-5.svg"/>
            </div>
            
            <div class="panel-content">
                <h2>Wil je meer informatie?</h2>
                <p>Download dan de Handleiding “Mijn Wmo“ Medipoint klantenportaal.</p>
                <p><a target="_BLANK" class = "document-download-link" href="https://docs.google.com/document/d/e/2PACX-1vQmWogcI6sPGvs2pb8Qko4uo0c-8DlvD0ynHRnHAzhxRCxKRTKvVHszo11WjPrxoTw-TslaQg-8H3bp/pub">Klik hier om te downloaden</a></p>
            </div>
        </div>
    </div>

    <div class="modal-actions">
        <div class="radio-btn-container">
            <input (click)="changeValues(1,false)" type="radio" name="editList" value="1" [(ngModel)]="panelcount" [value]="1">
            <input (click)="changeValues(2,false)" type="radio" name="editList" value="2" [(ngModel)]="panelcount" [value]="2">
            <input (click)="changeValues(3,false)" type="radio" name="editList" value="3" [(ngModel)]="panelcount" [value]="3">
            <input (click)="changeValues(4,false)" type="radio" name="editList" value="4" [(ngModel)]="panelcount" [value]="4">
            <input (click)="changeValues(5,false)" type="radio" name="editList" value="5" [(ngModel)]="panelcount" [value]="5">
        </div>
        <button class="cta back-btn" (click)="changeValues(0,true)" *ngIf="(panelcount > 1)">Vorige</button>
        <button class="cta" (click)="changeValues(0,false)">Volgende</button>
        <p><a style="color:#999999" (click)="closeMe()">Overslaan</a></p>
    </div>
</div>
