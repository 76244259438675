<form class="general-form-container reset-password" [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
  <h1>Wachtwoord instellen</h1>

  <p *ngIf="emailAddress">
    Stel een nieuw wachtwoord in voor<br/><strong>{{emailAddress}}</strong>.
    Het wachtwoord moet tenminste zes tekens bevatten
  </p>

  <div class="input-container"
    [ngClass]="{'invalid': passwordForm.get('new-password').touched && passwordForm.get('new-password').invalid}">
    <app-error-messages [formControls]="passwordForm.get('new-password')" [marginBottom]="true"></app-error-messages>
    <input autocomplete="off" type="password" name="password" class="form-control" formControlName="new-password" required />
    <label for="password">Wachtwoord</label>
  </div>

  <div class="input-container"
    [ngClass]="{'invalid': passwordForm.get('confirm-new-password').touched && passwordForm.get('confirm-new-password').invalid}">
    <app-error-messages [formControls]="passwordForm.get('confirm-new-password')" [marginBottom]="true"></app-error-messages>
    <input autocomplete="off" type="password" class="form-control" formControlName="confirm-new-password" required />
    <label for="confirmPassword">Bevestig wachtwoord</label>
  </div>

  <button type="submit" class="btn">Wachtwoord instellen</button>

  <app-spinner *ngIf="showSpinner"></app-spinner>

</form>
