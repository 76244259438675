<div class="general-form-header">
    <h1>Contracten</h1>
    <a routerLink="/contract" class="secondary-cta" href="/contract">+ Nieuw contract</a>
</div>

<div class="general-form-container">

    <div class="input-icon-container margin-bottom">
        <mat-icon>search</mat-icon>
        <input matInput (keyup)="filterTable($event.target.value)" placeholder="Zoek" type="text" />
    </div>

    <table class="lister-table contracts" mat-table [dataSource]="contractsTable">

        <!--TODO FIGURE OUT WHAT TO DO WITH THIS
        if ID is alladinId then maybe it shouldnt be in the table since its optional
        -->
        <!--<ng-container matColumnDef="id">
            <th class="id" mat-header-cell *matHeaderCellDef> ID </th>
            <td class="id" mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>-->

        <ng-container matColumnDef="name">
            <th class="name" mat-header-cell *matHeaderCellDef> Naam </th>
            <td class="name" mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="establishmentName">
            <th class="establishment" mat-header-cell *matHeaderCellDef> Locatie </th>
            <td class="establishment" mat-cell *matCellDef="let element"> {{(element.establishment | async)?.name}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th class="status" mat-header-cell *matHeaderCellDef> Status </th>
            <td class="status" mat-cell *matCellDef="let element">
                <span *ngIf="element.archived" class="inactive">
                    Inactief
                </span>
                <span *ngIf="!element.archived">
                    Actief
                </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="edit">
            <th class="edit" mat-header-cell *matHeaderCellDef>  </th>
            <td class="edit cta-td" mat-cell *matCellDef="let element">
                <a class="cta" routerLink="/contract/{{element.contractId}}" href="/contract/{{element.contractId}}">Bijwerken</a>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="contractsTableColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: contractsTableColumns;"></tr>

    </table>

   <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>

</div>

<app-spinner *ngIf="showContractsSpinner" loadingMessage=""></app-spinner>
