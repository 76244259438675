import { Component, Input, forwardRef, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  UntypedFormGroup,
  UntypedFormArray,
  AbstractControl,
  ValidationErrors,
  Validators,
  ValidatorFn
} from '@angular/forms';

import * as util from '@app/shared/util';
import * as globals from '@app/shared/globals';
import * as moment from 'moment';

import { ContactPersonForm } from '@app/shared/forms/contact-person.form';
import { ContactPerson } from '@app/shared/models/contact-person';
import { SocialSecurityNumberControl } from '@app/shared/forms/socialSecurityNumber.control';

@Component({
  selector: 'app-client-form',
  templateUrl: './client-form.component.html',
  styleUrls: ['./client-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ClientFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ClientFormComponent),
      multi: true
    }
  ]
})
export class ClientFormComponent implements ControlValueAccessor, OnChanges, OnInit {
  @Input() clientForm: UntypedFormGroup;
  @Input() socialSecurityNumberRequired: boolean;
  @Input() newClient: boolean;
  @Input() newOrder: boolean;
  @Input() headerIndex: number;
  @Input() userIsClient: boolean;
  @Input() existingClientWithContactPerson: boolean;
  @Input() iwmo: boolean;

  birthDateStartDate = moment.utc('1950-01-01').toDate();
  public util = util;
  lastNameRequired = true;
  partnerNameRequired = false;
  onlyNumericRegEx = new RegExp(/^[0-9]+$/);



  ngOnInit() {
    if (this.userIsClient) {
      this.setDisabledState(true);
    } else {
      this.startListeningToNameUsageChanges();
      this.clientForm.get('countyCustomerNumber').setValidators(this.countyCustomerNumberValidator());
    }
  }

  countyCustomerNumberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isValid =
        control.value === undefined || control.value === null || control.value === '' || this.onlyNumericRegEx.test(control.value);
      return isValid ? null : { onlyNumeric: false };
    };
  }

  startListeningToNameUsageChanges(): void {
    this.clientForm.get('name.nameUsage').valueChanges.subscribe((nameUsage) => {
      const nameValidators = [Validators.maxLength(30), Validators.pattern(globals.lettersAndAccentsRegex)];
      const nameValidatorsRequired = nameValidators.concat([Validators.required]);
      switch (nameUsage) {
        case 'FAMILY_NAME_ONLY':
          this.clientForm.get('name.lastName').setValidators(nameValidatorsRequired);
          this.clientForm.get('name.partnerName').setValidators(nameValidators);
          break;
        case 'PARTNER_NAME_ONLY':
          this.clientForm.get('name.lastName').setValidators(nameValidators);
          this.clientForm.get('name.partnerName').setValidators(nameValidatorsRequired);
          break;
        default:
          // In case of PARTNER_NAME_FOLLOWED_BY_FAMILY_NAME or FAMILY_NAME_FOLLOWED_BY_PARTNER_NAME
          this.clientForm.get('name.lastName').setValidators(nameValidatorsRequired);
          this.clientForm.get('name.partnerName').setValidators(nameValidatorsRequired);
      }

      this.clientForm.get('name.lastName').updateValueAndValidity();
      this.clientForm.get('name.partnerName').updateValueAndValidity();
    });
  }

  // Start of CVA's needed to connect to main form (https://blog.angularindepth.com/angular-nested-reactive-forms-using-cvas-b394ba2e5d0d)
  public onTouched: any = () => {};

  writeValue(val: any): void {
    if (val) {
      this.clientForm.setValue(val, { emitEvent: false });
    }
  }

  registerOnChange(fn: any): void {
    this.clientForm.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.clientForm.disable() : this.clientForm.enable();
  }
  // End of CVA's

  validate(c: AbstractControl): ValidationErrors | null {
    return this.clientForm.valid ? null : { invalidForm: { valid: false, message: 'clientForm fields are invalid' } };
  }

  addContactPerson(): void {
    const contactPersonsFormArray = this.clientForm.get('contactPersons') as UntypedFormArray;
    const contactPersonForm: UntypedFormGroup = new ContactPersonForm(new ContactPerson()).form;
    contactPersonsFormArray.push(contactPersonForm);
  }

  removeContactPerson(index): void {
    const addressesFormArray = this.clientForm.get('contactPersons') as UntypedFormArray;
    addressesFormArray.removeAt(index);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.socialSecurityNumberRequired && this.newClient && this.newOrder && !this.clientForm.controls.socialSecurityNumber) {
      this.clientForm.addControl('socialSecurityNumber', new SocialSecurityNumberControl().socialSecurityNumber);
    }
    if ((!this.socialSecurityNumberRequired || !this.newClient || !this.newOrder) && this.clientForm.controls.socialSecurityNumber) {
      this.clientForm.removeControl('socialSecurityNumber');
    }
  }
}
