import { OrderForm } from '@app/shared/forms/order.form';
import { Address } from '@app/shared/models/address';
import { ContactDetails } from '@app/shared/models/contact-details';
import { ContactPerson } from '@app/shared/models/contact-person';
import { Name } from '@app/shared/models/name';
import * as util from '@app/shared/util';

export class ChangedOrder {
  private client: {
    contactDetails: ContactDetails;
    name: Name;
    birthDate: Date;
    gender: string;
    deceased?: false;
    encryptedSocialSecurityNumber?: string;
  };
  private address: Address;
  private contactPerson?: ContactPerson;
  private applicantId: string;
  private countyId: string;
  private remarks?: string;
  private orderReferenceNumber?: string;
  misaOrderId: string;
  changeSectionList: string[];
  iWMODocUpload?: boolean;
  private documents?: {
    fileName: string;
    storageFilePath: string;
    type: string;
  }[];

  // ToDo: Move all properties to seperate models with their own constructors
  constructor(orderForm: OrderForm, countyId: string, changeSectionList: string[], iWMODocUpload?: boolean) {
    this.changeSectionList = changeSectionList;
    this.iWMODocUpload = util.checkOptBoolean(iWMODocUpload);

    if (!this.iWMODocUpload){
      this.client = {
        name: {
          initials: util.checkString(orderForm.value.client.name.initials),
          firstName: util.checkOptString(orderForm.value.client.name.firstName),
          prefix: util.checkOptString(orderForm.value.client.name.prefix),
          lastName: util.checkOptString(orderForm.value.client.name.lastName),
          partnerNamePrefix: util.checkOptString(orderForm.value.client.name.partnerNamePrefix),
          partnerName: util.checkOptString(orderForm.value.client.name.partnerName),
          nameUsage: util.checkString(orderForm.value.client.name.nameUsage)
        },
        contactDetails: {
          emailAddress: util.checkOptString(orderForm.value.client.contactDetails.emailAddress),
          phoneNumber: util.checkOptString(orderForm.value.client.contactDetails.phoneNumber),
          mobileNumber: util.checkOptString(orderForm.value.client.contactDetails.mobileNumber)
        },
        encryptedSocialSecurityNumber: util.checkOptString(orderForm.value.client.encryptedSocialSecurityNumber),
        birthDate: util.checkDate(orderForm.value.client.birthDate.toDate()),
        gender: util.checkString(orderForm.value.client.gender)
      };

      this.address = {
        street: util.checkString(orderForm.value.client.addresses[0].street),
        houseNumber: util.checkNumber(orderForm.value.client.addresses[0].houseNumber),
        houseNumberAddition: util.checkOptString(orderForm.value.client.addresses[0].houseNumberAddition),
        postalCode: util.checkString(orderForm.value.client.addresses[0].postalCode),
        city: util.checkString(orderForm.value.client.addresses[0].city)
      };

      this.countyId = util.checkString(countyId);
      this.remarks = util.checkOptString(orderForm.value.remarks);
      this.orderReferenceNumber = util.checkOptString(orderForm.value.orderReferenceNumber);
      if (orderForm.value.client.contactPersons[0]) {
        this.contactPerson = {
          // commented out because it might be added back in later
          // initials: util.checkString(orderForm.value.client.contactPersons[0].initials),
          firstName: util.checkOptString(orderForm.value.client.contactPersons[0].firstName),
          prefix: util.checkOptString(orderForm.value.client.contactPersons[0].prefix),
          lastName: util.checkString(orderForm.value.client.contactPersons[0].lastName),
          phoneNumber: util.checkString(orderForm.value.client.contactPersons[0].phoneNumber),
          emailAddress: util.checkOptString(orderForm.value.client.contactPersons[0].emailAddress)
        };
      }
    }
    else{
      this.client = {
        name: {
          initials: util.checkString(orderForm.getRawValue().client.name.initials),
          firstName: util.checkOptString(orderForm.getRawValue().client.name.firstName),
          prefix: util.checkOptString(orderForm.getRawValue().client.name.prefix),
          lastName: util.checkOptString(orderForm.getRawValue().client.name.lastName),
          partnerNamePrefix: util.checkOptString(orderForm.getRawValue().client.name.partnerNamePrefix),
          partnerName: util.checkOptString(orderForm.getRawValue().client.name.partnerName),
          nameUsage: util.checkString(orderForm.getRawValue().client.name.nameUsage)
        },
        contactDetails: {
          emailAddress: util.checkOptString(orderForm.getRawValue().client.contactDetails.emailAddress),
          phoneNumber: util.checkOptString(orderForm.getRawValue().client.contactDetails.phoneNumber),
          mobileNumber: util.checkOptString(orderForm.getRawValue().client.contactDetails.mobileNumber)
        },
        encryptedSocialSecurityNumber: util.checkOptString(orderForm.getRawValue().client.encryptedSocialSecurityNumber),
        birthDate: util.checkDate(orderForm.getRawValue().client.birthDate.toDate()),
        gender: util.checkString(orderForm.getRawValue().client.gender)
      };

      this.address = {
        street: util.checkString(orderForm.getRawValue().client.addresses[0].street),
        houseNumber: util.checkNumber(orderForm.getRawValue().client.addresses[0].houseNumber),
        houseNumberAddition: util.checkOptString(orderForm.getRawValue().client.addresses[0].houseNumberAddition),
        postalCode: util.checkString(orderForm.getRawValue().client.addresses[0].postalCode),
        city: util.checkString(orderForm.getRawValue().client.addresses[0].city)
      };
      this.countyId = util.checkString(countyId);
      this.remarks = util.checkOptString(orderForm.getRawValue().remarks);
      this.orderReferenceNumber = util.checkOptString(orderForm.getRawValue().orderReferenceNumber);
      if (orderForm.getRawValue().client.contactPersons[0]) {
        this.contactPerson = {
          // commented out because it might be added back in later
          // initials: util.checkString(orderForm.value.client.contactPersons[0].initials),
          firstName: util.checkOptString(orderForm.getRawValue().client.contactPersons[0].firstName),
          prefix: util.checkOptString(orderForm.getRawValue().client.contactPersons[0].prefix),
          lastName: util.checkString(orderForm.getRawValue().client.contactPersons[0].lastName),
          phoneNumber: util.checkString(orderForm.getRawValue().client.contactPersons[0].phoneNumber),
          emailAddress: util.checkOptString(orderForm.getRawValue().client.contactPersons[0].emailAddress)
        };
      }
    }

    if (orderForm.value.documents) {
      this.documents = [];
      orderForm.value.documents.forEach((document: any) => {
        this.documents.push({
          fileName: util.checkString(document.fileName),
          storageFilePath: util.checkString(document.storageFilePath),
          type: util.checkString(document.type)
        });
      });
    }
  }
}
