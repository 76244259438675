import {County} from '@app/modules/county/models/county';
import {AuthService, AuthUser} from '@app/shared/services/auth.service';
import {CloudLogger, CloudLoggingService} from '@app/shared/services/cloud-logging.service';
import {UserService, SignedInUser} from '@app/shared/services/user.service';
import {FirestoreObservableQuery} from '@app/shared/util/firestore-observable-query';
import * as devLog from '@app/shared/util/dev-log';
import * as util from '@app/shared/util';

import {Injectable} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/performance';
import {Observable, ReplaySubject} from 'rxjs';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import { AngularFirePerformance } from '@angular/fire/compat/performance';
import { trace } from '@angular/fire/compat/performance';

export type Counties = {[key: string]: County};


@Injectable({
  providedIn: 'root'
})
export class CountyService {

  counties$: Observable<Counties>;
  countyOfSignedInUser$: Observable<County | null>;

  private cloudLog: CloudLogger;
  private perf = firebase.performance();

  private changeContractValidateRequest$: (data: any) => Observable<any>;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private cloudLoggingService: CloudLoggingService,
    private firestore: AngularFirestore,
    private performance: AngularFirePerformance,
    functions: AngularFireFunctions,
  ) {
    this.cloudLog = this.cloudLoggingService.createLogger('county.service');

    const countiesQuery = new FirestoreObservableQuery<County>(firestore, {
      collection: 'counties'
    });

    this.counties$ = countiesQuery.observable$
      .pipe(trace('CountyService.counties$'));

    this.authService.user$.subscribe((user: AuthUser) => {
      countiesQuery.setCleanup(!user);
    });

    const countyOfSignedInUserSub$ = new Observable(subscriber => {
      this.userService.user$.subscribe(async (user?: SignedInUser) => {
        if (user && user.countyId) {
          try {
            const county: County = await this.getCounty(user.countyId);
            subscriber.next(county);
          }
          catch (error) {
            devLog.info(`Cannot get county for ${user.userId}`, error);
            subscriber.next(null);
          }
        }
        else {
          subscriber.next(null);
        }
      });
    });

    const countySubject = new ReplaySubject<County>(1);
    countyOfSignedInUserSub$.subscribe(countySubject);
    this.countyOfSignedInUser$ = countySubject.asObservable();

    this.changeContractValidateRequest$ = functions.httpsCallable('changeContractValidateRequest');
  }

  async getCounties(): Promise<Counties> {
    return util.takeOneAsPromise(this.counties$);
  }

  async getCountiesWithContract(contractId: string): Promise<Counties> {
    return util.takeOneAsPromise(this.getCountriesWithContract$(contractId));
  }

  getCountriesWithContract$(contractId: string): Observable<Counties> {
    return new FirestoreObservableQuery<County>(this.firestore, {
      collection: 'counties',
      queryFunction: ref => {
        return ref.where('contractId', '==', contractId);
      }
    }).observable$.pipe(trace('CountyService.getCountriesWithContract$'));
  }

  async getCounty(countyId: string): Promise<County> {
    const trace = this.perf.trace('CountyService.getCounty');
    trace.start();

    try {
      const snapshot = await this.getReference(countyId).ref.get();
      if (!snapshot.exists) {
        throw new Error(`County with ID ${countyId} does not exist.`);
      }

      return snapshot.data() as County;
    } finally {
      trace.stop();
    }
  }

  async createCounty(county: County): Promise<string> {
    const trace = this.perf.trace('CountyService.createCounty');
    trace.start();

    try {
      // @todo validate input
      const reference = await this.getCollection().add(county);
      const countyId = reference.id;

      this.cloudLog.info(`Created county ${countyId}`);

      return countyId;

    } finally {
      trace.stop();
    }
  }

  async updateCounty(countyId: string, county: County): Promise<void> {
    const trace = this.perf.trace('CountyService.updateCounty');
    trace.start();

    try {
      // @todo validate input
      const reference = this.getReference(countyId);

      if (!(await reference.get().toPromise()).exists) {
        throw new Error('County does not exist');
      }

      await reference.set(county);
      this.cloudLog.info(`Updated county ${countyId}`);

    } finally {
      trace.stop();
    }
  }

  private getReference(countyId: string): AngularFirestoreDocument<County> {
    return this.getCollection().doc(countyId);
  }

  private getCollection(): AngularFirestoreCollection<County> {
    return this.firestore.collection<County>('counties');
  }

  async changeContractValidateRequest(countyId: string, newContract: string): Promise<void> {
    await this.changeContractValidateRequest$({countyId, newContract})
      .pipe(trace('changeContractValidateRequest'))
      .toPromise();

    this.cloudLog.info(`Changed county ${countyId} contract to ${newContract}`);
  }
}
