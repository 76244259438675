import * as devLog from '@app/shared/util/dev-log';

import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable } from 'rxjs';


export class CloudLogger {

  service: CloudLoggingService;
  moduleName: string;

  constructor(service: CloudLoggingService, moduleName: string) {
    this.service = service;
    this.moduleName = moduleName;
  }

  info(message, additionalData?: {[key: string]: any}) {
    this.service.info(this.moduleName, message, additionalData);
  }

  warn(message, additionalData?: {[key: string]: any}) {
    this.service.warn(this.moduleName, message, additionalData);
  }

  error(message, additionalData?: {[key: string]: any}) {
    this.service.error(this.moduleName, message, additionalData);
  }
}


@Injectable({providedIn: 'root'})
export class CloudLoggingService {

  private logFunction$: (data: any) => Observable<any>;
  private sessionId: string;

  constructor(private functions: AngularFireFunctions) {
    this.logFunction$ = this.functions.httpsCallable('frontendLog');
    this.setSessionId();
  }

  createLogger(moduleName: string): CloudLogger {
    return new CloudLogger(this, moduleName);
  }

  info(moduleName, message, additionalData?: {[key: string]: any}) {
    this.log(moduleName, 'info', message, additionalData);
  }

  warn(moduleName, message, additionalData?: {[key: string]: any}) {
    this.log(moduleName, 'warning', message, additionalData);
  }

  error(moduleName, message, additionalData?: {[key: string]: any}) {
    this.log(moduleName, 'error', message, additionalData);
  }

  private log(module: string, severity: string, message: string, additionalData?: {[key: string]: any}) {
    let data = {
      sessionId: this.sessionId
    };

    if (additionalData) {
      data = Object.assign(data, additionalData);
    }

    devLog.info(`[${module}] ${message}`, data);

    this.logFunction$({
      isoTimestamp: new Date().toISOString(),
      severity,
      module,
      message,
      additionalData: data,

    }).subscribe({
      error: error => {
        console.error(`Cloud logging error: ${error}.`);
      }
    });
  }

  private setSessionId() {
    const sessionStorageId = sessionStorage.getItem('medux-uuid');

    if (sessionStorageId) {
      this.sessionId = sessionStorageId;
    } else {
      const { v4: uuidv4 } = require('uuid');
      this.sessionId = uuidv4();
      sessionStorage.setItem('medux-uuid', this.sessionId);
    }
  }
}
