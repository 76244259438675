<!-- Although the required flags are set in the controller,
    they have also been added in the template for styling purposes -->

<div class="address-form-container" [formGroup]="addressForm">

    <app-spinner class="absolute horizontal" *ngIf="apiStatus === 'loading'" loadingMessage="Even geduld terwijl we naar uw adres zoeken"></app-spinner>

    <div class="row three-columns-row">

        <div class="input-container"
            [ngClass]="{'invalid': (addressForm.controls.postalCode.touched && addressForm.controls.postalCode.invalid)}">
            <app-error-messages [formControls]="addressForm.controls.postalCode"></app-error-messages>
            <input type="text" formControlName="postalCode" id="postalCode" name="postalCode"
            (keyup)="postalCodeKeyUpListener($event)"
            (paste)="$event.preventDefault(); postalCodePasteListener($event)" required />
            <label for="postalCode">Postcode</label>
        </div>

        <div class="input-container"
            [ngClass]="{'invalid': (addressForm.controls.houseNumber.touched && addressForm.controls.houseNumber.invalid)}">
            <app-error-messages [formControls]="addressForm.controls.houseNumber"></app-error-messages>
            <input type="number" formControlName="houseNumber" id="houseNumber" name="houseNumber"
            (keyup)="checkAddressForApiWithTimer()"
            (paste)="checkAddressForApiWithTimer()" required />
            <label for="houseNumber">Huisnummer</label>
        </div>

        <div class="input-container">
            <app-error-messages [formControls]="addressForm.controls.houseNumberAddition"></app-error-messages>
            <input type="text" formControlName="houseNumberAddition" id="houseNumberAddition"
            name="houseNumberAddition"
            (keyup)="checkAddressForApiWithTimer()"
            (paste)="checkAddressForApiWithTimer()" />
            <label for="houseNumberAddition">Huisnummer toevoeging</label>
        </div>

    </div>

    <div class="low-row">

        <div class="input-container"
            [ngClass]="{'invalid': (addressForm.controls.street.touched && addressForm.controls.street.invalid)}">
            <app-error-messages [formControls]="addressForm.controls.street"></app-error-messages>
            <input type="text" formControlName="street" id="street" name="street" required />
            <label for="street">Straat</label>
        </div>

        <div class="input-container"
            [ngClass]="{'invalid': (addressForm.controls.city.touched && addressForm.controls.city.invalid)}">
            <app-error-messages [formControls]="addressForm.controls.city"></app-error-messages>
            <input type="text" formControlName="city" id="city" name="city" required />
            <label for="city">Plaats</label>
        </div>

    </div>

</div>
