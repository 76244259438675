import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { ContactPerson } from '@app/shared/models/contact-person';
import * as globals from '@app/shared/globals';
import { Injectable } from '@angular/core';

@Injectable()
export class ContactPersonForm {

    form: UntypedFormGroup;

    constructor(contactPerson: ContactPerson, ) {
        const formBuilder = new UntypedFormBuilder();
        this.form = formBuilder.group({
            // commented out because they might be added back in later
            // type: [null, [Validators.required]],
            // initials: [null, [Validators.required, Validators.maxLength(20), Validators.pattern(globals.initialsRegex)]],
            firstName: [null, [Validators.maxLength(20), Validators.pattern(globals.lettersAndAccentsRegex)]],
            prefix: [null, [Validators.maxLength(12), Validators.pattern(globals.lettersAndAccentsRegex)]],
            lastName: [null, [Validators.required, Validators.maxLength(100), Validators.pattern(globals.lettersAndAccentsRegex)]],
            emailAddress: [null, [Validators.email]],
            phoneNumber: [null, [Validators.pattern('[0-9]*'), Validators.minLength(10), Validators.maxLength(10)]],
        });
        this.form.patchValue(contactPerson);
    }
}
