import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NotificationService } from '@app/shared/services/notification.service';
import { DialogService } from '@app/shared/services/dialog.service';

import { FaqItem } from '../../models/faq-item';
import { FaqService } from '../../services/faq.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-faq-items-admin',
  templateUrl: './faq-items-admin.component.html',
  styleUrls: ['./faq-items-admin.component.scss'],
  animations: [
    trigger('rotateIcon', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(-180deg)' })),
      transition('rotated => default', animate('225ms ease-out')),
      transition('default => rotated', animate('225ms ease-in'))
    ])
  ]
})
export class FaqItemsAdminComponent implements OnInit {
  faqItems: FaqItem[] = [];
  @ViewChild('faqEditor') faqEditor: TemplateRef<any>;
  activeFaqItem: FaqItem;
  dialogRef: MatDialogRef<any, any>;
  constructor(
    private faqService: FaqService,
    public dialog: MatDialog,
    private dialogService: DialogService,
    private notificationService: NotificationService
  ) {}

  async ngOnInit(): Promise<void> {
    this.faqItems = await this.faqService.getFaqItems();
  }

  showEditor(item?: FaqItem) {
    if (!item) {
      item = { id: '', question: '', answer: '', dateCreated: '' };
    }
    this.activeFaqItem = { ...item };
    this.dialogRef = this.dialog.open(this.faqEditor, {
      width: '80%',
      maxWidth: '750px'
    });
  }

  isFormValid(): boolean {
    return this.activeFaqItem.question.length > 0 && this.activeFaqItem.answer.length > 0;
  }

  async saveActiveFaq() {
    if(this.isFormValid()){
      const savedItem: FaqItem = await this.faqService.saveFaqItem(this.activeFaqItem);
    const index = this.faqItems.findIndex((x) => x.id === savedItem.id);
    if (index >= 0) {
      this.faqItems[index] = savedItem;
    } else {
      this.faqItems.push(savedItem);
    }
    this.dialogRef.close();
    }
    else{
      this.dialogService.openErrorAlert(`Foutmelding`, 'Alle velden zijn verplicht.');
    }
  }

  async deleteFaqItem(itemId: string): Promise<void> {
    try {
      await this.faqService.deleteFaqItem(itemId);
      this.faqItems = this.faqItems.filter((x) => x.id !== itemId);
      this.notificationService.openSnackBar('Vraag is verwijderd');
    } catch (error) {
      this.dialogService.openErrorAlert(`Foutmelding`, 'Fout bij verwijderen van vraag.');
    }
  }
}
