export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyDy2CM43dDt6Qo_7nEMg2jKGmKVeaSHbvg',
    authDomain: 'medux-medipoint-portal.firebaseapp.com',
    databaseURL: 'https://medux-medipoint-portal.firebaseio.com',
    projectId: 'medux-medipoint-portal',
    storageBucket: 'medux-medipoint-portal.appspot.com',
    messagingSenderId: '384960775937',
    appId: '1:384960775937:web:084c5aac8e0f963c797d00',
    measurementId: 'G-JEKV55NJ1T'
  },
  name: null,
  version: require('../../package.json').version
};

export const orderDocumentsBucket = 'medux-medipoint-portal-order-documents';
export const contractImagesBucket = 'medux-medipoint-portal-contract-images';
