import { AuthService } from '@app/shared/services/auth.service';
import { UserService, SignedInUser } from '@app/shared/services/user.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {

  constructor(
    public authService: AuthService,
    private router: Router
  )
  {
  }
  ngOnInit(): void {
  }

  navigateToContact(){
    this.router.navigate(['/client-contact']);
  }
}
