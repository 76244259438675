<mat-drawer-container>
  <mat-drawer mode="side" [opened]="sideBarOpen">
    <div class="colored-bar">
      <app-sidenav></app-sidenav>
    </div>
  </mat-drawer>
  <mat-drawer-content class="colored-bar-right">
    <app-headerv2 (toggleSidebarForMe)="sideBarToggler($event)"></app-headerv2>
    <div class="content-right">
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
