import { AddressForm } from '@app/shared/forms/address.form';
import * as globals from '@app/shared/globals';
import { atLeastOne } from '@app/shared/validators/atLeastOne.validator';

import { Validators, UntypedFormGroup, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { Injectable } from '@angular/core';


@Injectable()
export class ClientForm extends UntypedFormGroup {

  constructor() {
    super({
      name: new UntypedFormGroup({
        initials: new UntypedFormControl(null, [Validators.required, Validators.maxLength(10), Validators.pattern(globals.initialsRegex)]),
        firstName: new UntypedFormControl(null, [Validators.maxLength(20), Validators.pattern(globals.lettersAndAccentsRegex)]),
        prefix: new UntypedFormControl(null, [Validators.maxLength(20), Validators.pattern(globals.lettersAndAccentsRegex)]),
        lastName: new UntypedFormControl(null, [
          Validators.required,
          Validators.maxLength(30),
          Validators.pattern(globals.lettersAndAccentsRegex)]),
        partnerNamePrefix: new UntypedFormControl(null, [Validators.maxLength(20), Validators.pattern(globals.lettersAndAccentsRegex)]),
        partnerName: new UntypedFormControl(null, [Validators.maxLength(30), Validators.pattern(globals.lettersAndAccentsRegex)]),
        nameUsage: new UntypedFormControl('FAMILY_NAME_ONLY', [Validators.required]),
      }),

      encryptedSocialSecurityNumber: new UntypedFormControl(null, []),
      countyCustomerNumber: new UntypedFormControl(null, [Validators.maxLength(16), Validators.pattern('[0-9a-zA-Z]*')]),
      birthDate: new UntypedFormControl(null, [Validators.required]),
      gender: new UntypedFormControl(null, [Validators.required]),

      contactDetails: new UntypedFormGroup({
        emailAddress: new UntypedFormControl(null, [Validators.email]),
        phoneNumber: new UntypedFormControl(null, [Validators.pattern('[0-9]{10}'), Validators.minLength(10), Validators.maxLength(10)]),
        mobileNumber: new UntypedFormControl(null, [Validators.pattern('06[0-9]{8}'), Validators.minLength(10), Validators.maxLength(10)]),
      }, {
        validators: atLeastOne(
          Validators.required,
          ['emailAddress', 'phoneNumber', 'mobileNumber']
        )
      }),
      addresses: new UntypedFormArray([new AddressForm()]),
      contactPersons: new UntypedFormArray([])
    });
  }
}
