import {Observable, ReplaySubject, Subscription} from 'rxjs';


export interface HasObservable<T> {
  observable$: Observable<T>;
};


export class LazyCachedObservableOptions<T> {
  createObservable$: () => Observable<T>;
};


export class LazyCachedObservable<T> implements HasObservable<T> {

  observable$: Observable<T>;

  private subject: ReplaySubject<T>;
  private subjectSubscription: Subscription;
  private cleanUpAfterLastObserver = true;

  constructor(options: LazyCachedObservableOptions<T>) {
    const self = this;

    this.observable$ = new Observable(subscriber => {
      if (!this.subject) {
        this.createSubject(options.createObservable$);
      }

      const subscription = this.subject.asObservable().subscribe(subscriber);

      return () => {
        subscription.unsubscribe();

        if (self.cleanUpAfterLastObserver) {
          if (self.subject.observers.length === 0) {
            self.destroySubject();
          }
        }
      };
    });
  }

  setCleanUpAfterLastObserver(shouldCleanUp: boolean) {
    this.cleanUpAfterLastObserver = shouldCleanUp;

    if (this.cleanUpAfterLastObserver) {
      if (this.subject && this.subject.observers.length === 0) {
        this.destroySubject();
      }
    }
  }

  private createSubject(createObservable$: () => Observable<T>) {
    const observable$ = createObservable$();
    this.subject = new ReplaySubject<T>(1);
    this.subjectSubscription = observable$.subscribe(this.subject);
  }

  private destroySubject() {
    this.subjectSubscription.unsubscribe();
    this.subjectSubscription = null;
    this.observable$ = null;
    this.subject = null;
  }
};
