<span class="spinner-container" *ngIf="!item">
  <app-spinner class="absolute horizontal"></app-spinner>
</span>

<table class="lister-table expanded-content-table" *ngIf="item">
  <thead>
    <tr>
      <th class="img">Foto</th>
      <th class="configuration">Configuratie</th>
      <th class="date-list">
        Inzethistorie
        <span *ngIf="item.deployCount && item.deployCount > 0">
          ({{item.deployCount}}x)
        </span>
      </th>
      <th class="date-list">
        Onderhoudshistorie
        <span *ngIf="item.maintenanceCount && item.maintenanceCount > 0">
          ({{item.maintenanceCount}}x)
        </span>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td class="img">
        <div class="image-container">
          <img [src]="item.photoBase64 ? ('data:' + item.photoMimeType + ';base64,' + item.photoBase64) : '/assets/images/medipoint-logo-tiny.png'" />
        </div>
      </td>
      <td class="configuration">
        <span class="tablet-small-label">
          Configuratie:
        </span>
        --
      </td>
      <td class="date-list action-history">
        <span class="tablet-small-label">
          Inzethistorie<span *ngIf="item.deployCount && item.deployCount > 0">({{item.deployCount}}x)</span>:
        </span>

        <span *ngIf="!item.deployHistory || item.deployHistory.length === 0">--</span>

        <table *ngIf="item.deployHistory && item.deployHistory.length > 0" class="sub-table">
          <thead>
            <tr>
              <th>Actie</th>
              <th>Datum</th>
            </tr>
          </thead>
          <tbody>

            <tr *ngFor="let deployHistoryEntry of item.deployHistory">
              <td>
                <span>{{translateAction(deployHistoryEntry.action)}}</span>
              </td>
              <td>
                <span>{{getDeployHistoryEntryFormattedDate(deployHistoryEntry)}}</span>
              </td>
            </tr>

          </tbody>
        </table>

      </td>

      <td class="date-list maintenence-history">
        <span class="tablet-small-label">
          Onderhoudshistorie <span *ngIf="item.maintenanceCount && item.maintenanceCount > 0">({{item.maintenanceCount}}x)</span>:
        </span>

        <span *ngIf="!item.maintenanceHistory || item.maintenanceHistory.length === 0">--</span>

        <table class="sub-table" *ngIf="item.maintenanceHistory || item.maintenanceHistory.length > 0">
          <thead>
            <tr>
              <th>Actie</th>
              <th>Afspraakdatum</th>
              <th>Afleverdatum</th>
            </tr>
          </thead>
          <tbody>

            <tr *ngFor="let maintenanceHistoryEntry of item.maintenanceHistory">
              <td>
                <span>{{translateAction(maintenanceHistoryEntry.action)}}</span>
              </td>
              <td>
                <span *ngIf="maintenanceHistoryEntry.dateAppointment">{{maintenanceHistoryEntry.dateAppointment | date:'dd-MM-yyyy'}}</span>
                <span *ngIf="!maintenanceHistoryEntry.dateAppointment">--</span>
              </td>
              <td>
                <span *ngIf="maintenanceHistoryEntry.dateDelivered">{{maintenanceHistoryEntry.dateDelivered | date:'dd-MM-yyyy'}}</span>
                <span *ngIf="!maintenanceHistoryEntry.dateDelivered">--</span>
              </td>
            </tr>

          </tbody>
        </table>

      </td>
    </tr>
  </tbody>
</table>
