import { Component, OnInit } from '@angular/core';
import { ContactFormItem, AttachStream } from '../../models/contact-form-item';
import { ContactService } from '../../services/contact.service';
@Component({
  selector: 'app-about-contact-form',
  templateUrl: './about-contact-form.component.html',
  styleUrls: ['./about-contact-form.component.scss']
})
export class AboutContactFormComponent implements OnInit {
  formData: FormData = new FormData();
  formSent: boolean = false;
  attachedFile: File = null;
  attachStream: AttachStream = null;
  constructor(private contactService: ContactService) {}

  ngOnInit() {
    this.formData.append('name', '');
    this.formData.append('email', '');
    this.formData.append('phone', '');
    this.formData.append('question', '');
  }

  handleInput(e: Event) {
    const target: HTMLInputElement = e.target as HTMLInputElement;
    this.formData.set(target.name, target.value);
  }

  toBase64 = (file) =>
    new Promise<any>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  async onFileChange(e: Event) {
    const targetElement = e.target as HTMLInputElement;
    if (targetElement.files.length > 0) {
      this.attachedFile = (e.target as HTMLInputElement).files[0];
      let base64Content: string = await this.toBase64(this.attachedFile);
      base64Content = base64Content.replace(`data:${this.attachedFile.type};base64,`, '');
      this.attachStream = {
        type: this.attachedFile.type,
        filename: this.attachedFile.name,
        content: base64Content
      };
    } else {
      this.attachedFile = null;
      this.attachStream = null;
    }
  }

  isEmail(search: string): boolean {
    var serchfind: boolean;
    var regexp = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    serchfind = regexp.test(search);
    return serchfind;
  }

  isFormValid(): boolean {
    let result: boolean = true;
    this.formData.forEach((val, key) => {
      if (val === '') {
        result = false;
      }
      if (key === 'email' && !this.isEmail(val.toString())) {
        result = false;
      }
    });
    return result;
  }

  sendForm(e: Event): void {
    const formData: ContactFormItem = {
      name: this.formData.get('name').toString(),
      email: this.formData.get('email').toString(),
      telephone: this.formData.get('phone').toString(),
      content: this.formData.get('question').toString(),
      attachStream: this.attachStream
    };
    this.contactService.sendContactForm(formData);
    this.formSent = true;
  }
}
