import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ActivationResult } from '@app/modules/client/models/activation-result';
import { ClientService } from '@app/modules/client/services/client.service';
import { CountyService } from '@app/modules/county/services/county.service';
import { DialogService } from '@app/shared/services/dialog.service';
import * as moment from 'moment';
@Component({
  selector: 'app-client-activation-form',
  templateUrl: './client-activation-form.component.html',
  styleUrls: ['./client-activation-form.component.scss']
})
export class ClientActivationFormComponent implements OnInit, OnDestroy {
  activationForm: UntypedFormGroup;
  countyList: any[] = [];
  isFormBusy = false;
  isCountySelected = false;

  constructor(private countyService: CountyService, private dialogService: DialogService, private clientService: ClientService) {
    this.activationForm = new UntypedFormGroup({
      misaClientId: new UntypedFormControl('', [Validators.required]),
      partialLastName: new UntypedFormControl('', [Validators.required]),
      birthDate: new UntypedFormControl('', [Validators.required]),
      cbsCode: new UntypedFormControl('', [Validators.required]),
      emailAddress: new UntypedFormControl('', [Validators.required, Validators.email]),
      termsAggreed: new UntypedFormControl(false, [Validators.requiredTrue])
    });
  }

  async ngOnInit(): Promise<void> {
    const counties = await this.countyService.getCounties();
    Object.keys(counties).forEach((key) => {
      if (counties[key].clientViewAccess) {
        this.countyList.push({
          cbsCode: counties[key].cbsCode,
          name: counties[key].name,
          clientViewAccess: counties[key].clientViewAccess,
          iWMO: counties[key].iWMO
        });
      }
    });
    this.countyList.sort((a, b) => (a.name > b.name ? 1 : -1));

  }

  ngOnDestroy() { }

  async onSubmit() {
    this.isFormBusy = true;
    const activationRequest = {
      ...this.activationForm.value,
      misaClientId: this.activationForm.value.misaClientId.toString(),
      birthDate: moment(this.activationForm.value.birthDate).toISOString()
    };
    delete activationRequest.termsAggreed;

    let message = '';
    let isSuccess = false;
    let title = 'Invoer ongeldig';
    try {
      const response = await this.clientService.activateClientByForm(activationRequest);
      switch (response.result) {
        case ActivationResult.BIRTHDATE_NOT_MATCH:
          message =
            'De combinatie van uw klantnummer en geboortedatum herkennen wij helaas niet. Kunt u deze gegevens controleren en aanpassen?';
          break;
        case ActivationResult.EMAIL_ALREADY_EXISTS:
          message =
            'Er is al een account geactiveerd voor een andere cliënt op dit e-mailadres. Om uw account te activeren dient u een ander e-mailadres te gebruiken.';
          break;
        case ActivationResult.ACTIVATED:
        case ActivationResult.CREATED:
          title = 'Verzoek ontvangen';
          message = 'Wij hebben uw verzoek ontvangen. U ontvangt van ons een e-mailbericht waarmee u het account kunt activeren.';
          isSuccess = true;
          break;
        case ActivationResult.COUNTY_NOT_MATCH:
          message = 'Controleer of u de juiste gemeente heeft geselecteerd.';
          break;
        case ActivationResult.ALREADY_ACTIVE:
          message = 'Uw account is al geactiveerd.';
          break;
        case ActivationResult.IWMO_NOT_ALLOW_CHANGE_ORDER:
          message = 'Het e-mail adres wat door u is ingevuld is anders dan het bij ons bekende e-mail adres, neem contact op met uw Wmo vestiging om uw e-mail adres te wijzigen.';
          break;
      }
    } catch (error) {
      title = 'Foutmelding';
      message = 'Er is iets mis gegaan.';
    }

    this.isFormBusy = false;

    if (!isSuccess) {
      this.dialogService.openErrorAlert(title, message);
    } else {
      this.activationForm.reset();
      this.dialogService.openInfoAlert(title, message);
    }
  }
}
