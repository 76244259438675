<div class="general-form-header">
  <h1>Gemeente {{ countyForm.value.name }}</h1>
</div>

<form [formGroup]="countyForm">
  <div class="general-form-container">
    <div class="row">
      <div class="input-container" [ngClass]="{ invalid: countyForm.controls.name.touched && countyForm.controls.name.invalid }">
        <app-error-messages [formControls]="countyForm.controls.name"></app-error-messages>
        <input type="text" formControlName="name" name="name" id="name" required />
        <label>Naam</label>
      </div>

      <div class="input-container" [ngClass]="{ invalid: countyForm.controls.cbsCode.touched && countyForm.controls.cbsCode.invalid }">
        <app-error-messages [formControls]="countyForm.controls.cbsCode"></app-error-messages>
        <input type="text" formControlName="cbsCode" name="cbsCode" id="cbsCode" required />
        <label>CBS nummer</label>
      </div>
    </div>

    <div class="low-row">
      <div
        class="input-container"
        [ngClass]="{ invalid: countyForm.controls.emailAddress.touched && countyForm.controls.emailAddress.invalid }"
      >
        <app-error-messages [formControls]="countyForm.controls.emailAddress"></app-error-messages>
        <input type="text" formControlName="emailAddress" name="emailAddress" id="emailAddress" />
        <label>Email adres</label>
      </div>

      <div
        class="input-container"
        [ngClass]="{ invalid: countyForm.controls.phoneNumber.touched && countyForm.controls.phoneNumber.invalid }"
      >
        <app-error-messages [formControls]="countyForm.controls.phoneNumber"></app-error-messages>
        <input type="text" formControlName="phoneNumber" name="phoneNumber" id="phoneNumber" />
        <label>Telefoonnummer</label>
      </div>
    </div>
  </div>

  <div class="general-form-container">
    <div class="general-form-subheader">
      <h2>Adres</h2>
    </div>

    <app-address-autocomplete [addressForm]="countyForm.controls.address"></app-address-autocomplete>
  </div>

  <div class="general-form-container no-padding-bottom">
    <div class="row checkboxes low-row">
      <div class="checkbox-container">
        <input formControlName="iWMO" type="checkbox" name="iWMO" id="iWMO" />
        <label for="iWMO">iWmo</label>
      </div>
      <div class="checkbox-container">
        <input
          formControlName="socialSecurityNumberRequired"
          type="checkbox"
          name="socialSecurityNumberRequired"
          id="socialSecurityNumberRequired"
        />
        <label for="socialSecurityNumberRequired">BSN nummer verplicht</label>
      </div>
      <div class="checkbox-container">
        <input formControlName="clientViewAccess" type="checkbox" name="clientViewAccess" id="clientViewAccess" />
        <label for="clientViewAccess">Inzage door cli&euml;nten toestaan</label>
      </div>
      <div class="checkbox-container">
        <input
          formControlName="clientModificationRequest"
          type="checkbox"
          name="clientModificationRequest"
          id="clientModificationRequest"
        />
        <label for="clientModificationRequest">Aanpassingsverzoeken door cli&euml;nten toestaan</label>
      </div>
      <div class="checkbox-container">
        <input formControlName="clientPickUpRequest" type="checkbox" name="clientPickUpRequest" id="clientPickUpRequest" />
        <label for="clientPickUpRequest">Ophaalverzoeken door cli&euml;nten toestaan</label>
      </div>
      <div class="checkbox-container">
        <input formControlName="clientRepairRequest" type="checkbox" name="clientRepairRequest" id="clientRepairRequest" />
        <label for="clientRepairRequest">Reparatieverzoeken door cli&euml;nten toestaan</label>
      </div>
      <div class="checkbox-container">
        <input
          formControlName="orderReferenceNumberRequired"
          type="checkbox"
          name="orderReferenceNumberRequired"
          id="orderReferenceNumberRequired"
        />
        <label for="orderReferenceNumberRequired">Order referentienummer verplicht</label>
      </div>
      <div class="checkbox-container">
        <input formControlName="hasDepot" type="checkbox" name="hasDepot" id="hasDepot" />
        <label for="hasDepot">Heeft een depot</label>
      </div>
    </div>
  </div>

  <div class="general-form-list" *ngIf="isAdmin">
    <div class="general-form-list-header">
      <h2 class="required">Contract</h2>
    </div>

    <div class="input-icon-container">
      <mat-icon>search</mat-icon>
      <input matInput (keyup)="filterContracts($event.target.value)" placeholder="Zoek" type="text" />
    </div>

    <table
      class="form-mat-table"
      id="contracts-table"
      [ngClass]="{ invalid: countyForm.controls.contractId.touched && countyForm.controls.contractId.invalid }"
      mat-table
      [dataSource]="contractsTable"
    >
      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell class="radio-checkbox-cell" *matHeaderCellDef></th>
        <td mat-cell class="radio-checkbox-cell" *matCellDef="let row">
          <input
            type="radio"
            name="contractId"
            id="{{ row.contractId }}"
            formControlName="contractId"
            value="{{ row.contractId }}"
            (change)="changeContractValidateRequest(row.contractId)"
          />
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Naam</th>
        <td mat-cell *matCellDef="let row">
          <label for="{{ row.contractId }}">{{ row.name }}</label>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="contractsTableColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: contractsTableColumns"></tr>
    </table>

    <mat-paginator #contractsPaginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
  </div>
</form>
<!--notification settings-->
<app-county-notification-settings *ngIf="!isAdmin" [countyId]="countyId"></app-county-notification-settings>

<!--employees table-->
<div class="general-form-list">
  <div class="general-form-list-header">
    <h2>Medewerkers</h2>
  </div>

  <div class="input-icon-container margin-bottom mobile-only">
    <mat-icon>search</mat-icon>
    <input matInput [(ngModel)]="employeesTableFilters.general" (keyup)="filterEmployees('general')" placeholder="Zoek" type="text" />
  </div>

  <table
    id="employees"
    class="lister-table employees"
    [class.show-header-search]="employeeColumnSearch"
    mat-table
    [dataSource]="employeesTable"
    multiTemplateDataRows
    matSort
    matSortActive="fullName"
    matSortDirection="asc"
    matSortDisableClear
  >
    <ng-container matColumnDef="fullName">
      <th mat-header-cell *matHeaderCellDef class="fullName">
        <span class="th-header">
          <span mat-sort-header>Naam</span>
          <mat-icon *ngIf="!employeeColumnSearch" class="toggle-search" (click)="toggleEmployeesColumnSearch()"> expand_more</mat-icon>
          <mat-icon *ngIf="employeeColumnSearch" class="toggle-search" (click)="toggleEmployeesColumnSearch()"> expand_less</mat-icon>
        </span>
        <div class="input-icon-container">
          <mat-icon>search</mat-icon>
          <input
            matInput
            [(ngModel)]="employeesTableFilters.fullName"
            (keyup)="filterEmployees('fullName')"
            placeholder="Zoek"
            type="text"
          />
        </div>
      </th>
      <td mat-cell class="fullName" *matCellDef="let row">
        {{ row.fullName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="emailAddress">
      <th mat-header-cell *matHeaderCellDef class="email">
        <span class="th-header">
          <span mat-sort-header> Email adres </span>
          <mat-icon *ngIf="!employeeColumnSearch" class="toggle-search" (click)="toggleEmployeesColumnSearch()"> expand_more</mat-icon>
          <mat-icon *ngIf="employeeColumnSearch" class="toggle-search" (click)="toggleEmployeesColumnSearch()"> expand_less</mat-icon>
        </span>
        <div class="input-icon-container">
          <mat-icon>search</mat-icon>
          <input
            matInput
            [(ngModel)]="employeesTableFilters.emailAddress"
            (keyup)="filterEmployees('emailAddress')"
            placeholder="Zoek"
            type="text"
          />
        </div>
      </th>
      <td mat-cell *matCellDef="let element" class="email">{{ element.emailAddress }}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef class="status">
        <span class="th-header">
          <span mat-sort-header> Status </span>
          <mat-icon *ngIf="!employeeColumnSearch" class="toggle-search" (click)="toggleEmployeesColumnSearch()"> expand_more</mat-icon>
          <mat-icon *ngIf="employeeColumnSearch" class="toggle-search" (click)="toggleEmployeesColumnSearch()"> expand_less</mat-icon>
        </span>
        <div class="checkboxes-container">
          <div class="checkbox-container" *ngFor="let key of Object.keys(employeesTableFilters.status)">
            <input
              [(ngModel)]="employeesTableFilters.status[key]"
              (change)="filterEmployees('status')"
              value="filter-employees-status-{{ key }}"
              type="checkbox"
              id="filter-employees-status-{{ key }}"
            />
            <label for="filter-employees-status-{{ key }}">{{ statusTranslations[key] }}</label>
          </div>
        </div>
      </th>
      <td mat-cell *matCellDef="let element" class="status">
        <span [class.text-color-primary]="element.archived" [class.text-color-cta]="element.new">{{ element.status }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="roles">
      <th mat-header-cell *matHeaderCellDef class="roles">
        <span class="th-header">
          <span mat-sort-header="rolesString"> Roll(en) </span>
          <mat-icon *ngIf="!employeeColumnSearch" class="toggle-search" (click)="toggleEmployeesColumnSearch()"> expand_more</mat-icon>
          <mat-icon *ngIf="employeeColumnSearch" class="toggle-search" (click)="toggleEmployeesColumnSearch()"> expand_less</mat-icon>
        </span>
        <div class="checkboxes-container">
          <div class="checkbox-container" *ngFor="let key of Object.keys(employeesTableFilters.roles)">
            <input
              [(ngModel)]="employeesTableFilters.roles[key]"
              (change)="filterEmployees('status')"
              (value)="(key)"
              type="checkbox"
              id="{{ key }}"
            />
            <label for="{{ key }}">{{ translateRole(key) }}</label>
          </div>
        </div>
      </th>
      <td mat-cell *matCellDef="let element" class="roles">
        {{ element.rolesString }}
      </td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef class="edit"></th>
      <td mat-cell *matCellDef="let element" class="edit cta-td">
        <button (click)="editEmployeeThroughDialog(element)" class="small-cta">Wijzigen</button>
      </td>
    </ng-container>

    <tr class="table-header-row" mat-header-row *matHeaderRowDef="employeesTableColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: employeesTableColumns" id="employee-row-{{ row.userId }}"></tr>
  </table>

  <mat-paginator #employeesPaginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

  <div class="button-container-below-paginator">
    <button class="cta" id="add-employee-button" (click)="addEmployeeThroughDialog()" [disabled]="!countyId">
      + Medewerkers toevoegen
    </button>
  </div>

  <div *ngIf="!countyId" class="info">Gebruikers kunnen worden toegevoegd aan de gemeente nadat de gemeente is aangemaakt.</div>
</div>

<div id="general-form-actions" [ngClass]="{ fixed: footerFixed }">
  <div class="general-form-actions-container">
    <button class="secondary-cta" routerLink="/counties">Annuleren</button>
    <button class="cta" (click)="submitForm()" *ngIf="isAdmin">Opslaan</button>
  </div>
</div>

<div id="general-form-actions-placeholder" [ngClass]="{ fixed: footerFixed }"></div>

<app-spinner *ngIf="showCountySpinner"></app-spinner>
