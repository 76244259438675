import {Config} from '@app/shared/models/config';
import {FirestoreObservableDocument} from '@app/shared/util/firestore-observable-document';
import * as util from '@app/shared/util';

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import { Reason } from '../models/reason';
import { AngularFirePerformance } from '@angular/fire/compat/performance';
import { trace } from '@angular/fire/compat/performance';

@Injectable({providedIn: 'root'})
export class ConfigService {

  config$: Observable<Config>;

  constructor(
    firestore: AngularFirestore,
    private performance: AngularFirePerformance
  ) {
    const document = new FirestoreObservableDocument<Config>(firestore, {
      documentPath: ['config', 'frontend']
    });

    document.setCleanup(false);

     this.config$ = document.observable$.pipe(trace('ConfigService.config$'));
  }

  async isSiteUnderMaintenance(): Promise<boolean> {
    return !!(await this.getConfig()).siteIsUnderMaintenance;
  }

  async getAllowedGoogleDomains(): Promise<Array<string>> {
    return (await this.getConfig()).allowedGoogleDomains;
  }

  async getFooterLinks(): Promise<object> {
    return (await this.getConfig()).footerLinks;
  }

  async getHolidays(): Promise<Array<any>>{
    return (await this.getConfig()).holidays;
  }

  async getConfig(): Promise<Config> {
    return util.takeOneAsPromise(this.config$);
  }
  async getWorkDayHour(): Promise<any>{
    return (await this.getConfig()).hours;
  }
  async getReason(): Promise<Reason[]>{
    return (await this.getConfig()).reasons;
  }
}
